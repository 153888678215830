import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { SelectBox, DateBox, LoadPanel, TextBox, CheckBox, TextArea,FileUploader,Button,LoadIndicator, } from "devextreme-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LookupService, { LookupTypeItem, LookupTypeIndexes } from "../../../services/LookupService";
import MatrixGridService from "../../../services/MatrixService";
import sharedUtils from "../../grid/sharedUtilities";
import {
    TextBoxOnValueChangedEvent,
    DateBoxOnValueChangedEvent,
    CheckBoxOnValueChanged,
    onKeyDownEvent,
    SelectBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    FileUploaderOnValueChangedEvent,
    FileUploadOnInitializedEvent
} from "../../../types/DevExtremeTypes";
import BulletinFormUtil, { BulletinFormData } from "./BulletinFormUtils";
import LookupTypeListDynamic from "../../select/LookupTypeListDynamic";
import { isValidImageFileType, isValidDocumentFileType } from '../../../common/CommonUIUtils';
import ArtistService, {OtherType} from "../../../services/ArtistService";

type NullableDate = Date | null | undefined;
//#region props
interface BulletinFormProps {
    location: any;
    history: any;
    onApplySuccess: (applySignal: string) => void;
}

// State
interface BulletinFormState {
    categoryLookup: LookupTypeItem[];
    bulletinFormData: BulletinFormData;
    priorityLookup: LookupTypeItem[];
    loadPanelVisible: boolean;
    categoryLoaded: boolean;
    priorityLoaded: boolean;
    errorMessage: any[];
    audienceLookup: LookupTypeItem[];
    companyLookup: LookupTypeItem[];
    serviceTypeLookup: LookupTypeItem[];
    isProfilePhotoAdded: boolean;
    profilePhotoHolder: string;
    selectedProfileFile: File[];
    selectedProfileFileContent: string;
    loadIndicatorVisibleForProfileUpload:boolean;
}

class BulletinForm extends React.Component<BulletinFormProps> {
    lookupService: LookupService;
    state: BulletinFormState;
    utils: BulletinFormUtil;
    sharedUtils: sharedUtils;
    matrixService: MatrixGridService;
    fileUploadElementForProfilePhoto: any;

    constructor(props: BulletinFormProps) {
        super(props);
        this.lookupService = new LookupService();
        this.utils = new BulletinFormUtil();
        this.sharedUtils = new sharedUtils();
        this.matrixService = new MatrixGridService();
        var convertedData = this.utils.initializeBulletinFormItem(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.state = {
            categoryLookup: [],
            bulletinFormData: convertedData,
            priorityLookup: [],
            loadPanelVisible: false,
            categoryLoaded: false,
            priorityLoaded: false,
            errorMessage: [],
            audienceLookup: [],
            companyLookup: [],
            serviceTypeLookup: [],
            isProfilePhotoAdded:false,
            profilePhotoHolder:'',
            selectedProfileFile: [],
            selectedProfileFileContent: "",
            loadIndicatorVisibleForProfileUpload:false
        };
        this.fileUploadElementForProfilePhoto = undefined;
    }

    componentDidMount() {
        this.retrieveLookupValues(LookupTypeIndexes.bulletincategory);
        this.retrieveLookupValues(LookupTypeIndexes.bulletinpriority);
        this.retrieveLookupValues(LookupTypeIndexes.userRoleType);
        this.retrieveLookupValues(LookupTypeIndexes.userCompanyType);
        this.retrieveLookupValues(LookupTypeIndexes.serviceType);
        this.getBulletinDataById();
    }

    // Helper function for fetching data
    retrieveLookupValues = (lookupTypeIndex: string) => {
        switch (lookupTypeIndex) {
            case LookupTypeIndexes.bulletincategory:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.bulletincategory)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            categoryLookup: response.data.data,
                            categoryLoaded: true,
                        })
                    )
                    .catch((error) =>
                        this.setState({
                            categoryLookup: [],
                            categoryLoaded: true,
                        })
                    );
                break;
            case LookupTypeIndexes.bulletinpriority:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.bulletinpriority)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            priorityLookup: response.data.data,
                            priorityLoaded: true,
                        })
                    )
                    .catch((error) =>
                        this.setState({
                            priorityLookup: [],
                            priorityLoaded: true,
                        })
                    );
                break;
            case LookupTypeIndexes.userRoleType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.userRoleType)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            audienceLookup: this.sharedUtils.dropdDownincludeAll(response.data.data)
                        })
                    )
                    .catch(() =>
                        this.setState({
                            audienceLookup: []
                        })
                    );
                break;
            case LookupTypeIndexes.userCompanyType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.userCompanyType)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            companyLookup: response.data.data
                        })
                    )
                    .catch(() =>
                        this.setState({
                            companyLookup: []
                        })
                    );
                break;
            case LookupTypeIndexes.serviceType:
                this.lookupService
                    .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
                    .then((response: AxiosResponse<any>) =>
                        this.setState({
                            serviceTypeLookup: this.sharedUtils.dropdDownincludeAll(response.data.data)
                        })
                    )
                    .catch(() =>
                        this.setState({
                            serviceTypeLookup: []
                        })
                    );
                break;
        }
    };

    getBulletinDataById = () => {
        if (this.props.location.state && this.props.location.state.id) {
            this.setState({
                loadPanelVisible: true,
            });
            this.matrixService
                .getBulletinFormData(this.props.location.state.id)
                .then(this.handleSuccess)
                .catch(this.handleStandardError);
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        var data = this.utils.convertBulletinResponeToFormData(response.data.data);
        this.setState({
            bulletinFormData: data,
            profilePhotoHolder:data.link,
            //loadPanelVisible: false,
        },()=>{
            setTimeout( () => {
                this.setState( prevState => ({
                    loadPanelVisible: false
                }));
              }, 1000);
            
        });
    };

    handleStandardError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleChangeCategory = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinCategoryId: dxValueChange.value,
            },
        });
    };

    handleChangePriority = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinPriorityId: dxValueChange.value,
            },
        });
    };

    handleChangeAudience = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                userRoleID: dxValueChange.value,
            },
        });
    };

    handleChangeCompany = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                businessEntityID: dxValueChange.value,
            },
        });
    };

    handleChangeService = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                serviceTypeID: dxValueChange.value,
            },
        });
    };

    handleChangeTitle = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinTitle: dxValueChange.value,
            },
        });
    };

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinName: dxValueChange.value,
            },
        });
    };

    handleChangeBulletinDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinDate: dxValueChange.value,
            },
        });
    };

    handleChangeValidFrom = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                validFrom: dxValueChange.value,
            },
        });
    };

    handleChangeValidTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                validTo: dxValueChange.value,
            },
        });
    };

    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                bulletinDescription: dxValueChange.value,
            },
        });
    };

    handleChangeActive = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                isActive: dxValueChange.value,
            },
        });
    };

    handleChangePopup = (dxValueChange: CheckBoxOnValueChanged) => {
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                isPopUpRequired: dxValueChange.value,
            },
        });
    };

    onApplyClick = () => {
        this.setState({
            loadPanelVisible: true,
        });
        var { bulletinFormData } = this.state;
        var bulletinSubmitRequest = this.utils.convertFormDataToBulletinRequest(bulletinFormData);
        this.matrixService
            .postBulletinFormData(bulletinSubmitRequest)
            .then(this.handleSuccessDataSubmission)
            .catch((err: any) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    loadPanelVisible: false,
                });
            });
    };

    //A helper function to display the error message, might move this to a common utils or something.
    renderErrorMessage = (errorMessage: any[]) => {
        let errorData: React.ReactNode = <></>;
        if (errorMessage) {
            errorData = (
                <span className="unscheduled-shift">
                    <ul>
                        {errorMessage.map((item: any, uniqueKey: number) => {
                            return (
                                <li key={uniqueKey}>
                                    {item.columnName}: {item.errorMessage}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            );
        }
        return errorData;
    };

    //A helper function that will prevent exponential "e" from being entered into the numberBox.
    handleKeyPressEvent = (e: onKeyDownEvent) => {
        if (e.event.key == "e") {
            e.event.preventDefault();
        }
    };

    handleSuccessDataSubmission = () => {
        this.setState({
            loadPanelVisible: false,
        });
        if (this.props.location.state && this.props.location.state.id) {
            this.props.onApplySuccess("EDIT");
        } else if (this.state.bulletinFormData.id == "0") {
            this.props.onApplySuccess("ADD");
        }
    };

    //#region Profile Photo
    onProfilePhotoUploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForProfilePhoto = e.component;
    };

    onValueChangedForProfilePhoto = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedProfileFileContent: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isProfilePhotoAdded: true,
                    selectedProfileFile: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };

    onCancelProfilePhoto = () => {
        this.setState({
            isProfilePhotoAdded: false,
            selectedProfileFileContent: "",
        });
    };
    onSubmitProfilePhoto = () => {
            this.setState({
                loadIndicatorVisibleForProfileUpload: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.BulletinPhoto);
            formData.append("files[]", this.state.selectedProfileFile[0], this.state.selectedProfileFile[0].name);
            //formData.append('BusinessEntityId',this.props.location?.state?.id ? this.props.location.state.id : '')

            this.matrixService
                .uploadBulletinPhoto(formData)
                .then(this.handleProfilePhotoUploadSuccess)
                .catch(this.handleProfilePhotoUploadUploadFailure);
    };
    handleProfilePhotoUploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForProfilePhoto.reset();
        this.setState({
            bulletinFormData: {
                ...this.state.bulletinFormData,
                linkId: response.data.data.id,
            },
            profilePhotoHolder: response.data.data.displayLink,
            loadIndicatorVisibleForProfileUpload: false,
            isProfilePhotoAdded: false,
        });
        // Reset the file uploader array
        this.setState({
            selectedProfileFile: [],
        });
    };
    handleProfilePhotoUploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForProfileUpload: false,
            isProfilePhotoAdded: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Profile Photo Upload failed with response: " + JSON.stringify(response);

        // if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
        //     // AppInsights is not available
        //     console.error(respMessage);
        // }
    };
    //#region render Component
    render() {
        var {
            errorMessage,
            categoryLookup,
            priorityLookup,
            categoryLoaded,
            priorityLoaded,
            bulletinFormData,
            audienceLookup,
            serviceTypeLookup
        } = this.state;
        return (
            <form data-testid="addEditShift-form" className="edit-form my-5 form_border row">
                <div className="container mb-6 edit-form__column col-9">
                    <h2 className="billingForm-heading mt-3">
                        <strong>Bulletin Form</strong>
                    </h2>

                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Category</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {categoryLoaded && (
                                <SelectBox
                                    dataSource={categoryLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeCategory}
                                    value={this.state.bulletinFormData.bulletinCategoryId}
                                />
                            )}
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Priority</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {priorityLoaded && (
                                <SelectBox
                                    dataSource={priorityLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangePriority}
                                    value={this.state.bulletinFormData.bulletinPriorityId}
                                />
                            )}
                        </div>
                        <div className="mt-3 col-10 col-lg-3">
                            <CheckBox
                                value={this.state.bulletinFormData.isActive}
                                onValueChanged={this.handleChangeActive}
                                width={130}
                                text="Active?"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Audience</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {categoryLoaded && (
                                <SelectBox
                                    dataSource={audienceLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeAudience}
                                    value={this.state.bulletinFormData.userRoleID}
                                />
                            )}
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Company</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {priorityLoaded && (
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.userCompanyType}
                                    onValueChanged={this.handleChangeCompany}
                                    isRoot={false}
                                    parentMappingId={""}
                                    value={this.state.bulletinFormData.businessEntityID}
                                    displayExpression={"value"}
                                    itemAll={true}
                                    isDisabled={false}
                                />
                            )}
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Service</div>
                        <div className="mt-3 col-10 col-lg-3">
                            {priorityLoaded && (
                                <SelectBox
                                    dataSource={serviceTypeLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeService}
                                    value={this.state.bulletinFormData.serviceTypeID}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Name</div>
                        <div className="mt-3 col-10 col-lg-7">
                            <TextBox
                                value={this.state.bulletinFormData.bulletinName}
                                onValueChanged={this.handleChangeName}
                            />
                        </div>
                        <div className="mt-3 col-10 col-lg-3">
                            <CheckBox
                                value={this.state.bulletinFormData.isPopUpRequired}
                                onValueChanged={this.handleChangePopup}
                                width={130}
                                text="Pop Up?"
                            />
                        </div>
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Title</div>
                        <div className="mt-3 col-lg-11">
                            <TextBox
                                value={this.state.bulletinFormData.bulletinTitle}
                                onValueChanged={this.handleChangeTitle}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {bulletinFormData.isPopUpRequired && (
                            <>
                                <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Pop Up Description</div>
                                <div className="mt-3 col-lg-11">
                                    <TextArea
                                        value={this.state.bulletinFormData.bulletinDescription}
                                        height={100}
                                        onValueChanged={this.handleChangeDescription}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Bulletin Date</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeBulletinDate}
                                value={
                                    this.state.bulletinFormData.bulletinDate
                                        ? this.state.bulletinFormData.bulletinDate
                                        : undefined
                                }
                                useMaskBehavior={true}
                            />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Valid From</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeValidFrom}
                                value={
                                    this.state.bulletinFormData.validFrom
                                        ? this.state.bulletinFormData.validFrom
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Valid To</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                onValueChanged={this.handleChangeValidTo}
                                value={
                                    this.state.bulletinFormData.validTo
                                        ? this.state.bulletinFormData.validTo
                                        : undefined
                                }
                                useMaskBehavior={true}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">ID</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.bulletinFormData.id} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated On</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.bulletinFormData.lastUpdatedOn} readOnly={true} />
                        </div>
                        <div className="mt-3 col-2 col-lg-1 font-weight-bold font_size">Last Updated By</div>
                        <div className="mt-3 col-10 col-lg-3">
                            <TextBox value={this.state.bulletinFormData.lastUpdatedBy} readOnly={true} />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    {this.renderErrorMessage(errorMessage)}
                    <div className="row mr-auto ml-3">
                        <div className="col-12 col-lg-2 mb-3">
                            <Link
                                to={{
                                    pathname: "/matrixView",
                                    state: {
                                        isCancel: true,
                                    },
                                }}
                                className="btn btn--ghost btn--large"
                            >
                                <span className="btn__icon">
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                                Cancel
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 mb-3">
                            <button
                                className="btn btn-primary btn--large"
                                type="button"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onApplyClick();
                                }}
                            >
                                <span className="btn__icon"></span>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="artist-profile__primary-image__container mb-4">
                        <div className="artist-profile__primary-image artist-profile__primary-image--edit-mode" style={{ marginTop: '0px' }}>
                            {this.state.isProfilePhotoAdded == false ? (
                                <>
                                    <img
                                        src={
                                            this.state.profilePhotoHolder
                                                ? this.state.profilePhotoHolder
                                                : "/assets/images/event-UK-image.jpeg"
                                        }
                                    />
                                    <FileUploader
                                        className="multi-image-banner__image-upload-button"
                                        selectButtonText="Select Image"
                                        uploadMode="useForm"
                                        showFileList={false}
                                        onValueChanged={this.onValueChangedForProfilePhoto}
                                        onInitialized={this.onProfilePhotoUploadInitialize}
                                    ></FileUploader>
                                </>
                            ) : (
                                <>
                                    <img src={this.state.selectedProfileFileContent} />
                                    <Button
                                        className="btn btn-primary artist-profile__image-preview-upload-button"
                                        onClick={this.onSubmitProfilePhoto}
                                    >
                                        <LoadIndicator
                                            className="artist-profile__image-preview-loader--header-image"
                                            // className="load-indicator--centered"
                                            height={30}
                                            width={30}
                                            visible={
                                                this.state.loadIndicatorVisibleForProfileUpload
                                            }
                                        />
                                        <span>Upload</span>
                                    </Button>
                                    <Button
                                        className="btn artist-profile__image-preview-cancel-button"
                                        onClick={this.onCancelProfilePhoto}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-1"></div>
            </form>
        );
    }
}

export default BulletinForm;
