import React, { Component, MouseEvent, ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UserService, { RoleGroupNames } from "../../services/UserService";
import RateMatrixGrid from "../Matrix/RateMatrixGrid";
import PeriodMatrixGrid from "../Matrix/PeriodMatrixGrid";
import PeriodMatrixForm from "../Matrix/PeriodMatrixForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/pro-regular-svg-icons";
import ContractCleaningGrid from "../ContractCleaning/ContractCleaningGrid";
import ContractActionForm from "../ContractCleaning/ContractActionForm";
import ResidencyGrid from "../Residencies/ResidencyGrid";
import ResidencyForm from "../Residencies/ResidencyForm";
import LightModePageTemplate from "./Templates/LightModeTemplate";
import { venueservice } from "../../services/VenueHierarachyService";
import VenueHierarchyGrid from "../VenueHierarchy/VenueHierarchyGrid";
import { feeService } from "../../services/ServiceFeesService";
import ServiceFeeGrid from "../ServiceFeeMatrix/ServiceFeeGrid";
import ServiceFeeForm from "../ServiceFeeMatrix/ServiceFeeForm";
import { providerservice } from "../../services/VenueProviderService";
import ProviderServiceGrid from "../ProviderService/ProviderServiceGrid";
import ProviderServiceForm from "../ProviderService/ProviderServiceForm";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import ManageOurServicesPanel from "../side-bar/ManageOurServicesPanel";
import { TypeOfFileUploaded } from "../../services/BillableItemHeaderService";
import UploadSchedulePage from "./UploadSchedulePage";
import { uploadedFileType } from "../../services/FileService";
import LicenseChecksPage from "../LicenseChecks/LicenseChecksPage";
import ShiftImportWarnings from "../ShiftImportWarnings/ShiftImportWarnings";
import Budget from "../BudgetUpload/Budget";
import BulletinForm from "../BulletinPanel/BulletinAuthoring/BulletinForm";
import BulletinGrid from "../BulletinPanel/BulletinAuthoring/BulletinGrid";
import UserView from "../UserView/UserViewGrid";
import ArtistGrid from "../FindAnArtist/ArtistGrid";
import ContactGrid from "../ContactInformation/ContactGrid"
import VenueGrid from "../ContactInformation/VenueGrid"
import ServiceTypesContainer from "../ServiceTypes/ServiceTypesContainer";
import FAQGrid from "../FAQ/FAQGrid";
import FAQForm from "../FAQ/FAQForm";
import GlobalService from "../../services/GlobalService";
import { Button, Popup, ScrollView } from "devextreme-react";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent"
import AddContactComponent from "../ContactInformation/AddContactComponent";
import AddVenueComponent from "../ContactInformation/AddVenueComponent";
import { confirm } from "devextreme/ui/dialog";
import PayDatesGrid from '../Matrix/PayDates';
import ProviderUploadMappingsGrid from "../Matrix/ProviderUploadMappingsGrid";
import ClientUploadMappingsGrid from "../Matrix/ClientUploadMappingsGrid";

interface MatrixPageComponentProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface MatrixBACSPageComponentState {
    displayPeriodMatrix: boolean;
    displayRateMatrix: boolean;
    id: string;
    refreshSignal: boolean;
    applySuccessful: string;
    isAddClick: boolean;
    isFormViewAfterSuccess: boolean;
    filterText: string;
    editSubForm: boolean;
    showSubstituteChildGrid: boolean;
    eventUKSMIndicator: boolean;
    closeSidebar: boolean;
    popupVisible: boolean;
    companyPopupVisible: boolean;
    artistAdded: string;
    contactPopupVisible: boolean;
    contactAdded: string;
    contactType: string;
    venuePopupVisible: boolean;
    venueAdded: string;
    isClickArtistSearch: boolean;
    artistSearchField: string;
}

class MatrixPage extends Component<MatrixPageComponentProps> {
    //Initialize the component's state.
    state: MatrixBACSPageComponentState;
    isEditMode: string;
    constructor(props: MatrixPageComponentProps) {
        super(props);
        this.isEditMode = "";
        this.state = {
            displayPeriodMatrix: false,
            displayRateMatrix: false,
            id: "",
            refreshSignal: false,
            applySuccessful: "",
            isAddClick: false,
            isFormViewAfterSuccess: false,
            filterText: "",
            editSubForm: false,
            showSubstituteChildGrid: true,
            closeSidebar: false,
            popupVisible: false,
            companyPopupVisible:false,
            artistAdded: "",
            contactPopupVisible: false,
            contactAdded: "",
            contactType: "",
            venuePopupVisible: false,
            venueAdded: "",
            isClickArtistSearch: false,
            artistSearchField: "",
            eventUKSMIndicator: UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? true : false,
        };
        this.displayPeriodMatrix = this.displayPeriodMatrix.bind(this);
        this.displayRateMatrix = this.displayRateMatrix.bind(this);
        this.onApplySuccess = this.onApplySuccess.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.sendEditFormSignal = this.sendEditFormSignal.bind(this);
    }
    componentDidMount(): void {
        if(GlobalService.getGridType() == "ResidencyShifts"){
            this.setState({ filterText: "Entertainment"});
        }
        else if(GlobalService.getGridType() == "ContractCleaningShifts"){
            this.setState({ filterText: "ContractCleaning"});
        }
        GlobalService.removeGridType();
    }
    displayPeriodMatrix = () => {
        this.setState({
            displayPeriodMatrix: !this.state.displayPeriodMatrix,
            displayRateMatrix: false,
        });
    };
    displayRateMatrix = () => {
        this.setState({
            displayRateMatrix: !this.state.displayRateMatrix,
            displayPeriodMatrix: false,
        });
    };
    setFormView() {
        this.setState({
            isFormViewAfterSuccess: false,
        });
    }
    onApplySuccess(applySignal: string) {
        // This refresh signal will toggle between true and false to indicate a refresh is required.
        if (this.props.location.state) {
            this.props.location.state = null;
        }
        this.setState({
            refreshSignal: !this.state.refreshSignal,
            applySuccessful: applySignal,
            isAddClick: applySignal == "ADD" ? true : applySignal == "EDIT" ? false : null,
            showSubstituteChildGrid: false,
        });
    }
    onAddClick() {
        if (this.props.location.state) {
            this.props.location.state = null;
        }
        this.setState({ isAddClick: true, applySuccessful: "" });
    }

    setFilter = (service: string) => {
        this.setState((prevState: MatrixBACSPageComponentState) => ({
            closeSidebar: !prevState.closeSidebar,
            filterText: service,
            companyPopupVisible: UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) && service == 'ViewCompany' ? true : false,
            //artistAdded : service == 'ViewClients' ? 'false' : ''
        }));
        // if(service == 'ViewClients'){
        //     this.setState({
        //         artistAdded :'false'
        //     })
        // }
    };

    setDate() { } //Anonymous functions as "dummy" props

    sendEditFormSignal(editFormSignal: boolean) {
        this.setState({
            editSubForm: true,
        });
    }

    addArtistPopup = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hideArtistPopup = (e: any) => {
        e.cancel = true;
        this.setState({
            popupVisible: false,
        });
    };

    handleAddArtist = (isAdded: string) => {
        this.setState({ 
            artistAdded: isAdded,
            popupVisible: false,
        });
    }
    hideCompanyPopup = (e:any)=>{
        e.cancel = true;
        this.setState({
            companyPopupVisible: false
        });
        
    }

    handleCompanyPopupClose = (flag: boolean, editmode: string) => {
        this.setState({
            companyPopupVisible: flag
        });
        
    }

    addContactPopup = (type: string) => { 
        this.setState({
            contactPopupVisible: true,
            contactType: type,
        });
    };

    hideContactPopup = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        contactPopupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                contactPopupVisible: false,
            });
        }
        this.setState({
            contactPopupVisible: false,
        });
    };

    handleContact = (isAdded: string) => {
        this.setState({ 
            contactAdded: isAdded,
            contactPopupVisible: false,
        });
    };

    addVenuePopup = () => { 
        this.setState({
            venuePopupVisible: true,
        });
    };

    hideVenuePopup = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        venuePopupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                venuePopupVisible: false,
            });
        }
    };

    handleVenue = (isAdded: string) => {
        this.setState({ 
            venueAdded: isAdded,
            venuePopupVisible: false,
        });
    };

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            contactPopupVisible: flag,
        });
    }

    handleVenuePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            venuePopupVisible: flag,
        });
    }

    searchArtists = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            artistSearchField: event.target.value
        });
    };

    onSearch = () => {
        this.setState({
            //artistSearchVal: this.state.artistSearchField,
            isClickArtistSearch: true,
        });
    }

    updateSearchButton = (flag: boolean) => {
        this.setState({
            isClickArtistSearch: flag
        });
    }

    render() {        
        let formView = false;
        let isEditClick = false;
        if (this.props.location.state) {
            if (
                this.props.location.state.id &&
                this.props.location.state.isEdit &&
                (this.state.filterText == "PeriodMatrix" ||
                    this.state.filterText == "ContractCleaning" ||
                    this.state.filterText == "RepeatingService" ||
                    this.state.filterText == "Entertainment" ||
                    this.state.filterText == "bulletins" ||
                    this.state.filterText == "FAQ" ||
                    this.state.filterText == venueservice.venueHierarchy ||
                    this.state.filterText == feeService.ServiceFeeMatrix ||
                    this.state.filterText == providerservice.venueProvider)
            ) {
                formView = true;
                isEditClick = true;
            }
            if (
                this.props.location.state.isCancel &&
                (this.state.filterText == "PeriodMatrix" ||
                    this.state.filterText == "ContractCleaning" || 
                    this.state.filterText == "RepeatingService" ||
                    this.state.filterText == "Entertainment" ||
                    this.state.filterText == "bulletins" ||
                    this.state.filterText == "FAQ" ||
                    this.state.filterText == venueservice.venueHierarchy ||
                    this.state.filterText == feeService.ServiceFeeMatrix ||
                    this.state.filterText == providerservice.venueProvider)
            ) {
                formView = false;
            }
            if (
                this.props.location.state.isDelete &&
                (this.state.filterText == "PeriodMatrix" ||
                    this.state.filterText == "ContractCleaning" || 
                    this.state.filterText == "RepeatingService" ||
                    this.state.filterText == "bulletins" ||
                    this.state.filterText == "FAQ" ||
                    this.state.filterText == venueservice.venueHierarchy)
            ) {
                formView = false;
            }
        } else if (
            this.state.isAddClick &&
            (this.state.filterText == "PeriodMatrix" ||
                this.state.filterText == "ContractCleaning" || 
                this.state.filterText == "RepeatingService" ||
                this.state.filterText == "Entertainment" ||
                this.state.filterText == "bulletins" ||
                this.state.filterText == "FAQ" ||
                this.state.filterText == venueservice.venueHierarchy ||
                this.state.filterText == feeService.ServiceFeeMatrix ||
                this.state.filterText == providerservice.venueProvider)
        ) {
            if (this.state.applySuccessful == "ADD") {
                formView = false;
            } else {
                formView = true;
            }
        } else if (
            this.state.applySuccessful == "EDIT" &&
            (this.state.filterText == "PeriodMatrix" ||
                this.state.filterText == "ContractCleaning" || 
                this.state.filterText == "RepeatingService" ||
                this.state.filterText == "Entertainment" ||
                this.state.filterText == "bulletins" ||
                this.state.filterText == "FAQ" ||
                this.state.filterText == venueservice.venueHierarchy ||
                this.state.filterText == feeService.ServiceFeeMatrix ||
                this.state.filterText == providerservice.venueProvider)
        ) {
            formView = false;
        }

        return (
            <LightModePageTemplate>
                <SideBarTemplate closeSidebar={this.state.closeSidebar}>
                    <ManageOurServicesPanel setFilter={this.setFilter} />
                </SideBarTemplate>
                <div className="page-content--with-sidebar">
                    <div className="row">
                        <div className="col-md">
                            {formView && this.state.filterText == "PeriodMatrix" ? (
                                <PeriodMatrixForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                ></PeriodMatrixForm>
                            ) : formView && (this.state.filterText == "ContractCleaning" || this.state.filterText == "RepeatingService") ? (
                                <ContractActionForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                    filterText={this.state.filterText}
                                    isEditForm={isEditClick}
                                ></ContractActionForm>
                            ) : formView && this.state.filterText == "Entertainment" ? (
                                <ResidencyForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                    isEditForm={isEditClick}
                                ></ResidencyForm>
                            ) : formView && this.state.filterText == feeService.ServiceFeeMatrix ? (
                                <ServiceFeeForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                    isEditForm={isEditClick}
                                ></ServiceFeeForm>
                            ) : formView && this.state.filterText == providerservice.venueProvider ? (
                                <ProviderServiceForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                    isEditForm={isEditClick}
                                ></ProviderServiceForm>
                            ) : formView && this.state.filterText == "bulletins" ? (
                                <BulletinForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                />
                            ) : formView && this.state.filterText == "FAQ" ? (
                                <FAQForm
                                    location={this.props.location}
                                    history={null}
                                    onApplySuccess={this.onApplySuccess}
                                />
                            )
                                : null}
                            {this.state.filterText == "PeriodMatrix" ? (
                                <div>
                                    <h2 className="page-title__black">Period Matrix</h2>
                                    <div className="row">
                                        <div className="col-12 col-lg-3 col-xl-2 mb-3">
                                            <button
                                                className="btn btn-primary btn--large btn--with-icon"
                                                onClick={this.onAddClick}
                                            >
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add Period
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.filterText == "RateMatrix" ? (
                                <>
                                    <div>
                                        <h2 className="page-title__black-table">Rate Matrix</h2>
                                    </div>
                                </>
                            ) : this.state.filterText == "ContractCleaning" ? (
                                <>
                                    <div className="row">
                                        <h2 className="page-title__black-table">Weekly Cleaning</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-3 col-xl-2 mb-3">
                                            <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add Contract
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : this.state.filterText == "RepeatingService" ? (
                                <>
                                    <div className="row">
                                        <h2 className="page-title__black-table">Repeating Service</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-3 col-xl-2 mb-3">
                                            <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add Contract
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : this.state.filterText == "Entertainment" ? (
                                <>
                                    <div className="row">
                                        <h2 className="page-title__black-table">Residencies</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-3 col-xl-2 mb-3">
                                            <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add Residency
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : this.state.filterText == feeService.ServiceFeeMatrix ? (
                                <>
                                    <div className="row mygig">
                                        <h2 className="page-title__black-table">Client Service Fees Matrix</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-2 mb-3">
                                            <button
                                                className="btn btn-primary btn--large"
                                                onClick={this.onAddClick}
                                                disabled={!this.state.eventUKSMIndicator}
                                            >
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : this.state.filterText == providerservice.venueProvider ? (
                                <>
                                    <div className="row mygig">
                                        <h2 className="page-title__black-table">Venue Service Provider</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-2 mb-3">
                                            <button
                                                className="btn btn-primary btn--large"
                                                onClick={this.onAddClick}
                                                disabled={!this.state.eventUKSMIndicator}
                                            >
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : this.state.filterText == "bulletins" ? (
                                <>
                                    <div className="row mygig">
                                        <h2 className="page-title__black-table">Bulletins</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-12 col-lg-2 mb-3">
                                            <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                                                <span className="btn__icon">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) :
                                this.state.filterText == "FAQ" ? (
                                    <>
                                        <div className="row mygig">
                                            <h2 className="page-title__black-table">FAQs</h2>
                                        </div>
                                        <div className="row pt-5">
                                            <div className="col-12 col-lg-2 mb-3">
                                                <button className="btn btn-primary btn--large" onClick={this.onAddClick}>
                                                    <span className="btn__icon">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </span>
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )
                                    : this.state.filterText == "userView" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">User View</h2>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "ViewClients" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">View Clients</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large"
                                                        disabled={false}
                                                        onClick={this.addArtistPopup}
                                                    >
                                                        ADD CLIENT
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "ViewClientContact" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">Client - Contacts</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large"
                                                        disabled={false}
                                                        onClick={() => this.addContactPopup("ViewClientContact")}
                                                    >
                                                        ADD CONTACT
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "ViewProviderContact" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">Provider - Contacts</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large"
                                                        disabled={false}
                                                        onClick={() => this.addContactPopup("ViewProviderContact")}
                                                    >
                                                        ADD CONTACT
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "ViewCompanyContact" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">Company - Contacts</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large"
                                                        disabled={false}
                                                        onClick={() => this.addContactPopup("ViewCompanyContact")}
                                                    >
                                                        ADD CONTACT
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "ViewVenue" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">Venues</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large"
                                                        disabled={false}
                                                        onClick={() => this.addVenuePopup()}
                                                    >
                                                        ADD VENUE
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "viewProviders" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">View Providers</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 mt-3 mb-3">
                                                    <div className="filter-box__search provider-box-color">
                                                        <input
                                                            type="search"
                                                            onChange={this.searchArtists}
                                                            //aria-label="Search"
                                                            placeholder="Search for providers"
                                                        ></input>
                                                        <button type="submit"
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.onSearch();
                                                            }}>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                            <span className="sr-only">Submit Search</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-2 mt-3 mb-3">
                                                    <Button
                                                        className="btn btn-primary btn--large provider-btn"
                                                        disabled={false}
                                                        onClick={this.addArtistPopup}
                                                    >
                                                        ADD Provider
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : this.state.filterText == "serviceTypes" ? (
                                        <>
                                            <div>
                                                <h2 className="page-title__black-table">Service Types</h2>
                                            </div>
                                        </>
                                    // ) : this.state.filterText == "managePayDates" ? (
                                    //     <>
                                    //         <div>
                                    //             <h2 className="page-title__black-table">Manage Pay Dates</h2>
                                    //         </div>
                                    //     </>
                                    ) : null}
                            <div className="row">
                                <div className="col-md">
                                    {this.state.filterText == "PeriodMatrix" ? (
                                        <PeriodMatrixGrid refreshSignal={this.state.refreshSignal} />
                                    ) : this.state.filterText == "RateMatrix" ? (
                                        <RateMatrixGrid />
                                    ) : this.state.filterText == "ContractCleaning" || this.state.filterText == "RepeatingService" ? (
                                        <ContractCleaningGrid refreshSignal={this.state.refreshSignal} filterText={this.state.filterText}/>
                                    ) : this.state.filterText == "Entertainment" ? (
                                        <ResidencyGrid
                                            refreshSignal={this.state.refreshSignal}
                                            sendEditFormSignal={this.sendEditFormSignal}
                                            showSubstituteChildGrid={this.state.showSubstituteChildGrid}
                                        />
                                    ) : this.state.filterText == venueservice.venueHierarchy ? (
                                        <VenueHierarchyGrid />
                                    ) : this.state.filterText == feeService.ServiceFeeMatrix ? (
                                        <ServiceFeeGrid refreshSignal={this.state.refreshSignal} />
                                    ) : this.state.filterText == providerservice.venueProvider ? (
                                        <ProviderServiceGrid refreshSignal={this.state.refreshSignal} />
                                    ) : this.state.filterText == "bulletins" ? (
                                        <BulletinGrid refreshSignal={this.state.refreshSignal} />
                                    ) : this.state.filterText == "FAQ" ? (
                                        <FAQGrid refreshSignal={this.state.refreshSignal} />
                                    ) : this.state.filterText == "userView" ? (
                                        <UserView />
                                    ) : this.state.filterText == "ViewClients" ? (
                                        <ArtistGrid
                                            location={this.props.location}
                                            history={this.props.history}
                                            searchFieldVal={""}
                                            typeList={""}
                                            trustedValue={false}
                                            rating={0}
                                            getVenue={()=>{return null}}
                                            updateSearchButton = {()=>{return null}}
                                            artistAdded={this.state.artistAdded}
                                            serviceType={'ViewClients'}
                                        />
                                    ) : this.state.filterText == "ViewClientContact" || this.state.filterText == "ViewProviderContact" || this.state.filterText == "ViewCompanyContact" ? (
                                        <ContactGrid
                                            location={this.props.location}
                                            history={this.props.history}
                                            artistAdded={this.state.contactAdded}
                                            contactType={this.state.filterText}
                                        />
                                    ) : this.state.filterText == "ViewVenue" ? (
                                            <VenueGrid
                                                location={this.props.location}
                                                history={this.props.history}
                                                artistAdded={this.state.venueAdded}
                                            />
                                    ) : this.state.filterText == "viewProviders" ? (
                                        <ArtistGrid
                                            location={this.props.location}
                                            history={this.props.history}
                                            searchFieldVal={this.state.artistSearchField}
                                            typeList={""}
                                            trustedValue={false}
                                            rating={0}
                                            getVenue={()=>{return null}}
                                            isClickArtistSearch={this.state.isClickArtistSearch}
                                            updateSearchButton = {this.updateSearchButton}
                                            artistAdded={this.state.artistAdded}
                                            serviceType={'viewProviders'}
                                        />
                                    ) : this.state.filterText == "serviceTypes" ? (
                                        <ServiceTypesContainer />
                                        // <></>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    {this.state.filterText == "VenueUploader" ? (
                        <UploadSchedulePage
                            landingPage={TypeOfFileUploaded.Venue}
                            title={""}
                            category={""}
                            typeId={uploadedFileType.VenueUpload}
                            landingPageTab=""
                        />
                    ) : null}
                    {this.state.filterText == "RateMatrixUploader" ? (
                        <UploadSchedulePage
                            landingPage={TypeOfFileUploaded.RateMatrixUpload}
                            title={""}
                            category={""}
                            typeId={uploadedFileType.RateMatrixUpload}
                            landingPageTab=""
                        />
                    ) : null}
                    {this.state.filterText == "BudgetUploader" ? (
                        <UploadSchedulePage
                            landingPage={TypeOfFileUploaded.BudgetUpload}
                            title={""}
                            category={""}
                            typeId={uploadedFileType.BudgetUpload}
                            landingPageTab=""
                        />
                    ) : null}
                    {this.state.filterText == "PeriodMatrixUploader" ? (
                        <UploadSchedulePage
                            landingPage={TypeOfFileUploaded.PeriodMatrixUpload}
                            title={""}
                            category={""}
                            typeId={uploadedFileType.PeriodMatrixUpload}
                            landingPageTab=""
                        />
                    ) : null}
                    {this.state.filterText == "LicenseChecks" ? <LicenseChecksPage /> : null}
                    {this.state.filterText == "NewLicenseChecks" ? (
                        <UploadSchedulePage
                            title={""}
                            category={""}
                            landingPage={TypeOfFileUploaded.SIA}
                            typeId={uploadedFileType.SIA}
                            landingPageTab=""
                        />
                    ) : null}
                    {this.state.filterText == "ShiftImportWarnings" ? <ShiftImportWarnings /> : null}
                    {this.state.filterText == "Budgets" ? <Budget /> : null}
                    {
                        this.state.popupVisible ? (
                            <Popup
                                visible={this.state.popupVisible}
                                onHiding={this.hideArtistPopup}
                                dragEnabled={false}
                                closeOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                //title="Add Client"
                                title = {this.state.filterText == "ViewClients" ? "Add Client" : "Add Provider"}
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <ArtistProfileComponent
                                        mode="edit"
                                        id="0"
                                        businessEntityId="0"
                                        handlePopUpClose={() => { return null }}
                                        handleFeedbackUpdate={() => { return null }}
                                        handleAddArtist={this.handleAddArtist}
                                        serviceType={this.state.filterText == "ViewClients" ? "ViewClients" : "viewProviders"}
                                    ></ArtistProfileComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                    {
                        this.state.contactPopupVisible ? (
                            <Popup
                                visible={this.state.contactPopupVisible}
                                onHiding={this.hideContactPopup}
                                dragEnabled={false}
                                closeOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title="Add Contact"
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <AddContactComponent
                                        mode="edit"
                                        id="0"
                                        handlePopUpClose={this.handlePopUpClose}
                                        handleContact={this.handleContact}
                                        contactType={this.state.contactType}
                                    ></AddContactComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                    {
                        this.state.venuePopupVisible ? (
                            <Popup
                                visible={this.state.venuePopupVisible}
                                onHiding={this.hideVenuePopup}
                                dragEnabled={false}
                                closeOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title="Add Venue"
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <AddVenueComponent
                                        mode="edit"
                                        id="0"
                                        handlePopUpClose={this.handleVenuePopUpClose}
                                        handleContact={this.handleVenue}
                                    ></AddVenueComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                    {/* {this.state.filterText == "ViewCompany" ? <> */}
                    {
                        this.state.companyPopupVisible ? (
                            <Popup
                                visible={this.state.companyPopupVisible}
                                onHiding={this.hideCompanyPopup}
                                dragEnabled={false}
                                closeOnOutsideClick={false}
                                showTitle={true}
                                showCloseButton={true}
                                title = {"Company Details"}
                                resizeEnabled={true}
                            >
                                <ScrollView width="100%" height="100%">
                                    <ArtistProfileComponent
                                        mode="edit"
                                        id="30"
                                        businessEntityId="30"
                                        handlePopUpClose={this.handleCompanyPopupClose}
                                        handleFeedbackUpdate={() => { return null }}
                                        handleAddArtist={() => { return null }}
                                        serviceType={'ViewCompany'}
                                    ></ArtistProfileComponent>
                                </ScrollView>
                            </Popup>
                        ) : null
                    }
                    {/* </> : null} */}
                    {this.state.filterText =='managePayDates' ? <><PayDatesGrid></PayDatesGrid></>:null}
                    {this.state.filterText =='providerUploadMappings' ? <><ProviderUploadMappingsGrid></ProviderUploadMappingsGrid></>:null}
                    {this.state.filterText =='clientUploadMappings' ? <><ClientUploadMappingsGrid></ClientUploadMappingsGrid></>:null}
                </div>
            </LightModePageTemplate>
        );
    }
}
export default withRouter(MatrixPage);
