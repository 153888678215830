import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    SearchPanel,
    FilterPanel,
    GroupPanel,
} from "devextreme-react/data-grid";
import gridUtils from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import AddContactComponent from "./AddContactComponent";
import FindAnArtistService from "../../services/FindAnArtistService";
import { ContactPageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MatrixGridService from "../../services/MatrixService";
import { LoadPanel } from "devextreme-react";

//props
interface ContactGridProps {
    location: any;
    history: any;
    contactType?: any;
    artistAdded: string;
}

// State
interface ContactGridState {
    ContactGridDataSource: ContactPageFormData[];
    showProgressIndicator: boolean;
    businessEntityId: string;
    providerPopupVisibleEntertainment?: boolean;
    loadPanelVisible: boolean;
}

// Component - displays both the header and child grids
class ContactGrid extends React.Component<ContactGridProps> {
    state: ContactGridState;
    service: FindAnArtistService;
    matrixService: MatrixGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    isEditMode: string;
    constructor(props: ContactGridProps) {
        super(props);
        this.isEditMode = "";
        // Initialize state and services/utils
        this.state = {
            ContactGridDataSource: [],
            showProgressIndicator: false,
            businessEntityId: "0",
            providerPopupVisibleEntertainment: false,
            loadPanelVisible: false,
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        // Functions
        this.service = new FindAnArtistService();
        this.matrixService = new MatrixGridService();
    } 

    getOrganisationTypeByContactType = () => {
        if (this.props.contactType == "ViewClientContact") {
            this.service.getListOfContactGrid("10").then(this.handleSuccess).catch(this.handleFailure);
        }
        else if (this.props.contactType == "ViewProviderContact"){
            this.service.getListOfContactGrid("20").then(this.handleSuccess).catch(this.handleFailure);
        }
        else {
            this.service.getListOfContactGrid("30").then(this.handleSuccess).catch(this.handleFailure); 
        }
    }

    componentDidMount() {
        this.setState({loadPanelVisible:true});
        this.getOrganisationTypeByContactType();
    }
    
    componentDidUpdate = (prevprops: ContactGridProps, prevState: ContactGridState) => { 
        if(this.props.contactType != prevprops.contactType){
            this.setState({
                ContactGridDataSource: [],
                loadPanelVisible: true,
            });
            this.getOrganisationTypeByContactType();
        }
        if (this.props.artistAdded !== prevprops.artistAdded) {
            this.setState({loadPanelVisible:true});
            this.getOrganisationTypeByContactType();         
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        for(let item of response.data.data){
            item.typeList = JSON.stringify(item.typeList);
        }
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
                ContactGridDataSource: response.data.data,
            });
    };

    handleProfileSuccess = () => {
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
            });
    };

    handleFailure = () => {
        this.setState({
            loadPanelVisible: false,
            showProgressIndicator: false,
        });
    };

    showInfoProvider = (businessEntityId: string) => {
        this.setState({
            businessEntityId: businessEntityId,
            providerPopupVisibleEntertainment: true,
        });
    };


    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
        if(editmode == ""){
            this.setState({loadPanelVisible:true,});
            this.getOrganisationTypeByContactType();
        }
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    artistProfileDisplay = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.id);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
            </div>
        );
    }

    contactLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.lastUpdatedOn !== null && cellInfo.data?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    userLastUpdatedDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        if(cellInfo.data?.user !== null && cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== ""){
            let dateCreatedTime = cellInfo.data?.user?.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
            finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>
                {cellInfo.data?.user?.lastUpdatedOn !== null && cellInfo.data?.user?.lastUpdatedOn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (  
                    <DataGrid
                        dataSource={this.state.ContactGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        columnMinWidth={100}
                        keyExpr="id"
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        
                        <Column
                            caption="PROFILE"
                            allowExporting={false}
                            allowFiltering={false}
                            allowSorting={false}
                            allowGrouping={false}
                            cellRender={this.artistProfileDisplay}
                        />
                        <Column 
                            dataField="id" 
                            caption="Contact Id" 
                        />
                        <Column 
                            dataField="businessEntity.shortName" 
                            caption={this.props.contactType == "ViewClientContact" ? "Client" : this.props.contactType == "ViewProviderContact" ? "Provider" : "Company"}
                        />
                        <Column 
                            dataField="name" 
                            caption="Name" 
                        />
                        <Column 
                            dataField="organisationType.value" 
                            caption="Organisation" 
                        />
                        <Column 
                            dataField="contactType.value" 
                            caption="Contact Type"
                        />
                        <Column 
                            dataField="jobTitle" 
                            caption="Job Title" 
                        />
                        <Column 
                            dataField="email" 
                            caption="Email" 
                        />
                        <Column 
                            dataField="mobile" 
                            caption="Mobile" 
                        />
                        <Column 
                            dataField="active" 
                            caption="Active" 
                        />
                        <Column 
                            dataField="lastUpdatedOn" 
                            caption="Last Updated"  
                            cellRender={this.contactLastUpdatedDisplay}
                            dataType="date"
                        />
                        <Column 
                            dataField="user.active" 
                            caption="Active User" 
                        />
                        <Column 
                            dataField="user.name" 
                            caption="User Name" 
                        />
                        <Column 
                            dataField="user.userRole.value" 
                            caption="User Role" 
                        />
                        <Column 
                            dataField="user.lastUpdatedOn" 
                            caption="User Last Updated" 
                            cellRender={this.userLastUpdatedDisplay}
                            dataType="date"
                        />                       
                        
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>                   
                )}
                {
                this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="Contact Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <AddContactComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    businessEntityId={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    handleContact={() => { return null }}
                                    contactType={this.props.contactType}
                                ></AddContactComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
            </div>
        );
    }
}

export default ContactGrid;
