import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

const DASHBOARD_API = "Dashboard/DashboardCount";

export interface DashboardResponseCount {
    paymentRequestPending: string,
    paymentRequestQueried: string,
    paymentFailed: string,
    fasterPayment: string,
    addJobPending: string,
    addJobQueried: string,
    signUpPending: string,
    queriesPending: string,
    gigRequestPending: string,
    gigRequestPublished: string,
    gigRequestOptionsSent: string,
    gigRequestConfirming: string,
    gigRequestReConfirm: string,
    gigRequestShortlisted: string,
    gigRequestOffered: string,
    requestByDate: BookingsByWeekGridData[];
}

export interface BookingsByWeekGridData  {
    date: string,
    newRequest: string,
    advertised: string,
    optionsSent: string,
    shortlisted: string,
    offered: string,
    confirming: string,
    reConfirm: string,
    booked: string
}

class DashBoardService {
    getDashboardCount(serviceTypeId: string, dateFrom: string, dateTo: string): Promise<AxiosResponse<any>> {
        return _get(`${DASHBOARD_API}?serviceTypeId=${serviceTypeId}&dateRangeFrom=${dateFrom}&dateRangeTo=${dateTo}`);
    }

    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}
export default DashBoardService;
