import { AxiosResponse } from "axios";
import { _get, _post, _put, _aiTraceAsError, _postBatchRequest } from "./BaseApiService";
import { LookupTypeItem } from "./LookupService";
import { ContactType } from "../components/Outstanding-Actions/OutstandingActionsUtilities";

// Endpoints
const FIND_AN_ARTIST_API = "BusinessEntity/Artists";
const FIND_AN_ARTIST_GRID_API = "BusinessEntity/ArtistsGrid";
const CONTACT_GRID_API = "Contact/GetContacts";
const VENUE_GRID_API = "Venue/GetVenues";
const FAVORITE_ARTIST_API = "BusinessEntity/Favourite/Artist";
const JOB_NOTIFICATION_API = "Notification/SendAddJobNotification";
const SAVE_AN_ARTIST_API = "Request";
const QUERY_TAG_USER_API = "Query/TagUntag";
const PAYMENT_TAG_USER_API = "PaymentRequest/TagUntag";
const BILLABLE_ITEM = "billableitem";
const PROFILE_BULLETIN_NOTIFICATION = "ProfileNBulletinNotificationProcessing";
const PUBLISH = "publish";
const STATUS = "status";
const SHOWCASE_PANEL_API = "BusinessEntity/ShowcasePanel";
const LOOKUP_API = "Lookup/EntertainmentSubTypeWithType";

export interface TypeList {
    id: string;
    lastUpdatedBy: string;
    lastUpdatedOn: string;
    user: string;
    value: string;
    otherDescription?: string;
}

export interface EquipmentTypeList {
    id: string;
    value: string;
}

export interface DjTypeList {
    id: string;
    value: string;
}

export interface ExcludedEntityList {
    businessEntityId: string;
    shortName: string;
}

export interface MusicList {
    id: string;
    value: string;
    description: string;
}

export interface GenreList {
    id: string;
    value: string;
    description: string;
}

export interface WeekList {
    id: string;
    value: string;
}

export interface showCasePanel {
    businessEntityId: string;
    utubeLink: string;
    artistName: string;
    artistScore: string;
    venueFavourite: string;
}

export interface LinkInformation {
    id: string;
    businessEntityId: string;
    linkTypeId: string;
    linkTypeName: string;
    linkTypeOther: string;
    displayLink: string;
    active: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

// security shift details
export interface securityShiftDetails {
    id: number;
    request_ID: number;
    request_Days_ID: number;
    date: string;
    serviceSubTypeId: number;
    typeId: number;
    equipmentScheduledQuantity: string;
    scheduledStartTime: string;
    scheduledFinishTime: string;
    scheduledMinutes: string;
}

// Interfaces
export interface ArtistProfileResponse {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    tradingName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    tax_Applicable: string;
    provider_Payment_Offset_Days: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    professionalName: string;
    score: string;
    bio: string;
    shortDescription: string;
    coverageMiles: string;
    termsAgreed: string;
    venueFavourite: string;
    lastPlayedVenue: string;
    contactInformation: ContactInformation | null;
    bankInformation: BankInformation | null;
    linkInformation: LinkInformation[];
    typeList: TypeList[];
    artistApart: string;
    showInterest: ShowInterestType[];
    addJobDetail?: any;
    makeOfferAmount: string; // this response is used for showing card on Request By Id page/Product Backlog Item 14306: Show Interest - Sprint 60 - Refinements
    comments: string; // this response is used for showing card on Request By Id page/Product Backlog Item 14306: Show Interest - Sprint 60 - Refinements,
    feedbackTypeId: string,
    feedbackScore: string,
    feedbackComments: string,
    trustedUser: boolean    
}

// interface for Optiones Sent 
export interface OptionsSentRequest {
    id: string,
    isOptionsSent: boolean,
    optionsSentDateTime: string,
    internalComments: string,
    isFromGrid: boolean,
    featured: boolean,
    reoccurrence: boolean,
    serviceTypeId?: string,
    serviceSubTypeId?: string,
    typeId?: string,
    clientBusinessEntityId?: string,
    requestStatusId?: string,
    previousRequestStatusId?: string,
}


export const DJType = ["Solo", "Duo", "Trio", "Band", "Instrumentalist", "Tribute"];

export const LiveMusicType = ["Club DJ", "Commercial DJ", "Party DJ"];

// Calculation response
export interface BankInformation {
    id: string;
    businessEntityID: string;
    contactID: string;
    bankName: string;
    branchName: string;
    accountNumber: string;
    sortCode: string;
    buildingSociety: string;
    nameOnAccount: string;
    active: string;
    serviceTypeLookUpId: string;
    serviceSubTypeLookUpId: string;
}

export interface ContactInformation {
    name: string;
    mobileTelephone: string;
    businessTelephone: string;
    otherTelephone: string;
    email: string;
    addressStreet1: string;
    addressStreet2: string;
    city: string;
    state: string;
    postCode: string;
    emailNotification: string;
    dateOfBirth: string;
    utr: string;
    ni: string;
}
export interface ShowInterestType {
    id?: string | null;
    providerBusinessEntityId?: string;
    providerBusinessEntity?: string | null;
    requestId?: string | null;
    showInterestDateTime?: string | null;
    showInterestStatusId?: string | null;
    showInterestStatus?: string | null;
    makeOfferAmount: string,
    comments: string,
    artistDetails?: ArtistProfileResponse;
    selectedOrderNumber: string,
    addJobDetail?: any,
}
export interface NewFormRequest {
    id?: string;
    startTime?: string;
    finishTime?: string;
    serviceSubTypeId?: string;
    serviceTypeId?: string;
    clientBusinessEntityId?: string;
    venueId?: string;
    venueTypeId?: string;
    dateFrom?: string;
    dateTo?: string;
    equipmentRequired?: string;
    equipmentType?: EquipmentTypeList[],
    musicTypeList?: MusicList[],
    genreList?: GenreList[],
    performanceInformationSetsNumber?: string;
    performanceInformationSetsDuration?: string;
    additionalInformation?: string;
    entertainmentDescription?: string;
    budget?: string;
    maxBudget?: string;
    marketPlaceBudget?:string;
    providerPayAmountLocal?: string;
    grossProfitAmountLocal?: string;
    clientBillableAmountLocal?: string;
    requestStatusId?: string;
    requestStatusValue?: string;
    requestTypeId?: string;
    internalComments?: string;
    isRounded?: boolean;
    typeLookUpId?: string;
    showInterest?: ShowInterestType[] | null;
    lastUpdatedOn?: string;
    featured?: boolean;
    reoccurrence?: boolean;
    reoccurrenceFrequency?: string;
    dateFromList?: string[];
    imageLinkId?: string;
    otherDescription?: string;
    description?: string;
    djStyleList?: DjTypeList[];
    reoccurrenceEndDateKnown? : boolean,
    reoccurrenceEndDate? : string,
    dayOfWeekList?: WeekList[],
    daysOfWeekList?: WeekList[],
    hours?: string,
    minutes?: string,  
    rate?: string,
    providerBusinessEntityId?: string,
    approvalDate?: string;
    invoiceId?: string;
    paymentId?: string;
    billableItemId?: string;
    shiftDetails?: securityShiftDetails[],
    requestShifts?: any[],
}
export interface IndependentBookFormRequest {
    id: string;
    startTime: string;
    entertainmentDescription: string;
    serviceSubTypeId: string;
    clientBusinessEntityId: string;
    venueId: string;
    venueTypeId: string;
    dateFrom: string;
    workerName: string;
    workerEmail: string;
    workerPhone: string;
    additionalInformation: string;
    budget: string;
    requestStatusId: string;
    requestTypeId: string;
}
export interface RequestTableType {
    id: string;
    startTime: string;
    finishTime: string;
    serviceTypeId: string;
    serviceType: string;
    serviceSubTypeId: string;
    serviceSubType: string;
    providerBusinessEntityId: string;
    clientBusinessEntityId: string;
    venueId: string;
    venueTypeId: string;
    townCity: string;
    country: string;
    dateFrom: string;
    providerPayAmountLocal: string;
    configurableText: string | null;
    entertainmentDescription: string;
    additionalInformation: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    requestStatusId: string;
    requestStatusValue: string;
    budget?: string;
    equipmentRequired?: string;
    performanceInformationSetsNumber?: string;
    performanceInformationSetsDuration?: string;
    internalComments?: string;
    //messages: null;
    showInterest: ShowInterestType[] | null;
    //validationErrors: ValidationErrorType[] | null;
}
export const artistCardUIType = {
    All: "All",
    Favorites: "Favorites",
    Requested: "Requested",
};
export const RequestStatus = {
    Pending: "10",
    Published: "20",
    Offered: "22",
    OptionsSent: "23",
    Shortlisted: "24",
    Booked: "30",
    Cancelled: "90",
};
export const RequestStatusTypes = {
    Pending: "Pending",
    Published: "Published",
    Booked: "Booked",
    Cancelled: "Cancelled",
};
export const ShowInterestStatusTypes = {
    Requested: "Requested",
    Suggested: "Suggested",
    Applied: "Applied",
    Booked: "Booked",
    Unavailable: "Unavailable",
    Removed: "Removed",
};
export const ShowInterestStatusIdTypes = {
    Requested: "10",
    Suggested: "20",
    Offered: "22",
    Applied: "30",
    Selected: "35",
    Accepted: "36",
    Declined: "37",
    Booked: "40",
    Unavailable: "50",
    Removed: "60",
};

export const PaymentTypes = {
    PaymentRequest: "10",
    AddJob: "20"
}

export const RequestTypeEnum = {
    IndependentBooking: "10",
    FindanArtist: "11",
};

export const PhotoTypes = {
    Primary: "20",
    AlternatePhoto: "24",
};

export const findAnArtistCycleStatus = {
    Pending: "10",
    Published: "20",
    OptionsSent: "23",
    ArtistConfirmation: "25",
    VenueConfirmation: "27",
    Booked: "30",
    Cancelled: "90",
    ArtistSelected: "24",
    Offered: "22",
};

export interface ShowInterestOptionsSentRequest {
    id: string,
    isOptionsSent: boolean;
    optionsSentDateTime: string,
    internalComments: string,
    isFromGrid: boolean,
    showInterest: AppliedArtistObj[],
    serviceTypeId?: string,
    serviceSubTypeId?: string,
    clientBusinessEntityId?: string,
    requestStatusId?: string,
    previousRequestStatusId?: string,
}

export interface AppliedArtistObj {
    id: string,
    providerBusinessEntityId: string,
    requestId: string,
    makeOfferAmount: string,
    optionsSent: string,
    serviceSubTypeId?: string,
}

export interface ArtistConfirmRequest {
    id: string,
    isArtistConfirmed: boolean,
    artistConfirmDateTime: string,
    internalComments: string,
    isFromGrid: boolean,
    featured: boolean,
    reoccurrence: boolean,
    serviceTypeId?: string,
    serviceSubTypeId?: string,
    clientBusinessEntityId?: string,
    requestStatusId?: string,
    previousRequestStatusId?: string,
}

export interface VenueConfirmRequest {
    id: string,
    isVenueConfirmed: boolean,
    venueConfirmDateTime: string,
    internalComments: string,
    isFromGrid: boolean,
    featured: boolean,
    reoccurrence: boolean,
    serviceTypeId?: string,
    serviceSubTypeId?: string,
    clientBusinessEntityId?: string,
    requestStatusId?: string,
    previousRequestStatusId?: string,
}

export interface ReConfirmRequest {
    id: string,
    isReConfirmed: boolean,
    reConfirmDateTime: string,
    internalComments: string,
    isFromGrid: boolean,
    featured: boolean,
    reoccurrence: boolean,
    serviceTypeId?: string,
    clientBusinessEntityId?: string,
}
export interface TaggedRequest {
    id: string,
    IsTagged: boolean,
    isFromGrid?: boolean,
    featured?: boolean,
    reoccurrence?: boolean,
    serviceTypeId?: string,
    serviceSubTypeId?: string,
    clientBusinessEntityId?: string,
}

export interface artistGridRowItem {
    providerId: string;
    firstName: string;
    lastName: string;
    email: string;
    addressStreet1: string;
    townCity: string;
    mobile: string;
    phone: string;
    businessType: string;
    companyNumber: string;
    vatNumber: string;
    professionalName: string;
    bio: string;
    coveragePostCode: string;
    coverageMiles: string;
    trustedUser: string;
    accountVerified: string;
    accountNo: string;
    sortCode: string;
    primaryPhoto: string;
    youTube: string;
    idLink: string;
    tracks: string;
    guiltyPleasure: string;
    active: string;
    optOut: string;
    verified: string;
    legalName: string;
    shortName: string;
    postCode: string;
    vatVerified: string;
}

/**
 * REST services for the BillableItem resource.
 */
class FindAnArtistService {
    // Retrieve the list of Artists from the Db.
    //Dynamic API call.
    //Works, irrespective of whether the parameters are supplied or not.
    getListOfArtists(postCode: string, distance: string, searchVal: string, typeList: string, trusted: boolean, rating: number): Promise<AxiosResponse<any>> {
        var query = `?postcode=${postCode}&distance=${distance}&search=${searchVal}&typeList=${typeList}&trusted=${trusted}&stars=${rating}&skip=0&take=100`;
        return _get(`${FIND_AN_ARTIST_API}${query}`);
    }
    //get the list of artist for artist grid.
    getListOfArtistsGrid(searchVal: string, typeList: string, trusted: boolean, rating: number, service: string): Promise<AxiosResponse<any>> {
        var query = `?trusted=${trusted}&typeList=${typeList}&search=${searchVal}&stars=${rating}&serviceType=${service}`;
        return _get(`${FIND_AN_ARTIST_GRID_API}${query}`);
    }
    //get the list of artist for artist grid for other providers.
    getListOfArtistsGridOther(searchVal: string, service: string): Promise<AxiosResponse<any>> {
        var query = `?search=${searchVal}&serviceType=${service}`;
        return _get(`${FIND_AN_ARTIST_GRID_API}${query}`);
    }
    //get the list of artist for artist grid for clients.
    getListOfArtistsGridClient(flag: string,): Promise<AxiosResponse<any>> {
        var query = `?profileFlag=${flag}`;
        return _get(`${FIND_AN_ARTIST_GRID_API}${query}`);
    }
    //get the list of contact for contact grid for clients/company/provider.
    getListOfContactGrid(flag: string,): Promise<AxiosResponse<any>> {
        var query = `?organisationContactType=${flag}`;
        return _get(`${CONTACT_GRID_API}${query}`);
    }
    //get the list of venue for venue grid for clients.
    getListOfVenueGrid(flag: string,): Promise<AxiosResponse<any>> {
        var query = `?organisationContactType=${flag}`;
        return _get(`${VENUE_GRID_API}${query}`);
    }
    //send notification to list of artist from artist grid.
    sendArtistNotifications(data:object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(PROFILE_BULLETIN_NOTIFICATION, data);
    }
    //Get the List of Type with subtypes on the side bar filter.
    gettypeLookup(): Promise<AxiosResponse<any>> {
        return _get(LOOKUP_API);
    }
    //Save new request
    saveNewArtistRequest(saveArtistObject: object): Promise<AxiosResponse<any>> {
        return _post(SAVE_AN_ARTIST_API, saveArtistObject);
    }
    //Publish request
    publishStatus(id: string, requestObject: object): Promise<AxiosResponse<any>> {
        return _put(`${SAVE_AN_ARTIST_API}/${id}/${PUBLISH}`, requestObject);
    }
    //UpdateRequest
    updateArtistRequest(id: string, requestObject: object): Promise<AxiosResponse<any>> {
        return _put(`${SAVE_AN_ARTIST_API}/${id}`, requestObject);
    }
    //Update tag/untag user Request for payment/manageQuery/artistprofile
    updatePaymentTagUntagRequest(id: string, requestObject: object): Promise<AxiosResponse<any>> {
        const query = `?id=${id}`;
        return _put(`${PAYMENT_TAG_USER_API}${query}`, requestObject);
    }
    updateQueryTagUntagRequest(id: string, requestObject: object): Promise<AxiosResponse<any>> {
        const query = `?id=${id}`;
        return _put(`${QUERY_TAG_USER_API}${query}`, requestObject);
    }
    //book request
    bookArtistRequest(id: string, requestObject: object): Promise<AxiosResponse<any>> {
        return _post(`${SAVE_AN_ARTIST_API}/${id}/${BILLABLE_ITEM}`, requestObject);
    }

    //FetchShowCasePanel Deatils
    getListOfYoutubeLinks(): Promise<AxiosResponse<any>> {
        return _get(SHOWCASE_PANEL_API);
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    //To submit the feedback for an artist.
    toggleFavorite(id: string): Promise<AxiosResponse<any>> {
        return _post(`${FAVORITE_ARTIST_API}/${id}`);
    }

    //To submit the job notification to artist.
    sendJobNotification(jobNotificationObj: object): Promise<AxiosResponse<any>> {
        return _post(JOB_NOTIFICATION_API, jobNotificationObj);
    }
}

export default FindAnArtistService;
