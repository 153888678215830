import axios,{ AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const EDIT_VIEW_API_URL = "BillableItem";
const CALCULATION_API = "BillableItem/Calculate";
const PAYMENT_CALCULATION_API = "PaymentRequest/CalculatePayment";
const TAX_APPLICABLE_API = "BusinessEntity";
const RAISE_QUERY_API = "Query";
const REQUEST_CALCULATION = "Request/Calculate";

//EndPoints for v2 
const EDIT_VIEW_API_URL_V2 = "BillableItem/V2";
const EDIT_VIEW_API_URL_V3 = "BillableItem/V3"

export const TaxPercentage = {
    Percentage: 0.2,
};

export const queryTypeLookup = {
    General: "10",
    Specific: "11",
    PendingRequest: "12",
    AddIncident: "15",
    costReport: "16",
    paymentRequest: "18",
    addJob: "19"
};

export const RoleTypes = {
    HeadDoorSupervisor: "111",
    DoorSupervisor: "112",
    SecondInCommand: "113",
    Medical: "114",
    Radio: "121",
    Bodycam: "122",
    Clicker: "123",
    Other: "991",
};
export const Status = {
    Pending: "121",
    Approved: "122",
    ReadyForBilling: "123",
    Billed: "124",
    BillPaid: "125",
    ReadyForPayment: "126",
    NettedForPayment: "127",
    PaymentValidationFailed: "128",
    PaymentPaid: "129",
    PaymentSettled: "130",
    PaymentBounced: "131",
    Queried: "132",
    Cancelled: "133",
    Accepted: "134",
    PendingAcceptance: "135",
};

export const Originator = {
    Request: "10",
    Residency: "20",
    ShiftImport: "21",
    Batch: "22",
    PaymentRequest: "30",
    ArtistNotification: "40",
    EventUK: "50",
    Venue: "60",
    Artist: "70",
    HeadOffice: "80",
    Other: "90"
};

// Quick Shift Add/Edit Request 

export interface QuickBillableItemSubmitRequest {
    serviceSubTypeId: string;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    providerId: string;
    otherDescription: string;
    date: string;
    dateTo: string;
    provider_Pay_Amount_Local_Ccy: string;
    comments: string;
    internalComments: string;
    equipmentQuantity: string;
    rate: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerPayAmountLocal: string;
    providerFasterPayOriginalAmount?: string;
    shift_Start: string;
    shift_End: string;
    name: string;
    identification_Number: string;
    typeId: string;
    additionalInformation: string;
    navigateFrom: string;
    validationError: [];
    billableItemId: string;
    equipmentScheduleQuantity: string;
    billableStatusLookupId: string;
    itemTypeId: string,
    recalculate: boolean,
    billable_Start: string;
    billable_Finish: string;
    fee_Percentage_Local_Ccy: string;
    scheduleRate: string;
    fasterPayment: boolean;
    fasterPaymentWithdrawn: boolean;
    //newly added 
    parentRecordId: string;
    cover: string;
    billable_Minutes: string;
    originalBillableMinutes: string;
    originalRate: string;
    billableRate: string;
    provider_Tax_Applicable: string,
    client_Tax_Applicable: string,
    fee_Tax_Applicable: string,
    pay_Date: string,
    bill_Date: string,
    provider_Pay_Tax_Local_Ccy: string,
    provider_Pay_Total_Local_Ccy: string,
    provider_Pay_Original_Amount_Local_Ccy: string,
    fee_Amount_Local_Ccy: string,
    fee_Tax_Local_Ccy: string,
    fee_Total_Local_Ccy: string,
    client_Billable_Amount_Local_Ccy: string,
    client_Billable_Tax_Local_Ccy: string,
    client_Billable_Total_Local_Ccy: string,
    client_Billable_Original_Amount_Local_Ccy: string,
    accept_Date: string,
    approval_Date: string,
    non_Standard: string,
    absent: string,
    unscheduled: string,
    originalBillableStartTime: string,
    originalBillableFinishTime: string,
    identificationTypeId: string,
    equipmentOriginalQuantity: string,
    equipmentBillableQuantity: string,
    isAmend: string,
    noCharge: string,
    scheduledMinutes: string,
    requestApprover: string,
    requestDate: string,
    performanceInformation: string,
    reoccurance: string,
    genre: string,
    equipmentRequired: string,
    description?: string,    
    providerPayAmountLocalExtrasDescription?: string,
    performanceInformationSetsNumber: string,
    performanceInformationSetsDuration: string,
    providerNetOverride: string,
    multiplierOverride: string,
    feeAmountOverride: string,
    shiftCompleteAcknowledgment: boolean,
    shiftCompleteAcknowledgmentDateTime: string,
    shiftCompleteAcknowledgmentTime: string,
    isAccrual: boolean,
    accrual: {
        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    hardApproval: string,
    holdPayment: string,
    lastUpdatedOn: string,
    paymentId: string,
    originator: {
        id: number,
        value: string
    } | null,
    updateOrCorrection: boolean,
    invoiceId: string,
}

// Quick Shift Add/Edit Response

export interface QuickBillableItemResponse {
    serviceSubTypeId: string;
    fasterPayment: boolean;
    fasterPaymentWithdrawn: boolean;
    serviceTypeId: string;
    clientId: string;
    venueId: string;
    providerId: string;
    otherDescription: string;
    date: string;
    dateTo: string;
    provider_Pay_Amount_Local_Ccy: string;
    comments: string;
    internalComments: string;
    equipmentQuantity: string;
    rate_Decimal: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: number;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    shift_Start: string;
    shift_End: string;
    name: string;
    identification_Number: string;
    typeId: string;
    additionalInformation: string;
    navigateFrom: string;
    validationError: [];
    billableItemId: string;
    equipmentScheduleQuantity: string;
    billableStatusLookupId: string;
    itemTypeId: string,
    recalculate: boolean,
    billable_Start: string;
    billable_Finish: string;
    fee_Percentage_Local_Ccy: string;
    scheduleRate: string;
    //newly added 
    parentRecordId: string;
    providerPayAmountLocal?: number;
    cover: string;
    billable_Minutes: string;
    originalBillableMinutes: string;
    originalRate: string;
    billableRate: string;
    provider_Tax_Applicable: string,
    client_Tax_Applicable: string,
    fee_Tax_Applicable: string,
    pay_Date: string,
    bill_Date: string,
    provider_Pay_Tax_Local_Ccy: string,
    provider_Pay_Total_Local_Ccy: string,
    provider_Pay_Original_Amount_Local_Ccy: string,
    fee_Amount_Local_Ccy: string,
    fee_Tax_Local_Ccy: number,
    fee_Total_Local_Ccy: number,
    client_Billable_Amount_Local_Ccy: string,
    client_Billable_Tax_Local_Ccy: number,
    client_Billable_Total_Local_Ccy: number,
    client_Billable_Original_Amount_Local_Ccy: string,
    accept_Date: string,
    approval_Date: string,
    non_Standard: string,
    absent: string,
    unscheduled: string,
    originalBillableStartTime: string,
    originalBillableFinishTime: string,
    identificationTypeId: string,
    equipmentOriginalQuantity: string,
    equipmentBillableQuantity: string,
    isAmend: string,
    noCharge: string,
    scheduledMinutes: string,
    requestApprover: string,
    requestDate: string,
    performanceInformation: string,
    reoccurance: string,
    genre: string,
    equipmentRequired: string,
    description?: string,
    providerPayAmountLocalExtrasDescription?: string,
    performanceInformationSetsNumber: string,
    performanceInformationSetsDuration: string,
    providerNetOverride: string,
    multiplierOverride: string,
    feeAmountOverride: string,
    shiftCompleteAcknowledgment: boolean,
    shiftCompleteAcknowledgmentDateTime: string,
    shiftCompleteAcknowledgmentTime: string,
    isAccrual: boolean,
    accrual: {
        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    hardApproval: string,
    holdPayment: string,
    lastUpdatedOn: string,
    lastUpdatedOnTZ:string,
    lastUpdatedBy:string,
    dateCreated:string,
    createdBy:string,
    paymentId: string,
    originator: {
        id: number,
        value: string
    } | null,
    updateOrCorrection: string,
    invoiceId: string,
}

// Interfaces
export interface BillableItemSubmitRequest {
    absent: string;
    accept_Date: string;
    approval_Date: string;
    bill_Date: string;
    billable_Finish: string;
    billable_Hours_Units: string;
    billable_Start: string;
    billable_Start_Finish: string;
    billableItemId: string;
    billableStatusLookupId: string;
    check_In: string;
    check_In_Out: string;
    check_Out: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    client: string;
    client_Billable_Amount_Base_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    client_Billable_Tax_Base_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Base_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Tax_Applicable: string;
    clientId: string;
    comments: string;
    cost: string;
    cover: string;
    date: string;
    dateTo: string;
    accrualBillableItemBillDate: string;
    equipmentQuantity: string;
    fee_Amount_Base_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Percentage_Base_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    fee_Tax_Base_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Base_Ccy: string;
    fee_Total_Local_Ccy: string;
    fee_Tax_Applicable: string;
    identification_Number: string;
    identificationTypeId: string;
    name: string;
    non_Standard: string;
    pay_Date: string;
    provider: string;
    provider_Pay_Amount_Base_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    provider_Pay_Tax_Base_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Base_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Tax_Applicable: string;
    providerId: string;
    rate: string;
    providerPayAmountLocal?: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    role: string;
    service: string;
    serviceSubTypeId: string;
    serviceTypeId: string;
    shift: string;
    shift_Color: string;
    shift_End: string;
    shift_Font: string;
    shift_Start: string;
    status: string;
    status_BackGroundColor: string;
    status_Color: string;
    tenseFlag: string;
    typeId: string;
    unscheduled: string;
    validationErrors: string | null;
    venueId: string;
    venue: string;
    isAmend: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    itemTypeId: string;
    itemTypeValue: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    originalBillableMinutes: string;
    originalRate: string;
    equipmentOriginalQuantity: string;
    noCharge: string;
    childRecordId: string;
    parentRecordId: string;
    internalComments: string;
    Recalculate: boolean;
    requestApprover: string;
    requestDate: string;
    otherDescription: string;
    equipmentBillableQuantity: string;
    billableRate: string;
    equipmentScheduleQuantity: string;
    scheduleRate: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    providerNetOverride: string,
    multiplierOverride: string,
    feeAmountOverride: string,
    performanceInformation: string;
    additionalInformation: string;
    genre: string;
    equipmentRequired: string;
    description?: string;
    shiftCompleteAcknowledgmentDescription?: string;
    providerPayAmountLocalExtrasDescription?: string;
    shiftCompleteAcknowledgment?: boolean;
    shiftCompleteAcknowledgmentDateTime?: string;
    shiftCompleteAcknowledgmentTime?: string;
    reoccurance: string;
    billPeriod: string;
    navigateFrom: string;
    isAccrual: boolean;
    hardApproval: string;
    holdPayment: string;
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    lastupdatedOn: string;
    paymentRequestId: string;
    paymentRequest: {
        id: string,
        fasterPayment: string,
        description: string,
    } | null;
    workerStatusId: string;
    workerStatusValue: string;
    fasterPayment?: boolean;
    fasterPaymentWithdrawn?: boolean;
    advancedpayment?: boolean;
    earlyPay?: boolean;
    originator?: {
        id: number,
        value: string
    } | null;
    requestId: string,
    batchId?: string,
    childRecordItemType?: string,
    clientAmount?: string,
    venuePostCode?: string,
    venueTownCity?: string,
    timeDecimal?: string,
    timeDiffIndicator?: string,
    quantityDiffIndicator?: string,
    gigTenseFlag?: string,
    scheduled_Hours_Units?: string,
    imageLink?: string,
    previousHoursRunningTotal?: string,
    previousQuantityRunningTotal?: string,
    entertainmentDateInfo?: string,
    address?: string,
    venue_Phone?: string,
    contacts?: Contact | null,
    rateDiffIndicator?: string,
    queries?: BillableItemQuery | null,
    feedback?: string,
    paymentRequestID?: string,
    paymentRequestStatusID?: string,
    paymentRequestStatus?: string,
    isUserAmendable?: boolean,
    scheduledStartTime?: string,
    scheduledFinishTime?: string,
    scheduledMinutes?: string,
    billableStartTime?: string,
    billableFinishTime?: string,
    serviceSubTypeValue?: string,
    typeValue?: string,
    updateOrCorrection: boolean,
}

export interface BillableItemResponse {
    absent: string;
    accept_Date: string;
    approval_Date: string;
    bill_Date: string;
    billable_Finish: string;
    billable_Hours_Units: string;
    billable_Minutes: string;
    billable_Start: string;
    billable_Start_Finish: string;
    billableItemId: string;
    billableStatusLookupId: string;
    check_In: string;
    check_In_Out: string;
    check_Out: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    client: string;
    client_Billable_Amount_Base_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    client_Billable_Tax_Base_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Base_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Tax_Applicable: string;
    clientId: string;
    comments: string;
    cost: string;
    cover: string;
    date: string;
    dateTo: string;
    accrualBillableItemBillDate: string;
    equipmentQuantity: string;
    fee_Amount_Base_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Percentage_Base_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    fee_Tax_Applicable: string;
    fee_Tax_Base_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Base_Ccy: string;
    fee_Total_Local_Ccy: string;
    identification_Number: string;
    identificationTypeId: string;
    name: string;
    non_Standard: string;
    pay_Date: string;
    provider: string;
    provider_Pay_Amount_Base_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    provider_Pay_Tax_Base_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Base_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Tax_Applicable: string;
    providerId: string;
    rate: string;
    rate_Decimal: string;
    scheduleRate: string;
    providerPayAmountLocal?: number;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    role: string;
    service: string;
    serviceSubTypeId: string;
    serviceTypeId: string;
    shift: string;
    shift_Color: string;
    shift_End: string;
    shift_Font: string;
    shift_Start: string;
    status: string;
    status_BackGroundColor: string;
    status_Color: string;
    tenseFlag: string;
    typeId: string;
    unscheduled: string;
    validationErrors: string;
    venueId: string;
    venue: string;
    isAmend: string;
    invoiceId: string;
    paymentId: string;
    paymentCycleId: string;
    itemTypeId: string;
    itemTypeValue: string;
    originalBillableStartTime: string;
    originalBillableFinishTime: string;
    originalBillableMinutes: string;
    originalRate: string;
    equipmentOriginalQuantity: string;
    equipmentScheduleQuantity: string;
    noCharge: string;
    childRecordId: string;
    parentRecordId: string;
    internalComments: string;
    Recalculate: boolean;
    requestApprover: string;
    requestDate: string;
    otherDescription: string;
    scheduledMinutes: string;
    equipmentBillableQuantity: string;
    billableRate: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    providerNetOverride: string,
    multiplierOverride: string,
    feeAmountOverride: string,
    performanceInformation: string;
    additionalInformation: string;
    genre: string;
    equipmentRequired: string;
    description?: string;
    shiftCompleteAcknowledgmentDescription?: string;
    providerPayAmountLocalExtrasDescription?: string;
    shiftCompleteAcknowledgment: boolean;
    shiftCompleteAcknowledgmentDateTime: string;
    shiftCompleteAcknowledgmentTime: string;
    reoccurance: string;
    requestId: string;
    contractHours: {
        monday: string;
        tuesday: string;
        wednesday: string;
        thursday: string;
        friday: string;
        saturday: string;
        sunday: string;
        totalHours: string;
        variableHours: string;
    };
    editAllowed: string;
    billPeriod: string;
    expectedFee: string;
    accepted_By: string,
    approved_By: string,
    isAccrual: boolean,
    hardApproval: string,
    holdPayment: string,
    lastUpdatedOn: string,
    lastUpdatedOnTZ:string,
    lastUpdatedBy:string,
    createdBy:string,
    dateCreated:string,
    accrual: {

        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    paymentRequest: {
        id: string,
        fasterPayment: string,
        description: string,
    } | null,
    workerStatusId: string,
    workerStatusValue: string,
    venueName: string,
    fasterPayment: boolean,
    fasterPaymentWithdrawn: boolean,
    advancedpayment: boolean,
    earlyPay: boolean,
    originator: {
        id: number,
        value: string    
    } | null,
    batchId: string,
    childRecordItemType: string,
    clientAmount: string,
    venuePostCode: string,
    venueTownCity: string,
    timeDecimal: string,
    timeDiffIndicator: string,
    quantityDiffIndicator: string,
    gigTenseFlag: string,
    scheduled_Hours_Units: string,
    imageLink: string,
    previousHoursRunningTotal: string,
    previousQuantityRunningTotal: string,
    entertainmentDateInfo: string,
    address: string,
    venue_Phone: string,
    contacts: Contact | null,
    rateDiffIndicator: string,
    queries: BillableItemQuery | null,
    feedback: string,
    paymentRequestID: string,
    paymentRequestStatusID: string,
    paymentRequestStatus: string,
    isUserAmendable: boolean,
    scheduledStartTime: string,
    scheduledFinishTime: string,
    billableStartTime: string,
    billableFinishTime: string,
    serviceSubTypeValue: string,
    typeValue: string,
    updateOrCorrection: string,
}

export interface BillableItemQuery {
    billableItemId: number,
    businessEntityId: number
    case_ID: number,
    d365_Case_Incident_Id: number,
    d365_Link_ID: number,
    queryPriorityId: number,
    query_Description: string,
    query_Status: number
}

export interface Contact {
    contactId: string,
    title: string,
    name: string,
    firstName: string,
    lastName: string,
    mobileTelephone: string,
    businessTelephone: string,
    otherTelephone: string,
    email: string,
    addressStreet1: string,
    addressStreet2: string,
    city: string,
    state: string,
    postCode: string,
    emailNotification: string,
    dateOfBirth: string,
    utr: string,
    ni: string,
    primarySecurityContactId: string,
    active: string,
    businessEntityId: string
}

// virtual object for adjustment scenario

export interface virtualBillableItemObject {
    billable_Start: string;
    billable_Finish: string;
    billable_Minutes: string;
    rate_Decimal: string;
    name: string;
    identification_Number: string;
    noCharge: string;
    cover: string;
    absent: string;
    equipmentQuantity: string;
    providerId: string;
    performanceInformation: string;
    typeId: string;
    genre: string;
    performanceInformationSetsNumber: string;
    performanceInformationSetsDuration: string;
    equipmentRequired: string;
    shift_End: string;
    shift_Start: string;
    otherDescription: string;
}

// Calculation response
export interface CalculationResponse {
    billableStartTime: string;
    billableFinishTime: string;
    billableMinutes: string;
    billableQuantity: string;
    billableRate: string;
    providerPayAmount: string;
    providerPayAmountLocal: string;
    providerPayAmountLocalCurrency: string;
    providerPayTaxApplicable: boolean;
    providerPayTaxLocalCcy: string;
    providerPayTotalLocalCcy: string;
    grossProfitAmount: string;
    grossProfitAmountLocalCurrency: string;
    grossProfitAmountLocal: string;
    grossProfitPercentage: string;
    grossProfitTaxApplicable: boolean;
    grossProfitTaxLocalCcy: string;
    grossProfitTotalLocalCcy: string;
    clientBillableAmount: string;
    clientBillableAmountLocal: string;
    clientBillableAmountLocalCurrency: string;
    clientOriginalBillableAmount: string;
    clientBillableTaxLocalCcy: string;
    clientBillableTotalLocalCcy: string;
    clientTaxApplicable: boolean;
    isAccrual: boolean;
    providerPayAmountLocalExtras: string;
    providerPayAmountLocalWOExtras: string;
    providerFasterPayFee: string;
    providerFasterPayOriginalAmount: string;
    rate: string;
    accrual: {
        id: number,
        accrualTypeId: number,
        accrualStatusId: number,
        accrualBillableItemId: number,
        accrualInvoiceId: number,
        providerPayAmountAccrual: number,
        providerPayAmountTaxAccrual: number,
        providerPayAmountTotalAccrual: number,
        grossProfitAmountAccrual: number,
        grossProfitAmountTaxAccrual: number,
        grossProfitAmountTotalAccrual: number,
        clientBillableAmountAccrual: number,
        clientBillableAmountTaxAccrual: number,
        clientBillableAmountTotalAccrual: number,
        lastUpdatedOn: string,
        lastUpdatedBy: number
    } | null,
    validationErrors: [];
}

export interface RaiseQueryFormData {
    id: string;
    queryTitle: string;
    queryDescription: string;
    queryTypeLookupID: string;
    contactId: string;
    contactName: string;
    companyId: string;
    company: string;
    d365LinkID: string;
    resolutionReason: string;
    billableItemId: string;
    queryStatusId: string;
    queryStatus: string;
    dateCreated: string;
    clientId: string;
    client: string;
    venueId: string;
    venue: string;
    venueName: string;
    comments: string;
    lastUpdate: string;
    assignedTo: string;
    raisedByContactName: string;
    raisedByContactTelNumber: string;
    queryTypeText?: string;
    serviceTypeId: string;
    linkedId: string;
    navigateFrom: string;
}

export const EquipmentType = {
    Radio: "121",
    BodyCam: "122",
    Clicker: "123",
    Earpiece: "124",
    Wand: "125"
};

export const RoleTypeForEquipment = {
    Radio: "Radio",
    BodyCam: "Bodycam",
    Clicker: "Clicker",
    Earpiece: "Earpiece",
    Wand: "Wand"
};

export const IdentificationType = {
    SIALicense: "10",
};

/**
 * REST services for the BillableItem resource.
 */
class AddEditPopUpService {
    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getEditViewData(id: number): Promise<AxiosResponse<any>> {
        return _get(`${EDIT_VIEW_API_URL}/${id}`);
    }
    // getEditViewDataAll(items: any): Promise<AxiosResponse<any>> {
    //     // axios.all(items.map((item:any)=>{
    //     //     let id = parseInt(item.id);
    //     //     return _get(`${EDIT_VIEW_API_URL}/${id}`);
    //     // }));

    //     // axios.all(items.map((item:any)=>{
    //     //     let id = parseInt(item.id);
    //     //     return _get(`${EDIT_VIEW_API_URL}/${id}`);
    //     // }));

    // }

    getEditViewDataAll(items: any): Promise<any[]> {
        //return Promise.all(URLs.map(fetchData));
        return Promise.all(
            items.map((item:any)=>{
                let id = parseInt(item.id);
                return _get(`${EDIT_VIEW_API_URL}/${id}`); 
            })
        )
      }
      
    // Enrichment field calculations.
    uploadFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(CALCULATION_API, data);
    }

    // Enrichment payment request form field calculations.
    uploadPaymentFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(PAYMENT_CALCULATION_API, data);
    }
    // Billable Item update request
    // uploadEditAddData(editAddData: object): Promise<AxiosResponse<any>> {
    //     return _post(EDIT_VIEW_API_URL, editAddData);
    // }
    //Post the query to the server.
    uploadQueryFormData(data: object): Promise<AxiosResponse<any>> {
        return _post(RAISE_QUERY_API, data);
    }

    /**
     * Retrieve all tax applicable statuses for all business entities
     */
    getTaxApplicableStatus(): Promise<AxiosResponse<any>> {
        return _get(TAX_APPLICABLE_API);
    }

    // Billable Item update request
    uploadEditAddDataV2(editAddData: object[]): Promise<AxiosResponse<any>> {
        return _post(EDIT_VIEW_API_URL_V2, editAddData);
    }
    // Billable Item update request
    uploadEditAddDataV3(editAddData: object[]): Promise<AxiosResponse<any>> {
        return _post(EDIT_VIEW_API_URL_V3, editAddData);
    }

    // Rounded and Not rounded amount calculation
    requestAmountCalculation(data: object): Promise<AxiosResponse<any>> {
        return _post(REQUEST_CALCULATION, data);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default AddEditPopUpService;
