import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DatePanel from "../side-bar/DatePanel";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import PaymentPrepareComponent from "./PaymentPrepareComponent";
import paymentFormUtils, { PaymentFormData } from "../Payments/PaymentFormUtils";
import PaymentsAndChecksComponent from "./PaymentsAndChecksComponent";
import PaymentStatusFlow from "../Payments/PaymentStatusFlow";
import PaymentDocumentSection from "./PaymentDocumentSection";
import PaymentSent from "./PaymentSent";
import PaymentInvoiceSection from "./PaymentInvoiceSection";
import PaymentNotificationSection from "./PaymentNotificationSection";
import PaymentEmailNotificationSection from "./PaymentEmailNotificationSection";
import { paymentStatus } from "../../services/PaymentService";
import PaymentPaidSection from "./PaymentPaidSection";
import PaymentRemittanceSection from "./PaymentRemittanceSection";
import { faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { custom } from "devextreme/ui/dialog";

interface PaymentsV2PageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
}

interface PaymentsV2PageState {
    paymentStatus: string;
    isClickedRestart: boolean;
    isRestartDisable: boolean;
    paymentCycleId: number;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    paymentData: PaymentFormData;
    isClickedLockedShift: boolean;
    isShowIncludedPayments: boolean;
    isPrepareDisable: boolean;
    status: string;
    isPrepareClicked: boolean;
    isSuccessRestart: boolean;
    loadPanelVisible: boolean;
    clickedOnButton: string;
    statusId: string;
    refreshPage: boolean;
    isShowDocumentSection: boolean;
    isShowPaymentSendSection: boolean;
    isShowInvoiceSection: boolean;
    isShowPaymentPaid: boolean;
    isShowPaymentRemittance: boolean;
}

class PaymentsV2Page extends Component<PaymentsV2PageProps> {
    //Initialize the component's state.
    state: PaymentsV2PageState;
    utils: paymentFormUtils;
    constructor(props: PaymentsV2PageProps) {
        super(props);
        this.utils = new paymentFormUtils();
        var convertedEditPaymentData = this.utils.initializePaymentItems("");
        this.state = {
            paymentStatus: paymentStatus.Draft,
            isClickedRestart: false,
            isRestartDisable: false,
            paymentCycleId: 0,
            calculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            paymentData: convertedEditPaymentData,
            isClickedLockedShift: false,
            isShowIncludedPayments: false,
            isPrepareDisable: false,
            status: paymentStatus.Draft,
            isPrepareClicked: false,
            isSuccessRestart: false,
            loadPanelVisible: false,
            clickedOnButton: "",
            statusId: paymentStatus.Draft,
            refreshPage: false,
            isShowDocumentSection: false,
            isShowPaymentSendSection: false,
            isShowInvoiceSection: false,
            isShowPaymentPaid: false,
            isShowPaymentRemittance: false,
        };
    }
    handleRestartOnClick = (event: any) => {
        let Restart = custom({
            title: "Confirm Restart?",
            messageHtml: "<b>Please confirm you wish to restart this cycle?</b>",
            buttons: [
                {
                    text: "Cancel",
                },
                {
                    text: "Confirm",
                    onClick: (isClickedRestart) => {
                        this.setState({
                            isClickedRestart: isClickedRestart,
                            status: paymentStatus.Pending,
                        });
                    },
                },
            ],
        });
        Restart.show();
    };
    // A helper function that would fire when the "Restart" button is clicked.
    onRestartClicked = (isClickedRestart: boolean) => {
        this.handleRestartOnClick(isClickedRestart);
    };

    onReceiveData = (
        paymentCycleId: string,
        formData: PaymentFormData,
        status: string,
        isRestartDisable: boolean,
        isShowIncludedPayments: boolean,
        isSuccessRestart: boolean,
        statusId: string
    ) => {
        this.setState((prevState: PaymentsV2PageState) => ({
            paymentCycleId: paymentCycleId,
            paymentData: formData,
            isShowIncludedPayments: isShowIncludedPayments,
            status: status,
            paymentStatus: status,
            statusId: statusId ? statusId : formData.statusId,
            isSuccessRestart: isSuccessRestart ? !prevState.isSuccessRestart : prevState.isSuccessRestart,
            isPrepareClicked: isSuccessRestart ? prevState.isPrepareClicked : !prevState.isPrepareClicked,
            isRestartDisable: isRestartDisable,
        }));
    };

    setCalculationsCallback = (calculationsObject: any) => {
        if (calculationsObject.total || calculationsObject.subTotal || calculationsObject.tax) {
            this.setState({
                calculationsObject: {
                    ...this.state.calculationsObject,
                    total: calculationsObject.total,
                    subTotal: calculationsObject.subTotal,
                    tax: calculationsObject.tax,
                },
            });
        }
    };

    onClickPaymentsStatus = (clickedOn: string, status: string, statusId: string) => {
        this.setState({
            status: status,
            clickedOnButton: clickedOn,
            paymentStatus: status,
            statusId: statusId,
        });
    };

    //A callback function that would trigger when the "Produce" button is clicked.
    onPaymentProduced = (status: string, statusId: string, isRestartDisable: boolean) => {
        this.setState({
            status: status,
            paymentStatus: status,
            statusId: statusId,
            isRestartDisable: isRestartDisable,
        });
    };

    // A function which is invoked when the "Save" button is clicked on the Summary panel.
    onSavePaymentData = (paymentCycleId: string, formData: PaymentFormData) => {
        this.setState({
            paymentCycleId: paymentCycleId,
            paymentData: formData,
            statusId: formData.statusId,
            isRestartDisable:
                formData.statusId == paymentStatus.PaymentAdvised ||
                paymentStatus.PaymentSettled ||
                paymentStatus.PaymentRemittanceRequested ||
                paymentStatus.PaymentRemitted
                    ? true
                    : false,
        });
    };

    //A callback function that would trigger when the "Paid" button is clicked.
    onPaymentPaid = (status: string, statusId: string) => {
        this.setState({
            status: status,
            paymentStatus: status,
            statusId: statusId,
            isRestartDisable: true,
        });
    };

    //A callback function that would trigger when the "Send Remittances" button is clicked.
    onPaymentRemitted = (status: string, statusId: string) => {
        this.setState({
            status: status,
            paymentStatus: status,
            statusId: statusId,
            isRestartDisable: true,
        });
    };

    syncDateFields = () => {};

    onRefreshBillingStatusClicked = () => {
        this.setState((prevState: PaymentsV2PageState) => ({
            refreshPage: !prevState.refreshPage,
        }));
    };

    render() {
        var viewDisabled: boolean = this.props.location.state?.viewDisabled;
        var title: string = "";
        var { statusId } = this.state;
        if (this.props.location.state && this.props.location.state.id) {
            title = "Payments - Edit Payment";
        } else {
            title = "Payments - New Payment";
        }
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixed={true}>
                    <DatePanel />
                    <BulletinPanel />
                </SideBarTemplate>
                <section className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mb-3">
                        <h2>
                            <b>{title}</b>
                            <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current billing status"
                                onClick={this.onRefreshBillingStatusClicked}
                            >
                                <FontAwesomeIcon icon={faSync} />
                            </button>
                        </h2>
                        <PaymentStatusFlow
                            statusId={this.state.statusId}
                            onRestartClicked={this.onRestartClicked}
                            isRestartDisable={viewDisabled ? true : this.state.isRestartDisable}
                        />
                        <PaymentPrepareComponent
                            isClickedLockedShift={this.state.isClickedLockedShift}
                            location={this.props.location}
                            onReceiveData={this.onReceiveData}
                            savePaymentData={this.onSavePaymentData}
                            calculationsObject={this.state.calculationsObject}
                            isClickedRestart={this.state.isClickedRestart}
                            status={this.state.status}
                            clickedOnButton={this.state.clickedOnButton}
                            statusId={this.state.statusId}
                            refreshPage={this.state.refreshPage}
                            history={this.props.history}
                        />
                        {this.state.isShowIncludedPayments ? (
                            <PaymentsAndChecksComponent
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId.toString()}
                                paymentData={this.state.paymentData}
                                onClickPaymentsStatus={this.onClickPaymentsStatus}
                                statusId={this.state.statusId}
                                isClickedRestart={this.state.isClickedRestart}
                                isPrepareClicked={this.state.isPrepareClicked}
                                isSuccessRestart={this.state.isSuccessRestart}
                                calculationObject={this.state.calculationsObject}
                                setCalculationsCallback={this.setCalculationsCallback}
                            />
                        ) : null}
                        {statusId &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted ? (
                            <PaymentDocumentSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                onPaymentProduced={this.onPaymentProduced}
                                statusId={statusId}
                                invoiceSentDate={null}
                                syncDateFields={this.syncDateFields}
                                calculationsObject={this.state.calculationsObject}
                            />
                        ) : (
                            <></>
                        )}
                        {statusId &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackFailed ? (
                            <PaymentSent
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                onPaymentProduced={this.onPaymentProduced}
                                statusId={statusId}
                                invoiceSentDate={null}
                                syncDateFields={this.syncDateFields}
                                calculationsObject={this.state.calculationsObject}
                                location={this.props.location}
                                history={this.props.history}
                            />
                        ) : (
                            <></>
                        )}
                        {statusId &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackFailed &&
                        statusId != paymentStatus.PaymentPackReady ? (
                            <PaymentNotificationSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                onPaymentProduced={this.onPaymentProduced}
                                statusId={statusId}
                                notificationSentDate={null}
                                syncDateFields={this.syncDateFields}
                                calculationsObject={this.state.calculationsObject}
                            />
                        ) : (
                            <></>
                        )}
                        {statusId &&
                        statusId != paymentStatus.PaymentNotificationRequested &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackReady &&
                        statusId != paymentStatus.PaymentPackFailed &&
                        statusId != paymentStatus.PaymentPaid ? (
                            <PaymentEmailNotificationSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                onPaymentProduced={this.onPaymentProduced}
                                statusId={statusId}
                                notificationSentDate={null}
                                syncDateFields={this.syncDateFields}
                                calculationsObject={this.state.calculationsObject}
                            />
                        ) : (
                            <></>
                        )}
                        {statusId &&
                        statusId != paymentStatus.PaymentNotificationRequested &&
                        statusId != paymentStatus.PaymentNotification &&
                        statusId != paymentStatus.PaymentEmailNotificationRequested &&
                        statusId != paymentStatus.PaymentPaid &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackReady &&
                        statusId != paymentStatus.PaymentPackFailed &&
                        statusId != paymentStatus.PaymentPaid ? (
                            <PaymentInvoiceSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                onPaymentProduced={this.onPaymentProduced}
                                statusId={statusId}
                                invoiceSentDate={null}
                                syncDateFields={this.syncDateFields}
                                calculationsObject={this.state.calculationsObject}
                            />
                        ) : (
                            <></>
                        )}                        
                        {statusId &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackReady &&
                        statusId != paymentStatus.PaymentPackFailed &&
                        statusId != paymentStatus.PaymentPaid &&
                        statusId != paymentStatus.PaymentNotificationRequested &&
                        statusId != paymentStatus.PaymentEmailNotificationRequested &&
                        statusId != paymentStatus.PaymentEmailNotification &&
                        statusId != paymentStatus.PaymentNotification &&
                        statusId != paymentStatus.PaymentAdviseRequested ? (
                            <PaymentPaidSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                statusId={this.state.statusId}
                                invoiceSentDate={null}
                                calculationsObject={this.state.calculationsObject}
                                syncDateFields={this.syncDateFields}
                                onPaymentProduced={this.onPaymentProduced}
                                setCalculationsCallback={this.setCalculationsCallback}
                            />
                        ) : (
                            <> </>
                        )}
                        {statusId &&
                        statusId != paymentStatus.Draft &&
                        statusId != paymentStatus.Pending &&
                        statusId != paymentStatus.PaymentNetted &&
                        statusId != paymentStatus.PaymentChecked &&
                        statusId != paymentStatus.PaymentPackRequested &&
                        statusId != paymentStatus.PaymentPackFailed &&
                        statusId != paymentStatus.PaymentPackReady &&
                        statusId != paymentStatus.PaymentAdviseRequested &&
                        statusId != paymentStatus.PaymentNotificationRequested &&
                        statusId != paymentStatus.PaymentEmailNotificationRequested &&
                        statusId != paymentStatus.PaymentEmailNotification &&
                        statusId != paymentStatus.PaymentNotification &&
                        statusId != paymentStatus.PaymentPaid &&
                        statusId != paymentStatus.PaymentAdvised ? (
                            <PaymentRemittanceSection
                                viewDisabled={viewDisabled}
                                paymentCycleId={this.state.paymentCycleId}
                                paymentData={this.state.paymentData}
                                statusId={this.state.statusId}
                                invoiceSentDate={null}
                                calculationsObject={this.state.calculationsObject}
                                onPaymentProduced={this.onPaymentProduced}
                            />
                        ) : (
                            <> </>
                        )}
                    </header>
                </section>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(PaymentsV2Page);
