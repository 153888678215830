import React, { MouseEvent } from "react";
import axios, { AxiosResponse,AxiosError } from "axios";
import {
    FileUploader,
    Button,
    LoadIndicator,
    TextBox,
    TextArea,
    TagBox,
    SelectBox,
    DateBox,
    RadioGroup,
    CheckBox,
    LoadPanel,
    Popup,
    ScrollView
} from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import CustomStore from "devextreme/data/custom_store";
import ArtistService, {
    PhotoIdType,
    OtherType,
    Types,
    AgreementType
} from "../../services/ArtistService";
import { ConfigurableTextIndexes } from "../../services/configurationValueService";
import {
    FileUploaderOnValueChangedEvent,
    FileUploadOnInitializedEvent,
    TextBoxOnValueChangedEvent,
    TagBoxOnValueChangeEvent,
    DateBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    CheckBoxOnValueChanged,
    OnRowUpdatingEvent,
    OnRowInsertingEvent,
    OnRowRemovingEvent,
    onEditCanceledEvent,
} from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import artistProfileUtils, { ArtistPageFormData, genreList, linkTypeInfo, musicTypeList, typeList } from "./ArtistProfileUtilitise";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import StarRating from "../FindAnArtist/starRating";
import FavouriteButton from "../FindAnArtist/favouriteButton";
import "../../../assets/images/event-UK-image.jpeg";
import { withTranslation, WithTranslation } from "react-i18next";
import sharedUtils from "../grid/sharedUtilities";
import GlobalService from "../../services/GlobalService";
import { isValidImageFileType, isValidDocumentFileType } from "../../common/CommonUIUtils";
import { faThumbsDown, faFaceThinking, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { feedbackScoreType } from "../Outstanding-Actions/OutstandingActionsUtilities";
import DataGrid, { Paging, FilterRow, Column, FilterPanel, GroupPanel, Pager, SearchPanel, Scrolling, Selection, Editing } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import moment from "moment";
import ShiftMatrixGridContents from "../ShiftMatrix/ShiftMatrixGridContents";
import SimpleGridService, { ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";

type NullableDate = Date | null | undefined;
let signingCeremonyWindow:any = null;

export interface LookUpTypeItemForArtistType {
    id: number;
    value: string;
    parentMappingId: string;
}

export interface LookUpTypeItemForServiceType {
    serviceTypeLookupId: number;
    value: string;
    parentMappingId: string;
}

interface ArtistProfileProps extends WithTranslation {
    history?: any;
    location?: any;
    mode?: string;
    id?: string;
    businessEntityId?: string;
    serviceType?: any;
    handlePopUpClose: (isPopupClose: boolean, isEditMode: string) => void;
    handleFeedbackUpdate: (score: string, trustedUser: string) => void;
    handleAddArtist: (isAdded: string) => void;
}

interface ArtistProfileState {
    isEditMode: boolean;
    alternatePhoto1Id: string;
    alternatePhoto2Id: string;
    alternatePhoto3Id: string;
    alternatePhoto4Id: string;
    alternatePhoto5Id: string;
    primaryPhotoId: string;
    profilePhotoId: string;
    photoIdProofId: string;
    addressProofId: string;
    isAlternatePhoto1Added: boolean;
    isAlternatePhoto2Added: boolean;
    isAlternatePhoto3Added: boolean;
    isAlternatePhoto4Added: boolean;
    isAlternatePhoto5Added: boolean;
    isPrimaryPhotoAdded: boolean;
    isProfilePhotoAdded: boolean;
    isPhotoIdProofAdded: boolean;
    isAddressProofAdded: boolean;
    isAgreementProofAdded: boolean;
    loadIndicatorVisibleForUpload1: boolean;
    loadIndicatorVisibleForUpload2: boolean;
    loadIndicatorVisibleForUpload3: boolean;
    loadIndicatorVisibleForUpload4: boolean;
    loadIndicatorVisibleForUpload5: boolean;
    loadIndicatorVisibleForPrimaryUpload: boolean;
    loadIndicatorVisibleForProfileUpload: boolean;
    loadIndicatorVisibleForPhotoIdProofUpload: boolean;
    loadIndicatorVisibleForAddressProofUpload: boolean;
    loadIndicatorVisibleForAgreementDocUpload: boolean;
    displayWrongFileTypeError: boolean;
    selectedAlternateFile1: File[];
    selectedAlternateFile1Content: string;
    selectedAlternateFile2: File[];
    selectedAlternateFile2Content: string;
    selectedAlternateFile3: File[];
    selectedAlternateFile3Content: string;
    selectedAlternateFile4: File[];
    selectedAlternateFile4Content: string;
    selectedAlternateFile5: File[];
    selectedAlternateFile5Content: string;
    selectedPrimaryFile: File[];
    selectedProfileFile: File[];
    selectedPrimaryFileContent: string;
    selectedProfileFileContent: string;
    selectedPhotoIdProofFile: File[];
    selectedPhotoIdProofFileContent: string;
    selectedPhotoIdProofFileIsInvalid: boolean;
    selectedAddressProofFile: File[];
    selectedAddressProofFileContent: string;
    selectedAddressProofFileIsInvalid: boolean;
    selectedAgreementDocFileContent: string;
    selectedAgreementDocFile: File[];
    selectedAgreementDocIsInvalid: boolean;
    alternatePhoto1Holder: string;
    alternatePhoto2Holder: string;
    alternatePhoto3Holder: string;
    alternatePhoto4Holder: string;
    alternatePhoto5Holder: string;
    photoIdProofLink: string;
    primaryPhotoHolder: string;
    profilePhotoHolder: string;
    artistTypeLookUp: LookUpTypeItemForArtistType[];
    genreTypeLookUp: LookupTypeItem[];
    musicTypeLookUp: LookupTypeItem[];
    editViewDataItem: ArtistPageFormData;
    alternatePhoto1Link: string;
    alternatePhoto2Link: string;
    alternatePhoto3Link: string;
    alternatePhoto4Link: string;
    alternatePhoto5Link: string;
    primaryPhotoLink: string;
    profilePhotoLink: string;
    youTubeLink: [];
    trackLink: [];
    webSiteLink: string;
    youtubeVideoHolder: string;
    trackVideoHolder: string;
    instagramVideoHolder: string;
    artistTypeList: [];
    genreTypeList: [];
    musicTypeList: [];
    artistTypeValues: string;
    genreTypeValues: string;
    musicTypeValues: string;
    loadIndicatorForDeactivation: boolean;
    isDeactivateButtonDisable: boolean;
    businessTypeLU: LookupTypeItem[];
    tax_applicable: LookupTypeItem[];
    photoIdLookUp: LookupTypeItem[];
    agreementLookUp: LookupTypeItem[];
    photoLookUpId: string;
    photoLookUpValue: string;
    linkTypeInfo: linkTypeInfo[];
    loadPanelVisible: boolean;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
    photoIdSelectedOrNotMsg: string;
    selfBillingErrors:[];
    isBankDetailsMandatory: boolean;
    configurationText: LookupTypeItem[];
    isComingFromHeader: boolean;
    photoProofHolder: any[];
    agreementHolder: any[];
    addressProofHolder: string;
    isAddressProofVisible: boolean;
    isPhotoIdProofVisible: boolean;
    isAgreementVisible: boolean;
    otherDescriptionVal: string;
    hideOtherDescVal: boolean;
    isThumbsUpClicked: boolean;
    isThumbsDownClicked: boolean;
    isFaceThinkingClicked: boolean;
    isAllTrackAdding: boolean;
    vatDetails: any;
    showVatDetails: boolean;
    bankDetails: [];
    showBankDetails: boolean;
    showChangeEmailPopup: boolean;
    showEmailValidationPopup: boolean;
    email: string;
    selectedBankData: any;
    serviceTypeLookUp: LookUpTypeItemForServiceType[];
    serviceTypeClientLookUp: LookUpTypeItemForServiceType[];
    serviceTypeList: [];
    agreementDate: NullableDate;
    agreementTypeId: string;
    signURL: string;
    contactCreatedDateTime: string;
    userCreatedDateTime: string;
    showUploadWarningPopup: boolean;
    shiftsPopupVisible: boolean;
    viewShiftsDataSource: ShiftMatrixRemoteGridRowItem[];
    loadIndicatorForPage:boolean;
}

class ArtistProfile extends React.Component<ArtistProfileProps> {
    conversionUtils: sharedUtils;
    utils: artistProfileUtils;
    artistService: ArtistService;
    shiftService: SimpleGridService;
    state: ArtistProfileState;
    lookupService: lookupService;
    taxApplicable: LookupTypeItem[];
    fileUploadElementForAlternate1: any;
    fileUploadElementForAlternate2: any;
    fileUploadElementForAlternate3: any;
    fileUploadElementForAlternate4: any;
    fileUploadElementForAlternate5: any;
    fileUploadElementForPrimaryPhoto: any;
    fileUploadElementForProfilePhoto: any;
    fileUploadElementForPhotoIdProof: any;
    fileUploadElementForDocument: any;
    constructor(props: ArtistProfileProps) {
        super(props);
        this.conversionUtils = new sharedUtils();
        this.utils = new artistProfileUtils();
        this.artistService = new ArtistService();
        this.shiftService = new SimpleGridService();
        this.lookupService = new lookupService();
        this.fileUploadElementForAlternate1 = undefined;
        this.fileUploadElementForAlternate2 = undefined;
        this.fileUploadElementForAlternate3 = undefined;
        this.fileUploadElementForAlternate4 = undefined;
        this.fileUploadElementForAlternate5 = undefined;
        this.fileUploadElementForPrimaryPhoto = undefined;
        this.fileUploadElementForProfilePhoto = undefined;
        this.fileUploadElementForPhotoIdProof = undefined;
        this.fileUploadElementForDocument = undefined;
        this.taxApplicable = this.lookupService.getDecisionTypes();
        // Initialize state
        var convertedEditViewDataItem = this.utils.initializeArtistProfileItem(this.getId());

        this.state = {
            isEditMode: false,
            alternatePhoto1Id: "",
            alternatePhoto2Id: "",
            alternatePhoto3Id: "",
            alternatePhoto4Id: "",
            alternatePhoto5Id: "",
            primaryPhotoId: "",
            profilePhotoId: "",
            photoIdProofId: "",
            addressProofId: "",
            displayWrongFileTypeError: false,
            isAlternatePhoto1Added: false,
            isAlternatePhoto2Added: false,
            isAlternatePhoto3Added: false,
            isAlternatePhoto4Added: false,
            isAlternatePhoto5Added: false,
            isPrimaryPhotoAdded: false,
            isProfilePhotoAdded: false,
            isPhotoIdProofAdded: false,
            isAddressProofAdded: false,
            isAgreementProofAdded: false,
            loadIndicatorVisibleForUpload1: false,
            loadIndicatorVisibleForUpload2: false,
            loadIndicatorVisibleForUpload3: false,
            loadIndicatorVisibleForUpload4: false,
            loadIndicatorVisibleForUpload5: false,
            loadIndicatorVisibleForPrimaryUpload: false,
            loadIndicatorVisibleForProfileUpload: false,
            loadIndicatorVisibleForPhotoIdProofUpload: false,
            loadIndicatorVisibleForAddressProofUpload: false,
            loadIndicatorVisibleForAgreementDocUpload: false,
            selectedAlternateFile1: [],
            selectedAlternateFile1Content: "",
            selectedAlternateFile2: [],
            selectedAlternateFile2Content: "",
            selectedAlternateFile3: [],
            selectedAlternateFile3Content: "",
            selectedAlternateFile4: [],
            selectedAlternateFile4Content: "",
            selectedAlternateFile5: [],
            selectedAlternateFile5Content: "",
            selectedPrimaryFile: [],
            selectedProfileFile: [],
            selectedPrimaryFileContent: "",
            selectedProfileFileContent: "",
            selectedPhotoIdProofFile: [],
            selectedPhotoIdProofFileContent: "",
            selectedPhotoIdProofFileIsInvalid: false,
            selectedAddressProofFile: [],
            selectedAddressProofFileContent: "",
            selectedAddressProofFileIsInvalid: false,
            selectedAgreementDocFileContent: "",
            selectedAgreementDocFile: [],
            selectedAgreementDocIsInvalid: false,
            alternatePhoto1Holder: "",
            alternatePhoto2Holder: "",
            alternatePhoto3Holder: "",
            alternatePhoto4Holder: "",
            alternatePhoto5Holder: "",
            photoIdProofLink: "",
            primaryPhotoHolder: "",
            profilePhotoHolder: "",
            artistTypeLookUp: [],
            editViewDataItem: convertedEditViewDataItem,
            alternatePhoto1Link: "",
            alternatePhoto2Link: "",
            alternatePhoto3Link: "",
            alternatePhoto4Link: "",
            alternatePhoto5Link: "",
            primaryPhotoLink: "",
            profilePhotoLink: "",
            youTubeLink: [],
            trackLink: [],
            webSiteLink: "",
            youtubeVideoHolder: "",
            trackVideoHolder: "",
            instagramVideoHolder: "",
            artistTypeList: [],
            loadIndicatorForDeactivation: false,
            isDeactivateButtonDisable: false,
            artistTypeValues: "",
            businessTypeLU: [],
            tax_applicable: [],
            photoIdLookUp: [],
            agreementLookUp: [],
            photoLookUpId: "",
            photoLookUpValue: "",
            linkTypeInfo: [],
            loadPanelVisible: false,
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
            photoIdSelectedOrNotMsg: "",
            selfBillingErrors:[],
            isBankDetailsMandatory: false,
            configurationText: [],
            isComingFromHeader: false,
            photoProofHolder: [],
            agreementHolder: [],
            addressProofHolder: "",
            isAddressProofVisible: false,
            isPhotoIdProofVisible: false,
            isAgreementVisible: false,
            genreTypeList: [],
            musicTypeList: [],
            genreTypeLookUp: [],
            musicTypeLookUp: [],
            genreTypeValues: "",
            musicTypeValues: "",
            otherDescriptionVal: "",
            hideOtherDescVal: true,
            isThumbsUpClicked: false,
            isThumbsDownClicked: false,
            isFaceThinkingClicked: false,
            isAllTrackAdding: true,
            vatDetails:{},
            showVatDetails: false,
            bankDetails: [],
            showBankDetails: false,
            showChangeEmailPopup: false,
            showEmailValidationPopup: false,
            email:"",
            selectedBankData: {},
            serviceTypeLookUp: [],
            serviceTypeClientLookUp: [],
            serviceTypeList: [],
            agreementDate: undefined,
            agreementTypeId: "",
            signURL: "",
            contactCreatedDateTime: "",
            userCreatedDateTime: "",
            showUploadWarningPopup: false,
            shiftsPopupVisible: false,
            viewShiftsDataSource: [],
            loadIndicatorForPage:false
        };
    }

    componentDidMount() {
        this.setState({
            isEditMode: this.props.mode === "edit",
        });
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.serviceType)
            .then(this.handleSuccessServiceTypeLookUp)
            .catch(this.handleStandardError);
        if (UserService.isUserInGroup(RoleGroupNames.Artist)) {
            this.artistTypeDropDown();
            this.genreTypeDropDown();
            this.musicTypeDropDown();
            this.businessTypeDropDown();
            this.linkTypeDropDown();
            this.AgreementTypeDropDown();
            this.configurationTypeText();
            if (this.props.businessEntityId) {
                this.artistService
                    .getArtistPageDataByQueryParam(parseInt(this.props.businessEntityId))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
            } else {
                this.artistService
                    .getArtistPageData()
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
            }
        } else if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            this.configurationTypeText();
            if (this.props.businessEntityId) {
                this.artistService
                    .getArtistPageDataByQueryParam(parseInt(this.props.businessEntityId))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
            } else {
                this.artistService
                    .getArtistPageDataById(parseInt(this.props.id ? this.props.id : this.props.location.state.id))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
            }
        } else if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            this.artistTypeDropDown();
            this.genreTypeDropDown();
            this.musicTypeDropDown();
            this.businessTypeDropDown();
            this.linkTypeDropDown();
            this.AgreementTypeDropDown();
            this.configurationTypeText();
            if (this.props.businessEntityId) {
                if(this.props.serviceType == 'ViewCompany'){
                    this.setState({loadIndicatorForPage:true});
                    this.artistService
                    .getBusinessEntityTypeIdDetails(parseInt(this.props.businessEntityId))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
                }
                else {
                    this.artistService
                    .getArtistPageDataByQueryParam(parseInt(this.props.businessEntityId))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
                }
            } else {
                this.artistService
                    .getArtistPageDataById(parseInt(this.props.id ? this.props.id : this.props.location.state.id))
                    .then(this.handleSuccessArtistViewPage)
                    .catch(this.handleStandardError);
            }
        }
        this.setBurgerMenuState();
    }

    handleSuccessServiceTypeLookUp = (response: AxiosResponse<any>) => {
        var serviceTypeLUItem: LookUpTypeItemForServiceType;
        var serviceTypeClientLUItem: LookUpTypeItemForServiceType;
        var serviceTypeLUItemArray: LookUpTypeItemForServiceType[];
        var serviceTypeLUItemClientArray: LookUpTypeItemForServiceType[];
        serviceTypeLUItemArray = [];
        serviceTypeLUItemClientArray = [];
        response.data.data.map((item: any) => {
            if(item.id !== "30"){
                serviceTypeLUItem = {
                    serviceTypeLookupId: parseInt(item.id),
                    value: item.value,
                    parentMappingId: "0",
                };
                serviceTypeLUItemArray.push(serviceTypeLUItem);
            }
            serviceTypeClientLUItem = {
                serviceTypeLookupId: parseInt(item.id),
                value: item.value,
                parentMappingId: "0",
            };
            serviceTypeLUItemClientArray.push(serviceTypeClientLUItem);
        });
        this.setState({
            serviceTypeLookUp: serviceTypeLUItemArray,
            serviceTypeClientLookUp: serviceTypeLUItemClientArray,
        });
    };

    getId = () => {
        if (this.props.id) {
            return this.props.id;
        } else if (this.props?.location?.state && this.props.location?.state?.id) {
            return this.props?.location?.state?.id.toString();
        }
        return "";
    };

    setBurgerMenuState = () => {
        this.setState({
            isComingFromHeader: this.props.location
                ? this.props.location.state
                    ? this.props.location.state.isFromBurgerMenu
                        ? this.props.location.state.isFromBurgerMenu
                        : false
                    : false
                : false,
        });
    };
    configurationTypeText = () => {
        this.artistService
            .getConfigurationText("configuration", "WebArtistProfile_")
            .then(this.handleSuccessConfigurationText)
            .catch(this.handleError);
    };

    handleSuccessConfigurationText = (response: AxiosResponse<any>) => {
        this.setState({
            configurationText: response.data.data,
        });
    };

    artistTypeDropDown = () => {
        this.lookupService
            .getArtistTypes(LookupTypeIndexes.offeringsType, "Entertainment", "artistwebprofile")
            .then(this.handleSuccessArtistTypeLookUp)
            .catch(this.handleError);
    };

    genreTypeDropDown = () => {
        this.lookupService.getLookupByLookupTypeIndex(LookupTypeIndexes.Genre)
            .then(this.handleSuccessGenreTypeLookUp)
            .catch(this.handleError);
    };

    musicTypeDropDown = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.MusicType)
            .then(this.handleSuccessMusicTypeLookUp)
            .catch(this.handleError);
    };

    linkTypeDropDown = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.linktype)
            .then(this.handleSuccessLinkTypeLookUp)
            .catch(this.handleError);
    };
    AgreementTypeDropDown = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.documentTypeLookup)
            .then(this.handleSuccessAgreementTypeLookUp)
            .catch(this.handleError);
    };
    setBankMandatoryFields = (value: string) => {
        if (value) {
            this.setState({
                isBankDetailsMandatory: true,
            });
        } else {
            this.setState({
                isBankDetailsMandatory: false,
            });
        }
    };

    businessTypeDropDown = () => {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.lookuptype)
            .then(this.handleSuccessLookUpType)
            .catch(this.handleError);
    };

    handleSuccessLinkTypeLookUp = (response: AxiosResponse<any>) => {
        var linkTypeItem = response.data.data;
        var photoIDTypeLUItem: LookupTypeItem;
        var photoIDTypeLUItemArray: LookupTypeItem[];
        photoIDTypeLUItemArray = [];
        if (linkTypeItem) {
            linkTypeItem.map((item: any, uniqueKey: number) => {
                if (item.id == 40 || item.id == 41 || item.id == 42 || item.id == 43) {
                    photoIDTypeLUItem = {
                        id: item.id,
                        value: item.value,
                        parentMappingId: "0",
                    };
                    photoIDTypeLUItemArray.push(photoIDTypeLUItem);
                }
            });
        }

        this.setState({
            photoIdLookUp: photoIDTypeLUItemArray,
        });
    };
    handleSuccessAgreementTypeLookUp = (response: AxiosResponse<any>) => {
        var typeItem = response.data.data;
        var agreementTypeLUItem: LookupTypeItem;
        var agreementTypeLUItemArray: LookupTypeItem[];
        agreementTypeLUItemArray = [];
        if (typeItem) {
            typeItem.map((item: any, uniqueKey: number) => {
                if (item.id == 25) {
                    agreementTypeLUItem = {
                        id: item.id,
                        value: item.value,
                        parentMappingId: "0",
                    };
                    agreementTypeLUItemArray.push(agreementTypeLUItem);
                }
            });
        }

        this.setState({
            agreementLookUp: agreementTypeLUItemArray,
        });
    };
    handleSuccessLookUpType = (response: AxiosResponse<any>) => {
        this.setState({
            businessTypeLU: response.data.data,
        });
    };
    handleSuccessArtistViewPage = (response: AxiosResponse<any>) => {
        let contactCreatedDateTime = "";
        let userCreatedDateTime = "";
        // Convert to proper data types
        var viewDataSource = this.utils.convertArtistProfileResponeToFormData(response.data.data);
        let agreementDate = null;
        let agreementTypeId = '';
        let agreementHolderObj = {};
        if(viewDataSource?.businessEntityDocument && viewDataSource?.businessEntityDocument.length && viewDataSource?.businessEntityDocument.length > 0){
            let filtered =  viewDataSource.businessEntityDocument.filter(a=>a.documentType == '25');
            //let filtered = filteredAll.sort((a, b) => parseInt(b.id) - parseInt(a.id));
            if( filtered &&  filtered.length && filtered.length > 0 ){
                agreementTypeId = filtered[0]?.documentType;
                //agreementHolder = filtered[0];
                agreementHolderObj = {
                    id:filtered[0]?.documentType,
                    link:filtered[0]?.displayLink, 
                    name:filtered[0]?.name
                }
                if(filtered[0]?.dateFrom){
               //let formattedDate = moment(viewDataSource.businessEntityDocument[0].dateFrom).format("DD/MM/YYYY");
               let formattedDate = moment(filtered[0].dateFrom).format("DD/MM/YYYY");
               agreementDate = formattedDate ? this.conversionUtils.convertStringToDate(formattedDate) : null;
                }
            }
        }
        // In case of Amend status will be pre set to "Approve"
        this.setState(
            {
                editViewDataItem: viewDataSource,
                feedBackScore: viewDataSource.score,
                agreementDate: agreementDate,
                agreementTypeId :agreementTypeId,
                agreementHolder : [agreementHolderObj]
                //agreementTypeId: viewDataSource.businessEntityDocument.length > 0 ? viewDataSource.businessEntityDocument[0]?.documentType : "",
                //agreementHolder: [{id:viewDataSource.businessEntityDocument[0]?.documentType, link:viewDataSource.businessEntityDocument[0]?.displayLink, name:viewDataSource.businessEntityDocument[0]?.name}],
            },
            () => {
                contactCreatedDateTime = this.contactLastUpdatedDisplay(this.state.editViewDataItem.contactInformation?.lastUpdatedOn, true);
                userCreatedDateTime = this.contactLastUpdatedDisplay(this.state.editViewDataItem.user?.lastUpdatedOn, true);
                this.linkInformationSet();
                this.setFeedbackState(viewDataSource);
            }
        );
        this.setState({
            contactCreatedDateTime: contactCreatedDateTime,
            userCreatedDateTime: userCreatedDateTime,
            loadIndicatorForPage:false
        })
    };

    contactLastUpdatedDisplay = (date:any, type:boolean) => {
        let finalFormattedDate: string = "";
        let stringFormattedDate;
        let formattedDate;
        if(date !== null && date !== "" && date !== undefined){
            let dateCreatedTime = date;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.conversionUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            if(type == true){
                stringFormattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
                formattedDate = this.conversionUtils.convertStringToddmmmyyyyForm(stringFormattedDate);
            }
            else {
                formattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
            }
            finalFormattedDate = `${formattedDate} ${formattedTime}`;            
        }
        return finalFormattedDate;
    };
    
    setFeedbackState = (dataSource : ArtistPageFormData) => {
         if(dataSource.feedbackScore == feedbackScoreType.thumbsUp){
            this.setState({
                isThumbsUpClicked: true
            });
         }
         else if(dataSource.feedbackScore == feedbackScoreType.faceThinking){
            this.setState({
                isFaceThinkingClicked: true
            });
         }
         else if(dataSource.feedbackScore == feedbackScoreType.thumbsDown){
            this.setState({
                isThumbsDownClicked: true
            });
         }
    }
    handleStandardError = (error: any) => {
        this.setState({loadIndicatorForPage:false});
        var respMessage: string = "Artist Page View load/ Post failed with response: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    handleSuccessArtistTypeLookUp = (response: AxiosResponse<any>) => {
        var artistTypeLUItem: LookUpTypeItemForArtistType;
        var artistTypeLUItemArray: LookUpTypeItemForArtistType[];
        artistTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            artistTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            artistTypeLUItemArray.push(artistTypeLUItem);
        });
        this.setState({
            artistTypeLookUp: artistTypeLUItemArray,
        });
    };

    handleSuccessGenreTypeLookUp = (response: AxiosResponse<any>) => {
        var genreTypeLUItem: LookupTypeItem;
        var genreTypeLUItemArray: LookupTypeItem[];
        genreTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            genreTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            genreTypeLUItemArray.push(genreTypeLUItem);
        });
        this.setState({
            genreTypeLookUp: genreTypeLUItemArray,
        });
    };

    handleSuccessMusicTypeLookUp = (response: AxiosResponse<any>) => {
        var musicTypeLUItem: LookupTypeItem;
        var musicTypeLUItemArray: LookupTypeItem[];
        musicTypeLUItemArray = [];
        response.data.data.map((item: any) => {
            musicTypeLUItem = {
                id: item.id,
                value: item.value,
                parentMappingId: "0",
            };
            musicTypeLUItemArray.push(musicTypeLUItem);
        });
        this.setState({
            musicTypeLookUp: musicTypeLUItemArray,
        });
    };
    handleError = (error: any) => {
        var respMessage: string = "Get Configuration Text service failed with response: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    linkInformationSet = () => {
        var alternatePhotoOne = "";
        var alternatePhotoTwo = "";
        var alternatePhotoThree = "";
        var alternatePhotoFour = "";
        var alternatePhotoFive = "";
        var primaryPhoto = "";
        var profilePhoto = "";
        var websiteUrl = "";
        let youtubeUrl:any = [];
        let trackUrl:any = [];
        var instagramHolderUrl = "";
        var photoLookUpId = "";
        var photoProof = "";
        var addressProof = "";
        if (this.state.editViewDataItem.linkInformation) {
            this.state.editViewDataItem.linkInformation.forEach((item: any, uniqueKey: number) => {
                if (item.linkTypeName.toLowerCase() == "primary photo") {
                    primaryPhoto = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "profile photo") {
                    profilePhoto = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "alternate photo 1") {
                    alternatePhotoOne = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "alternate photo 2") {
                    alternatePhotoTwo = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "alternate photo 3") {
                    alternatePhotoThree = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "alternate photo 4") {
                    alternatePhotoFour = item.displayLink;
                }
                if (item.linkTypeName.toLowerCase() == "alternate photo 5") {
                    alternatePhotoFive = item.displayLink;
                }
                // if (item.linkTypeName.toLowerCase() == "website") {
                //     websiteUrl = item.link;
                // }
                if (item.linkTypeName.toLowerCase() == "youtube") {
                    youtubeUrl.push({"link":item.link});
                }
                if (item.linkTypeName.toLowerCase() == "track1" || item.linkTypeName.toLowerCase() == "track2" || 
                    item.linkTypeName.toLowerCase() == "track3" || item.linkTypeName.toLowerCase() == "track4" || 
                    item.linkTypeName.toLowerCase() == "track5") {
                    trackUrl.push({"link":item.link});
                }
                if (
                    item.linkTypeName.toLowerCase() == "photo driving license" ||
                    item.linkTypeName.toLowerCase() == "passport" ||
                    item.linkTypeName.toLowerCase() == "national id card" ||
                    item.linkTypeName.toLowerCase() == "other id" ||
                    item.linkTypeName.toLowerCase() == "photoid"
                ) {
                    photoLookUpId = item.linkTypeId;
                    photoProof = item.displayLink;
                }
            });

            if(this.state.editViewDataItem.webSite){
                //if has website in object but missing in linkInformation array,then add it to the link information array
                //let links = this.state.editViewDataItem.linkInformation.filter(a=>a.linkTypeName.toLowerCase() == "website");
                //if(links?.length <= 0){
                    websiteUrl = this.state.editViewDataItem.webSite;
                    //this.linkInformationSetOnSuccess("Website", this.state.editViewDataItem.webSite, OtherType.Website);
                //}
            } else if(this.state.editViewDataItem.linkInformation) {
                let links = this.state.editViewDataItem?.linkInformation?.filter(a=>a.linkTypeName.toLowerCase() == "website");
                if(links && links.length && links.length > 0){
                    websiteUrl = links[0].link;
                }
            }

            this.setState({
                alternatePhoto1Holder: alternatePhotoOne,
                alternatePhoto2Holder: alternatePhotoTwo,
                alternatePhoto3Holder: alternatePhotoThree,
                alternatePhoto4Holder: alternatePhotoFour,
                alternatePhoto5Holder: alternatePhotoFive,
                primaryPhotoHolder: primaryPhoto,
                profilePhotoHolder: profilePhoto,
                photoProofHolder: [{id:photoLookUpId, link:photoProof}],
                addressProofHolder: addressProof,
                webSiteLink: websiteUrl,
                youTubeLink: youtubeUrl,
                trackLink: trackUrl,
                instagramVideoHolder: instagramHolderUrl,
                photoLookUpId: photoLookUpId,
            });
        }
       
        this.artistTypeSet();
        this.genreTypeSet();
        this.musicTypeSet();
        this.serviceTypeSet();
    };
    artistTypeSet = () => {
        var typeIdArray: number[];
        typeIdArray = [];
        var typeValueArray: string[];
        var artistTypeValue = "";
        let otherDescVal: string = "";
        typeValueArray = [];
        if (this.state.editViewDataItem.typeList) {
            this.state.editViewDataItem.typeList.forEach((item: any, uniqueKey: number) => {
                if (item.value && item.value.toLowerCase().includes("other")) {
                    otherDescVal = item.otherDescription;
                }
                typeIdArray.push(item.id);
                typeValueArray.push(item.value);
            });
        }
        artistTypeValue = typeValueArray.toString();
        this.setState({
            artistTypeList: typeIdArray,
            artistTypeValues: artistTypeValue,
            otherDescriptionVal: otherDescVal,
            hideOtherDescVal: otherDescVal ? false : true
        });
    };
    genreTypeSet = () => {
        var genreIdArray: string[];
        genreIdArray = [];
        var genreValueArray: string[];
        var genreTypeValue = "";
        genreValueArray = [];
        if (this.state.editViewDataItem.genreList) {
            this.state.editViewDataItem.genreList.forEach((item: any, uniqueKey: number) => {
                genreIdArray.push(item.id);
                genreValueArray.push(item.value);
            });
        }
        genreTypeValue = genreValueArray.toString();
        this.setState({
            genreTypeList: genreIdArray,
            genreTypeValues: genreTypeValue,
        });
    };
    musicTypeSet = () => {
        var musicIdArray: number[];
        musicIdArray = [];
        var musicValueArray: string[];
        var musicTypeValue = "";
        musicValueArray = [];
        if (this.state.editViewDataItem.musicTypeList) {
            this.state.editViewDataItem.musicTypeList.forEach((item: any, uniqueKey: number) => {
                musicIdArray.push(item.id);
                musicValueArray.push(item.value);
            });
        }
        musicTypeValue = musicValueArray.toString();
        this.setState({
            musicTypeList: musicIdArray,
            musicTypeValues: musicTypeValue
        });
    };
    serviceTypeSet = () => {
        var typeIdArray: number[];
        typeIdArray = [];
        var typeValueArray: string[];
        typeValueArray = [];
        if (this.state.editViewDataItem.serviceTypeList) {
            this.state.editViewDataItem.serviceTypeList.forEach((item: any, uniqueKey: number) => {
                typeIdArray.push(item.id);
                typeValueArray.push(item.value);
            });
        }
        this.setState({
            serviceTypeList: typeIdArray,
        });
    };
    onHidingWrongFileTypeError = () => {
        this.setState({ displayWrongFileTypeError: false });
    };
    //#region Alternate photo 1 upload
    onAlternatePhoto1UploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForAlternate1 = e.component;
    };
    onValueChangedForAlternate1 = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAlternateFile1Content: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAlternatePhoto1Added: true,
                    selectedAlternateFile1: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelAlternate1 = () => {
        this.setState({
            isAlternatePhoto1Added: false,
            selectedAlternateFile1Content: "",
        });
    };
    onSubmitAlternate1 = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForUpload1: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.AlternatePhoto1);
            formData.append("files[]", this.state.selectedAlternateFile1[0], this.state.selectedAlternateFile1[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleAlternatePhoto1UploadSuccess)
                .catch(this.handleAlternatePhoto1UploadUploadFailure);
        }
    };
    handleAlternatePhoto1UploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForAlternate1.reset();
        this.setState({
            alternatePhoto1Id: response.data.data.id,
            alternatePhoto1Holder: response.data.data.displayLink,
            alternatePhoto1Link: response.data.data.link,
            loadIndicatorVisibleForUpload1: false,
            isAlternatePhoto1Added: false,
        });
        this.linkInformationSetOnSuccess(
            "Alternate Photo 1",
            this.state.alternatePhoto1Link,
            OtherType.AlternatePhoto1,
            this.state.alternatePhoto1Id
        );
        // Reset the file uploader array
        this.setState({
            selectedAlternateFile1: [],
        });
    };
    handleAlternatePhoto1UploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForUpload1: false,
            isAlternatePhoto1Added: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Alternate Photo1 Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion

    //#region AlternatePhoto2
    onAlternatePhoto2UploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForAlternate2 = e.component;
    };

    onValueChangedForAlternate2 = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAlternateFile2Content: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAlternatePhoto2Added: true,
                    selectedAlternateFile2: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelAlternate2 = () => {
        this.setState({
            isAlternatePhoto2Added: false,
            selectedAlternateFile2Content: "",
        });
    };
    onSubmitAlternate2 = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForUpload2: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.AlternatePhoto2);
            formData.append("files[]", this.state.selectedAlternateFile2[0], this.state.selectedAlternateFile2[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleAlternatePhoto2UploadSuccess)
                .catch(this.handleAlternatePhoto2UploadUploadFailure);
        }
    };
    handleAlternatePhoto2UploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForAlternate2.reset();
        this.setState({
            alternatePhoto2Id: response.data.data.id,
            alternatePhoto2Link: response.data.data.link,
            alternatePhoto2Holder: response.data.data.displayLink,
            loadIndicatorVisibleForUpload2: false,
            isAlternatePhoto2Added: false,
        });
        this.linkInformationSetOnSuccess(
            "Alternate Photo 2",
            this.state.alternatePhoto2Link,
            OtherType.AlternatePhoto2,
            this.state.alternatePhoto2Id
        );
        // Reset the file uploader array
        this.setState({
            selectedAlternateFile2: [],
        });
    };
    handleAlternatePhoto2UploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForUpload2: false,
            isAlternatePhoto2Added: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Alternate Photo2 Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion
    //#region Alternate photo 3 upload
    onAlternatePhoto3UploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForAlternate3 = e.component;
    };

    onValueChangedForAlternate3 = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAlternateFile3Content: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAlternatePhoto3Added: true,
                    selectedAlternateFile3: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelAlternate3 = () => {
        this.setState({
            isAlternatePhoto3Added: false,
            selectedAlternateFile3Content: "",
        });
    };
    onSubmitAlternate3 = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForUpload3: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.AlternatePhoto3);
            formData.append("files[]", this.state.selectedAlternateFile3[0], this.state.selectedAlternateFile3[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleAlternatePhoto3UploadSuccess)
                .catch(this.handleAlternatePhoto3UploadUploadFailure);
        }
    };
    handleAlternatePhoto3UploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForAlternate3.reset();
        this.setState({
            alternatePhoto3Id: response.data.data.id,
            alternatePhoto3Holder: response.data.data.displayLink,
            alternatePhoto3Link: response.data.data.link,
            loadIndicatorVisibleForUpload3: false,
            isAlternatePhoto3Added: false,
        });
        this.linkInformationSetOnSuccess(
            "Alternate Photo 3",
            this.state.alternatePhoto3Link,
            OtherType.AlternatePhoto3,
            this.state.alternatePhoto3Id
        );
        // Reset the file uploader array
        this.setState({
            selectedAlternateFile3: [],
        });
    };
    handleAlternatePhoto3UploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForUpload3: false,
            isAlternatePhoto3Added: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Alternate Photo3 Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#region AlternatePhoto4
    onAlternatePhoto4UploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForAlternate4 = e.component;
    };

    onValueChangedForAlternate4 = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAlternateFile4Content: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAlternatePhoto4Added: true,
                    selectedAlternateFile4: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelAlternate4 = () => {
        this.setState({
            isAlternatePhoto4Added: false,
            selectedAlternateFile4Content: "",
        });
    };
    onSubmitAlternate4 = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForUpload4: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.AlternatePhoto4);
            formData.append("files[]", this.state.selectedAlternateFile4[0], this.state.selectedAlternateFile4[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleAlternatePhoto4UploadSuccess)
                .catch(this.handleAlternatePhoto4UploadUploadFailure);
        }
    };
    handleAlternatePhoto4UploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForAlternate4.reset();
        this.setState({
            alternatePhoto4Id: response.data.data.id,
            alternatePhoto4Link: response.data.data.link,
            alternatePhoto4Holder: response.data.data.displayLink,
            loadIndicatorVisibleForUpload4: false,
            isAlternatePhoto4Added: false,
        });
        this.linkInformationSetOnSuccess(
            "Alternate Photo 4",
            this.state.alternatePhoto4Link,
            OtherType.AlternatePhoto4,
            this.state.alternatePhoto4Id
        );
        // Reset the file uploader array
        this.setState({
            selectedAlternateFile4: [],
        });
    };
    handleAlternatePhoto4UploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForUpload4: false,
            isAlternatePhoto4Added: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Alternate Photo4 Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion
    //#region AlternatePhoto5
    onAlternatePhoto5UploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForAlternate5 = e.component;
    };

    onValueChangedForAlternate5 = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAlternateFile5Content: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAlternatePhoto5Added: true,
                    selectedAlternateFile5: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelAlternate5 = () => {
        this.setState({
            isAlternatePhoto5Added: false,
            selectedAlternateFile5Content: "",
        });
    };
    onSubmitAlternate5 = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForUpload5: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.AlternatePhoto5);
            formData.append("files[]", this.state.selectedAlternateFile5[0], this.state.selectedAlternateFile5[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleAlternatePhoto5UploadSuccess)
                .catch(this.handleAlternatePhoto5UploadUploadFailure);
        }
    };
    handleAlternatePhoto5UploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForAlternate5.reset();
        this.setState({
            alternatePhoto5Id: response.data.data.id,
            alternatePhoto5Link: response.data.data.link,
            alternatePhoto5Holder: response.data.data.displayLink,
            loadIndicatorVisibleForUpload5: false,
            isAlternatePhoto5Added: false,
        });
        this.linkInformationSetOnSuccess(
            "Alternate Photo 5",
            this.state.alternatePhoto5Link,
            OtherType.AlternatePhoto5,
            this.state.alternatePhoto5Id
        );
        // Reset the file uploader array
        this.setState({
            selectedAlternateFile4: [],
        });
    };
    handleAlternatePhoto5UploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForUpload5: false,
            isAlternatePhoto5Added: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Alternate Photo5 Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion
    //#region Primary Photo
    onPrimaryPhotoUploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForPrimaryPhoto = e.component;
    };

    onValueChangedForPrimaryPhoto = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedPrimaryFileContent: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isPrimaryPhotoAdded: true,
                    selectedPrimaryFile: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelPrimaryPhoto = () => {
        this.setState({
            isPrimaryPhotoAdded: false,
            selectedPrimaryFileContent: "",
        });
    };
    onSubmitPrimaryPhoto = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForPrimaryUpload: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.PrimaryPhoto);
            formData.append("files[]", this.state.selectedPrimaryFile[0], this.state.selectedPrimaryFile[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handlePrimaryPhotoUploadSuccess)
                .catch(this.handlePrimaryPhotoUploadUploadFailure);
        }
    };
    handlePrimaryPhotoUploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForPrimaryPhoto.reset();
        this.setState({
            primaryPhotoId: response.data.data.id,
            primaryPhotoLink: response.data.data.link,
            primaryPhotoHolder: response.data.data.displayLink,
            loadIndicatorVisibleForPrimaryUpload: false,
            isPrimaryPhotoAdded: false,
        });
        this.linkInformationSetOnSuccess(
            "Primary Photo",
            this.state.primaryPhotoLink,
            OtherType.PrimaryPhoto,
            this.state.primaryPhotoId
        );
        // Reset the file uploader array
        this.setState({
            selectedPrimaryFile: [],
        });
    };
    handlePrimaryPhotoUploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForPrimaryUpload: false,
            isPrimaryPhotoAdded: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Primary Photo Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion  

    //#region Profile Photo
    onProfilePhotoUploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForProfilePhoto = e.component;
    };

    onValueChangedForProfilePhoto = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedProfileFileContent: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isProfilePhotoAdded: true,
                    selectedProfileFile: e.value,
                });
            } else {
                this.setState({
                    displayWrongFileTypeError: true,
                });
            }
        }
    };
    onCancelProfilePhoto = () => {
        this.setState({
            isProfilePhotoAdded: false,
            selectedProfileFileContent: "",
        });
    };
    onSubmitProfilePhoto = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            this.setState({
                loadIndicatorVisibleForProfileUpload: true,
            });
            var formData = new FormData();
            formData.append("linktypeid", OtherType.ProfilePhoto);
            formData.append("files[]", this.state.selectedProfileFile[0], this.state.selectedProfileFile[0].name);
            formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");

            this.artistService
                .uploadPhoto(formData)
                .then(this.handleProfilePhotoUploadSuccess)
                .catch(this.handleProfilePhotoUploadUploadFailure);
        }
    };
    handleProfilePhotoUploadSuccess = (response: AxiosResponse<any>) => {
        this.fileUploadElementForProfilePhoto.reset();
        this.setState({
            profilePhotoId: response.data.data.id,
            profilePhotoLink: response.data.data.link,
            profilePhotoHolder: response.data.data.displayLink,
            loadIndicatorVisibleForProfileUpload: false,
            isProfilePhotoAdded: false,
        });
        this.linkInformationSetOnSuccess(
            "Profile Photo",
            this.state.profilePhotoLink,
            OtherType.ProfilePhoto,
            this.state.profilePhotoId
        );
        // Reset the file uploader array
        this.setState({
            selectedProfileFile: [],
        });
    };
    handleProfilePhotoUploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForProfileUpload: false,
            isProfilePhotoAdded: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Profile Photo Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion  

    //#region photoId proof upload
    onPhotoIdProofUploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForPhotoIdProof = e.component;
    };

    onValueChangedForPhotoIdProof = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidImageFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedPhotoIdProofFileContent: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isPhotoIdProofAdded: true,
                    selectedPhotoIdProofFile: e.value,
                    selectedPhotoIdProofFileIsInvalid: false,
                });
            } else {
                this.setState({
                    selectedPhotoIdProofFileIsInvalid: true,
                });
            }
        }
    };

    onCancelPhotoIdProof = () => {
        this.setState({
            isPhotoIdProofAdded: false,
            selectedPhotoIdProofFileContent: "",
        });
    };

    onSubmitPhotoIdProof = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
            var photoIdSelected = "";
            if (!this.state.photoLookUpId) {
                photoIdSelected = "Please select the type of Photo ID";
                this.setState({
                    photoIdSelectedOrNotMsg: photoIdSelected,
                });
            }
            if (photoIdSelected == "") {
                const index = this.state.editViewDataItem.linkInformation.findIndex(item => item.linkTypeName.toLowerCase() == "photo driving license" ||
                    item.linkTypeName.toLowerCase() == "passport" ||
                    item.linkTypeName.toLowerCase() == "national id card" ||
                    item.linkTypeName.toLowerCase() == "other id" ||
                    item.linkTypeName.toLowerCase() == "photoid");
                if(index >= 0){
                    const filterId = this.state.editViewDataItem.linkInformation.filter((item) => {
                        return item.linkTypeName.toLowerCase() !== "photo driving license" &&
                        item.linkTypeName.toLowerCase() !== "passport" &&
                        item.linkTypeName.toLowerCase() !== "national id card" &&
                        item.linkTypeName.toLowerCase() !== "other id" &&
                        item.linkTypeName.toLowerCase() !== "photoid";
                    });
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            linkInformation: filterId,
                        },
                    })
                }
                var photoIdLookUpValue = "";
                this.setState({
                    loadIndicatorVisibleForPhotoIdProofUpload: true,
                    photoIdSelectedOrNotMsg: "",
                });
                var formData = new FormData();
                if (this.state.photoLookUpId == PhotoIdType.PhotoDrivingLicense) {
                    formData.append("linktypeid", PhotoIdType.PhotoDrivingLicense);
                    photoIdLookUpValue = "Photo Driving License";
                } else if (this.state.photoLookUpId == PhotoIdType.Passport) {
                    formData.append("linktypeid", PhotoIdType.Passport);
                    photoIdLookUpValue = "Passport";
                } else if (this.state.photoLookUpId == PhotoIdType.NationalIDCard) {
                    formData.append("linktypeid", PhotoIdType.NationalIDCard);
                    photoIdLookUpValue = "National ID Card";
                } else if (this.state.photoLookUpId == PhotoIdType.OtherID) {
                    formData.append("linktypeid", PhotoIdType.OtherID);
                    photoIdLookUpValue = "Other ID";
                } 
                this.setState({
                    photoLookUpValue: photoIdLookUpValue,
                });
                formData.append(
                    "files[]",
                    this.state.selectedPhotoIdProofFile[0],
                    this.state.selectedPhotoIdProofFile[0].name
                );
                formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");
                this.artistService
                    .uploadPhoto(formData)
                    .then(this.handlePhotoIdProofUploadSuccess)
                    .catch(this.handlePhotoIdProofUploadUploadFailure);
            }
        }
    };
    handlePhotoIdProofUploadSuccess = (response: AxiosResponse<any>) => {
        //handle success
        this.fileUploadElementForPhotoIdProof.reset();
        let proofArray:any;
        let photoProofIndex = this.state.photoProofHolder ? this.state.photoProofHolder?.findIndex(item => item.id == this.state.photoLookUpId) : -1;
        if(photoProofIndex !== -1){
            this.state.photoProofHolder[photoProofIndex].link = response.data.data.displayLink
            proofArray = this.state.photoProofHolder;
        }
        else{
            proofArray = this.state.photoProofHolder;
            let proofObj:any = {
                id:response.data.data.linkTypeId,
                link: response.data.data.displayLink
            }
            proofArray.push(proofObj);
        }        
        this.setState({
            photoProofHolder: proofArray,
            photoIdProofId: response.data.data.id,
            photoIdProofLink: response.data.data.link,
            loadIndicatorVisibleForPhotoIdProofUpload: false,
            isPhotoIdProofAdded: false,
        });
        this.linkInformationSetOnSuccess(
            this.state.photoLookUpValue,
            this.state.photoIdProofLink,
            this.state.photoLookUpId,
            this.state.photoIdProofId
        );
        // Reset the file uploader array
        this.setState({
            selectedPhotoIdProofFile: [],
        });
    };
    handlePhotoIdProofUploadUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForPhotoIdProofUpload: false,
            isPhotoIdProofAdded: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Photo ID Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion

    //#region signed document/agreement upload
    onAgreementDocUploadInitialize = (e: FileUploadOnInitializedEvent) => {
        this.fileUploadElementForDocument = e.component; 
    };

    onValueChangedForAgreementDoc = (e: FileUploaderOnValueChangedEvent) => {
        var reader = new FileReader();
        if (e && e.value && e.value[0]) {
            if (isValidDocumentFileType(e.value[0].type)) {
                var that = this;
                reader.onload = (function (file) {
                    return function (e: any) {
                        that.setState({
                            selectedAgreementDocFileContent: e.target.result,
                        });
                    };
                })(e.value[0]);
                reader.readAsDataURL(e.value[0]);
                this.setState({
                    isAgreementProofAdded: true,
                    selectedAgreementDocFile: e.value, 
                    selectedAgreementDocIsInvalid: false,
                });
            } else {
                this.setState({
                    selectedAgreementDocIsInvalid: true,
                });
            }
        }
        this.setState({
            selfBillingErrors: [], 
    });
    };

    onCancelAgreementDoc = () => {
        this.setState({
            isAgreementProofAdded: false,
            selectedAgreementDocFileContent: "",
            selfBillingErrors:[]
        });
    };

    onSubmitAgreementDoc = () => {
        if(this.props.businessEntityId == "0"){
            this.setState({
                showUploadWarningPopup: true,
            });
        }
        else {
           let errors = this.selfBilling_CheckForError();
            this.setState({
                selfBillingErrors: errors
            });
            if (errors && errors?.length <= 0) {
                const index = this.state.editViewDataItem.linkInformation.findIndex(item => item.linkTypeName.toLowerCase() == "self bill invoice agreement");
                if(index >= 0){
                    const filterId = this.state.editViewDataItem.linkInformation.filter((item) => {
                        return item.linkTypeName.toLowerCase() !== "self bill invoice agreement"
                    });
                    this.setState({
                        editViewDataItem: {
                            ...this.state.editViewDataItem,
                            linkInformation: filterId,
                        },
                    })
                }
                // var agreementTypeLookUpValue = "";
                this.setState({
                    loadIndicatorVisibleForAgreementDocUpload: true
                });
                var formData = new FormData();
                let agreementDate = this.state.agreementDate ? this.conversionUtils.convertDateToString(this.state.agreementDate) : "";
                let dateTo = "";            
                if(this.state.agreementDate){
                    let agreeDate = moment(this.state.agreementDate).format("YYYY-MM-DD");
                    let converteddate = moment(agreeDate, "YYYY-MM-DD");
                    let addYear1 = converteddate.add(1, 'y');
                    let afterDate = moment(addYear1).format("YYYY-MM-DD");
                    let convertedafterDate= moment(afterDate, "YYYY-MM-DD");
                    let subtractDay = convertedafterDate.subtract(1, 'd');
                    dateTo = moment(subtractDay).format("DD/MM/YYYY");
                }
                if (this.state.agreementTypeId == AgreementType.SelfBillInvoiceAgreement) {
                    // formData.append("linktypeid", "");
                    formData.append("documenttypeid", AgreementType.SelfBillInvoiceAgreement);
                    formData.append("dateFrom", agreementDate);
                    formData.append("dateTo", dateTo);
                }
                formData.append(
                    "files[]",
                    this.state.selectedAgreementDocFile[0],
                    this.state.selectedAgreementDocFile[0].name
                );
                formData.append("BusinessEntityId", this.props.businessEntityId ? this.props.businessEntityId : "");
                this.artistService
                    .uploadPhoto(formData)
                    .then(this.handleAgreementDocUploadSuccess)
                    .catch(this.handleAgreementDocUploadFailure);
            }
        }
    };
    handleAgreementDocUploadSuccess = (response: AxiosResponse<any>) => {
        //handle success
        this.fileUploadElementForDocument.reset(); 
        let proofArray:any;
        let index = this.state.agreementHolder ? this.state.agreementHolder?.findIndex(item => item.id == this.state.agreementTypeId) : -1;
        if(index !== -1){
            this.state.agreementHolder[index].link = response.data.data.displayLink
            proofArray = this.state.agreementHolder;
        }
        else{
            proofArray = this.state.agreementHolder;
            let proofObj:any = {
                id:response.data.data.linkTypeId,
                link: response.data.data.displayLink
            }
            proofArray.push(proofObj);
        }        
        this.setState({
            agreementHolder: proofArray,
            loadIndicatorVisibleForAgreementDocUpload: false, 
            isAgreementProofAdded: false,
        });
        // Reset the file uploader array
        this.setState({
            selectedAgreementDocFile: [],
        });
    };
    handleAgreementDocUploadFailure = (response: AxiosResponse<any>) => {
        this.setState({
            loadIndicatorVisibleForAgreementDocUpload: false,
            isAgreementProofAdded: false,
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Agreement Document Upload failed with response: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    //#endregion

    linkInformationSetOnSuccess = (linktypeName: string, link: string, linkTypeId: string, id?: string) => {
        var isLinkFound = false;
        // push the new image link in the linkInformarion object of the form request
        this.state.editViewDataItem.linkInformation.forEach((item: any, uniqueKey: number) => {
            if (item.linkTypeName == linktypeName) {
                isLinkFound = true;
            }
            if (item.linkTypeName == linktypeName && isLinkFound) {
                if (
                    linkTypeId == OtherType.Website ||
                    linkTypeId == OtherType.YouTube
                ) {
                    item.link = link;
                } else {
                    item.link = link;
                    item.id = id;
                }
            }
        });
        if (!isLinkFound && linktypeName !== 'Website') {
            var newLinkTypeInfo: linkTypeInfo;
            newLinkTypeInfo = {
                id: "",
                businessEntityId: "",
                linkTypeId: linkTypeId,
                linkTypeName: linktypeName,
                linkTypeOther: "",
                link: link,
                active: "",
                lastUpdatedOn: null,
                lastUpdatedBy: null,
            };
            this.state.editViewDataItem.linkInformation.push(newLinkTypeInfo);
        }
    };
    
    selfBilling_CheckForError = () => {
        let error = [];
        if (!this.state.agreementDate) {
            let obj = {
                columnName:'Agreement Date',
                errorMessage:'Please select the Agreement Date'
            }
            error.push(obj);
        }
        if (!this.state.agreementTypeId) {
            let obj = {
                columnName:'Agreement Type',
                errorMessage:'Please select the type of Agreement'
            }
            error.push(obj);
        }
        return error;
    }

    // create agreement doc
    createDocument = () => {
        let errors = this.selfBilling_CheckForError();
        this.setState({
            selfBillingErrors: errors
        });
        if (errors && errors?.length <= 0) {
            this.setState({
                loadPanelVisible: true,
            });
            let agreementDate = this.state.agreementDate ? this.conversionUtils.convertDateToString(this.state.agreementDate) : "";
            let dateTo = "";            
            if(this.state.agreementDate){
                let agreeDate = moment(this.state.agreementDate).format("YYYY-MM-DD");
                let converteddate = moment(agreeDate, "YYYY-MM-DD");
                let addYear1 = converteddate.add(1, 'y');
                let afterDate = moment(addYear1).format("YYYY-MM-DD");
                let convertedafterDate= moment(afterDate, "YYYY-MM-DD");
                let subtractDay = convertedafterDate.subtract(1, 'd');
                dateTo = moment(subtractDay).format("DD/MM/YYYY");
            }
            let data = {
                navigateFrom: this.props.serviceType == "Entertainment" ? "WebArtistProfile" : "WebProviderProfile",
                createSignDoc: false,
                businessEntityId: this.props.businessEntityId,
                dateFrom: agreementDate,
                dateTo: dateTo,
            }
            this.artistService
                    .getAgreementDoc(data)
                    .then(this.handleCreateDocSuccess)
                    .catch(this.handleCreateDocFailure);
        }
    }

    handleCreateDocSuccess = (response: AxiosResponse<any>) => {
        let data = response?.data?.data?.byteArray;
        let convertedData = `data:application/pdf;base64,${data}`
        let link = document.createElement("a");
        link.href = convertedData;
        link.download = "Self-Billing-Agreement";
        link.click();
        this.setState({loadPanelVisible: false});
    }

    handleCreateDocFailure = (error: AxiosError<any>) => {
        this.setState({
            loadPanelVisible: false,
        });

        let errors = [];
        if(error && error.response?.data && error.response?.data.error && error.response?.data.error.length && error.response?.data.error.length > 0){
            errors = error.response.data.error;
        }
        this.setState({
            selfBillingErrors: errors 
        });

        // Log to AppInsights as a TRACE
        var respMessage: string = "Create document is failed: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    // get document to sign
    getSignDocument = () => {
        let errors = this.selfBilling_CheckForError();
        this.setState({
            selfBillingErrors: errors
        });
        if (errors && errors?.length <= 0) {
            this.setState({
                loadPanelVisible: true
            });
            let agreementDate = this.state.agreementDate ? this.conversionUtils.convertDateToString(this.state.agreementDate) : "";
            let dateTo = "";            
            if(this.state.agreementDate){
                let agreeDate = moment(this.state.agreementDate).format("YYYY-MM-DD");
                let converteddate = moment(agreeDate, "YYYY-MM-DD");
                let addYear1 = converteddate.add(1, 'y');
                let afterDate = moment(addYear1).format("YYYY-MM-DD");
                let convertedafterDate= moment(afterDate, "YYYY-MM-DD");
                let subtractDay = convertedafterDate.subtract(1, 'd');
                dateTo = moment(subtractDay).format("DD/MM/YYYY");
            }
            let data = {
                navigateFrom: this.props.serviceType == "Entertainment" ? "WebArtistProfile" : "WebProviderProfile",
                createSignDoc: true,
                businessEntityId: this.props.businessEntityId,
                dateFrom: agreementDate,
                dateTo: dateTo,
            }
            this.artistService
                    .getAgreementDoc(data)
                    .then(res => this.handleGetDocSuccess(res,data))
                    .catch(this.handleGetDocFailure);
        }
    }

    handleGetDocSuccess = (response: AxiosResponse<any>,reqData:any) => {
        let data = response.data.data;  
        let businessEntityId = this.props.businessEntityId ? this.props.businessEntityId : "";
        localStorage.setItem("EnvelopeId", data.envelopeId);
        localStorage.setItem("businessEntityId", businessEntityId);
        localStorage.setItem("agreementDateFrom", reqData?.dateFrom);
        localStorage.setItem("agreementDateTo", reqData?.dateTo);
        signingCeremonyWindow = window.open(data.signURL, "_blank");
        signingCeremonyWindow.focus();
        this.setState({
            loadPanelVisible: false,
            signURL: data.signURL,
        });
    }

    handleGetDocFailure = (error: AxiosError<any>) => {
        localStorage.setItem("EnvelopeId", '');
        localStorage.setItem("businessEntityId", '');
        localStorage.setItem("agreementDateFrom", '');
        localStorage.setItem("agreementDateTo", '');

        this.setState({
            loadPanelVisible: false,
        });
        let errors = [];
        if(error && error.response?.data && error.response?.data.error && error.response?.data.error.length && error.response?.data.error.length > 0){
            errors = error.response.data.error;
        }

        this.setState({
            selfBillingErrors: errors, 
        });
        // Log to AppInsights as a TRACE
        var respMessage: string = "Get Sign document is failed: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };
    
    handleChangeTermsAndConditionCheck = (dxValue: CheckBoxOnValueChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                termsAgreed: dxValue.value,
            },
        });
    };
    handleChangeReference = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    buildingSociety: dxValue.value,
                },
            },
        });
        this.setBankMandatoryFields(dxValue.value);
    };
    handleChangeAccountName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    nameOnAccount: dxValue.value,
                },
            },
        });
        this.setBankMandatoryFields(dxValue.value);
    };
    handleChangeAccountNumber = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    accountNumber: dxValue.value,
                },
            },
        });
        this.setBankMandatoryFields(dxValue.value);
    };
    handleChangeSortCode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    sortCode: dxValue.value,
                },
            },
        });
        this.setBankMandatoryFields(dxValue.value);
    };
    handleChangeCoverageMiles = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                coverageMiles: dxValue.value,
            },
        });
    };
    handleChangeTradingPostCode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                postCode: dxValue.value,
            },
        });
    };
    handleChangeCoveragePostCode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                coveragePostCode: dxValue.value,
            },
        });
    }
    handleChangeTownCity = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                coverageTownCity: dxValue.value,
            },
        });
    }
    handleChangeTradingState = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                state: dxValue.value,
            },
        });
    };
    handleChangeTradingCountry = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                country: dxValue.value,
            },
        });
    };
    handleChangeTradingCity = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                city: dxValue.value,
            },
        });
    };
    handleChangeTradingAddressStreet3 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet3: dxValue.value,
            },
        });
    };
    handleChangeTradingAddressStreet2 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet2: dxValue.value,
            },
        });
    };
    handleChangeTradingAddressStreet1 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                addressStreet1: dxValue.value,
            },
        });
    };
    handleChangeCompanyNumber = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                companyNumber: dxValue.value,
            },
        });
    };
    handleChangeVAT = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                taxRegistrationNumber: dxValue.value,
            },
        });
    };
    handleChangeTaxApplicable = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                taxApplicable: dxValue.value,
            },
        });
    };  
    handleChangeVATVerification = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                taxVerified: dxValue.value,
            },
        });
    }; 
    handleChangeBusinessSetUpVerification = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                verified: dxValue.value,
            },
        });
    }; 
    handleChangeBankAccountActivation = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    active: dxValue.value,
                }
            },
        });
    }

    handleChangeBankAccountVerification = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    verified: dxValue.value == "Yes" ? "True" : dxValue.value == "No" ? "False" : dxValue.value,
                }
            },
        });
    }

    handleChangeEquipmentApplicable = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                equipment: dxValue.value,
            },
        });
    }

    handleChangeActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                isActive: dxValue.value,
            },
        });
    }
    handleChangeTrusted = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                trustedUser: dxValue.value,
            },
        });
    }
    handleChangeHideProfileExternally = (dxValue : RadioButtonOnSelectionChanged)=>{
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                hideProfileExternally: dxValue.value
            }
        })
    }
    handleChangeHideFromSource = (dxValue : RadioButtonOnSelectionChanged)=>{
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                hideFromSource: dxValue.value,
            }
        })
    }

    handleChangeAgent = (dxValue : RadioButtonOnSelectionChanged)=>{
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                agent: dxValue.value,
            }
        })
    }

    // handleChangeArtist = (dxValue : RadioButtonOnSelectionChanged)=>{
    //     this.setState({
    //         editViewDataItem: {
    //             ...this.state.editViewDataItem,
    //             artist: dxValue.value,
    //         }
    //     })
    // }
    
    handleChangeFactored = (dxValue : RadioButtonOnSelectionChanged)=>{
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                factored: dxValue.value,
            }
        })
    }
    handleChangeTradingName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                tradingName: dxValue.value,
            },
        });
    };
    handleChangeBusinessName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                legalName: dxValue.value,
            },
        });
    };
    handleChangeShortName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                shortName: dxValue.value,
            },
        });
    };
    handleChangeBusinessTypeValue = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                businessEntityLegalStatusLookUpId: dxValue.value,
            },
        });
    };
    handleChangePhotoIDValue = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({
            photoLookUpId: dxValue.value,
        });
    };
    handleChangeAgreementValue = (dxValue: SelectBoxOnValueChangedEvent) => {
        this.setState({agreementTypeId: dxValue.value});
    };
    handleChangeDateOfBirth = (dxValue: DateBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    dateOfBirth: dxValue.value,
                },
            },
        });
    };
    handleChangeOnboardedDate = (dxValue: DateBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                onboardedDate: dxValue.value
            },
        });
    };
    handleChangeAgreementDate = (dxValue: DateBoxOnValueChangedEvent) => {        
        this.setState({agreementDate: dxValue.value});
    };
    handleChangePostCode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    postCode: dxValue.value,
                },
            },
        });
    };
    handleChangeState = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    state: dxValue.value,
                },
            },
        });
    };
    handleChangeCity = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    city: dxValue.value,
                },
            },
        });
    };
    handleChangeAddressStreet2 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    addressStreet2: dxValue.value,
                },
            },
        });
    };
    handleChangeAddressStreet1 = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    addressStreet1: dxValue.value,
                },
            },
        });
    };
    handleChangeEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    email: dxValue.value,
                },
            },
        });
    };
    handleChangeProviderEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                email: dxValue.value,
            },
        });
    };
    handleChangeNewEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({email: dxValue.value});
    };
    handleChangeLandLine = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    otherTelephone: dxValue.value,
                },
            },
        });
    };
    handleChangeMobile = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    mobileTelephone: dxValue.value,
                },
            },
        });
    };
    handleChangeOfficePhone = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                officePhone: dxValue.value,
            },
        });
    };
    handleChangeContactName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    name: dxValue.value,
                },
            },
        });
    };
    handleChangeOptOut = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    optOut: dxValue.value,
                },
            },
        });
    };
    handleChangeContactActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                contactInformation: {
                    ...this.state.editViewDataItem.contactInformation,
                    active: dxValue.value,
                },
            },
        });
    };
    handleChangeUserName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                user: {
                    ...this.state.editViewDataItem.user,
                    name: dxValue.value,
                },
            },
        });
    };
    handleChangeUserActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                user: {
                    ...this.state.editViewDataItem.user,
                    active: dxValue.value,
                },
            },
        });
    }

    handleChangeAllowWebApp = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                user: {
                    ...this.state.editViewDataItem.user,
                    allowWebApp: dxValue.value,
                },
            },
        });
    }

    handleChangeDisallowApp = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                user: {
                    ...this.state.editViewDataItem.user,
                    disallowApp: dxValue.value,
                },
            },
        });
    }
    handleChangeDisallowPortal = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                user: {
                    ...this.state.editViewDataItem.user,
                    disallowPortal: dxValue.value,
                },
            },
        });
    }
    

    handleChangeBioValue = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bio: dxValue.value,
            },
        });
    };
    handleChangeWebsiteValue = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            webSiteLink: dxValue.value,
            editViewDataItem: {
                ...this.state.editViewDataItem,
                webSite: dxValue.value,
            },
        });
        this.linkInformationSetOnSuccess("Website", dxValue.value, OtherType.Website);
    };
    handleChangeOOOProtocol = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                oooProtocol: dxValue.value,
            },
        });
    };
    handleChangeServiceType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            serviceTypeList: dxValue.value,
        });
        this.setServiceTypeListOnChange(dxValue.value);
    };
    handleChangeArtistType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            artistTypeList: dxValue.value,
        });
        this.setArtistTypeListOnChange(dxValue.value);
    };

    handleChangeMusicType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            musicTypeList: dxValue.value,
        });
        this.setMusicTypeListOnChange(dxValue.value);
    };

    handleChangeGenreType = (dxValue: TagBoxOnValueChangeEvent) => {
        this.setState({
            genreTypeList: dxValue.value,
        });
        this.setGenreTypeListOnChange(dxValue.value);
    };
    setArtistTypeListOnChange = (listOfArtistType: number[]) => {
        var artistTypeList: typeList;
        var artistTypeListArray: typeList[];
        artistTypeListArray = [];
        listOfArtistType.forEach((item: any) => {
            artistTypeList = {
                id: item,
                value: "",
                otherDescription: ""
            };
            artistTypeListArray.push(artistTypeList);
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                typeList: artistTypeListArray,
            },
        }, () => { this.setArtistTypeListWithOtherDesc() });

    };

    setServiceTypeListOnChange = (listOfServiceType: number[]) => {
        var serviceTypeList: any;
        var serviceTypeListArray: any[];
        serviceTypeListArray = [];

        listOfServiceType.forEach((item: any) => {
            serviceTypeList = {
                id: item.toString(),
                value: "",
            };
            serviceTypeListArray.push(serviceTypeList);
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                serviceTypeList: serviceTypeListArray,
            },
        });
    };

    setArtistTypeListWithOtherDesc = () => {
        let isOtherExist: boolean = false;
        this.state.editViewDataItem.typeList.forEach((item: any) => {
            if (item.id == Types.Other) {
                item.otherDescription = this.state.otherDescriptionVal;
                isOtherExist = true;
                this.setState({
                    hideOtherDescVal: false
                });
            }
        });
        if (!isOtherExist) {

            this.setState({
                hideOtherDescVal: true,
                otherDescriptionVal: ""
            });

        }
    }

    setGenreTypeListOnChange = (listOfGenreType: number[]) => {
        var genreTypeList: genreList;
        var genreTypeListArray: genreList[];
        genreTypeListArray = [];
        listOfGenreType.forEach((item: any) => {
            genreTypeList = {
                id: item,
                value: "",
            };
            genreTypeListArray.push(genreTypeList);
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                genreList: genreTypeListArray,
            },
        });
    };

    setMusicTypeListOnChange = (listOfMusicType: number[]) => {
        var musicTypeList: musicTypeList;
        var musicTypeListArray: musicTypeList[];
        musicTypeListArray = [];
        listOfMusicType.forEach((item: any) => {
            musicTypeList = {
                id: item,
                value: "",
            };
            musicTypeListArray.push(musicTypeList);
        });
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                musicTypeList: musicTypeListArray,
            },
        });
    };
    handleChangeAtristName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                professionalName: dxValue.value,
            },
        });
    };

    handleChangeFeedbackComments = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                feedbackComments: dxValue.value,
            },
        });
    };
    handleChangeOtherDescription = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            otherDescriptionVal: dxValue.value
        }, () => { this.setArtistTypeListWithOtherDesc() });
    }
    onClickDeactivate = () => {
        this.setState({
            loadIndicatorForDeactivation: true,
        });
        let businessEntity = {
            id: this.state.editViewDataItem.businessEntityID
        };
        this.artistService
            .doAccountDeactivation(businessEntity)
            .then(this.handleSuccessDeactivation)
            .catch(this.handleErrorDeactivation);
    };
    handleSuccessDeactivation = () => {
        this.setState({
            loadIndicatorForDeactivation: false,
            isDeactivateButtonDisable: true,
        });
    };
    handleErrorDeactivation = () => {
        this.setState({
            loadIndicatorForDeactivation: false,
        });
    };

    loadDataSource = () => {
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults
        });
        this.setState({
            viewShiftsDataSource: dataSource,
            shiftsPopupVisible:true
        });
    }

    getResults = (loadOptions: any) => {
        this.setState({
            loadIndicatorForGrid: true
        })
        let params = buildRequestParameters(loadOptions);
        params += `&requireTotalCount=true&filter=[%22providerBusinessEntity.id%22,%22contains%22,%22${this.state.editViewDataItem.businessEntityID}%22]`; 
        return this.shiftService.getShiftMatrixGridRowsWithinRemoteOperations(params)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { this.setState({loadPanelVisible: false,}); throw 'Data Loading Error';});
    }

    // getShiftResults = () => {
    //     this.setState({
    //         loadIndicatorForGrid: true
    //     })
    //     let params = `?skip=0&take=10&requireTotalCount=true&filter=[%22providerBusinessEntity.id%22,%22contains%22,%22${this.state.editViewDataItem.businessEntityID}%22]`;           
    //     return this.shiftService.getShiftMatrixGridRowsWithinRemoteOperations(params)
    //         .then(this.handleSuccessLoadDataSource)
    //         .catch(() => { this.setState({loadPanelVisible: false,}); throw 'Data Loading Error';});
    // }
    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        //if (response.data && response.data.billableItemRemoteGrid) {
            // this.setState({
            //     viewShiftsDataSource: response.data.billableItemRemoteGrid ? response.data.billableItemRemoteGrid : [],
            //     shiftsPopupVisible: true,
            // });
            return {
                data:response?.data?.billableItemRemoteGrid ? response.data.billableItemRemoteGrid : [],
                totalCount: response?.data?.totalCount
            }
        //} 
        
    }
    onClickViewShifts = () => {
        this.setState({
            loadPanelVisible: true,
        });
        localStorage.setItem("businessEntityId", this.state.editViewDataItem.businessEntityID);
        //this.getShiftResults();
        this.loadDataSource();
    }
    hideShiftsGrid = () => {
        this.setState({shiftsPopupVisible: false});
    }
    selectFavorite = () => { };
    getVATDetails = () => {
        this.setState({
            loadPanelVisible: true,
        });
        this.artistService
            .getArtistVATDetails(this.state.editViewDataItem.taxRegistrationNumber)
            .then(this.handleSuccessVATDetail)
            .catch((err) => {
                this.setState({                    
                    loadPanelVisible: false,
                });
                notify({ message: "The VAT number has not been found.", width: 300, shading: true, position: "center" }, 'success', 600);
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                });
            });
    };
    handleSuccessVATDetail = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        if(response.data?.target?.vatNumber !== ""){
            this.setState({
                vatDetails: response.data.target,
                showVatDetails: true
            });
        }
    };
    hideDetail = () => {
        this.setState({
            showVatDetails: false,
            vatDetails: {},
            showChangeEmailPopup: false,
            showEmailValidationPopup: false,
        })
    };
    hideWarningPopup = () => {
        this.setState({
            showUploadWarningPopup: false,
        })
    } 
    hideValidationDetail = () => {
        if(this.state.showChangeEmailPopup == true){
            this.setState({
                showChangeEmailPopup: true,
                showEmailValidationPopup: false,
            })
        }
        else {
            this.setState({
                showChangeEmailPopup: false,
                showEmailValidationPopup: false,
            })
        }
    }    
    changeEmail = () => {
        if(this.state.editViewDataItem.contactInformation?.email == "" || this.state.editViewDataItem.contactInformation?.email == null){
            this.setState({
                showEmailValidationPopup: true,
            });
        }
        else{
            this.setState({
                showChangeEmailPopup: true,
            });
        }        
    };
    getBankDetails = () => {
        this.setState({
            loadPanelVisible: true,
        });
        this.artistService
            .getBankDetails(this.state.editViewDataItem.businessEntityID)
            .then(this.handleSuccessBankDetail)
            .catch((err) => {
                this.setState({                    
                    loadPanelVisible: false,
                });
                notify({ message: "The Bank details has not been found.", width: 300, shading: true, position: "center" }, 'success', 600);
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                });
            });
    };
    handleSuccessBankDetail = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
        this.setState({
            bankDetails: response.data.data,
            showBankDetails: true
        });
    };
    hideBankDetail = () => {
        this.setState({
            showBankDetails: false,
            bankDetails: [],
        })
    } 
    
    onBankSelectionChanged = (rowData: any) => {
        const data = rowData.selectedRowsData[0];
        this.setState({selectedBankData: data});
    }

    onUpdateBankDetails = () => {
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                bankInformation: {
                    ...this.state.editViewDataItem.bankInformation,
                    sortCode: this.state.selectedBankData.sortCode,
                    accountNumber: this.state.selectedBankData.accountNumber,
                    nameOnAccount: this.state.selectedBankData.nameOnAccount,
                    buildingSociety: this.state.selectedBankData.buildingSociety,
                    active: this.state.selectedBankData.active,
                    verified: this.state.selectedBankData.verified,
                    verifiedBy: this.state.selectedBankData.verifiedBy,
                    verifiedDateTime: this.state.selectedBankData.verifiedDateTime,
                },
            },
            showBankDetails: false,
        });
    }

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
         
        var artistProfileSubmitRequest = this.utils.convertFormDataToArtistProfileRequest(this.state.editViewDataItem, this.props.serviceType);
        if(this.state.editViewDataItem.termsAgreed == true && this.state.editViewDataItem.termsAgreedDateTime == ""){
            let currentdate = new Date().toJSON();
            artistProfileSubmitRequest.termsAgreedDateTime = currentdate;
        }
        if(artistProfileSubmitRequest?.bankInformation?.sortCode == "" && artistProfileSubmitRequest?.bankInformation?.accountNumber == "" 
            && artistProfileSubmitRequest?.bankInformation?.nameOnAccount == "" && artistProfileSubmitRequest?.bankInformation?.active == "" 
            && artistProfileSubmitRequest?.bankInformation?.buildingSociety == ""){
                artistProfileSubmitRequest.bankInformation = null;
        }
        if(artistProfileSubmitRequest.isActive == "Yes"){
            artistProfileSubmitRequest.isActive = "true";
        }
        if(this.props.serviceType == "Entertainment"){
            artistProfileSubmitRequest.profileFlag = "Artist";
        }
        else if(this.props.serviceType == "ViewClients"){
            artistProfileSubmitRequest.profileFlag = "Clients"; 
            artistProfileSubmitRequest.contactInformation = null;
            artistProfileSubmitRequest.typeList = [];
        }
        else {
            artistProfileSubmitRequest.profileFlag = "OtherProviders"; 
            artistProfileSubmitRequest.contactInformation = null;
            artistProfileSubmitRequest.typeList = [];
        }
        this.artistService
            .saveArtistPageData(artistProfileSubmitRequest)
            .then(this.handleSuccessOnFormSubmit)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    loadPanelVisible: false,
                    IsShowError: true,
                    IsShowSuccess: false,
                    showUploadWarningPopup: false,
                });
                this.topFunction();
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                });
            });
    };

    handleSuccessOnFormSubmit = () => {
        this.setState({
            loadPanelVisible: false,
            IsShowError: false,
            IsShowSuccess: true,
        });
        this.props.handleFeedbackUpdate(this.state.editViewDataItem.feedbackScore, this.state.editViewDataItem.trustedUser);
        if(this.props.businessEntityId == "0"){
            this.props.handleAddArtist("true");
        }
        notify({ message: "Thank You! Profile Information Saved Successfully!", width: 300, shading: true, position: "center" }, 'success', 600);
        if(this.state.showUploadWarningPopup == true){
            this.setState({
                showUploadWarningPopup: false,
            });
        }
        else {
            if (this.state.isComingFromHeader) {
                this.props.history.goBack();
            }
            else {
                this.props.handlePopUpClose(false, "");
            }
        }
    };
    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    onEditButtonClick = () => {        
        this.setState({
            isEditButtonClicked: true,
            isComingFromHeader: false,
            isEditMode: true,
        });
        this.props.handlePopUpClose(true, "editMode");
    };

    togglePhotoIdProofVisible = () => {
        this.setState({
            isPhotoIdProofVisible: !this.state.isPhotoIdProofVisible,
        });
    };

    toggleAgreementVisible = () => {
        this.setState({
            isAgreementVisible: !this.state.isAgreementVisible,
        });
    };

    getImageContainerCssClass = (isPhotoAdded: boolean) => {
        if (isPhotoAdded) {
            return "multi-image-banner__image--edit-mode";
        }
        return "multi-image-banner__image--no-image";
    };

    renderCancelButton() {
        if (this.state.isComingFromHeader) {
            return (
                <button onClick={(e: MouseEvent) => {
                    this.handleCancelOnClick()
                }} className="btn btn--ghost btn--large">
                    <span className="btn__icon"></span>
                    {this.props.t("manageArtistProfile.buttons.cancel.text")}
                </button>
            );
        }
        return (
            <button onClick={(e: MouseEvent) => {
                this.handleCancelOnClick()
            }}
                className="btn btn--ghost btn--large">
                <span className="btn__icon"></span>
                {this.props.t("manageArtistProfile.buttons.cancel.text")}
            </button>
        );
    }

    handleCancelOnClick = () => {
        confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
            if (hasConfirmed) {
                //call the API for posting the Billable Item list to the server.
                if (this.state.isComingFromHeader) {
                    this.props.history.goBack();
                }
                else {
                    this.props.handlePopUpClose(false, "");
                }
            }
        });
    };

    handleThumbsUpIconClick = (event: any) => {
        if (this.state.editViewDataItem.feedbackScore && this.state.editViewDataItem.feedbackScore == feedbackScoreType.thumbsUp) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: "",
                    feedbackTypeId: ""
                },
                //isFeedbackEdited: true,
                isThumbsUpClicked: false,
            });
        }
        else {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: feedbackScoreType.thumbsUp,
                    feedbackTypeId: "13"
                },
                isThumbsUpClicked: true,
                isThumbsDownClicked: false,
                isFaceThinkingClicked: false
            });

        }
        event.preventDefault();
    };

    handleThumbsDownIconClick = (event: any) => {
        if (this.state.editViewDataItem.feedbackScore && this.state.editViewDataItem.feedbackScore == feedbackScoreType.thumbsDown) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: "",
                    feedbackTypeId: ""
                },
                //isFeedbackEdited: true,
                isThumbsDownClicked: false,
            });
        }
        else {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: feedbackScoreType.thumbsDown,
                    feedbackTypeId: "13"
                },
                isThumbsDownClicked: true,
                isThumbsUpClicked: false,
                isFaceThinkingClicked: false
            });

        }
        event.preventDefault();
    };

    handleFaceThinkingIconClick = (event: any) => {
        if (this.state.editViewDataItem.feedbackScore && this.state.editViewDataItem.feedbackScore == feedbackScoreType.faceThinking) {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: "",
                    feedbackTypeId: ""
                },
                //isFeedbackEdited: true,
                isFaceThinkingClicked: false,
            });
        }
        else {
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    feedbackScore: feedbackScoreType.faceThinking,
                    feedbackTypeId: "13"
                },
                isFaceThinkingClicked: true,
                isThumbsDownClicked: false,
                isThumbsUpClicked: false
            });
        }
        event.preventDefault();
    };

    // favourite track grid operations

    allowAddTrack= () => {
        let data = this.state.editViewDataItem.linkInformation.filter(item => item.linkTypeName.includes("Track"));
        if(data.length >= 5){
            this.setState({isAllTrackAdding:false});
        }
        else{
            this.setState({isAllTrackAdding:true});
        }       
    };

    onRowUpdatingTrack = (e: OnRowUpdatingEvent) => {
        const index = this.state.editViewDataItem.linkInformation.findIndex(item => item.link === e.oldData.link  && item.linkTypeName.includes("Track"));
        this.state.editViewDataItem.linkInformation[index].link = e.newData.link;        
    };

    onRowInsertingTrack = (e: OnRowInsertingEvent) => {
        let newTrackInfo: linkTypeInfo;        
        for(let i=1; i<6; i++){
            let typeName = "Track" + i;
            if(this.state.editViewDataItem.linkInformation.find(item => item.linkTypeName === typeName)){
                // put this condition to check for availability of already existing Track(s)
            }   
            else{
                let id = OtherType[typeName as keyof typeof OtherType];
                newTrackInfo = {
                    id: "",
                    businessEntityId: "",
                    linkTypeId: id,
                    linkTypeName: typeName,
                    linkTypeOther: "",
                    link: e.data.link,
                    active: "True",
                    lastUpdatedOn: null,
                    lastUpdatedBy: null,
                };
                this.state.editViewDataItem.linkInformation.push(newTrackInfo); 
                this.allowAddTrack();
                return;
            }             
        }         
    };    

    onRowRemovingTrack = (e: OnRowRemovingEvent) => {        
        const updatedList = this.state.editViewDataItem.linkInformation.filter((item) => item.link !== e.data.link);
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                linkInformation: updatedList,
            },
        });
        this.allowAddTrack();
    };

    // youtube grid operations

    onRowUpdating = (e: OnRowUpdatingEvent) => {
        const index = this.state.editViewDataItem.linkInformation.findIndex(item => item.link === e.oldData.link  && item.linkTypeId === "10");
        this.state.editViewDataItem.linkInformation[index].link = e.newData.link;        
    };

    onRowInserting = (e: OnRowInsertingEvent) => {
        let newYoutubeInfo: linkTypeInfo;
        newYoutubeInfo = {
            id: "",
            businessEntityId: "",
            linkTypeId: "10",
            linkTypeName: "YouTube",
            linkTypeOther: "",
            link: e.data.link,
            active: "True",
            lastUpdatedOn: null,
            lastUpdatedBy: null,
        };
        this.state.editViewDataItem.linkInformation.push(newYoutubeInfo);        
    };    

    onRowRemoving = (e: OnRowRemovingEvent) => {
        const updatedList = this.state.editViewDataItem.linkInformation.filter((item) => item.link !== e.data.link);
        this.setState({
            editViewDataItem: {
                ...this.state.editViewDataItem,
                linkInformation: updatedList,
            },
        });
    };

    onEditCancelled = (e: onEditCanceledEvent) => {
        this.setState({
            errorMessage: [],
        });
    };

    onRowEditting = () => {
        this.setState({
            active: false,
        });
    };
    onRowAdding = () => {
        this.setState({
            active: true,
        });
    };

    onSelectionChanged = (rowData: any) => {
        const data = rowData.selectedRowsData[0].link;
        let updatedUrl;
        if (data) {
            var substr2 = "";
            if (data.toLowerCase().includes("list") && data.toLowerCase().includes("playlist")) {
                substr2 = data.substring(data.indexOf("?"));
                updatedUrl = "https://www.youtube.com/embed/videoseries" + substr2;
            } else if (data.toLowerCase().includes("list")) {
                substr2 = "?" + data.substring(data.indexOf("list"));
                updatedUrl = "https://www.youtube.com/embed/videoseries" + substr2;
            } else {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = data.match(regExp);
                updatedUrl = match !== null ? "https://www.youtube.com/embed/" + match[2] : "Not match";
            }
        }
        this.setState({
            youtubeVideoHolder: updatedUrl,
        });
    }   

    renderLinkError = () => {
        if(this.state.youtubeVideoHolder == "Not match"){
            return(
                <div className="configurationText_Color text-center">
                    <b>Sorry! Link to Video appears to be incorrect - please check.</b>
                </div>
            )
        }        
    }

    onSelectionChangedTrack = (rowData: any) => {
        const data = rowData.selectedRowsData[0].link;
        let updatedUrl;
        if (data) {
            var substr1 = "";
           if (data.toLowerCase().includes("track")) {
                substr1 = "/" + data.substring(data.indexOf("track"));                
            } else if(data.toLowerCase().includes("album")) {
                substr1 = "/" + data.substring(data.indexOf("album"));
            } else if(data.toLowerCase().includes("playlist")) {
                substr1 = "/" + data.substring(data.indexOf("playlist"));
            } else if(data.toLowerCase().includes("artist")) {
                substr1 = "/" + data.substring(data.indexOf("artist"));
            } else if(data.toLowerCase().includes("podcast")) {
                substr1 = "/" + data.substring(data.indexOf("podcast"));
            } else if(data.toLowerCase().includes("episode")) {
                substr1 = "/" + data.substring(data.indexOf("episode"));
            } else {
                substr1 = "";
            }
            updatedUrl = "https://widget.deezer.com/widget/dark" + substr1;
        }
        this.setState({
            trackVideoHolder: updatedUrl,
        });
    }

    renderStatusFlow = (sectionHeading:string, completionStatus:boolean ) => {
        return (
            <div className="container">
                <section className="artist-profile__edit-section">
                    <div className="row mt-3">
                        <div className="col-lg-3"></div>                                    
                            <h5 className="artist-profile__section-header">
                                {sectionHeading}
                            </h5>
                            <section className="col-lg-9 mt-3 status-flow flow-margin">
                                <ul className="status-flow__artist-progress-bar">
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                        <CheckBox
                                            value={this.state.editViewDataItem.professionalName !== ""}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Profile
                                    </li>
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                        <CheckBox
                                            value={this.state.editViewDataItem.contactInformation?.email !== ""}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Contact Details
                                    </li>
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                        <CheckBox
                                            value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "" && this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "99" && 
                                            (this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True") && (this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True")}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Business Set-Up
                                    </li>
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                        <CheckBox
                                            value={this.state.editViewDataItem.bankInformation?.nameOnAccount !== "" && this.state.editViewDataItem.bankInformation?.accountNumber !== "" 
                                                && this.state.editViewDataItem.bankInformation?.sortCode !== ""}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Bank Account
                                    </li>
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                        <CheckBox
                                            value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "30" || 
                                                this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "71"}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Identification
                                    </li>
                                    <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                        <CheckBox
                                            value={completionStatus}
                                            readOnly={true}
                                        />
                                        &nbsp;
                                        Complete
                                    </li>

                                </ul>
                            </section>
                    </div>
                </section>
            </div>
        );
    }

    dateFormatChange = (date: any) => {
        let termsAgreedDateTimeLocal = new Date(date);
        let formattedTime = this.conversionUtils.convertDateTimeStringToHHMM(termsAgreedDateTimeLocal.toString());
        let formattedDate = this.conversionUtils.convertDateToString(termsAgreedDateTimeLocal);
        let finalFormattedDate = `${formattedDate} ${formattedTime}`;
        return finalFormattedDate;
    }

    getTownCity = () => {
        this.setState({
            loadPanelVisible: true,
        });               
        let code = encodeURIComponent(this.state.editViewDataItem.coveragePostCode.trim());
        axios.get("https://api.os.uk/search/places/v1/postcode?postcode=" + code + "&key=px4Fk29whVrhHebZYjc998iFmRLboL4i").then(res => {      
            let data = res.data;
            this.setState({
                loadPanelVisible: false,
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    coverageTownCity: data.results[0].DPA.POST_TOWN,
                },
            });
        }).catch(error => {
            this.setState({
                loadPanelVisible: false
            });
        });
    }

    onConfirm = () => {
        if(this.state.email == "" || this.state.email == null){
            this.setState({
                showEmailValidationPopup: true,
            });
        }
        else{
            this.setState({
                editViewDataItem: {
                    ...this.state.editViewDataItem,
                    contactInformation: {
                        ...this.state.editViewDataItem.contactInformation,
                        email: this.state.email,
                    },
                    updateEmail: true,
                },
                showChangeEmailPopup: false,
                showEmailValidationPopup: false,
            });
        }         
    }

    headerOfSelectedCell = () => {
        return <div>SELECT</div>;
    };

    isSelfBillingButtonsDisabled = () => {
          let isDisabled = true;
          if(this.state.agreementDate && this.state.agreementTypeId){
            isDisabled = false;
          }
          return isDisabled;
    }

    hasSelfBillingError = () => {
        if (this.state.selfBillingErrors && this.state.selfBillingErrors.length && this.state.selfBillingErrors.length > 0) {
            return <span className="unscheduled-shift"><ul>
                {this.state.selfBillingErrors.map((item: any, uniqueKey: number) => {
                    return (
                        <li key={uniqueKey}>
                            {item.columnName}: {item.errorMessage}
                        </li>
                    );
                })}
            </ul>
            </span>
        }
        return;
    }

    getCreatedAndUpdatedString = () => {
        let finalString = '';
        if(this.state.editViewDataItem.lastUpdatedOn){
            let formattedDate = moment(this.state.editViewDataItem.lastUpdatedOn).format("Do MMM YYYY HH:mm:ss");
            let updatedBy = '';
            if(this.state.editViewDataItem.lastUpdatedBy){
                updatedBy = this.state.editViewDataItem.lastUpdatedBy;
            }
            finalString += `Last Updated On ${formattedDate} ${updatedBy?`by ${updatedBy}`:''}`;
          }
          if(finalString){
            finalString =` ( ${finalString} )`;
          }
       return finalString;
    }

    isViewCompany = () =>{
        let isCompany = false;
        if(this.props.serviceType && this.props.serviceType == "ViewCompany"){
            isCompany = true;
        }
        return isCompany;
    }

    render() {
        let location = "";
        let city = "";
        let state = "";
        let isVenueManager = false;
        let isEventUKStaff = false;
        let isArtist = false;
        let isNotActiveArtist = false;
        let termsAgreedDateTime = "";
        let bankDetailsCreatedDateTime = "";
        let verifiedDateTime = "";
        let taxVerifiedDateTime = "";
        let artistNameText = "";
        let artistTypeText = "";
        let websiteText = "";
        let biocommentsText = "";
        let nameText = "";
        let mobileText = "";
        let landlineText = "";
        let emailText = "";
        let addressLine1Text = "";
        let addressLine2Text = "";
        let town_cityText = "";
        let countyText = "";
        let postcodeText = "";
        let dateofBirthText = "";
        let onboardedText = "";
        let photoIDText = "";
        let businessTypeText = "";
        let businessNameText = "";
        let tradingNameFieldNameText = "";
        let tradingNameText = "";
        let VATQuestionFieldNameText = "";
        let VATNumberText = "";
        let companyNumberText = "";
        let registeredAddressLine1Text = "";
        let registeredAddressLine2Text = "";
        let registeredAddressLine3Text = "";
        let registeredAddressTown_CityText = "";
        let registeredAddressCountyText = "";
        let registeredAddressCountryText = "";
        let registeredAddressPostcodeText = "";
        let travelText = "";
        let sortCodeText = "";
        let accountNumberText = "";
        let nameonAccountText = "";
        let referenceText = "";
        let termsConditionsText = "";   
        let dataSourceOfServiceFeeMatrix: any = GlobalService.configurationValue();
        if (dataSourceOfServiceFeeMatrix && dataSourceOfServiceFeeMatrix.length > 0) {
            artistNameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_ArtistName;
            })[0].value;
            artistTypeText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_ArtistType;
            })[0].value;
            websiteText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Website;
            })[0].value;
            biocommentsText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Biocomments;
            })[0].value;
            nameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Name;
            })[0].value;
            mobileText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Mobile;
            })[0].value;
            landlineText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Facebook;
            })[0].value;
            emailText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Facebook;
            })[0].value;
            addressLine1Text = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_AddressLine1;
            })[0].value;
            addressLine2Text = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_AddressLine2;
            })[0].value;
            countyText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_County;
            })[0].value;
            postcodeText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Postcode;
            })[0].value;
            dateofBirthText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_DateofBirth;
            })[0].value;
            photoIDText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_PhotoID;
            })[0].value;
            businessTypeText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_BusinessType;
            })[0].value;
            businessNameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_BusinessName;
            })[0].value;
            tradingNameFieldNameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_TradingNameFieldName;
            })[0].value;
            tradingNameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_TradingName;
            })[0].value;
            VATQuestionFieldNameText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_VATQuestionFieldName;
            })[0].value;
            VATNumberText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_VATNumber;
            })[0].value;
            companyNumberText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_CompanyNumber;
            })[0].value;
            registeredAddressLine1Text = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_RegisteredAddressLine1;
            })[0].value;
            registeredAddressLine2Text = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_RegisteredAddressLine2;
            })[0].value;
            // registeredAddressLine3Text = dataSourceOfServiceFeeMatrix.filter((item: any) => {
            //     return item.id == ConfigurableTextIndexes.Artisteditprofile_RegisteredAddressLine3;
            // })[0]?.value;
            registeredAddressCountyText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_RegisteredAddressCounty;
            })[0].value;
            registeredAddressPostcodeText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_RegisteredAddressPostcode;
            })[0].value;
            travelText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Travel;
            })[0].value;
            sortCodeText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_SortCode;
            })[0].value;
            accountNumberText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_AccountNumber;
            })[0].value;
            nameonAccountText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_NameonAccount;
            })[0].value;
            referenceText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_Reference;
            })[0].value;
            termsConditionsText = dataSourceOfServiceFeeMatrix.filter((item: any) => {
                return item.id == ConfigurableTextIndexes.Artisteditprofile_TermsConditions;
            })[0].value;

        }
        if (this.state.editViewDataItem.termsAgreedDateTime !== "") {
            let newFormattedDate = this.dateFormatChange(this.state.editViewDataItem.termsAgreedDateTime);
            var parts = newFormattedDate.split(" ");
            termsAgreedDateTime = parts[0] + " at " + parts[1];
        }
        if(this.state.editViewDataItem.bankInformation?.createdDateTime){
            var parts = this.state.editViewDataItem.bankInformation?.createdDateTime.split(" ");
            bankDetailsCreatedDateTime = parts[0]+" "+parts[1]+" "+parts[2] + " at " + parts[3];
        }
        if(this.state.editViewDataItem.bankInformation?.verifiedDateTime){
            let parts = this.state.editViewDataItem.bankInformation?.verifiedDateTime.split(" ");
            verifiedDateTime = parts[0]+" "+parts[1]+" "+parts[2] + " at " + parts[3];
        }
        if (this.state.editViewDataItem.taxVerifiedDateTime !== null && this.state.editViewDataItem.taxVerifiedDateTime !== "") {
            let finalFormattedDate: string = "";
            let editDateTime = this.state.editViewDataItem.taxVerifiedDateTime;
            let editDateTimeLocal = new Date(editDateTime);
            let formattedTime = this.conversionUtils.convertDateTimeStringToHHMM(editDateTimeLocal.toString());
            let formattedDate = this.conversionUtils.convertDayDateMonthToString(editDateTimeLocal);
            let parts = formattedDate.split(" ");
            finalFormattedDate = `${parts[1]} ${parts[2]} ${parts[3]} at ${formattedTime}`;
            taxVerifiedDateTime = finalFormattedDate;
        }       
        if (this.state.editViewDataItem.isActive == "false") {
            isNotActiveArtist = true;
        }
        if (UserService.isUserInGroup(RoleGroupNames.Artist)) {
            isArtist = true;
            isVenueManager = false;
        } else if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
            isVenueManager = true;
            isArtist = false;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager)
        ) {
            isEventUKStaff = true;
        }
        if (this.state.editViewDataItem.city || this.state.editViewDataItem.state) {
            city = this.state.editViewDataItem.city;
            if (this.state.editViewDataItem.state) {
                state = this.state.editViewDataItem.state;
            }
            location = city + "," + state;
        }
        let completionStatus: boolean = false;
        let sectionHeading: string = "";
        if(this.props.serviceType == "Entertainment"){
            if (this.state.editViewDataItem.professionalName !== "" && this.state.editViewDataItem.contactInformation?.email !== "" 
                && this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "" && this.state.editViewDataItem.bankInformation?.nameOnAccount !== "" 
                && this.state.editViewDataItem.bankInformation?.accountNumber !== "" && this.state.editViewDataItem.bankInformation?.sortCode !== "") {

                completionStatus = true;
                sectionHeading = `Profile (Completed)`;
            }
            else {
                sectionHeading = `Profile (In Progress)`;
            }
        }
        else {
            if ((this.state.editViewDataItem.bankInformation?.nameOnAccount !== "" && this.state.editViewDataItem.bankInformation?.accountNumber !== "" 
                && this.state.editViewDataItem.bankInformation?.sortCode !== "") && 
                (this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "" && this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "99" && 
                (this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True") && (this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True"))) {

                completionStatus = true;
                sectionHeading = `Profile (Completed)`;
            }
            else {
                sectionHeading = `Profile (In Progress)`;
            }
        }
        let photoProofIndex = this.state.photoProofHolder ? this.state.photoProofHolder?.findIndex(item => item.id == this.state.photoLookUpId) : -1;
        let agreementIndex = this.state.agreementHolder ? this.state.agreementHolder?.findIndex(item => item.id == this.state.agreementTypeId) : -1;
        
        return (
            <>
               <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadIndicatorForPage} />
                {isVenueManager || !this.state.isEditMode || this.state.isComingFromHeader ? (
                    <div className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-2 mr-auto"></div>
                                <div className="col-12 col-md-2"></div>
                                <div className="col-12 col-md-2">
                                    {isEventUKStaff || isArtist ? (
                                        <button className="btn btn-primary btn--large" onClick={this.onEditButtonClick}>
                                            EDIT
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="multi-image-banner mt-6" hidden={this.props.serviceType !="Entertainment"}>
                            <div className="multi-image-banner__container">
                                <div
                                    className="multi-image-banner__image"
                                    style={{
                                        backgroundImage: `url("${this.state.alternatePhoto1Holder}")`,
                                    }}
                                ></div>
                                <div
                                    className="multi-image-banner__image"
                                    style={{
                                        backgroundImage: `url("${this.state.alternatePhoto2Holder}")`,
                                    }}
                                ></div>
                                <div
                                    className="multi-image-banner__image"
                                    style={{
                                        backgroundImage: `url("${this.state.alternatePhoto3Holder}")`,
                                    }}
                                ></div>
                                <div
                                    className="multi-image-banner__image"
                                    style={{
                                        backgroundImage: `url("${this.state.alternatePhoto4Holder}")`,
                                    }}
                                ></div>
                                <div
                                    className="multi-image-banner__image"
                                    style={{
                                        backgroundImage: `url("${this.state.alternatePhoto5Holder}")`,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="container">
                            <section className="artist-profile__bio-section">
                                <div className="row">
                                    <div className="col-12 col-lg-3">
                                        <div className="artist-profile__primary-image__container">
                                            <div className="artist-profile__primary-image">
                                                <img
                                                    className=""
                                                    src={
                                                        this.state.primaryPhotoHolder
                                                            ? this.state.primaryPhotoHolder
                                                            : "/assets/images/event-UK-image.jpeg"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-9">
                                        <div className="artist-profile__details">
                                            <div className="artist-profile__header">
                                                <div>
                                                    <h4 className="artist-profile__title" hidden={this.props.serviceType !="Entertainment"}>
                                                        {this.state.editViewDataItem.professionalName}
                                                    </h4>
                                                    <h4 className="artist-profile__title" hidden={this.props.serviceType =="Entertainment"}>
                                                        {this.state.editViewDataItem.legalName}
                                                    </h4>
                                                    <FavouriteButton
                                                        favourited={true}
                                                        selectFavorite={this.selectFavorite}
                                                    />
                                                </div>
                                                {this.state.editViewDataItem.score ? (
                                                    <StarRating
                                                        score={this.conversionUtils.convertStringToFloat(
                                                            this.state.editViewDataItem.score
                                                        )}
                                                    />
                                                ) : null}
                                            </div>
                                            <br></br>
                                            <div>
                                                {this.state.editViewDataItem.typeList.length > 0 && this.state.editViewDataItem.typeList.map((item, index) => {
                                                    return (
                                                        <span className="badge badge-secondary bg-light text-dark font-weight-normal mr-1" key={index}>
                                                            {item.value}
                                                        </span>
                                                    )
                                                })
                                                }
                                                {/* <b>{this.state.artistTypeValues ? this.state.artistTypeValues : ""}</b> */}
                                            </div>
                                            <br></br>
                                            <div className="artist-profile__location">{location}</div>
                                            <a className="artist-profile__website" href="#">
                                                {this.state.webSiteLink}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-lg-5">
                                    <div className="col-12 col-lg-3">
                                        <div className="artist-profile__primary-image__container">
                                            <div className="artist-profile__primary-image-circle" hidden={this.props.serviceType != "Entertainment"}>
                                                <img
                                                    className=""
                                                    src={
                                                        this.state.profilePhotoHolder
                                                            ? this.state.profilePhotoHolder
                                                            : "/assets/images/event-UK-image.jpeg"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-lg-5">
                                    <div className="col-12 col-lg-3 mt-3 mt-lg-0">
                                        <h5 className="artist-profile__contact-heading">
                                            {this.props.t("manageArtistProfile.contact.caption.label")}
                                        </h5>
                                        <div className="artist-profile__contact-detail"  hidden={this.props.serviceType != "Entertainment"}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>
                                                {this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.name
                                                    : null}
                                            </span>
                                        </div>
                                        <div className="artist-profile__contact-detail"  hidden={this.props.serviceType == "Entertainment"}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>
                                                {this.state.editViewDataItem.legalName
                                                    ? this.state.editViewDataItem.legalName
                                                    : null}
                                            </span>
                                        </div>
                                        {this.state.editViewDataItem.contactInformation &&
                                            this.state.editViewDataItem.contactInformation.mobileTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`mobile:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .mobileTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.mobileTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`mobile:${this.state.editViewDataItem.officePhone}`}
                                                >
                                                    {this.state.editViewDataItem.officePhone}
                                                </a>
                                            </div>
                                        )}
                                        {this.state.editViewDataItem.contactInformation &&
                                            !this.state.editViewDataItem.contactInformation.mobileTelephone &&
                                            this.state.editViewDataItem.contactInformation.businessTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`business:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .businessTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .businessTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : null}
                                        {this.state.editViewDataItem.contactInformation &&
                                            this.state.editViewDataItem.contactInformation.otherTelephone &&
                                            !this.state.editViewDataItem.contactInformation.mobileTelephone &&
                                            !this.state.editViewDataItem.contactInformation.businessTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`other:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .otherTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.otherTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : null}
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType != "Entertainment"}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <a
                                                href={`mailto:${this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.email
                                                    : null
                                                    }`}
                                            >
                                                {this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.email
                                                    : null}
                                            </a>
                                        </div>
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType == "Entertainment"}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <a
                                                href={`mailto:${this.state.editViewDataItem.email}`}
                                            >
                                                {this.state.editViewDataItem.email}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-9 mt-3 mt-lg-0">
                                        {this.state.editViewDataItem.bio}
                                    </div>
                                </div>
                            </section>
                            <section className="mb-5" hidden={this.props.serviceType !="Entertainment"}>
                                <h5 className="artist-profile__section-header">
                                    {this.props.t("manageArtistProfile.youtube.caption.label")}
                                </h5>
                                <div>
                                    {this.state.youTubeLink ? (
                                        <DataGrid
                                            dataSource={this.state.youTubeLink}
                                            showBorders={false}
                                            showColumnLines={false}
                                            hoverStateEnabled={true}
                                            columnAutoWidth={true}
                                            onSelectionChanged={this.onSelectionChanged}
                                            allowColumnResizing={true}
                                            columnResizingMode={"widget"}
                                            className="youtube-grid"
                                        >
                                            <Selection mode="single" />
                                            <Paging defaultPageSize={5} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                            <Editing
                                                mode="row"
                                                useIcons={false}
                                                allowAdding={false}
                                                allowUpdating={false}
                                                allowDeleting={false}
                                            />
                                            <Column dataField="link" allowSorting={true} caption="YouTube" />
                                            <FilterRow visible={false} applyFilter="auto" />
                                        </DataGrid>
                                     ) : (
                                        <span className="configurationText_Color">
                                            <b>No Videos To Show</b>
                                        </span>
                                     )} 
                                    {this.state.youtubeVideoHolder && this.state.youtubeVideoHolder !== "Not match" ? (
                                        <section>
                                            <iframe
                                                className="artist-profile__latest-videos"
                                                src={this.state.youtubeVideoHolder}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </section>
                                    ) : (
                                        this.renderLinkError()
                                    )}                                            

                                </div>
                            </section>
                            <section className="mb-5" hidden={this.props.serviceType !="Entertainment"}>
                                <h5 className="artist-profile__section-header mb-0">
                                    {this.props.t("manageArtistProfile.track.caption.label")}
                                </h5>
                                <div>
                                    {this.state.trackLink ? (
                                        <DataGrid
                                            dataSource={this.state.trackLink}
                                            showBorders={false}
                                            showColumnLines={false}
                                            hoverStateEnabled={true}
                                            columnAutoWidth={true}
                                            onSelectionChanged={this.onSelectionChangedTrack}
                                            allowColumnResizing={true}
                                            columnResizingMode={"widget"}
                                            className="youtube-grid"
                                        >
                                            <Selection mode="single" />
                                            <Paging defaultPageSize={5} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5]} showInfo={true} />
                                            <Editing
                                                mode="row"
                                                useIcons={false}
                                                allowAdding={false}
                                                allowUpdating={false}
                                                allowDeleting={false}
                                            />
                                            <Column dataField="link" allowSorting={true} caption="YouTube" />
                                            <FilterRow visible={false} applyFilter="auto" />
                                        </DataGrid>
                                    ):(
                                        <span className="configurationText_Color">
                                            <b>No Videos To Show</b>
                                        </span>
                                    )}
                                    {this.state.trackVideoHolder ? (
                                        <section>
                                            <iframe title="deezer-widget" 
                                                src={this.state.trackVideoHolder} 
                                                className="artist-profile__latest-videos" 
                                                allow="encrypted-media; clipboard-write"></iframe>
                                        </section>
                                    ) : (null)}                                            

                                </div>
                            </section>
                            {/* <section className="mt-5">
                                <h5 className="artist-profile__section-header">Latest From Instagram</h5>
                                <div>Instagram photos will be shown over here</div>
                            </section> */}
                        </div>
                    </div>
                ) : this.state.isEditMode || isEventUKStaff || isArtist ? (
                    <div className="page-content">
                        <div className="multi-image-banner--edit-mode" hidden={this.props.serviceType != "Entertainment"}>
                            <Popup
                                visible={this.state.displayWrongFileTypeError}
                                onHiding={this.onHidingWrongFileTypeError}
                                dragEnabled={false}
                                closeOnOutsideClick={true}
                                showCloseButton={true}
                                className={"pop-up--small"}
                                title="Error"
                            >
                                File unsupported - allowed types: .jpg, .jpeg, .gif, .png
                            </Popup>

                            {/* Empty divs are bad practice but this is a fix for IE to make use of background images */}
                            {/* Inline styles are normally not ok but to support IE, this is the best way */}
                            <div className="multi-image-banner__container">
                                <div
                                    className={this.getImageContainerCssClass(this.state.isAlternatePhoto1Added)}
                                    style={{
                                        backgroundImage: `url("${this.state.isAlternatePhoto1Added
                                            ? this.state.selectedAlternateFile1Content
                                            : this.state.alternatePhoto1Holder
                                            }")`,
                                    }}
                                >
                                    {this.state.isAlternatePhoto1Added == false ? (
                                        <FileUploader
                                            className="multi-image-banner__image-upload-button"
                                            selectButtonText="Select Image"
                                            uploadMode="useForm"
                                            showFileList={false}
                                            onValueChanged={this.onValueChangedForAlternate1}
                                            onInitialized={this.onAlternatePhoto1UploadInitialize}
                                        ></FileUploader>
                                    ) : (
                                        <>
                                            <Button
                                                className="btn btn-primary artist-profile__image-preview-upload-button"
                                                onClick={this.onSubmitAlternate1}
                                            >
                                                <LoadIndicator
                                                    className="artist-profile__image-preview-loader--header-image"
                                                    height={15}
                                                    width={15}
                                                    visible={this.state.loadIndicatorVisibleForUpload1}
                                                />
                                                <span>Upload</span>
                                            </Button>
                                            <Button
                                                className="btn artist-profile__image-preview-cancel-button"
                                                onClick={this.onCancelAlternate1}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={this.getImageContainerCssClass(this.state.isAlternatePhoto2Added)}
                                    style={{
                                        backgroundImage: `url("${this.state.isAlternatePhoto2Added
                                            ? this.state.selectedAlternateFile2Content
                                            : this.state.alternatePhoto2Holder
                                            }")`,
                                    }}
                                >
                                    {this.state.isAlternatePhoto2Added == false ? (
                                        <FileUploader
                                            className="multi-image-banner__image-upload-button"
                                            selectButtonText="Select Image"
                                            uploadMode="useForm"
                                            showFileList={false}
                                            onValueChanged={this.onValueChangedForAlternate2}
                                            onInitialized={this.onAlternatePhoto2UploadInitialize}
                                        ></FileUploader>
                                    ) : (
                                        <>
                                            <Button
                                                className="btn btn-primary artist-profile__image-preview-upload-button"
                                                onClick={this.onSubmitAlternate2}
                                            >
                                                <LoadIndicator
                                                    className="artist-profile__image-preview-loader--header-image"
                                                    height={15}
                                                    width={15}
                                                    visible={this.state.loadIndicatorVisibleForUpload2}
                                                />
                                                <span>Upload</span>
                                            </Button>
                                            <Button
                                                className="btn artist-profile__image-preview-cancel-button"
                                                onClick={this.onCancelAlternate2}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={this.getImageContainerCssClass(this.state.isAlternatePhoto3Added)}
                                    style={{
                                        backgroundImage: `url("${this.state.isAlternatePhoto3Added
                                            ? this.state.selectedAlternateFile3Content
                                            : this.state.alternatePhoto3Holder
                                            }")`,
                                    }}
                                >
                                    {this.state.isAlternatePhoto3Added == false ? (
                                        <FileUploader
                                            className="multi-image-banner__image-upload-button"
                                            selectButtonText="Select Image"
                                            uploadMode="useForm"
                                            showFileList={false}
                                            onValueChanged={this.onValueChangedForAlternate3}
                                            onInitialized={this.onAlternatePhoto3UploadInitialize}
                                        ></FileUploader>
                                    ) : (
                                        <>
                                            <Button
                                                className="btn btn-primary artist-profile__image-preview-upload-button"
                                                onClick={this.onSubmitAlternate3}
                                            >
                                                <LoadIndicator
                                                    className="artist-profile__image-preview-loader--header-image"
                                                    height={15}
                                                    width={15}
                                                    visible={this.state.loadIndicatorVisibleForUpload3}
                                                />
                                                <span>Upload</span>
                                            </Button>
                                            <Button
                                                className="btn artist-profile__image-preview-cancel-button"
                                                onClick={this.onCancelAlternate3}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={this.getImageContainerCssClass(this.state.isAlternatePhoto4Added)}
                                    style={{
                                        backgroundImage: `url("${this.state.isAlternatePhoto4Added
                                            ? this.state.selectedAlternateFile4Content
                                            : this.state.alternatePhoto4Holder
                                            }")`,
                                    }}
                                >
                                    {this.state.isAlternatePhoto4Added == false ? (
                                        <FileUploader
                                            className="multi-image-banner__image-upload-button"
                                            selectButtonText="Select Image"
                                            uploadMode="useForm"
                                            showFileList={false}
                                            onValueChanged={this.onValueChangedForAlternate4}
                                            onInitialized={this.onAlternatePhoto4UploadInitialize}
                                        ></FileUploader>
                                    ) : (
                                        <>
                                            <Button
                                                className="btn btn-primary artist-profile__image-preview-upload-button"
                                                onClick={this.onSubmitAlternate4}
                                            >
                                                <LoadIndicator
                                                    className="artist-profile__image-preview-loader--header-image"
                                                    height={15}
                                                    width={15}
                                                    visible={this.state.loadIndicatorVisibleForUpload4}
                                                />
                                                <span>Upload</span>
                                            </Button>
                                            <Button
                                                className="btn artist-profile__image-preview-cancel-button"
                                                onClick={this.onCancelAlternate4}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={this.getImageContainerCssClass(this.state.isAlternatePhoto5Added)}
                                    style={{
                                        backgroundImage: `url("${this.state.isAlternatePhoto5Added
                                            ? this.state.selectedAlternateFile5Content
                                            : this.state.alternatePhoto5Holder
                                            }")`,
                                    }}
                                >
                                    {this.state.isAlternatePhoto5Added == false ? (
                                        <FileUploader
                                            className="multi-image-banner__image-upload-button"
                                            selectButtonText="Select Image"
                                            uploadMode="useForm"
                                            showFileList={false}
                                            onValueChanged={this.onValueChangedForAlternate5}
                                            onInitialized={this.onAlternatePhoto5UploadInitialize}
                                        ></FileUploader>
                                    ) : (
                                        <>
                                            <Button
                                                className="btn btn-primary artist-profile__image-preview-upload-button"
                                                onClick={this.onSubmitAlternate5}
                                            >
                                                <LoadIndicator
                                                    className="artist-profile__image-preview-loader--header-image"
                                                    height={15}
                                                    width={15}
                                                    visible={this.state.loadIndicatorVisibleForUpload5}
                                                />
                                                <span>Upload</span>
                                            </Button>
                                            <Button
                                                className="btn artist-profile__image-preview-cancel-button"
                                                onClick={this.onCancelAlternate5}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <section>
                                <div className="row">
                                    <div className="col-12 col-lg-3">
                                        <div className="artist-profile__primary-image__container mb-4">
                                            <div className="artist-profile__primary-image artist-profile__primary-image--edit-mode" hidden={this.props.serviceType != "Entertainment"}>
                                                {this.state.isPrimaryPhotoAdded == false ? (
                                                    <>
                                                        <img
                                                            src={
                                                                this.state.primaryPhotoHolder
                                                                    ? this.state.primaryPhotoHolder
                                                                    : "/assets/images/event-UK-image.jpeg"
                                                            }
                                                        />
                                                        <FileUploader
                                                            className="multi-image-banner__image-upload-button"
                                                            selectButtonText="Select Image"
                                                            uploadMode="useForm"
                                                            showFileList={false}
                                                            onValueChanged={this.onValueChangedForPrimaryPhoto}
                                                            onInitialized={this.onPrimaryPhotoUploadInitialize}
                                                        ></FileUploader>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={this.state.selectedPrimaryFileContent} />
                                                        <Button
                                                            className="btn btn-primary artist-profile__image-preview-upload-button"
                                                            onClick={this.onSubmitPrimaryPhoto}
                                                        >
                                                            <LoadIndicator
                                                                className="artist-profile__image-preview-loader--header-image"
                                                                height={15}
                                                                width={15}
                                                                visible={
                                                                    this.state.loadIndicatorVisibleForPrimaryUpload
                                                                }
                                                            />
                                                            <span>Upload</span>
                                                        </Button>
                                                        <Button
                                                            className="btn artist-profile__image-preview-cancel-button"
                                                            onClick={this.onCancelPrimaryPhoto}
                                                        >
                                                            <span>Cancel</span>
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="artist-profile__primary-image-other artist-profile__primary-image-other--edit-mode" hidden={this.props.serviceType == "Entertainment"}>
                                                {this.state.isPrimaryPhotoAdded == false ? (
                                                    <>
                                                        <img
                                                            src={
                                                                this.state.primaryPhotoHolder
                                                                    ? this.state.primaryPhotoHolder
                                                                    : "/assets/images/event-UK-image.jpeg"
                                                            }
                                                        />
                                                        <FileUploader
                                                            className="multi-image-banner__image-upload-button"
                                                            selectButtonText="Select Image"
                                                            uploadMode="useForm"
                                                            showFileList={false}
                                                            onValueChanged={this.onValueChangedForPrimaryPhoto}
                                                            onInitialized={this.onPrimaryPhotoUploadInitialize}
                                                        ></FileUploader>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={this.state.selectedPrimaryFileContent} />
                                                        <Button
                                                            className="btn btn-primary artist-profile__image-preview-upload-button"
                                                            onClick={this.onSubmitPrimaryPhoto}
                                                        >
                                                            <LoadIndicator
                                                                className="artist-profile__image-preview-loader--header-image"
                                                                height={15}
                                                                width={15}
                                                                visible={
                                                                    this.state.loadIndicatorVisibleForPrimaryUpload
                                                                }
                                                            />
                                                            <span>Upload</span>
                                                        </Button>
                                                        <Button
                                                            className="btn artist-profile__image-preview-cancel-button"
                                                            onClick={this.onCancelPrimaryPhoto}
                                                        >
                                                            <span>Cancel</span>
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="artist-profile__primary-image__container mb-4">
                                            <div className="artist-profile__primary-image-circle artist-profile__primary-image--edit-mode" hidden={this.props.serviceType != "Entertainment"}>
                                                {this.state.isProfilePhotoAdded == false ? (
                                                    <>
                                                        <img
                                                            src={
                                                                this.state.profilePhotoHolder
                                                                    ? this.state.profilePhotoHolder
                                                                    : "/assets/images/event-UK-image.jpeg"
                                                            }
                                                        />
                                                        <FileUploader
                                                            className="multi-image-banner__image-upload-button-circle"
                                                            selectButtonText="Select Image"
                                                            uploadMode="useForm"
                                                            showFileList={false}
                                                            onValueChanged={this.onValueChangedForProfilePhoto}
                                                            onInitialized={this.onProfilePhotoUploadInitialize}
                                                        ></FileUploader>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={this.state.selectedProfileFileContent} />
                                                        <Button
                                                            className="btn btn-primary artist-profile__image-preview-upload-button-circle"
                                                            onClick={this.onSubmitProfilePhoto}
                                                        >
                                                            <LoadIndicator
                                                                className="artist-profile__image-preview-loader--header-image"
                                                                height={15}
                                                                width={15}
                                                                visible={
                                                                    this.state.loadIndicatorVisibleForProfileUpload
                                                                }
                                                            />
                                                            <span>Upload</span>
                                                        </Button>
                                                        <Button
                                                            className="btn artist-profile__image-preview-cancel-button-circle"
                                                            onClick={this.onCancelProfilePhoto}
                                                        >
                                                            <span>Cancel</span>
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <h5 className="artist-profile__contact-heading">
                                            {" "}
                                            {this.props.t("manageArtistProfile.contact.caption.label")}
                                        </h5>
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType != "Entertainment"}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>
                                                {this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.name
                                                    : null}
                                            </span>
                                        </div>
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType == "Entertainment"}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>
                                                {this.state.editViewDataItem.legalName
                                                    ? this.state.editViewDataItem.legalName
                                                    : null}
                                            </span>
                                        </div>
                                        {this.state.editViewDataItem.contactInformation &&
                                            this.state.editViewDataItem.contactInformation.mobileTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`mobile:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .mobileTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.mobileTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`mobile:${this.state.editViewDataItem.officePhone}`}
                                                >
                                                    {this.state.editViewDataItem.officePhone}
                                                </a>
                                            </div>
                                        )}
                                        {this.state.editViewDataItem.contactInformation &&
                                            !this.state.editViewDataItem.contactInformation.mobileTelephone &&
                                            this.state.editViewDataItem.contactInformation.businessTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`business:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .businessTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .businessTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : null}
                                        {this.state.editViewDataItem.contactInformation &&
                                            this.state.editViewDataItem.contactInformation.otherTelephone &&
                                            !this.state.editViewDataItem.contactInformation.mobileTelephone &&
                                            !this.state.editViewDataItem.contactInformation.businessTelephone && this.props.serviceType == "Entertainment" ? (
                                            <div className="artist-profile__contact-detail">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <a
                                                    href={`other:${this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation
                                                            .otherTelephone
                                                        : null
                                                        }`}
                                                >
                                                    {this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.otherTelephone
                                                        : null}
                                                </a>
                                            </div>
                                        ) : null}
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType != "Entertainment"}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <a
                                                href={`mailto:${this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.email
                                                    : null
                                                    }`}
                                            >
                                                {this.state.editViewDataItem.contactInformation
                                                    ? this.state.editViewDataItem.contactInformation.email
                                                    : null}
                                            </a>
                                        </div>
                                        <div className="artist-profile__contact-detail" hidden={this.props.serviceType == "Entertainment"}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <a
                                                href={`mailto:${this.state.editViewDataItem.email}`}
                                            >
                                                {this.state.editViewDataItem.email}
                                            </a>
                                        </div>
                                        <fieldset className="artist-feedback-heading p-2">
                                            <legend className="mb-0 mt-2 float-none w-auto p-2 font-weight-bold text-center">RATINGS</legend>
                                            <div>
                                                <h2 className="artist-profile__contact-heading">Venue Rating</h2>
                                            </div>
                                            {this.state.editViewDataItem.score ? (
                                                    <StarRating
                                                        score={this.conversionUtils.convertStringToFloat(
                                                            this.state.editViewDataItem.score
                                                        )}
                                                    />
                                                ) : null
                                            }
                                            <br></br>
                                            <div>
                                                <h2 className="artist-profile__contact-heading">Event UK Rating</h2>
                                            </div>
                                            <div>
                                                <span style={ this.isViewCompany() ? { pointerEvents:'none'} : {}}>
                                                    <a
                                                        href="#"
                                                        className={`faThumbsUp-btn-artist ${this.state.isThumbsUpClicked ? "text-success" : ""}`}
                                                        onClick={this.handleThumbsUpIconClick}
                                                    >
                                                        <FontAwesomeIcon icon={faThumbsUp} />
                                                    </a>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <a
                                                        href="#"
                                                        className={`faFaceThinking-btn-artist ${this.state.isFaceThinkingClicked ? "text-warning" : ""}`}
                                                        onClick={this.handleFaceThinkingIconClick}
                                                    >
                                                        <FontAwesomeIcon icon={faFaceThinking} />
                                                    </a>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <a
                                                        href="#"
                                                        className={`faThumbsDown-btn-artist ${this.state.isThumbsDownClicked ? "text-danger" : ""}`}
                                                        onClick={this.handleThumbsDownIconClick}
                                                    >
                                                        <FontAwesomeIcon icon={faThumbsDown} />
                                                    </a>
                                                </span>
                                            </div>
                                            <br></br>
                                            <h2 className="artist-profile__contact-heading">
                                                <b>{this.props.t("manageArtistProfile.details.caption.artistComment.label")}</b>
                                            </h2>
                                            <TextBox
                                                readOnly={this.isViewCompany() ? true : false}
                                                value={this.state.editViewDataItem.feedbackComments}
                                                valueChangeEvent="keyup"
                                                onValueChanged={this.handleChangeFeedbackComments}
                                            />                                            
                                                <br></br>
                                            <div>
                                                Active Artist?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                    disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.isActive == "Yes" ||
                                                            this.state.editViewDataItem.isActive == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeActive}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>
                                            <div>
                                                Recommended Artist?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.trustedUser == "Yes" ||
                                                            this.state.editViewDataItem.trustedUser == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeTrusted}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>
                                            {/* {true && <> */}
                                            <div>
                                                Hide Profile Externally?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.hideProfileExternally == "Yes" ||
                                                            this.state.editViewDataItem.hideProfileExternally == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeHideProfileExternally}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>

                                            <div>
                                                Hide From Source?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.hideFromSource == "Yes" ||
                                                            this.state.editViewDataItem.hideFromSource == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeHideFromSource}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>

                                            
                                            <div>
                                                Agent?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.agent == "Yes" ||
                                                            this.state.editViewDataItem.agent == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeAgent}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>

                                            {/* {false && <>
                                            <div>
                                                Artist?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.artist == "Yes" ||
                                                            this.state.editViewDataItem.artist == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeArtist}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>
                                            </>} */}
                                            
                                            <div>
                                                Factored?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                disabled={this.isViewCompany() ? true : false}
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.factored == "Yes" ||
                                                            this.state.editViewDataItem.factored == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeFactored}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <br></br>
                                            {/* </>} */}
                                        </fieldset> 
                                    </div>
                                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                                    <div className="col-12 col-lg-9">

                                        <div className="container">
                                            <section>
                                                <div className="row mt-3">                                    
                                                        <h5 className="artist-profile__section-header">
                                                            {sectionHeading}
                                                        </h5>
                                                        <section className="mt-3 artist-status-flow">
                                                            <ul className="status-flow__artist-progress-bar">
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                                                    <CheckBox
                                                                        value={this.state.editViewDataItem.professionalName !== ""}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Profile
                                                                </li>
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                                                    <CheckBox
                                                                        value={this.state.editViewDataItem.contactInformation?.email !== ""}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Contact Details
                                                                </li>
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                                                    <CheckBox
                                                                        value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "" && this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() !== "99" && 
                                                                        (this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True") && (this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True")}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Business Set-Up
                                                                </li>
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                                                    <CheckBox
                                                                        value={this.state.editViewDataItem.bankInformation?.nameOnAccount !== "" && this.state.editViewDataItem.bankInformation?.accountNumber !== "" 
                                                                            && this.state.editViewDataItem.bankInformation?.sortCode !== ""}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Bank Account
                                                                </li>
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style" hidden={this.props.serviceType != "Entertainment"}>
                                                                    <CheckBox
                                                                        value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "30" || 
                                                                            this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "71"}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Identification
                                                                </li>
                                                                <li className="status-flow__progress-bar__artist-item artist-profile__div-style">
                                                                    <CheckBox
                                                                        value={completionStatus}
                                                                        readOnly={true}
                                                                    />
                                                                    &nbsp;
                                                                    Complete
                                                                </li>

                                                            </ul>
                                                        </section>
                                                </div>
                                            </section>
                                        </div>

                                        <div className="artist-profile__details mt-5" hidden={this.props.serviceType != "Entertainment"}>                                                                                    
                                            <h2 className="artist-profile__section-header">
                                                <b>{this.props.t("manageArtistProfile.details.caption.label")}</b>
                                                <span className="mx-3">({this.state.editViewDataItem.businessEntityID})</span>
                                            </h2>
                                            <div>
                                                Act Name
                                                <span className="unscheduled-shift">*</span>
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    value={this.state.editViewDataItem.professionalName}
                                                    valueChangeEvent="keyup"
                                                    onValueChanged={this.handleChangeAtristName}
                                                    placeholder={artistNameText}
                                                />
                                            </div>
                                            <br></br>
                                            <div>
                                                {this.props.t("manageArtistProfile.detailsSection.artistType.label")}
                                            </div>
                                            <div>
                                                <TagBox
                                                    value={this.state.artistTypeList}
                                                    dataSource={this.state.artistTypeLookUp}
                                                    width={"100%"}
                                                    valueExpr="id"
                                                    displayExpr="value"
                                                    showSelectionControls={true}
                                                    onValueChanged={this.handleChangeArtistType}
                                                    placeholder={artistTypeText}
                                                ></TagBox>
                                            </div>
                                            <br></br>
                                            <div hidden={this.state.hideOtherDescVal}>
                                                Other Description
                                                <span className="unscheduled-shift">*</span>
                                            </div>
                                            <div hidden={this.state.hideOtherDescVal}>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    value={this.state.otherDescriptionVal}
                                                    valueChangeEvent="keyup"
                                                    onValueChanged={this.handleChangeOtherDescription}
                                                    placeholder="Please specify (Other Description)"
                                                />
                                            </div>
                                            <br hidden={this.state.hideOtherDescVal}></br>
                                            <div>
                                                Genre
                                            </div>
                                            <div>
                                                <TagBox
                                                    value={this.state.genreTypeList}
                                                    dataSource={this.state.genreTypeLookUp}
                                                    width={"100%"}
                                                    valueExpr="id"
                                                    displayExpr="value"
                                                    showSelectionControls={true}
                                                    onValueChanged={this.handleChangeGenreType}
                                                ></TagBox>
                                            </div>
                                            <br></br>
                                            <div>
                                                Music Type
                                            </div>
                                            <div>
                                                <TagBox
                                                    value={this.state.musicTypeList}
                                                    dataSource={this.state.musicTypeLookUp}
                                                    width={"100%"}
                                                    valueExpr="id"
                                                    displayExpr="value"
                                                    showSelectionControls={true}
                                                    onValueChanged={this.handleChangeMusicType}
                                                ></TagBox>
                                            </div>
                                            <br></br>
                                            <div className="mt-3">
                                                Do you have equipment (decks, PA, etc.)?
                                            </div>
                                            <div>
                                                <RadioGroup
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.equipment == "Yes" ||
                                                            this.state.editViewDataItem.equipment == "true"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeEquipmentApplicable}
                                                    layout="horizontal"
                                                />
                                            </div>                                            
                                            <br></br>
                                            <div>
                                                {this.props.t("manageArtistProfile.detailsSection.bio.label")}
                                            </div>
                                            <div>
                                                <TextArea
                                                    height={100}
                                                    width={"100%"}
                                                    readOnly={false}
                                                    value={this.state.editViewDataItem.bio}
                                                    valueChangeEvent="keyup"
                                                    onValueChanged={this.handleChangeBioValue}
                                                    placeholder={biocommentsText}
                                                />
                                            </div>
                                            <br></br>
                                            <div className="artist-profile__social-links mb-3">
                                                <div>
                                                    {this.props.t("manageArtistProfile.detailsSection.website.label")}
                                                </div>
                                                <div>
                                                    <TextBox
                                                        width={"100%"}
                                                        readOnly={false}
                                                        value={this.state.webSiteLink}
                                                        valueChangeEvent="keyup"
                                                        onValueChanged={this.handleChangeWebsiteValue}
                                                        placeholder={websiteText}
                                                    />
                                                </div>
                                            </div>
                                            <h5 className="artist-profile__section-header mb-0">
                                                {this.props.t("manageArtistProfile.youtube.caption.label")}
                                            </h5>
                                            <div className="mb-5">
                                                <DataGrid
                                                    dataSource={this.state.youTubeLink}
                                                    showBorders={false}
                                                    showColumnLines={false}
                                                    hoverStateEnabled={true}
                                                    columnAutoWidth={true}
                                                    allowColumnReordering={true}
                                                    onRowUpdating={this.onRowUpdating}
                                                    onRowInserting={this.onRowInserting}
                                                    onRowRemoving={this.onRowRemoving}
                                                    onEditCanceling={this.onEditCancelled}
                                                    onEditingStart={this.onRowEditting}
                                                    onInitNewRow={this.onRowAdding}
                                                    onSelectionChanged={this.onSelectionChanged}
                                                    allowColumnResizing={true}
                                                    columnResizingMode={"widget"}
                                                    className="youtube-grid"
                                                >
                                                    <Selection mode="single" />
                                                    <Paging defaultPageSize={5} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                                    <Editing
                                                        mode="row"
                                                        useIcons={true}
                                                        allowAdding={true}
                                                        allowUpdating={true}
                                                        allowDeleting={true}
                                                    />
                                                    <Column type="buttons" buttons={["edit", "delete"]} />
                                                    <Column dataField="link" allowSorting={true} caption="YouTube" />
                                                    <FilterRow visible={false} applyFilter="auto" />
                                                </DataGrid>
                                                {this.state.youtubeVideoHolder && this.state.youtubeVideoHolder !== "Not match" ? (
                                                    <section>
                                                        <iframe
                                                            className="artist-profile__latest-videos"
                                                            src={this.state.youtubeVideoHolder}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </section>
                                                ) : (
                                                    this.renderLinkError()
                                                )}  
                                            </div>
                                            <h5 className="artist-profile__section-header mb-0">
                                                {this.props.t("manageArtistProfile.track.caption.label")}
                                            </h5>
                                            <div className="mb-5">
                                                <DataGrid
                                                    dataSource={this.state.trackLink}
                                                    showBorders={false}
                                                    showColumnLines={false}
                                                    hoverStateEnabled={true}
                                                    columnAutoWidth={true}
                                                    allowColumnReordering={true}
                                                    onRowUpdating={this.onRowUpdatingTrack}
                                                    onRowInserting={this.onRowInsertingTrack}
                                                    onRowRemoving={this.onRowRemovingTrack}
                                                    onEditCanceling={this.onEditCancelled}
                                                    onEditingStart={this.onRowEditting}
                                                    onInitNewRow={this.onRowAdding}
                                                    onSelectionChanged={this.onSelectionChangedTrack}
                                                    allowColumnResizing={true}
                                                    columnResizingMode={"widget"}
                                                    className="youtube-grid"
                                                >
                                                    <Selection mode="single" />
                                                    <Paging defaultPageSize={5} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[5]} showInfo={true} />
                                                    <Editing
                                                        mode="row"
                                                        useIcons={true}
                                                        allowAdding={this.state.isAllTrackAdding}
                                                        allowUpdating={true}
                                                        allowDeleting={true}
                                                    />
                                                    <Column type="buttons" buttons={["edit", "delete"]} />
                                                    <Column dataField="link" allowSorting={true} caption="YouTube" />
                                                    <FilterRow visible={false} applyFilter="auto" />
                                                </DataGrid>
                                                {this.state.trackVideoHolder ? (
                                                    <section>
                                                        <iframe title="deezer-widget" 
                                                            src={this.state.trackVideoHolder} 
                                                            className="artist-profile__latest-videos" 
                                                            allow="encrypted-media; clipboard-write"></iframe>
                                                    </section>
                                                ) : (null)}                                            

                                            </div>
                                            <div className="row mx-0 mt-3">
                                                <div className="col-12 col-lg-12 row pr-0">
                                                    <div className="col-12 col-xl-4 pl-0">
                                                        Travel From PostCode (i.e. willing to travel from)
                                                    </div>
                                                    <div className="col-12 col-xl-4 pl-0">
                                                        
                                                    </div>
                                                    <div className="col-12 col-xl-4 pl-0">
                                                        Town/City
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-12 row pr-0">
                                                    <div className="col-12 col-xl-4 pl-0">
                                                        <TextBox
                                                            width={"100%"}
                                                            readOnly={false}
                                                            onValueChanged={this.handleChangeCoveragePostCode}
                                                            valueChangeEvent="keyup"
                                                            value={this.state.editViewDataItem.coveragePostCode}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                                        <button
                                                            className="btn btn-primary btn--large p-1"
                                                            disabled={this.state.editViewDataItem.coveragePostCode == "" || this.state.editViewDataItem.coveragePostCode == null}
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.getTownCity();
                                                            }}
                                                        >
                                                            TOWN/CITY LOOKUP
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-xl-4 pl-0">
                                                        <TextBox
                                                            width={"100%"}
                                                            readOnly={false}
                                                            onValueChanged={this.handleChangeTownCity}
                                                            valueChangeEvent="keyup"
                                                            value={this.state.editViewDataItem.coverageTownCity}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <b>
                                                    {this.props.t(
                                                        "manageArtistProfile.tradingInfoSection.coverageMiles.label"
                                                    )}
                                                </b>
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeCoverageMiles}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.coverageMiles}
                                                    placeholder={travelText}
                                                />
                                            </div>  
                                        </div>
                                        <div className="artist-profile__details mt-5" hidden={this.props.serviceType == "Entertainment"}> 
                                            <h5 className="artist-profile__section-header">
                                                {this.props.t("manageArtistProfile.tradingInformation.caption.label")}
                                                {this.state?.editViewDataItem?.businessEntityID && <span className="mx-3">({this.state.editViewDataItem.businessEntityID})</span>}
                                            </h5>
                                            <div>
                                                Services
                                            </div>
                                            <div hidden={this.props.serviceType == "ViewClients" || this.props.serviceType == "ViewCompany"}>
                                                <TagBox
                                                    value={this.state.serviceTypeList}
                                                    dataSource={this.state.serviceTypeLookUp} //excludes Entertainment 
                                                    width={"100%"}
                                                    valueExpr="serviceTypeLookupId"
                                                    displayExpr="value"
                                                    showSelectionControls={true}
                                                    onValueChanged={this.handleChangeServiceType}
                                                ></TagBox>
                                            </div>
                                            <div hidden={this.props.serviceType !== "ViewClients" && this.props.serviceType !== "ViewCompany"}>
                                                <TagBox
                                                    value={this.state.serviceTypeList}
                                                    dataSource={this.state.serviceTypeClientLookUp} //includes Entertainment 
                                                    width={"100%"}
                                                    valueExpr="serviceTypeLookupId"
                                                    displayExpr="value"
                                                    showSelectionControls={true}
                                                    onValueChanged={this.handleChangeServiceType}
                                                ></TagBox>
                                            </div>                                            
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.tradingInfoSection.businessType.label")}
                                                <span className="unscheduled-shift">*</span>
                                            </div>
                                            <div>
                                                <SelectBox
                                                    dataSource={this.state.businessTypeLU}
                                                    width={"100%"}
                                                    readOnly={false}
                                                    valueExpr="id"
                                                    displayExpr="value"
                                                    onValueChanged={this.handleChangeBusinessTypeValue}
                                                    value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId}
                                                    placeholder={businessTypeText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.tradingInfoSection.businessName.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeBusinessName}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.legalName}
                                                    placeholder={businessNameText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                Short Name
                                                <span className="unscheduled-shift">*</span>
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeShortName}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.shortName}
                                                    placeholder={businessNameText}
                                                />
                                            </div>
                                            <div className="mt-3">{tradingNameFieldNameText}</div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingName}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.tradingName}
                                                    placeholder={tradingNameText}
                                                />
                                            </div>                                        
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.tradingInfoSection.companyNo.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeCompanyNumber}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.companyNumber}
                                                    placeholder={companyNumberText}
                                                />
                                            </div>
                                            <div className="mt-3">{VATQuestionFieldNameText}</div>
                                            <div>
                                                <RadioGroup
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.taxApplicable == "Yes" ||
                                                            this.state.editViewDataItem.taxApplicable == "True"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeTaxApplicable}
                                                    layout="horizontal"
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.tradingInfoSection.vatNo.label")}
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col-12 col-lg-12 row pr-0">
                                                    <div className="col-12 col-xl-8 pl-0">
                                                        <TextBox
                                                            readOnly={false}
                                                            onValueChanged={this.handleChangeVAT}
                                                            valueChangeEvent="keyup"
                                                            value={this.state.editViewDataItem.taxRegistrationNumber}
                                                            placeholder={VATNumberText}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                                        <button
                                                            className="btn btn-primary btn--large p-1"
                                                            disabled={this.state.editViewDataItem.taxRegistrationNumber == ""}
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.getVATDetails();
                                                            }}
                                                        >
                                                            SEE VAT DETAILS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            { (this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True") && this.state.editViewDataItem.taxVerifiedBy !== null ? 
                                                <div className="mt-3">
                                                    VAT Verified (by {this.state.editViewDataItem.taxVerifiedBy} on {taxVerifiedDateTime})
                                                </div>
                                                : <div className="mt-3">
                                                        VAT Verified
                                                </div>
                                            }
                                            <div>
                                                <RadioGroup
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeVATVerification}
                                                    layout="horizontal"
                                                    disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}
                                                />
                                            </div>
                                            { (this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True") && this.state.editViewDataItem.verifiedBy !== null ? 
                                                <div className="mt-3">
                                                    Business Set-Up Verified (by {this.state.editViewDataItem.taxVerifiedBy} on {taxVerifiedDateTime})
                                                </div>
                                                : <div className="mt-3">
                                                        Business Set-Up Verified
                                                </div>
                                            }
                                            <div>
                                                <RadioGroup
                                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                    displayExpr="value"
                                                    valueExpr="value"
                                                    value={
                                                        this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True"
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onValueChanged={this.handleChangeBusinessSetUpVerification}
                                                    layout="horizontal"
                                                    disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                Office Phone
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeOfficePhone}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.officePhone}
                                                    placeholder={mobileText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                Email
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeProviderEmail}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.email}
                                                    placeholder={mobileText}
                                                />
                                            </div>
                                            <br></br>
                                            <b>
                                                {this.props.t(
                                                    "manageArtistProfile.tradingInfoSection.registeredAddress.label"
                                                )}
                                            </b>
                                            <div>
                                                {this.props.t(
                                                    "manageArtistProfile.contactDetailsSection.addressLine1.label"
                                                )}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingAddressStreet1}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.addressStreet1}
                                                    placeholder={registeredAddressLine1Text}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t(
                                                    "manageArtistProfile.contactDetailsSection.addressLine2.label"
                                                )}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingAddressStreet2}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.addressStreet2}
                                                    placeholder={registeredAddressLine2Text}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t(
                                                    "manageArtistProfile.contactDetailsSection.addressLine3.label"
                                                )}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingAddressStreet3}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.addressStreet3}
                                                    placeholder={registeredAddressLine3Text}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.contactDetailsSection.town.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingCity}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.city}
                                                    placeholder={registeredAddressTown_CityText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.contactDetailsSection.county.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingState}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.state}
                                                    placeholder={registeredAddressCountyText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.contactDetailsSection.country.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingCountry}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.country}
                                                    placeholder={registeredAddressCountryText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.contactDetailsSection.postcode.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingPostCode}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.postCode}
                                                    placeholder={registeredAddressPostcodeText}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                {this.props.t("manageArtistProfile.detailsSection.bio.label")}
                                            </div>
                                            <div>
                                                <TextArea
                                                    height={100}
                                                    width={"100%"}
                                                    readOnly={false}
                                                    value={this.state.editViewDataItem.bio}
                                                    valueChangeEvent="keyup"
                                                    onValueChanged={this.handleChangeBioValue}
                                                    placeholder={"e.g. past experience! (Max 600 characters)"}
                                                />
                                            </div>
                                            <div className="artist-profile__social-links mb-3">
                                                <div>
                                                    {this.props.t("manageArtistProfile.detailsSection.website.label")}
                                                </div>
                                                <div>
                                                    <TextBox
                                                        width={"100%"}
                                                        readOnly={false}
                                                        value={this.state.webSiteLink}
                                                        valueChangeEvent="keyup"
                                                        onValueChanged={this.handleChangeWebsiteValue}
                                                        placeholder={websiteText}
                                                    />
                                                </div>
                                            </div>
                                            <div className="artist-profile__social-links mb-3">
                                                <div>
                                                    OOO Protocol
                                                </div>
                                                <div>
                                                    <TextBox
                                                        width={"100%"}
                                                        readOnly={false}
                                                        value={this.state.editViewDataItem.oooProtocol}
                                                        valueChangeEvent="keyup"
                                                        onValueChanged={this.handleChangeOOOProtocol}
                                                        placeholder={"Please add any OOO Protocol here"}
                                                    />
                                                </div>
                                            </div>  
                                            <div>
                                            <div>Onboarded Date</div>
                                        <div>
                                            <DateBox
                                                width={"100%"}
                                                readOnly={false}
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                onValueChanged={this.handleChangeOnboardedDate}
                                                valueChangeEvent="keyup"
                                                value={ this.state.editViewDataItem.onboardedDate ? this.state.editViewDataItem.onboardedDate : undefined } 
                                                placeholder={onboardedText}
                                            />
                                        </div>
                                            </div>                                                                                     
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section" hidden={this.props.serviceType != "Entertainment"}>
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            {this.props.t("manageArtistProfile.details.contactDetails.label")}
                                        </h5>
                                        <div>
                                            {this.props.t("manageArtistProfile.contactDetailsSection.name.label")}
                                            <span className="unscheduled-shift" hidden={this.state.editViewDataItem.contactInformation?.active == undefined || this.state.editViewDataItem.contactInformation?.active == "No" || this.state.editViewDataItem.contactInformation?.active == "False" 
                                                || this.state.editViewDataItem.contactInformation?.active == "" || this.state.editViewDataItem.user?.active == undefined 
                                                || this.state.editViewDataItem.user?.active == "No" || this.state.editViewDataItem.user?.active == "False" 
                                                || this.state.editViewDataItem.user?.active == ""}>*</span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeContactName}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.name
                                                        : ""
                                                }
                                                placeholder={nameText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.mobile.label")}
                                            <span className="unscheduled-shift">*</span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeMobile}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.mobileTelephone
                                                        : ""
                                                }
                                                placeholder={mobileText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t(
                                                "manageArtistProfile.contactDetailsSection.otherTelePhone.label"
                                            )}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeLandLine}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.otherTelephone
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.email.label")}
                                            <span className="unscheduled-shift" hidden={this.state.editViewDataItem.user?.active == undefined || this.state.editViewDataItem.user?.active == "No" || this.state.editViewDataItem.user?.active == "False" 
                                                || this.state.editViewDataItem.user?.active == ""}>*</span>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-12 col-lg-12 row pr-0">
                                                <div className="col-12 col-xl-8 pl-0">
                                                    <TextBox
                                                        width={"100%"}
                                                        readOnly={false}
                                                        onValueChanged={this.handleChangeEmail}
                                                        valueChangeEvent="keyup"
                                                        value={
                                                            this.state.editViewDataItem.contactInformation
                                                                ? this.state.editViewDataItem.contactInformation.email
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                                    <button
                                                        className="btn btn-primary btn--large p-1"
                                                        disabled={this.state.editViewDataItem.contactInformation?.email == ""}
                                                        onClick={(e: MouseEvent) => {
                                                            e.preventDefault();
                                                            this.changeEmail();
                                                        }}
                                                    >
                                                        Change Email
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t(
                                                "manageArtistProfile.contactDetailsSection.addressLine1.label"
                                            )}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeAddressStreet1}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.addressStreet1
                                                        : ""
                                                }
                                                placeholder={addressLine1Text}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t(
                                                "manageArtistProfile.contactDetailsSection.addressLine2.label"
                                            )}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeAddressStreet2}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.addressStreet2
                                                        : ""
                                                }
                                                placeholder={addressLine2Text}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.town.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeCity}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.city
                                                        : ""
                                                }
                                                placeholder={town_cityText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.county.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeState}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.state
                                                        : ""
                                                }
                                                placeholder={countyText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.postcode.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangePostCode}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.postCode
                                                        : ""
                                                }
                                                placeholder={postcodeText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            Opt Out (No Communications)
                                        </div>
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={ this.state.editViewDataItem.contactInformation?.optOut == "Yes" ||
                                                    this.state.editViewDataItem.contactInformation?.optOut == "true"
                                                    ? "Yes"
                                                    : "No"
                                                }
                                                onValueChanged={this.handleChangeOptOut}
                                                layout="horizontal"
                                            />
                                        </div>   
                                        <div className="mt-3">
                                            Active
                                            <span className="unscheduled-shift">*</span>
                                        </div>
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={ this.state.editViewDataItem.contactInformation?.active == "Yes" ||
                                                    this.state.editViewDataItem.contactInformation?.active == "true"
                                                    ? "Yes"
                                                    : "No"
                                                }
                                                onValueChanged={this.handleChangeContactActive} 
                                                layout="horizontal"
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}
                                            />
                                        </div> 
                                        <div className="artist-profile__section-header mt-3">
                                            <span className="font-size-one">Last Updated:&nbsp;{this.state.contactCreatedDateTime}</span>
                                        </div>
                                    </div>{" "}
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section" hidden={this.props.serviceType != "Entertainment"}>
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            User Details
                                            <span className="mx-3">({this.state.editViewDataItem.user?.id})</span>
                                        </h5>
                                        <div>
                                            User Name
                                            <span className="unscheduled-shift" hidden={this.state.editViewDataItem.user?.active == undefined || this.state.editViewDataItem.user?.active == "No" || this.state.editViewDataItem.user?.active == "False" 
                                                || this.state.editViewDataItem.user?.active == ""}>*</span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeUserName}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem?.user?.name}
                                                disabled={!this.state.isEditMode}
                                            />
                                        </div>
                                            <div className="row">
                                                <div className='col'>
                                                    <div className="mt-3">
                                                        Active User
                                                        <span className="unscheduled-shift">*</span>
                                                    </div>
                                                    <div>
                                                        <RadioGroup
                                                            items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                            displayExpr="value"
                                                            valueExpr="value"
                                                            value={this.state.editViewDataItem?.user?.active == "Yes" || this.state.editViewDataItem?.user?.active == "True"
                                                                ? "Yes"
                                                                : "No"
                                                            }
                                                            onValueChanged={this.handleChangeUserActive}
                                                            layout="horizontal"
                                                            disabled={!this.state.isEditMode}
                                                        />
                                                    </div>
                                                </div>
                                                {/* {true && <> */}
                                                    <div className="col">
                                                        <div className="mt-3">
                                                            Allow Internal Web Access?
                                                            {/* <span className="unscheduled-shift">*</span> */}
                                                        </div>

                                                        <div>
                                                            <RadioGroup
                                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                                displayExpr="value"
                                                                valueExpr="value"
                                                                value={this.state.editViewDataItem?.user?.allowWebApp == "Yes" || this.state.editViewDataItem?.user?.allowWebApp == "True"
                                                                    ? "Yes"
                                                                    : "No"
                                                                }
                                                                onValueChanged={this.handleChangeAllowWebApp}
                                                                layout="horizontal"
                                                                disabled={!this.state.isEditMode}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="mt-3">
                                                            Disallow App Access?
                                                            {/* <span className="unscheduled-shift">*</span> */}
                                                        </div>

                                                        <div>
                                                            <RadioGroup
                                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                                displayExpr="value"
                                                                valueExpr="value"
                                                                value={this.state.editViewDataItem?.user?.disallowApp == "Yes" || this.state.editViewDataItem?.user?.disallowApp == "True"
                                                                    ? "Yes"
                                                                    : "No"
                                                                }
                                                                onValueChanged={this.handleChangeDisallowApp}
                                                                layout="horizontal"
                                                                disabled={!this.state.isEditMode}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="mt-3">
                                                            Disallow Portal Access?
                                                            {/* <span className="unscheduled-shift">*</span> */}
                                                        </div>

                                                        <div>
                                                            <RadioGroup
                                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                                displayExpr="value"
                                                                valueExpr="value"
                                                                value={this.state.editViewDataItem?.user?.disallowPortal == "Yes" || this.state.editViewDataItem?.user?.disallowPortal == "True"
                                                                    ? "Yes"
                                                                    : "No"
                                                                }
                                                                onValueChanged={this.handleChangeDisallowPortal}
                                                                layout="horizontal"
                                                                disabled={!this.state.isEditMode}
                                                            />
                                                        </div>
                                                    </div>
                                                {/* </>} */}
                                            </div>
                                        
                                        <div className="artist-profile__section-header mt-3">
                                            <span className="font-size-one">Last Updated:&nbsp;{this.state.userCreatedDateTime}</span>
                                        </div>
                                    </div>{" "}
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section" hidden={this.props.serviceType != "Entertainment"}>
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            {this.props.t("manageArtistProfile.tradingInformation.caption.label")}
                                        </h5>
                                        <div>
                                            {this.props.t("manageArtistProfile.tradingInfoSection.businessType.label")}
                                            <span className="unscheduled-shift">*</span>
                                        </div>
                                        <div>
                                            <SelectBox
                                                dataSource={this.state.businessTypeLU}
                                                width={"100%"}
                                                readOnly={false}
                                                valueExpr="id"
                                                displayExpr="value"
                                                onValueChanged={this.handleChangeBusinessTypeValue}
                                                value={this.state.editViewDataItem.businessEntityLegalStatusLookUpId}
                                                placeholder={businessTypeText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.tradingInfoSection.businessName.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeBusinessName}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.legalName}
                                                placeholder={businessNameText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            Short Name
                                            <span className="unscheduled-shift">*</span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeShortName}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.shortName}
                                                placeholder={businessNameText}
                                            />
                                        </div>
                                        <div className="mt-3">{tradingNameFieldNameText}</div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingName}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.tradingName}
                                                placeholder={tradingNameText}
                                            />
                                        </div>                                        
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.tradingInfoSection.companyNo.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeCompanyNumber}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.companyNumber}
                                                placeholder={companyNumberText}
                                            />
                                        </div>
                                        <div className="mt-3">{VATQuestionFieldNameText}</div>
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.taxApplicable == "Yes" ||
                                                        this.state.editViewDataItem.taxApplicable == "True"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeTaxApplicable}
                                                layout="horizontal"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.tradingInfoSection.vatNo.label")}
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-12 col-lg-12 row pr-0">
                                                <div className="col-12 col-xl-8 pl-0">
                                                    <TextBox
                                                        readOnly={false}
                                                        onValueChanged={this.handleChangeVAT}
                                                        valueChangeEvent="keyup"
                                                        value={this.state.editViewDataItem.taxRegistrationNumber}
                                                        placeholder={VATNumberText}
                                                    />
                                                </div>
                                                <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                                    <button
                                                        className="btn btn-primary btn--large p-1"
                                                        disabled={this.state.editViewDataItem.taxRegistrationNumber == ""}
                                                        onClick={(e: MouseEvent) => {
                                                            e.preventDefault();
                                                            this.getVATDetails();
                                                        }}
                                                    >
                                                        SEE VAT DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        { (this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True") && this.state.editViewDataItem.taxVerifiedBy !== null ? 
                                            <div className="mt-3">
                                                VAT Verified (by {this.state.editViewDataItem.taxVerifiedBy} on {taxVerifiedDateTime})
                                            </div>
                                            : <div className="mt-3">
                                                    VAT Verified
                                              </div>
                                        }
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.taxVerified == "Yes" || this.state.editViewDataItem.taxVerified == "True"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeVATVerification}
                                                layout="horizontal"
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}
                                            />
                                        </div>
                                        { (this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True") && this.state.editViewDataItem.verifiedBy !== null ? 
                                            <div className="mt-3">
                                                Business Set-Up Verified (by {this.state.editViewDataItem.taxVerifiedBy} on {taxVerifiedDateTime})
                                            </div>
                                            : <div className="mt-3">
                                                    Business Set-Up Verified
                                              </div>
                                        }
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.verified == "Yes" || this.state.editViewDataItem.verified == "True"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeBusinessSetUpVerification}
                                                layout="horizontal"
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}
                                            />
                                        </div>
                                        <br></br>
                                        <b>
                                            {this.props.t(
                                                "manageArtistProfile.tradingInfoSection.registeredAddress.label"
                                            )}
                                        </b>
                                        <div>
                                            {this.props.t(
                                                "manageArtistProfile.contactDetailsSection.addressLine1.label"
                                            )}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingAddressStreet1}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.addressStreet1}
                                                placeholder={registeredAddressLine1Text}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t(
                                                "manageArtistProfile.contactDetailsSection.addressLine2.label"
                                            )}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingAddressStreet2}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.addressStreet2}
                                                placeholder={registeredAddressLine2Text}
                                            />
                                        </div>
                                        <div className="mt-3">
                                                {this.props.t(
                                                    "manageArtistProfile.contactDetailsSection.addressLine3.label"
                                                )}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingAddressStreet3}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.addressStreet3}
                                                    placeholder={registeredAddressLine3Text}
                                                />
                                            </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.town.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingCity}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.city}
                                                placeholder={registeredAddressTown_CityText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.county.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingState}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.state}
                                                placeholder={registeredAddressCountyText}
                                            />.
                                        </div>
                                        <div className="mt-3">
                                                {this.props.t("manageArtistProfile.contactDetailsSection.country.label")}
                                            </div>
                                            <div>
                                                <TextBox
                                                    width={"100%"}
                                                    readOnly={false}
                                                    onValueChanged={this.handleChangeTradingCountry}
                                                    valueChangeEvent="keyup"
                                                    value={this.state.editViewDataItem.country}
                                                    placeholder={registeredAddressCountryText}
                                                />
                                            </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.postcode.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeTradingPostCode}
                                                valueChangeEvent="keyup"
                                                value={this.state.editViewDataItem.postCode}
                                                placeholder={registeredAddressPostcodeText}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section">
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>                                    
                                    <div className="col-lg-9">
                                        <div className="row mx-0">
                                        <div className="col-12 col-lg-12 row pr-0">
                                            <div className="col-12 col-xl-8 pl-0">
                                                { this.state.editViewDataItem.bankInformation?.createdDateTime !== "" ? 
                                                    <div className="artist-profile__section-header">
                                                        <span>{this.props.t("manageArtistProfile.bankDetails.caption.label")}</span><span className="font-size-one mx-3">(Created / Updated On&nbsp;{bankDetailsCreatedDateTime})</span>
                                                    </div>
                                                    : <h5 className="artist-profile__section-header">
                                                        {this.props.t("manageArtistProfile.bankDetails.caption.label")}
                                                    </h5>
                                                }  
                                            </div>
                                            <div className="col-12 col-xl-4 pl-0 mt-3 mt-xl-0">
                                                <button disabled={this.isViewCompany()}
                                                    className="btn btn-primary btn--large p-1"
                                                    onClick={(e: MouseEvent) => {
                                                        e.preventDefault();
                                                        this.getBankDetails();
                                                    }}
                                                >
                                                    SEE BANK DETAILS
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.bankDetailsSection.sortCode.label")}
                                            <span className="unscheduled-shift">
                                                {this.state.isBankDetailsMandatory ? "*" : null}
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeSortCode}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.bankInformation
                                                        ? this.state.editViewDataItem.bankInformation.sortCode
                                                        : ""
                                                }
                                                placeholder={sortCodeText}
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.bankDetailsSection.accountNumber.label")}
                                            <span className="unscheduled-shift">
                                                {this.state.isBankDetailsMandatory ? "*" : null}
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeAccountNumber}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.bankInformation
                                                        ? this.state.editViewDataItem.bankInformation.accountNumber
                                                        : ""
                                                }
                                                placeholder={accountNumberText}
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.bankDetailsSection.accountName.label")}
                                            <span className="unscheduled-shift">
                                                {this.state.isBankDetailsMandatory ? "*" : null}
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeAccountName}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.bankInformation
                                                        ? this.state.editViewDataItem.bankInformation.nameOnAccount
                                                        : ""
                                                }
                                                placeholder={nameonAccountText}
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.bankDetailsSection.reference.label")}
                                        </div>
                                        <div>
                                            <TextBox
                                                width={"100%"}
                                                readOnly={false}
                                                onValueChanged={this.handleChangeReference}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.bankInformation
                                                        ? this.state.editViewDataItem.bankInformation.buildingSociety
                                                        : ""
                                                }
                                                placeholder={referenceText}
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            Active
                                        </div>
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.bankInformation && this.state.editViewDataItem.bankInformation.active == "Yes" ||
                                                        this.state.editViewDataItem.bankInformation && this.state.editViewDataItem.bankInformation.active == "True"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeBankAccountActivation}
                                                layout="horizontal"
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                        { this.state.editViewDataItem.bankInformation?.verified == "True" && this.state.editViewDataItem.bankInformation?.verifiedBy !== "" ? 
                                            <div className="mt-3">
                                                Verified (by {this.state.editViewDataItem.bankInformation?.verifiedBy} on {verifiedDateTime})
                                            </div>
                                            : <div className="mt-3">
                                                    Verified
                                              </div>
                                        }
                                        <div>
                                            <RadioGroup
                                                items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                                displayExpr="value"
                                                valueExpr="value"
                                                value={
                                                    this.state.editViewDataItem.bankInformation && this.state.editViewDataItem.bankInformation.verified == "Yes" ||
                                                        this.state.editViewDataItem.bankInformation && this.state.editViewDataItem.bankInformation.verified == "True"
                                                        ? "Yes"
                                                        : "No"
                                                }
                                                onValueChanged={this.handleChangeBankAccountVerification}
                                                layout="horizontal"
                                                disabled={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) || this.isViewCompany()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            
                            <section className="artist-profile__edit-section" hidden={this.props.serviceType != "Entertainment"}>
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            {this.props.t("manageArtistProfile.details.identificationDetails.label")}
                                        </h5>
                                        <div>{this.props.t("manageArtistProfile.contactDetailsSection.dob.label")}</div>
                                        <div>
                                            <DateBox
                                                width={"100%"}
                                                readOnly={false}
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                onValueChanged={this.handleChangeDateOfBirth}
                                                valueChangeEvent="keyup"
                                                value={
                                                    this.state.editViewDataItem.contactInformation
                                                        ? this.state.editViewDataItem.contactInformation.dateOfBirth
                                                            ? this.state.editViewDataItem.contactInformation.dateOfBirth
                                                            : undefined
                                                        : undefined
                                                }
                                                placeholder={dateofBirthText}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            {this.props.t("manageArtistProfile.contactDetailsSection.photoId.label")}
                                            {this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "30" ||
                                                this.state.editViewDataItem.businessEntityLegalStatusLookUpId.toString() == "71" ? <span className="unscheduled-shift">*</span>
                                            : null }
                                        </div>
                                        {photoProofIndex !== -1 ? (
                                            <button 
                                                className="btn btn-link btn-link--inline"
                                                onClick={this.togglePhotoIdProofVisible}
                                            >
                                                <span className="dx-button-text">VIEW DOCUMENT</span>
                                            </button>
                                        ) : null}
                                        <SelectBox
                                            className="mt-2"
                                            dataSource={this.state.photoIdLookUp}
                                            width={"100%"}
                                            readOnly={false}
                                            valueExpr="id"
                                            displayExpr="value"
                                            onValueChanged={this.handleChangePhotoIDValue}
                                            value={this.state.photoLookUpId}
                                            placeholder={photoIDText}
                                        />
                                        {this.state.isPhotoIdProofAdded == false ? (
                                            <div className="row mt-2">
                                                <div className="col-12 col-lg-5">
                                                    <FileUploader
                                                        className="artist-profile__file-uploader"
                                                        selectButtonText="Select Image"
                                                        uploadMode="useButtons"
                                                        allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                                                        showFileList={false}
                                                        onValueChanged={this.onValueChangedForPhotoIdProof}
                                                        onInitialized={this.onPhotoIdProofUploadInitialize}
                                                    ></FileUploader>
                                                </div>
                                                <div className="col-12 col-lg-7 mt-1 text-lg-right">
                                                    {this.state.selectedPhotoIdProofFileIsInvalid ? (
                                                        <span className="text-danger">
                                                            File unsupported - allowed types: .jpg, .jpeg, .gif, .png
                                                        </span>
                                                    ) : (
                                                        "Allowed file types: .jpg, .jpeg, .gif, .png"
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-2">
                                                {this.state.photoIdSelectedOrNotMsg ? (
                                                    <span className="d-block my-2 text-danger">
                                                        {this.state.photoIdSelectedOrNotMsg}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="artist-profile__image-preview-container">
                                                    <LoadIndicator
                                                        id="button-indicator"
                                                        className="artist-profile__image-preview-loader"
                                                        visible={this.state.loadIndicatorVisibleForPhotoIdProofUpload}
                                                    />
                                                    <Button
                                                        className="btn artist-profile__image-preview-cancel-button--tight"
                                                        onClick={this.onCancelPhotoIdProof}
                                                    >
                                                        <span>Cancel</span>
                                                    </Button>
                                                    <Button
                                                        className="btn btn-primary artist-profile__image-preview-upload-button--tight"
                                                        onClick={this.onSubmitPhotoIdProof}
                                                    >
                                                        <span>Upload</span>
                                                    </Button>
                                                    <img src={this.state.selectedPhotoIdProofFileContent} width="400" />
                                                </div>
                                            </div>
                                        )}

                                        <Popup
                                            visible={this.state.isPhotoIdProofVisible}
                                            onHiding={this.togglePhotoIdProofVisible}
                                            dragEnabled={false}
                                            showCloseButton={true}
                                            closeOnOutsideClick={true}
                                            className="pop-up"
                                        >
                                            <img
                                                width="100%"
                                                src={photoProofIndex !== -1 ? this.state.photoProofHolder[photoProofIndex]?.link : ""}
                                            />
                                        </Popup> 
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>

                            <section className="artist-profile__edit-section" hidden={this.isViewCompany()}>
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            Self-Billing
                                        </h5>
                                        <div>Agreement Date<span className="unscheduled-shift">*</span></div>
                                        <div>
                                            <DateBox
                                                width={"100%"}
                                                readOnly={false}
                                                useMaskBehavior={true}
                                                displayFormat="dd/MM/yyyy"
                                                onValueChanged={this.handleChangeAgreementDate}
                                                valueChangeEvent="keyup"
                                                value={ this.state.agreementDate ? this.state.agreementDate : undefined }                                                
                                            />
                                        </div>
                                        <div className="mt-3">
                                            Agreement<span className="unscheduled-shift">*</span>
                                        </div>
                                        {agreementIndex !== -1 ? (
                                            <button 
                                                className="btn btn-link btn-link--inline"
                                                onClick={this.toggleAgreementVisible}
                                            >
                                                <span className="dx-button-text">VIEW DOCUMENT</span>
                                            </button>
                                         ) : null} 
                                        <SelectBox
                                            className="mt-2"
                                            dataSource={this.state.agreementLookUp}
                                            width={"100%"}
                                            readOnly={false}
                                            valueExpr="id"
                                            displayExpr="value"
                                            onValueChanged={this.handleChangeAgreementValue}
                                            value={this.state.agreementTypeId}
                                        />
                                        {this.state.isAgreementProofAdded == false ? (
                                            <div>
                                                <div className="row mt-2">
                                                    <div className="col-12 col-lg-3">
                                                        <FileUploader
                                                            className="artist-profile__file-uploader"
                                                            selectButtonText="Select Document"
                                                            uploadMode="useButtons"
                                                            allowedFileExtensions={['.pdf', '.rtf', 'word']}
                                                            showFileList={false}
                                                            onValueChanged={this.onValueChangedForAgreementDoc}
                                                            onInitialized={this.onAgreementDocUploadInitialize}
                                                        ></FileUploader>
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <button
                                                            className="btn btn--ghost btn--ghost--teal btn--large p-1"
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.getSignDocument();
                                                            }}
                                                            disabled={this.isSelfBillingButtonsDisabled()}
                                                        >
                                                            Get Doc To Sign
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <button
                                                            className="btn btn--ghost btn--ghost--teal btn--large p-1"
                                                            onClick={(e: MouseEvent) => {
                                                                e.preventDefault();
                                                                this.createDocument();
                                                            }}
                                                            disabled={this.isSelfBillingButtonsDisabled()}
                                                        >
                                                            Create New Document
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-lg-3 mt-1 text-lg-right">
                                                        {this.state.selectedAgreementDocIsInvalid ? (
                                                            <span className="text-danger">
                                                                File unsupported - allowed types: .pdf, word, .rtf
                                                            </span>
                                                        ) : (
                                                            "Allowed file types: .pdf, word, .rtf"
                                                        )}
                                                    </div>
                                                </div>
                                                    {
                                                        this.hasSelfBillingError()
                                                    }
                                            </div>
                                            
                                        ) : (
                                            <div className="mt-2">
                                                {
                                                    this.hasSelfBillingError()
                                                }
                                                <div className="mx-3 text-danger"> {this.state.selectedAgreementDocFile[0].name} is ready to be uploaded, please select Upload to continue or Cancel to select alternate document </div>
                                                <div className="artist-profile__image-preview-container mb-3">
                                                    <LoadIndicator
                                                        id="button-indicator"
                                                        className="artist-profile__image-preview-loader"
                                                        visible={this.state.loadIndicatorVisibleForAgreementDocUpload}
                                                    />
                                                    <Button
                                                        className="btn artist-profile__image-preview-cancel-button--tight"
                                                        onClick={this.onCancelAgreementDoc}
                                                    >
                                                        <span>Cancel</span>
                                                    </Button>
                                                    <Button
                                                        className="btn btn-primary artist-profile__image-preview-upload-button--tight"
                                                        onClick={this.onSubmitAgreementDoc}
                                                    >
                                                        <span>Upload</span>
                                                    </Button>                                                    
                                                </div>
                                            </div>
                                        )}

                                        <Popup
                                            visible={this.state.isAgreementVisible}
                                            onHiding={this.toggleAgreementVisible}
                                            dragEnabled={false}
                                            showCloseButton={true}
                                            closeOnOutsideClick={true}
                                            className="pop-up"
                                        >
                                            <section>
                                                <iframe
                                                    className="artist-profile__latest-videos"
                                                    src={agreementIndex !== -1 ? this.state.agreementHolder[agreementIndex]?.link : ""}
                                                ></iframe>                                               
                                            </section>
                                        </Popup>
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section">
                                <div className="row mt-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-lg-9">
                                        <h5 className="artist-profile__section-header">
                                            {" "}
                                            {this.props.t("manageArtistProfile.termsAndCondition.caption.label")}
                                        </h5>
                                        <div>
                                            <CheckBox
                                                value={this.state.editViewDataItem.termsAgreed}
                                                onValueChanged={this.handleChangeTermsAndConditionCheck}
                                            />
                                            &nbsp; &nbsp;
                                            {termsConditionsText}
                                        </div>
                                        <div className="mt-3">
                                            <b>Accepted on {termsAgreedDateTime}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-3"></div>
                                </div>
                            </section>
                            <section className="artist-profile__edit-section">
                                <div className="row mt-3">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-6 style-validation_msg">
                                        {this.state.IsShowSuccess ? (
                                            <span className="text-success">
                                                <b>Profile Information Saved Successfully!</b>
                                            </span>
                                        ) : null}
                                        {this.state.IsShowError && this.state.errorMessage != null ? (
                                            <span className="unscheduled-shift">
                                                <ul>
                                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                        return (
                                                            <li key={uniqueKey}>
                                                                {item.columnName}: {item.errorMessage}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </section>
                        </div>


                        <div className="container">
                            <div className="row mt-3">
                                <div className="col-lg-3"></div>
                                <div className="col-12 col-lg-2 mb-3">
                                    {this.state.isDeactivateButtonDisable || isNotActiveArtist ? (
                                        <button hidden={this.isViewCompany()}
                                            className="btn btn--ghost btn--large"
                                            type="button"
                                            disabled={this.state.isDeactivateButtonDisable || isNotActiveArtist}
                                        >
                                            <span className="btn__icon"></span>
                                            {this.props.t("manageArtistProfile.buttons.deactivated.text")}
                                        </button>
                                    ) : (
                                        <button  hidden={this.isViewCompany()}
                                            className="btn btn--ghost btn--large"
                                            type="button"
                                            disabled={this.state.isDeactivateButtonDisable || isNotActiveArtist}
                                            onClick={(e: MouseEvent) => {
                                                e.preventDefault();
                                                this.onClickDeactivate();
                                            }}
                                        >
                                            <span className="btn__icon"></span>
                                            <LoadIndicator
                                                id="button-indicator"
                                                height={20}
                                                width={20}
                                                visible={this.state.loadIndicatorForDeactivation}
                                            />
                                            {this.props.t("manageArtistProfile.buttons.accountDeactivate.text")}
                                        </button>
                                    )}
                                </div>
                                <div className="col-12 col-lg-2 mb-3 mr-auto">
                                     <button hidden={this.isViewCompany()}
                                        className="btn btn--ghost btn--large"
                                        type="button"
                                        disabled={this.state.isDeactivateButtonDisable || isNotActiveArtist}
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickViewShifts();
                                        }}
                                    >
                                        <span className="btn__icon"></span>
                                        View Shifts
                                    </button>
                                </div>
                                <div className="col-12 col-lg-2 mb-3">{this.renderCancelButton()}</div>
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        // disabled={this.state.isDisableApplyButton}
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onSubmit();
                                        }}
                                    >
                                        {this.props.t("manageArtistProfile.buttons.save.text")}
                                    </button>
                                </div>
                            </div>
                            <div className="row "> 
                            <div className="col-lg-3"></div>
                            <div className="col-9"><b className="mt-3">{this.getCreatedAndUpdatedString()}</b></div>
                            </div>
                        </div>

                        { this.renderStatusFlow(sectionHeading, completionStatus) }
                        <Popup
                            visible={this.state.shiftsPopupVisible}
                            onHiding={this.hideShiftsGrid}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title={"Shifts"}
                            width={"90%"}
                            height={"auto"}
                        >
                            <ScrollView width="100%" height="100%">
                                <ShiftMatrixGridContents
                                    dataSource={this.state.viewShiftsDataSource}
                                    navigateFrom={this.props.serviceType == "Entertainment" ? "artistProfile" : "providerProfile"} 
                                    providerId={this.state.editViewDataItem.businessEntityID}
                                ></ShiftMatrixGridContents>
                            </ScrollView>
                        </Popup>
                        <Popup
                            visible={this.state.showVatDetails}
                            onHiding={this.hideDetail}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="VAT DETAILS"
                            width={"30%"}
                            height={"auto"}
                        >
                            <ScrollView width="100%" height="100%">
                                <div className="px-3 configurationText_Color font-size-one">
                                   <div> <strong>NAME:</strong> {this.state.vatDetails.name == null ? "" : this.state.vatDetails.name} </div>
                                   <div> <strong>ADDRESS LINE1:</strong> {this.state.vatDetails.address?.line1 == null ? "" : this.state.vatDetails.address?.line1} </div>
                                   <div> <strong>ADDRESS LINE2:</strong> {this.state.vatDetails.address?.line2 == null ? "" : this.state.vatDetails.address?.line2}  </div>
                                   <div> <strong>ADDRESS LINE3:</strong> {this.state.vatDetails.address?.line3 == null ? "" : this.state.vatDetails.address?.line3} </div>
                                   <div> <strong>ADDRESS LINE4:</strong> {this.state.vatDetails.address?.line4 == null ? "" : this.state.vatDetails.address?.line4} </div>
                                   <div> <strong>POSTCODE:</strong> {this.state.vatDetails.address?.postcode == null ? "" : this.state.vatDetails.address?.postcode} </div>
                                   <div> <strong>COUNTRY CODE:</strong> {this.state.vatDetails.address?.countryCode == null ? "" : this.state.vatDetails.address?.countryCode} </div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <Popup
                            visible={this.state.showChangeEmailPopup}
                            onHiding={this.hideDetail}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showTitle={true}
                            showCloseButton={true}
                            title="CHANGE EMAIL"
                            width={"30%"}
                            height={"auto"}
                        >
                            <ScrollView width="100%" height="100%">
                                <div>
                                    <div className="text-center"><b>EXISTING EMAIL: {this.state.editViewDataItem.contactInformation?.email}</b></div>
                                    <div className="mt-3">Please enter new email here</div>
                                    <div>
                                        <TextBox
                                            width={"100%"}
                                            readOnly={false}
                                            onValueChanged={this.handleChangeNewEmail}
                                            valueChangeEvent="keyup"
                                            value={this.state.email}
                                        />
                                    </div>
                                    <div className="text-center mt-3">This will take effect once you save the profile but contact IT before making this change as Azure needs to change first.</div>
                                </div>
                                <div className="text-center">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onConfirm();
                                        }}
                                    >
                                        CONFIRM
                                    </button>
                                </div>
                            </ScrollView>
                        </Popup>
                        <Popup
                            visible={this.state.showUploadWarningPopup}
                            onHiding={this.hideWarningPopup}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showTitle={true}
                            showCloseButton={true}
                            title="ADD IMAGES/DOCS: Provider needs to be created"
                            width={"30%"}
                            height={"auto"}
                        >
                            <ScrollView width="100%" height="100%">                                
                                <div className="text-center">For images or documents to be uploaded - please save new provider first</div> 
                                <div className="row mt-3 d-flex justify-content-center">
                                <div className="col-12 col-lg-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onSubmit();
                                        }}
                                    >
                                        ADD
                                    </button>
                                </div>
                                <div className="col-12 col-lg-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.hideWarningPopup();
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <Popup
                            visible={this.state.showEmailValidationPopup}
                            onHiding={this.hideValidationDetail}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showTitle={true}
                            showCloseButton={true}
                            title="Error"
                            width={"30%"}
                            height={"auto"}
                        >
                            <ScrollView width="100%" height="100%">
                                <div>
                                    <div className="text-center font-size-one mt-3">Please enter a valid email first.</div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <Popup
                            visible={this.state.showBankDetails}
                            onHiding={this.hideBankDetail}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title="BANK ACCOUNTS"
                            width={"90%"}
                            height={500}
                        >
                            <ScrollView width="100%" height="100%">
                                <div className="px-3 configurationText_Color font-size-one mb-3">
                                   <div> <strong>To update bank details - please select row and click button below</strong> </div>
                                </div>
                                <DataGrid
                                    dataSource={this.state.bankDetails}
                                    showBorders={false}
                                    showColumnLines={false}
                                    hoverStateEnabled={true}
                                    columnAutoWidth={true}
                                    allowColumnResizing={true}
                                    allowColumnReordering={true}
                                    columnResizingMode={"widget"}
                                    onSelectionChanged={this.onBankSelectionChanged}
                                    noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                                    columnMinWidth={100}
                                    className="bank-grid"
                                >
                                    <SearchPanel visible={true} placeholder={"Search"} />
                                    <GroupPanel visible={true} />
                                    <FilterPanel visible={true} />
                                    <Scrolling useNative={true} showScrollbar={"always"} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                    <Selection mode="single"/>

                                    <Column
                                        dataField="sortCode"
                                        caption="SORT CODE"
                                    />
                                    <Column
                                        dataField="accountNumber"
                                        caption="ACCOUNT NUMBER"
                                    />
                                    <Column
                                        dataField="nameOnAccount"
                                        caption="NAME ON ACCOUNT"
                                    />
                                    <Column
                                        dataField="matchPercentage"
                                        caption="PAYEE % MATCH"
                                    />                                                       

                                    <FilterRow visible={true} applyFilter="auto" />
                                </DataGrid>
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onUpdateBankDetails();
                                        }}
                                    >
                                        UPDATE BANK DETAILS
                                    </button>
                                </div>
                            </ScrollView>
                        </Popup>
                    </div >
                ) : null
                }
            </>
        );
    }
}
export default withTranslation()(ArtistProfile);
