import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { Button, LoadPanel } from "devextreme-react";
import gridUtils, { currencyTypeToSymbolMatrix } from "../../grid/GridUtilities";
import sharedUtils from "../../grid/sharedUtilities";

interface InvoiceAmountCellProps {
    data: any;
}

interface InvoiceAmountCellState {
    filetype: string;
    loadPanelVisible: boolean;
}

class InvoiceAmountCell extends React.Component<InvoiceAmountCellProps> {
    state: InvoiceAmountCellState;
    service: ManageDocumentsGridService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: InvoiceAmountCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
        };
        this.service = new ManageDocumentsGridService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
    }

    onDownloadClick = (event: any) => {
        var id = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "selfbillinvoice") {
                    id = item.id;
                }
            });
        }
        this.setState({
            loadPanelVisible: true,
        });

        this.service.getDocumentById(id).then(this.handleDownloadSuccess).catch(this.handleDownloadError);
        event.event.stopPropagation();
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        var linkRaw = "";
        if (this.props.data && this.props.data.data && this.props.data.data.documents) {
            this.props.data.data.documents.map((item: any) => {
                if (item.documentType.toLowerCase() == "selfbillinvoice") {
                    linkRaw = item.link;
                }
            });
        }
        let link = linkRaw.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.sharedUtils.thousandsSeparator(this.props.data.data.amount)}
                </div>
                {this.props.data && this.props.data.data && this.props.data.data.documents ? (
                    this.props.data.data.documents.map((item: any) => {
                        if (item.documentType.toLowerCase() == "selfbillinvoice") {
                            return (
                                <Button className="btn icon-btn btn-file-downloader" onClick={this.onDownloadClick}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            );
                        }
                    })
                ) : (
                    <></>
                )}

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.loadPanelVisible}
                    style={{
                        position: "absolute",
                    }}
                />
            </div>
        );
    }
}

export default InvoiceAmountCell;
