import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import sharedUtils from "../grid/sharedUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { PaymentRequestMatchedPopUpFormData } from "./PaymentRequestUtils";
import EventUkUserGrid from "../grid/EventUkUserGrid";
import { GridTypes } from "../../services/SimpleGridService";
import { AddEditFormData } from "../grid/AddEditPopUpUtilities";
import { Originator, Status } from "../../services/AddEditPopUpService";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import { NullableDate } from "../PostApproval/PostApprovalUtils";
import { PaymentTypes } from "../../services/FindAnArtistService";

interface MatchedPopUpGridProps extends RouteComponentProps, WithTranslation {
    editViewDataItem: PaymentRequestMatchedPopUpFormData;
    setBillableItemObj: (billableItemObj: AddEditFormData) => void;
    setMatchButtonState: (buttonState: boolean, loadPanelVisible: boolean) => void;
    setRemoveButtonState: (buttonState: boolean) => void;
    isUnMatched: boolean;
    disable: boolean;
    isClickedCancel: boolean;
}

interface MatchedPopUpGridState {
    count: string;
    paymentDateVal: string;
    paymentDate: NullableDate;
    actualPaymentDate: string;
}

class MatchedPopUpGrid extends Component<MatchedPopUpGridProps> {
    sharedUtils: sharedUtils;
    state: MatchedPopUpGridState;
    conversionUtils: outstandingActionsUtilities;
    dateTo: string;
    dateFrom: string;
    constructor(props: MatchedPopUpGridProps) {
        super(props);
        this.sharedUtils = new sharedUtils();
        this.conversionUtils = new outstandingActionsUtilities();
        this.dateTo = "";
        this.dateFrom = "";
        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            count: "",
            paymentDateVal: "",
            paymentDate: null,
            actualPaymentDate: ""
        };
    }

    componentDidUpdate(prevProps: Readonly<MatchedPopUpGridProps>): void {
        if (this.props.editViewDataItem.dateFrom != prevProps.editViewDataItem.dateFrom) {
            let copiedPaymentDate = new Date(JSON.parse(JSON.stringify(this.props.editViewDataItem.dateFrom)));
            this.setState({
                paymentDateVal: this.sharedUtils.convertDateToString(copiedPaymentDate),
                paymentDate: copiedPaymentDate,
                actualPaymentDate: this.sharedUtils.convertDateToString(copiedPaymentDate)
            }, () => { this.setdateVal(copiedPaymentDate) });
        }
    }

    setdateVal = (paymentDateVal: NullableDate) => {
        if (paymentDateVal) {
            let startDate: any;
            startDate = new Date(paymentDateVal);
            startDate.setDate(startDate.getDate() - 3);
            let formatStartDate = this.sharedUtils.convertDateToString(startDate);
            let convertedStartDate: string = "";
            convertedStartDate = this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(formatStartDate);
            this.dateFrom = convertedStartDate;
            let convertedEndDate: string = "";
            let endDate: any;
            endDate = new Date(paymentDateVal);
            endDate.setDate(endDate.getDate() + 3);
            let formatEndDate = this.sharedUtils.convertDateToString(endDate);
            convertedEndDate = this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(formatEndDate);
            this.dateTo = convertedEndDate;
        }
    }

    setBillableItemCount = (recordCount: string) => {
        this.setState({
            count: recordCount
        });
    }

    onClickRightArrow = () => {
        if (this.state.paymentDate) {
            let dateFromVal = this.state.paymentDate;
            dateFromVal.setDate(dateFromVal.getDate() + 7);
            let formatPaymentDate = this.sharedUtils.convertDateToString(dateFromVal);
            this.setState({
                paymentDateVal: formatPaymentDate
            });
            this.setdateVal(dateFromVal);
        }
    }

    onClickLeftArrow = () => {
        if (this.state.paymentDate) {
            let dateToVal = this.state.paymentDate;
            dateToVal.setDate(dateToVal.getDate() - 7);
            let formatPaymentDate = this.sharedUtils.convertDateToString(dateToVal);
            this.setState({
                paymentDateVal: formatPaymentDate
            });
            this.setdateVal(dateToVal);
        }
    }
    render() {
        let heading: string = "";
        heading = `${this.dateFrom} - ${this.dateTo}`;
        let originatorId: string = "";
        if (this.props.editViewDataItem && this.props.editViewDataItem.paymentRequestType && this.props.editViewDataItem.paymentRequestType.id == PaymentTypes.AddJob) {
            originatorId = Originator.ArtistNotification;
        }
        else {
            originatorId = Originator.PaymentRequest;
        }
        let artistFee: number = 0;
        if (this.props.editViewDataItem.fasterPayment.toString() == "false" || this.props.editViewDataItem.fasterPayment.toString() == "No") {
            if (this.props.editViewDataItem.providerPayAmountLocal && this.props.editViewDataItem.providerPayAmountLocalExtras) {
                artistFee = this.props.editViewDataItem.providerPayAmountLocal + this.props.editViewDataItem.providerPayAmountLocalExtras;
            }
            else if (this.props.editViewDataItem.providerPayAmountLocal) {
                artistFee = this.props.editViewDataItem.providerPayAmountLocal;
            }
        }
        else {
            if (this.props.editViewDataItem.providerFasterPayOriginalAmount && this.props.editViewDataItem.providerPayAmountLocalExtras) {
                artistFee = this.props.editViewDataItem.providerFasterPayOriginalAmount + this.props.editViewDataItem.providerPayAmountLocalExtras;
            }
            else if (this.props.editViewDataItem.providerFasterPayOriginalAmount) {
                artistFee = this.props.editViewDataItem.providerFasterPayOriginalAmount;
            }
        }

        return (
            <section className="card card-form my-5" hidden={this.props.disable}>
                <div className="card-body">
                    <h3 className="card-form__section-heading"><b>Similar</b> Venue or Provider <b>Shifts</b> For Matching <b>({this.state.count})</b></h3>
                    <hr className="horizontal_border_style"></hr>
                    <div className="row mx-0">
                        <div className="col-12 row mx-0 pr-0 mt-3">
                            <div className="grid-info__button-container">
                                <Link
                                    className={
                                        "btn btn-primary btn--large btn--with-icon"
                                    }
                                    to={
                                        {
                                            pathname: "/AddEditFormV2",
                                            state: {
                                                id: 0,
                                                serviceTypeId: this.props.editViewDataItem.serviceType.id,
                                                pointOfNavigation: "MatchedPopUp",
                                                filterStartDate: "",
                                                filterEndDate: "",
                                                providerId: this.props.editViewDataItem.providerBusinessEntity.id,
                                                clientId: this.props.editViewDataItem.clientBusinessEntity.id,
                                                venueId: this.props.editViewDataItem.venue.id,
                                                date: this.props.editViewDataItem.dateFrom,
                                                dateTo: this.props.editViewDataItem.dateTo,
                                                artistFee: artistFee,
                                                providerPayAmountLocalExtras: this.props.editViewDataItem.providerPayAmountLocalExtras,
                                                providerPayAmountLocalExtrasDescription: this.props.editViewDataItem.providerPayAmountLocalExtrasDescription,
                                                providerFasterPayFee: this.props.editViewDataItem.providerFasterPayFee,
                                                fasterPayment: this.props.editViewDataItem.fasterPayment,
                                                serviceSubTypeId: this.props.editViewDataItem.serviceSubType.id,
                                                statusId: Status.Pending,
                                                internalComments: this.props.editViewDataItem.description,
                                                otherDescription: this.props.editViewDataItem.otherDescription,
                                                typeLookUpId: this.props.editViewDataItem.typeLookUpId.id,
                                                typeLookUpValue: this.props.editViewDataItem.typeLookUpId.value,
                                                startTime: this.props.editViewDataItem.startTime,
                                                finishTime: this.props.editViewDataItem.finishTime,
                                                originatorId: originatorId,
                                                requestType: this.props.editViewDataItem.paymentRequestType,
                                                quantity: this.props.editViewDataItem.quantityOrHours,
                                                rate: this.props.editViewDataItem.rate,
                                            }
                                        }
                                    }
                                >
                                    <span className="btn__icon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span>
                                    Add Shift
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col col-lg-2 text-right">
                                <button
                                    className={"btn__booking_panel"}
                                    onClick={this.onClickLeftArrow}
                                >
                                    <b>
                                        <FontAwesomeIcon icon={faAngleLeft} /></b>
                                </button>

                            </div>
                            <div className="col-md-auto">
                                <h4>{heading}</h4>
                            </div>
                            <div className="col col-lg-2">

                                <button
                                    className={"btn__booking_panel"}
                                    onClick={this.onClickRightArrow}
                                >
                                    <b> <FontAwesomeIcon icon={faAngleRight} /></b> </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md">
                            <EventUkUserGrid
                                dateFrom={this.state.paymentDateVal}
                                isClickedCancel={this.props.isClickedCancel}
                                paymentDate={this.state.actualPaymentDate}
                                dateTo={""}
                                setSummary={() => { }}
                                setCalculations={() => { }}
                                setParentButtonStatesSignal={() => { }}
                                refreshSignal={false}
                                gridType={GridTypes.paymentsRequest}
                                id={0}
                                setBillableItemList={() => { return null }}
                                filterText={""}
                                billableItemId={""}
                                filterId={""}
                                clientId={""}
                                venueId={this.props.editViewDataItem.venue.id}
                                shiftStart={""}
                                shiftEnd={""}
                                isSchedulePage={false}
                                periodGridTypeParams={undefined}
                                setIndicatorStates={() => { }}
                                signalForArrowClick={""}
                                serviceTypeValue={
                                    this.props.editViewDataItem.serviceType.id
                                }
                                providerId={this.props.editViewDataItem.providerBusinessEntity.id}
                                setBillableItemObj={this.props.setBillableItemObj}
                                providerName={this.props.editViewDataItem.providerBusinessEntity ? this.props.editViewDataItem.providerBusinessEntity.shortName : ""}
                                venueName={this.props.editViewDataItem.venue.venueName ? this.props.editViewDataItem.venue.venueName : ""}
                                fee={artistFee.toString()}
                                setMatchButtonState={this.props.setMatchButtonState}
                                paymentObject={this.props.editViewDataItem}
                                isUnMatched={this.props.isUnMatched}
                                setRemoveButtonState={this.props.setRemoveButtonState}
                                setBillableItemCount={this.setBillableItemCount}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(withTranslation()(MatchedPopUpGrid));
