import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import CostReportService, { costReportDates } from "../../services/CostReportService";
import LookupService from "../../services/LookupService";
import SimpleShiftsPanel from "./SimpleShiftsSidebarPanel";
import SimpleShiftsByService from "./SimpleShiftsByService";
import outstandingActionsUtilities from "../Outstanding-Actions/OutstandingActionsUtilities";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faPlusCircle, faRectangle, IconDefinition, faSync } from "@fortawesome/pro-regular-svg-icons";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { ServiceType } from "../../common/ServiceUtilities";
import { faCalendarStar } from "@fortawesome/pro-light-svg-icons";
import GridTitleCard from "../Cards/GridTitleCard";
import { confirm } from "devextreme/ui/dialog";
import SimpleGridService from "../../services/SimpleGridService";
import notify from 'devextreme/ui/notify';
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";

interface SimpleShiftsPageProps extends RouteComponentProps, WithTranslation {
    title: string;
    category: string;
    location: any;
    getBuild: (flag: boolean) => void;  
}

interface SimpleShiftsPageState {
    dateList: costReportDates[];
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDate: string;
    endDate: string;
    sumTotal: string;
    gridLoaded: boolean;
    onUpdate: boolean;
    isApplyButtonClick: boolean;
    billableItemId: string;
    isShowUnbilled: boolean;
    isUnPaid: boolean;
    isUnconfirmedItems: boolean;
    isUnapprovedItems:boolean;
    approvalSuccess: boolean;
    billableItemIdArray: any[];
    billableItemAllIdArray: any[];
    loadPanelVisible: boolean;
    approvalResponse: any;
    shiftResponse: any;
    shiftConfirmed: boolean;
    isRefreshButtonClicked: boolean;
}

class SimpleShiftsPage extends Component<SimpleShiftsPageProps> {
    conversionUtils: outstandingActionsUtilities;
    state: SimpleShiftsPageState;
    Service: CostReportService;
    SimpleGridService: SimpleGridService;
    lookupService: LookupService;

    constructor(props: SimpleShiftsPageProps) {
        super(props);
        this.lookupService = new LookupService();
        this.Service = new CostReportService();
        this.SimpleGridService = new SimpleGridService();
        this.conversionUtils = new outstandingActionsUtilities();

        // Initialize button states to false as a user selection of a grid row is required
        this.state = {
            dateList: [],
            clientId:
                this.props.location.state && this.props.location.state.clientId
                    ? this.props.location.state.clientId
                    : "",
            venueId:
                this.props.location.state && this.props.location.state.venueId ? this.props.location.state.venueId : "",
            serviceTypeId: "",
            startDate:
                this.props.location.state && this.props.location.state.startDate
                    ? this.props.location.state.startDate
                    : "",
            endDate:
                this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : "",
            sumTotal: "",
            gridLoaded: false,
            onUpdate: false,
            isApplyButtonClick: false,
            billableItemId: this.props.location.state ? this.props.location.state.id : "",
            isShowUnbilled: false,
            isUnPaid: false,
            isUnconfirmedItems: false,
            isUnapprovedItems:false,
            approvalSuccess: false,
            billableItemIdArray: [],
            billableItemAllIdArray: [],
            loadPanelVisible: false,
            approvalResponse: [],
            shiftResponse: [],
            shiftConfirmed: false,
            isRefreshButtonClicked: false,
        };
    }

    parentIdSet = (
        venueId: string,
        startDate: string,
        endDate: string,
        clientId: string,
        serviceTypeId: string,
        onUpdate: boolean,
        paymentTypeId: string,
        isShowUnbilledItems: boolean,
        isUnPaidItems: boolean,
        isUnconfirmedItems: boolean,
        isUnapprovedItems:boolean
    ) => {
        this.setState({
            clientId: clientId ? clientId : "",
            venueId: venueId ? venueId : "",
            serviceTypeId: serviceTypeId ? serviceTypeId : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            onUpdate: onUpdate,
            billableItemId: "",
            isShowUnbilled: isShowUnbilledItems,
            isUnPaid: isUnPaidItems,
            isUnconfirmedItems: isUnconfirmedItems,
            isUnapprovedItems:isUnapprovedItems
        });
    };

    setCalculatedValue = (totalValue: number, loaded: boolean) => {
        this.setState({
            sumTotal: totalValue.toString(),
            gridLoaded: loaded,
        });
    };

    getApprovalCount = (totalValue: number, loaded: boolean) => {
        this.setState({
            sumTotal: totalValue.toString(),
            gridLoaded: loaded,
        });
    };

    getSelectedBillableItems = (approveitem: any, confirmitem: any) => {
        let Ids:any = [];    
        let allIds:any = [];  
        approveitem.forEach((o:any) => { 
            Ids.push(o.id);
        });
        Ids = Ids.map(Number);
        confirmitem.forEach((o:any) => { 
            allIds.push(o.id);
        });
        allIds = allIds.map(Number);
        this.setState({
            billableItemIdArray: Ids,
            billableItemAllIdArray: allIds
        })
    };

    refreshBulkApproval = (flag: boolean) => {
        if(flag == true){
            this.setState({approvalSuccess: false});
        }        
    };

    refreshBulkConfirmed = (flag: boolean) => {
        if(flag == true){
            this.setState({shiftConfirmed: false});
        }        
    };    

    approveShifts = () => {
        if(this.state.billableItemIdArray.length > 0){
            confirm("<div class='text-center'>Approve the Shift?</div>", "Submit").then((hasConfirmed) => {
                if (hasConfirmed) {
                    //call the API for posting the Billable Item list to the server.
                    this.onConfirmation();
                }
            });
        }
    };

    approveConfirm = () => {
        if(this.state.billableItemAllIdArray.length > 0){
            confirm("<div class='text-center'>Confirm the Shift?</div>", "Submit").then((hasConfirmed) => {
                if (hasConfirmed) {
                    //call the API for posting the Billable Item list to the server.
                    this.onShiftConfirmation();
                }
            });
        }
    };

    onConfirmation = () => {
        this.setState({ loadPanelVisible: true });
        this.SimpleGridService.submitApproveShift(this.state.billableItemIdArray)
            .then(this.handlePostApprovalSuccess)
            .catch((error) => {
                var respMessage: string = "Approval failed with response: " + JSON.stringify(error);
                this.setState({ 
                    loadPanelVisible: false,
                    billableItemIdArray: [],
                    billableItemAllIdArray: [],
                });
                this.state.billableItemIdArray.length = 0;
                this.state.billableItemAllIdArray.length = 0;
                notify({ message: "Sorry! The shift could not be approved.", width: 300, shading: true }, 'error', 600);
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    };

    onShiftConfirmation = () => {
        this.setState({ loadPanelVisible: true });
        this.SimpleGridService.submitConfirmShift(this.state.billableItemAllIdArray)
            .then(this.handlePostConfirmationSuccess)
            .catch((error) => {
                var respMessage: string = "Confirmation failed with response: " + JSON.stringify(error);
                this.setState({ 
                    loadPanelVisible: false,
                    shiftConfirmed: true,
                    billableItemIdArray: [],
                    billableItemAllIdArray: [],
                });
                this.state.billableItemIdArray.length = 0;
                this.state.billableItemAllIdArray.length = 0;
                notify({ message: "Sorry! The shift could not be confirmed.", width: 300, shading: true }, 'error', 600);
                if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                    console.error(respMessage);
                }
            });
    };

    handlePostApprovalSuccess = (response: AxiosResponse<any>) => {
        this.setState({ 
            approvalSuccess: true,
            loadPanelVisible: false,
            billableItemIdArray: [],
            billableItemAllIdArray: [],
            approvalResponse: response,
            isRefreshButtonClicked:true
        });
        this.state.billableItemIdArray.length = 0;
        this.state.billableItemAllIdArray.length = 0;
        notify({ message: "Thank you - shift has been approved.", width: 300, shading: true }, 'success', 600);
    };

    handlePostConfirmationSuccess = (response: AxiosResponse<any>) => {
        this.setState({ 
            loadPanelVisible: false,
            billableItemIdArray: [],
            billableItemAllIdArray: [],
            shiftConfirmed: true,
            shiftResponse: response,
            isRefreshButtonClicked:true
        });
        this.state.billableItemIdArray.length = 0;
        this.state.billableItemAllIdArray.length = 0;
        notify({ message: "Thank you - shift has been confirmed.", width: 300, shading: true }, 'success', 600);
    };

    onRefreshRequest = () => {
        this.setState({isRefreshButtonClicked: true});
    }

    resetRefreshShift = () => {
        this.setState({isRefreshButtonClicked: false});
    }
    onRefresh = (isRefresh:boolean)=>{
        this.setState({isRefreshButtonClicked: isRefresh});
    }

    getBuild = (flag: boolean) => {
        this.props.getBuild(flag);
    };

    render() {
        let serviceTypeId: string | null = sessionStorage.getItem("serviceTypeFilter");
        let dateDisplayText: string = "";
        let { startDate, endDate } = this.state;
        if (startDate.localeCompare(endDate) == 0) {
            var startDateText: string = startDate
                ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(startDate)
                : "";
            dateDisplayText = `${startDateText ? startDateText : ""}`;
        } else {
            if (startDate.localeCompare(endDate) != 0) {
                var minDateText: string = startDate
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(startDate)
                    : "";
                var maxDateText: string = endDate
                    ? this.conversionUtils.convertddmmyyyyStringToCustomizedFormat(endDate)
                    : "";
                dateDisplayText = `${minDateText ? minDateText : ""} - ${maxDateText ? maxDateText : ""}`;
            }
        }
        var headerText: string = "";
        var shiftsText: string = "";
        let gridTitleIcon: IconDefinition = faAsterisk;
        let gridTitleStyle: string = "";
        if (serviceTypeId == ServiceType.Security) {
            headerText = "Door Supervision";
            shiftsText = "Shifts";
        } else if (serviceTypeId == ServiceType.Entertainment) {
            headerText = "Entertainment";
            shiftsText = "Scheduled Artists";
            gridTitleIcon = faCalendarStar;
            gridTitleStyle = "scheduled-artist";
        } else if (serviceTypeId == ServiceType.ContractCleaning) {
            headerText = "Contract Cleaning";
            shiftsText = "Shifts";
        } else if (serviceTypeId == ServiceType.Gardening) {
            headerText = "Gardening";
            shiftsText = "Shifts";
        } else if (serviceTypeId == ServiceType.WindowCleaning) {
            headerText = "Window Cleaning";
            shiftsText = "Shifts";
        } else if (serviceTypeId == ServiceType.Maintenance) {
            headerText = "Maintenance";
            shiftsText = "Shifts";
        } else if (serviceTypeId == ServiceType.Miscelleanous) {
            headerText = "Miscelleanous";
            shiftsText = "Shifts";
        }
        var header: string = `${headerText} - ${shiftsText}`;
        var isEventUser: boolean = false;
        if (
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)
        ) {
            isEventUser = true;
        }
        return (
            <LightModePageTemplate>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Please wait..."
                    visible={this.state.loadPanelVisible}
                />
                <SideBarTemplate isFixed={true}>
                    <SimpleShiftsPanel
                        location={this.props.location}
                        serviceTypeId={serviceTypeId ? serviceTypeId : ""}
                        onApplyButtonClick={this.parentIdSet}
                        clientId={this.state.clientId}
                        venueId={this.state.venueId}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        pointOfNavigation={this.props.location.state ? this.props.location.state.pointOfNavigation : ""}
                        billableItemId={this.props.location.state && this.props.location.state.id}
                    />
                    <BulletinPanel />
                </SideBarTemplate>

                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        {serviceTypeId == ServiceType.Entertainment ?
                            <GridTitleCard title={header} icon={gridTitleIcon} cardStyle={gridTitleStyle} /> :
                            <h2 className="page-title__black">{header}</h2>}
                        <h5 className="font-weight-bold text-center text-lg-left">
                            {dateDisplayText ? dateDisplayText : ""}
                        </h5>
                    </header>
                    <div className="grid-info__button-shiftContainer text-center text-lg-left" hidden={!isEventUser}>
                        {isEventUser && (serviceTypeId == ServiceType.Security || serviceTypeId == ServiceType.ContractCleaning || serviceTypeId == ServiceType.Entertainment
                            || serviceTypeId == ServiceType.Gardening || serviceTypeId == ServiceType.WindowCleaning || serviceTypeId == ServiceType.Maintenance || serviceTypeId == ServiceType.Miscelleanous) ?
                            <>
                                <Link
                                    className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-2 mb-3"}
                                    to={{
                                        pathname: "/QuickAddShiftForm",
                                        state: {
                                            id: "",
                                            serviceTypeId: serviceTypeId,
                                            pointOfNavigation: "simpleshifts",
                                            filterStartDate: this.state.startDate,
                                            filterEndDate: this.state.endDate,
                                        },
                                    }}
                                >
                                    {/* <span className="btn__icon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span> */}
                                    Add Shift (Standard)
                                </Link>
                                &nbsp;&nbsp;
                                <Link
                                    className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-2  mb-3"}
                                    to={{
                                        pathname: "/AddEditFormV2",
                                        state: {
                                            id: 0,
                                            serviceTypeId: serviceTypeId,
                                            pointOfNavigation: "simpleshifts",
                                            filterStartDate: this.state.startDate,
                                            filterEndDate: this.state.endDate,
                                        },
                                    }}
                                >
                                    {/* <span className="btn__icon">
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span> */}
                                    Add Shift (Custom)
                                </Link>
                                &nbsp;&nbsp;
                                <button className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-2  mb-3"} onClick={this.approveShifts} disabled={this.state.billableItemIdArray.length == 0}>
                                    {/* <span className="btn__icon">
                                        <FontAwesomeIcon icon={faRectangle} />
                                    </span> */}
                                    Multi Approve
                                </button>
                                &nbsp;&nbsp;
                                <button className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-2  mb-3"} onClick={this.approveConfirm} disabled={this.state.billableItemAllIdArray.length == 0}>
                                    {/* <span className="btn__icon">
                                        <FontAwesomeIcon icon={faRectangle} />
                                    </span> */}
                                    Multi Confirm Work
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    className="status-flow__refresh-shiftbutton btn gig-icon-btn"
                                    aria-label="Refresh the current billing status"
                                    onClick={this.onRefreshRequest}
                                >
                                    <FontAwesomeIcon icon={faSync} />
                                </button> 
                            </> : null}
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {
                                <SimpleShiftsByService
                                    billableItemId={this.state.billableItemId}
                                    clientId={this.state.clientId}
                                    venueId={this.state.venueId}
                                    serviceTypeId={this.state.serviceTypeId}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    setCalculations={this.setCalculatedValue}
                                    approvalSuccess={this.state.approvalSuccess}
                                    approvalResponse={this.state.approvalResponse}
                                    getBuild={this.getBuild}
                                    getSelectedBillableItems={this.getSelectedBillableItems}
                                    refreshBulkApproval={this.refreshBulkApproval}
                                    refreshBulkConfirmed={this.refreshBulkConfirmed}
                                    onRefresh={this.onRefresh}
                                    pointOfNavigation={
                                        this.props.location.state ? this.props.location.state.pointOfNavigation : ""
                                    }
                                    isShowUnbilledItems={this.state.isShowUnbilled}
                                    isUnPaidItems={this.state.isUnPaid}
                                    isUnconfirmedItems={this.state.isUnconfirmedItems}
                                    isUnapprovedItems={this.state.isUnapprovedItems}
                                    shiftConfirmed={this.state.shiftConfirmed}
                                    shiftResponse={this.state.shiftResponse}
                                    isRefreshButtonClicked={this.state.isRefreshButtonClicked}
                                    resetRefreshShift={this.resetRefreshShift}
                                />
                            }
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(withTranslation()(SimpleShiftsPage));
