import { AxiosResponse } from "axios";
import { _postFormData, _aiTraceAsError } from "./BaseApiService";

const DOWNLOAD_TEMPLATE_API = "file/DownloadTemplate";
const UPLOAD_API = "file/upload";

// Indicate which template URL to provide for download
export const TemplateDownloadTypes = {
    personnel: 11,
    equipment: 12,
};

export const serviceTypes = {
    security: "10",
    Entertainment: "30",
    ContractCleaning: "20",
    Gardening: "40",
    WindowCleaning: "50",
    Miscelleanous: "80",
    Maintenance: "60"
};

export const ApprovalCardTypes = {
    personnel: "11",
    equipment: "12",
    WeeklyCleaning: "21",
    AdditionalCleaning: "22"
};

export const uploadedFileType = {
    personnel: "11",
    equipment: "12",
    otherServiceUploader: "18",
    SIA: "13",
    VenueUpload: "14",
    VenueUploadV2: "17",
    BudgetUpload: "15",
    RateMatrixUpload: "16",
    PeriodMatrixUpload: "19"
};

/// REST service for the file resource.
class FileService {
    // Provide the URL that downloads the file template
    downloadTemplateUrl(templateTypeId: number): string {
        return DOWNLOAD_TEMPLATE_API + "?type=" + templateTypeId;
    }

    uploadFormData(formData: FormData): Promise<AxiosResponse<any>> {
        return _postFormData(UPLOAD_API, formData);
    }

    // endpoints for Venue hierarchy V1 and V2
    uploadV1FormData(formData: FormData): Promise<AxiosResponse<any>> { 
        return _postFormData(UPLOAD_API + "?venueVersion=V1", formData);        
    }

    uploadV2FormData(formData: FormData): Promise<AxiosResponse<any>> {
        return _postFormData(UPLOAD_API + "?venueVersion=V2", formData);
    }

    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default FileService;
