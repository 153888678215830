import React, { MouseEvent } from "react";
import { AxiosResponse, AxiosError } from "axios";
import DataGrid, { Pager, Paging, FilterRow, Column, Export, SearchPanel, Selection } from "devextreme-react/data-grid";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import gridUtils from "../grid/GridUtilities";
import PaymentService, { PaymentGridRowItem } from "../../services/PaymentService";
import paymentActionCell from "../Payments/PaymentActionCellComponent";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { DataGridOnSelectionChanged,onExportingEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import InvoiceAmountCell from "../Payments/ProviderPayment/InvoiceAmountCellComponent";
import sharedUtils from "../grid/sharedUtilities";
import GlobalService from "../../services/GlobalService";
import { Link } from "react-router-dom";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import { closeDrawer } from "../header/NavigationUtils";

interface NewPaymentsGridProps {
    setCalculations: (CalculationsObject: any) => void;
    setPaymentCycleIdOnParent: (paymentCycleId: string) => void;
    id: string;
    paymentsRefresh: boolean;
    pontOfNavigation: string;
}
interface NewPaymentsGridState {
    gridDataSource: PaymentGridRowItem[];
    showProgressIndicator: boolean;
    CalculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    checkSquareIconVisible: boolean;
    simpleShiftPopupVisible: boolean;
    serviceId: string;
    paymentId: string;
}

class NewPaymentsGrid extends React.Component<NewPaymentsGridProps> {
    state: NewPaymentsGridState;
    //Initialize the service to fetch the data.
    Service: PaymentService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    userHasAccess: boolean;

    constructor(props: NewPaymentsGridProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new PaymentService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
            CalculationsObject: {
                subTotal: "",
                total: "",
                tax: "",
            },
            checkSquareIconVisible: false,
            simpleShiftPopupVisible: false,
            serviceId: "",
            paymentId: "",
        };
        this.Calculations = this.Calculations.bind(this);
        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    componentDidMount() {
        this.updateGridDataSource();
    }

    componentDidUpdate(prevprops: NewPaymentsGridProps) {
        if (this.props.paymentsRefresh != prevprops.paymentsRefresh) {
            this.updateGridDataSource();
        }
    }
    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            //topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && (
                        gridCell.column.dataField === "subAmount" ||
                        gridCell.column.dataField === "tax" ||
                        gridCell.column.dataField === "amount" ||
                        gridCell.column.dataField === "lastPayment" ||
                        gridCell.column.dataField === "varience")) {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    // Fire parent component's callback with data from grid
    onSelectionChanged = (event: DataGridOnSelectionChanged) => {
        // Capture the PaymentId of the clicked row.
        if (window.location.href.indexOf("PaymentsV2Page")) {
            if (event.selectedRowKeys) {
                event.selectedRowKeys.forEach((items) => {
                    GlobalService.setPaymentCycleIds(items.paymentId);
                });
            }
        } else {
            var paymentId: string = "";
            if (event.selectedRowsData && event.selectedRowsData.length > 0) {
                paymentId = event.selectedRowsData[0].paymentId;
            }
            this.props.setPaymentCycleIdOnParent(paymentId);
        }
    };

    handleCalculations = () => {
        let subTotal = 0.0,
            tax = 0.0,
            amount = 0.0;
        let subtotalValues = this.state.gridDataSource.map((item) => item.subAmount);
        let taxValues = this.state.gridDataSource.map((item) => item.tax);
        let amountValues = this.state.gridDataSource.map((item) => item.amount);
        subtotalValues.forEach((rowElement) => {
            subTotal = subTotal + this.gridUtils.convertDecimalAsStringToFloat(rowElement);
        });

        taxValues.forEach((rowElement) => {
            tax = tax + this.gridUtils.convertDecimalAsStringToFloat(rowElement);
        });

        amountValues.forEach((rowElement) => {
            amount = amount + this.gridUtils.convertDecimalAsStringToFloat(rowElement);
        });

        this.setState(
            {
                CalculationsObject: {
                    ...this.state.CalculationsObject,
                    total: amount.toString(),
                    subTotal: subTotal.toString(),
                    tax: tax.toString(),
                },
            },
            () => this.Calculations()
        );
    };

    Calculations() {
        this.props.setCalculations(this.state.CalculationsObject);
    }
    updateGridDataSource() {
        if (this.userHasAccess) {
            this.setState({
                showProgressIndicator: true,
            });
            this.Service.getPaymentGridRowItems(this.props.id).then(this.handleSuccess).catch(this.handleError);
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.handleCalculations();
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        });
        this.handleCalculations();
        this.Calculations();
    }

    handleError(error: AxiosError<any>) {
        this.setState({
            showProgressIndicator: false,
        });
        var respMessage: string = "Get payment Grid rows service load failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    setVarianceColour = (cellElement: any, cellInfo: any) => {
        var cellVarianceWithCurrencySymbol = this.sharedUtils.thousandsSeparator(cellInfo.data.varience);
        if (cellInfo.data.rag == "Green" && cellInfo.data.varience) {
            cellElement.classList.add("text-success");
        } else if (cellInfo.data.rag == "Amber" && cellInfo.data.varience) {
            cellElement.classList.add("text-warning");
        }
        if (cellInfo.data.varience) {
            cellElement.append(cellVarianceWithCurrencySymbol);
        }
    };

    headerExcludeCellTemplate = () => {
        return <div>EXCLUDE</div>;
    };

    headerBouncedCellTemplate = () => {
        return <div> <span>BOUNCED?</span> </div>;
    };

    DisplaySimpleShiftGrid = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showSimpleShiftGrid(cellInfo.data);
                    }}
                    className="approval-query-column top-12px"
                    to="#"
                >
                    {this.sharedUtils.thousandsSeparator(cellInfo.data.subAmount)}
                </Link>
            </div>
        );
    };
    showSimpleShiftGrid = (cellInfo: any) => {
        this.setState({
            simpleShiftPopupVisible: true,
            serviceId: cellInfo.serviceId,
            paymentId: cellInfo.paymentId,
        }, () => {
            closeDrawer();
        });
    };
    hideSimpleShiftGrid = () => {
        this.setState({
            simpleShiftPopupVisible: false,
        });
    };
    setCalculations = () => {};
    render() {
        return (
            <div>
                <h4 className="ml-3 font-weight-bold"></h4>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={50}
                            width={50}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        onSelectionChanged={this.onSelectionChanged}
                        onExporting={this.onExporting}
                    >
                        <Selection mode="single" />
                        <Export enabled={true} />
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Paging defaultPageSize={10} />                        
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        {this.props.pontOfNavigation.toLowerCase() == "paymentsandchecks" ? (
                            <Column type="selection" headerCellRender={this.headerExcludeCellTemplate} />
                        ) : this.props.pontOfNavigation.toLowerCase() == "paymentspaid" ? (
                            <Column type="selection" headerCellRender={this.headerBouncedCellTemplate} width={100} />
                        ) : null}
                        <Selection mode="multiple"></Selection>
                        <Column dataField="paymentId" caption="ID" />
                        <Column dataField="entity" caption="PROVIDER" />
                        <Column dataField="entityId" caption="PROVIDER ID" />
                        <Column dataField="serviceValue" caption="SERVICE" />
                        <Column dataField="reference" caption="REFERENCE" />
                        <Column
                            dataField="subAmount"
                            caption="PROVIDER NET"
                            cellRender={this.DisplaySimpleShiftGrid}
                            // calculateDisplayValue={(rowData: any) => {
                            //     return this.sharedUtils.thousandsSeparator(rowData.subAmount);
                            // }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.subAmount);
                            }}
                        />
                        <Column
                            dataField="tax"
                            caption="PROVIDER VAT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.tax);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.tax);
                            }}
                        />
                        <Column
                            dataField="amount"
                            caption="PROVIDER GROSS"
                            cellComponent={InvoiceAmountCell}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.amount);
                            }}
                        />
                        <Column dataField="sortCode" caption="SORT CODE" />
                        <Column dataField="accountNumber" caption="ACCOUNT #" />
                        <Column dataField="status" caption="STATUS" />
                        <Column dataField="action" caption="ACTION" cellComponent={paymentActionCell} />
                        <Column
                            dataField="payDate"
                            caption="EARLIEST PAY DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="lastPayment"
                            caption="LAST PAYMENT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.sharedUtils.thousandsSeparator(rowData.lastPayment);
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.lastPayment);
                            }}
                        />
                        <Column
                            dataField="varience"
                            caption="VARIANCE"
                            cellTemplate={this.setVarianceColour}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.varience);
                            }}
                        />
                        <Column
                            dataField="advisedDate"
                            caption="INVOICE PAY DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.advisedDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.advisedDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="paymentDate"
                            caption="PAYMENT DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.paymentDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.paymentDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="remittanceDate"
                            caption="REMITTANCE DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.remittanceDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.remittanceDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column
                            dataField="bounceDate"
                            caption="BOUNCE DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.bounceDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.bounceDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />
                        <Column dataField="rag" caption="RAG" visible={false} />
                        <Column dataField="currency" caption="CURRENCY" visible={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}
                <Popup
                    visible={this.state.simpleShiftPopupVisible}
                    onHiding={this.hideSimpleShiftGrid}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Included Shifts"}
                    width={"90%"}
                    height={"auto"}
                >
                    <ScrollView width="100%" height="100%">
                        <SimpleShiftsByService
                            clientId=""
                            venueId=""
                            serviceTypeId={this.state.serviceId}
                            startDate=""
                            endDate=""
                            setCalculations={this.setCalculations}
                            paymentId={this.state.paymentId}
                            getBuild={() => { return null }}
                        ></SimpleShiftsByService>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}
export default NewPaymentsGrid;
