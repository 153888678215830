import sharedUtils from "../grid/sharedUtilities";
import { cardSections, queryCard, QueryFormResponse, QueryFormSubmitRequest, ResolveSubmitRequest } from "../../services/QueryService";
import { LookupTypeItem, VenueLookupTypeItem } from "../../services/LookupService";
import { ServiceType } from "../../common/ServiceUtilities";
import { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { queryStatusType } from "../../services/CostReportService";

export interface QueryFormObject {
    id: string,
    queryStatusId: string,
    queryStatus: string,
    queryTitle: string,
    queryDescription: string,
    resolutionReason: string,
    billableItemId: string,
    dateCreated: NullableDate,
    venueId: string,
    providerBusinessEntityId: string,
    venue: string,
    venueName: string,
    comments: string,
    lastUpdate: string,
    serviceTypeId: string,
    serviceTypeValue: string,
    serviceSubTypeId: string,
    queryTypeLookupID: string,
    queryTypeLookupValue: string,
    lastUpdatedBy: string,
    businessEntityId: string,
    businessEntityTypeText: string,
    raisedByContactName: string,
    raisedByContactTelNumber: string,
    queryRaisedBy:string,
    typeLookupId: string,
    typeLookupValue: string,
    queryTypeText: string,
    queryTypeEnum: number,
    activity_Comments?: string,
    activity_Type?: string,
    activity_LastUpdatedBy?: string,
    activity_LastUpdated?: string,
    activity_external?: string,
    activity: [{
        id: string,
        query_Id: string,
        activity_Comments: string,
        activity_Type: string,
        activity_LastUpdatedBy: string,
        activity_LastUpdated: string,
        activity_external: boolean,
    }],
    internal_Comment: string,
    validationErrors: [],
    user: [{
        id: string,
        name: string
    }],
    lastUpdated_DateTime: string,
    clientId: string,
    contactId: string,
    queryClientBusinessEntityId: string,
    queryClientBusinessEntityValue: string,
    queryServiceTypeId: string,
    queryServiceTypeValue: string,
    queryRaisedTime: NullableDate,
    queryDateCreated: string,
    linkedId: string
}

export interface QueryResolveObject {
    id: string,
    resolutionreason: string
}

export default class queryUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeQueryItem = this.initializeQueryItem.bind(this);
        this.convertQueryResponeToFormData = this.convertQueryResponeToFormData.bind(this);
        this.convertFormDataToQueryRequest = this.convertFormDataToQueryRequest.bind(this);
    }

    initializeCardSections = (): cardSections => {
        var accordionSectionData: cardSections = {
            security: [],
            cleaning: [],
            entertainment: [],
            general: [],
        };
        return accordionSectionData;
    };

    //Helper function that will get the response i.e; Query card List data and would group them by service.
    groupCardListByService = (queryCardList: queryCard[]): cardSections => {
        var cardAccordionSectionByService: cardSections = this.initializeCardSections();
        queryCardList.forEach((queryItem: queryCard) => {
            if (queryItem.serviceTypeId == ServiceType.Security) {
                cardAccordionSectionByService.security.push(queryItem);
            } else if (queryItem.serviceTypeId == ServiceType.ContractCleaning) {
                cardAccordionSectionByService.cleaning.push(queryItem);
            } else if (queryItem.serviceTypeId == ServiceType.Entertainment) {
                cardAccordionSectionByService.entertainment.push(queryItem);
            } else {
                cardAccordionSectionByService.general.push(queryItem);
            }
        });
        return cardAccordionSectionByService;
    };

    extractVenueListBasedOnClient = (venueList: VenueLookupTypeItem[], clientId: string): VenueLookupTypeItem[] => {
        var lookupType: VenueLookupTypeItem = {
            id: "0",
            value: "ALL",
            postCode: "",
            parentMappingId: "0",
            venueTypeId: ""
        };
        var listOfVenues: VenueLookupTypeItem[] = venueList.filter((item) => {
            return item.parentMappingId == clientId;
        });
        return [lookupType].concat(listOfVenues);
    };

    lookUpincludeAll = (serviceList: LookupTypeItem[]): LookupTypeItem[] => {
        var lookupType: LookupTypeItem = {
            id: "0",
            value: "ALL",
            parentMappingId: "0",
        };
        return [lookupType].concat(serviceList);
    };

    initializeQueryResolveItem(_idFromGrid: string): QueryResolveObject {
        var formData: QueryResolveObject = {
            id: "",
            resolutionreason: ""
        }
        return formData;
    }

    initializeQueryItem(_idFromGrid: string): QueryFormObject {
        var formData: QueryFormObject = {
            id: "",
            queryStatusId: "",
            queryStatus: "",
            queryTitle: "",
            queryDescription: "",
            resolutionReason: "",
            billableItemId: "",
            dateCreated: undefined,
            venueId: "",
            providerBusinessEntityId: "",
            venue: "",
            venueName: "",
            comments: "",
            lastUpdate: "",
            serviceTypeId: "",
            serviceTypeValue: "",
            serviceSubTypeId: "",
            queryTypeLookupID: "",
            queryTypeLookupValue: "",
            lastUpdatedBy: "",
            businessEntityId: "",
            businessEntityTypeText: "",
            raisedByContactName: "",
            raisedByContactTelNumber: "",
            queryRaisedBy:"",
            typeLookupId: "",
            typeLookupValue: "",
            queryTypeText: "",
            queryTypeEnum: 0,
            activity_Comments: "",
            activity_Type: "",
            activity_LastUpdatedBy: "",
            activity_LastUpdated: "",
            activity_external: "",
            activity: [{
                id: "0",
                query_Id: "",
                activity_Comments: "",
                activity_Type: "",
                activity_LastUpdatedBy: "",
                activity_LastUpdated: "",
                activity_external: false,
            }],
            internal_Comment: "",
            validationErrors: [],
            user: [{
                id: "",
                name: ""
            }],
            lastUpdated_DateTime: "",
            clientId: "",
            contactId: "",
            queryClientBusinessEntityId: "",
            queryClientBusinessEntityValue: "",
            queryServiceTypeId: "",
            queryServiceTypeValue: "",
            queryRaisedTime: null,
            queryDateCreated: "",
            linkedId: ""
        }
        return formData;
    }

    // Convert the server response into the component's form state
    convertQueryResponeToFormData(serverResponse: QueryFormResponse): QueryFormObject {
        var formData: QueryFormObject = {
            id: serverResponse.id,
            queryStatusId: serverResponse.queryStatusId,
            queryStatus: serverResponse.queryStatus,
            queryTitle: serverResponse.queryTitle,
            queryDescription: serverResponse.queryDescription,
            resolutionReason: serverResponse.resolutionReason,
            billableItemId: serverResponse.billableItemId,
            dateCreated: this.utils.convertStringToFormattedDate(serverResponse.dateCreated),
            venueId: serverResponse.venueId,
            providerBusinessEntityId: serverResponse.providerBusinessEntityId, 
            venue: serverResponse.venue,
            venueName: serverResponse.venueName,
            comments: serverResponse.comments,
            lastUpdate: serverResponse.lastUpdate,
            serviceTypeId: serverResponse.serviceTypeId,
            serviceTypeValue: serverResponse.serviceTypeValue,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            queryTypeLookupID: serverResponse.queryTypeLookupID,
            queryTypeLookupValue: serverResponse.queryTypeLookupValue,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            businessEntityId: serverResponse.businessEntityId,
            businessEntityTypeText: serverResponse.businessEntityTypeText,
            raisedByContactName: serverResponse.raisedByContactName,
            raisedByContactTelNumber: serverResponse.raisedByContactTelNumber,
            queryRaisedBy:serverResponse.queryRaisedBy,
            typeLookupId: serverResponse.typeLookupId,
            typeLookupValue: serverResponse.typeLookupValue,
            queryTypeText: serverResponse.queryTypeText,
            queryTypeEnum: serverResponse.queryTypeEnum,
            activity: serverResponse.activity,
            internal_Comment: serverResponse.internal_Comment,
            validationErrors: serverResponse.validationErrors,
            user: serverResponse.user,
            lastUpdated_DateTime: serverResponse.lastUpdated_DateTime,
            clientId: serverResponse.clientId,
            contactId: serverResponse.contactId,
            queryClientBusinessEntityId: serverResponse.queryClientBusinessEntityId,
            queryClientBusinessEntityValue: serverResponse.queryClientBusinessEntityValue,
            queryServiceTypeId: serverResponse.queryServiceTypeId,
            queryServiceTypeValue: serverResponse.queryServiceTypeValue,
            queryRaisedTime: this.utils.convertStringToTime(serverResponse.queryRaisedTime),
            queryDateCreated: serverResponse.queryDateCreated,
            linkedId: serverResponse.linkedId
        };

        return formData;
    }

    convertFormDataToQueryRequest(
        formData: QueryFormObject,
        navigation: string
    ): QueryFormSubmitRequest {
        let queryStatus: string = "";
        if (navigation) {
            queryStatus = queryStatusType.Resolved;
        }
        else if (!formData.id) {
            queryStatus = queryStatusType.Pending;
        }
        else {
            queryStatus = formData.queryStatusId;
        }
        let queryDateCreated: string = "";
        if(formData.id){
            queryDateCreated = formData.queryDateCreated;
        }
        else {
            queryDateCreated =  this.utils.convertDateToString(new Date(formData.queryDateCreated));
        }
        var submitRequest: QueryFormSubmitRequest = {
            id: formData.id,
            queryStatusId: queryStatus,
            queryStatus: formData.queryStatus,
            queryTitle: formData.queryTitle,
            queryDescription: formData.queryDescription,
            resolutionReason: formData.resolutionReason,
            billableItemId: formData.billableItemId,
            dateCreated: this.utils.convertDateToString(formData.dateCreated),
            venueId: formData.venueId,
            providerBusinessEntityId: formData.providerBusinessEntityId,
            venue: formData.venue,
            venueName: formData.venueName,
            comments: formData.comments,
            lastUpdate: formData.lastUpdate,
            serviceTypeId: formData.serviceTypeId,
            serviceTypeValue: formData.serviceTypeValue,
            serviceSubTypeId: formData.serviceSubTypeId,
            queryTypeLookupID: formData.queryTypeLookupID,
            queryTypeLookupValue: formData.queryTypeLookupValue,
            lastUpdatedBy: formData.lastUpdatedBy,
            businessEntityId: formData.businessEntityId,
            businessEntityTypeText: formData.businessEntityTypeText,
            raisedByContactName: formData.raisedByContactName,
            raisedByContactTelNumber: formData.raisedByContactTelNumber,
            typeLookupId: formData.typeLookupId,
            typeLookupValue: formData.typeLookupValue,
            queryTypeText: formData.queryTypeText,
            queryTypeEnum: formData.queryTypeEnum,
            activity_Comments: formData.activity_Comments ? formData.activity_Comments : "",
            activity_Type: formData.activity_Type ? formData.activity_Type : "",
            activity_LastUpdatedBy: formData.activity_LastUpdatedBy ? formData.activity_LastUpdatedBy : "",
            activity_LastUpdated: formData.activity_LastUpdated ? formData.activity_LastUpdated : "",
            activity_external: formData.activity_external == "Yes" || formData.activity_external == "True"  || formData.activity_external == "1" ? true : false,
            internal_Comment: formData.internal_Comment,
            clientId: formData.clientId,
            contactId: formData.contactId,
            queryClientBusinessEntityId: formData.queryClientBusinessEntityId,
            queryClientBusinessEntityValue: formData.queryClientBusinessEntityValue,
            queryServiceTypeId: formData.queryServiceTypeId,
            queryServiceTypeValue: formData.queryServiceTypeValue,
            queryRaisedTime: this.utils.convertTimeToString(formData.queryRaisedTime),
            queryDateCreated: queryDateCreated,
            linkedId: formData.linkedId
        };

        return submitRequest;
    }

    

    convertFormDataToResolveQueryRequest(formData: QueryResolveObject) {
        var submitRequest: ResolveSubmitRequest = {
            id: formData.id,
            resolutionreason: formData.resolutionreason
        }
        return submitRequest;
    }
}
