import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import {
    TextBox,
    SelectBox,
    RadioGroup,
    LoadPanel
} from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import ArtistService,{logicAppPostRequest} from "../../services/ArtistService";
import {
    TextBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    RadioButtonOnSelectionChanged,
    LookUpOnValueChangedEvent
} from "../../types/DevExtremeTypes";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import artistProfileUtils, { VenuePageFormData } from "../Artist-Profile/ArtistProfileUtilitise";
import "../../../assets/images/event-UK-image.jpeg";
import { withTranslation, WithTranslation } from "react-i18next";
import sharedUtils from "../grid/sharedUtilities";
import notify from "devextreme/ui/notify";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";

interface AddVenueProps extends WithTranslation {
    history?: any;
    location?: any;
    mode?: string;
    id?: string;
    businessEntityId?: string;
    contactType?: any;
    handlePopUpClose: (isPopupClose: boolean, isEditMode: string) => void;
    handleContact: (isAdded: string) => void;
}

interface AddVenueState {
    isEditMode: boolean;
    requestFormData: VenuePageFormData;
    contactTypeLookUp: LookupTypeItem[];
    roleLookUp: LookupTypeItem[];
    organisationLookUp: LookupTypeItem[];
    loadPanelVisible: boolean;
    venueCreatedDateTime: string;
    userCreatedDateTime: string;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
}

class AddVenue extends React.Component<AddVenueProps> {
    conversionUtils: sharedUtils;
    lookupService: lookupService;
    artistService: ArtistService;
    utils: artistProfileUtils;
    taxApplicable: LookupTypeItem[];
    state: AddVenueState;
    convertedRequestFormData: any;
    constructor(props: AddVenueProps) {
        super(props);
        this.conversionUtils = new sharedUtils();        
        this.lookupService = new lookupService();
        this.artistService = new ArtistService();
        this.utils = new artistProfileUtils();
        this.taxApplicable = this.lookupService.getDecisionTypes();
        this.convertedRequestFormData = this.utils.initializeVenuePageItem();
        this.state = {
            isEditMode: false,
            requestFormData: this.convertedRequestFormData,
            contactTypeLookUp: [],
            roleLookUp: [],
            organisationLookUp: [],
            loadPanelVisible: false,
            venueCreatedDateTime: "",
            userCreatedDateTime: "",
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
        };
    }

    componentDidMount() {
        this.setState({
            isEditMode: this.props.mode === "edit",
        });
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.organisationType)
            .then(this.handleSuccessOrganisationTypeLookUp)
            .catch(this.handleError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.contactTypeDetails)
            .then(this.handleSuccessContactTypeLookUp)
            .catch(this.handleError);
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.userRoleType)
            .then(this.handleSuccessRoleTypeLookUp)
            .catch(this.handleError);

        if(this.props.businessEntityId){    
            this.artistService
                .getVenuePageDataById(parseInt(this.props.businessEntityId))
                .then(this.handleSuccessViewPage)
                .catch((err) => {
                    this.setState({                        
                        loadPanelVisible: false,
                        IsShowError: true,
                        IsShowSuccess: false,
                    });
                })
                .finally(() => {
                    this.setState({
                        loadPanelVisible: false,
                    });
                });
        }  
    }

    handleSuccessViewPage = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        let venueCreatedDateTime = "";
        let userCreatedDateTime = "";
        var viewDataSource = this.utils.convertVenueProfileResponeToFormData(response.data.data);
        this.setState({
                requestFormData: viewDataSource,
            }, () => {
                venueCreatedDateTime = this.venueInfoLastUpdatedDisplay(this.state.requestFormData.lastUpdatedOn, false);
                userCreatedDateTime = this.venueInfoLastUpdatedDisplay(this.state.requestFormData.user.lastUpdatedOn, true);
        });
        this.setState({
            venueCreatedDateTime: venueCreatedDateTime,
            userCreatedDateTime: userCreatedDateTime
        })
    };

    handleError = (error: any) => {
        var respMessage: string = "Get Configuration Text service failed with response: " + JSON.stringify(error);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleSuccessOrganisationTypeLookUp = (response: AxiosResponse<any>) => {
        var itemData = response.data.data;
        var organisationTypeLUItem: LookupTypeItem;
        var organisationTypeLUItemArray: LookupTypeItem[];
        organisationTypeLUItemArray = [];
        if (itemData) {
            itemData.map((item: any, uniqueKey: number) => {
                organisationTypeLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                organisationTypeLUItemArray.push(organisationTypeLUItem);
            });
        }

        this.setState({
            organisationLookUp: organisationTypeLUItemArray,
        });
    };

    handleSuccessContactTypeLookUp = (response: AxiosResponse<any>) => {
        var itemData = response.data.data;
        var contactTypeLUItem: LookupTypeItem;
        var contactTypeLUItemArray: LookupTypeItem[];
        contactTypeLUItemArray = [];
        if (itemData) {
            itemData.map((item: any, uniqueKey: number) => {
                contactTypeLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                contactTypeLUItemArray.push(contactTypeLUItem);
            });
        }

        this.setState({
            contactTypeLookUp: contactTypeLUItemArray,
        });
    };

    handleSuccessRoleTypeLookUp = (response: AxiosResponse<any>) => {
        var itemData = response.data.data;
        var roleTypeLUItem: LookupTypeItem;
        var roleTypeLUItemArray: LookupTypeItem[];
        roleTypeLUItemArray = [];
        if (itemData) {
            itemData.filter((a:any)=>a.id == '10').map((item: any, uniqueKey: number) => {
                roleTypeLUItem = {
                    id: item.id,
                    value: item.value,
                    parentMappingId: "0",
                };
                roleTypeLUItemArray.push(roleTypeLUItem);
            });
        }

        this.setState({
            roleLookUp: roleTypeLUItemArray,
        });
    };

    handleChangeVenueName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                venueName: dxValue.value,
            },
        });
    };

    handleChangeVenueShortName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                venueNameWOLocation: dxValue.value,
            },
        });
    };

    handleChangeEmail = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                email: dxValue.value,
            },
        });
    };

    handleChangePhone = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                phone: dxValue.value,
            },
        });
    };

    handleChangeHouseNumber = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                houseNumber: dxValue.value,
            },
        });
    };

    handleChangeAddress = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                address: dxValue.value,
            },
        });
    };

    handleChangeCity = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                city: dxValue.value,
            },
        });
    };

    handleChangeCountry = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                county: dxValue.value,
            },
        });
    };

    handleChangePostcode = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                postCode: dxValue.value,
            },
        });
    };

    handleChangeUserActive = (dxValue: RadioButtonOnSelectionChanged) => {      
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    active: dxValue.value,
                },
            },
        });
        if(this.state.requestFormData.user.name == "" && dxValue.value == true){
            this.setState({
                requestFormData: {
                    ...this.state.requestFormData,
                    user: {
                        ...this.state.requestFormData.user,
                        name: this.state.requestFormData.venueName,
                    },
                },
            });
        }
    }

    handleChangeClientTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                businessEntityId: dxValueChange.value,
            },
        });
    }

    handleChangeVenueTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                venueTypeId: dxValueChange.value,
            },
        });
    }

    handleChangeVenueInfo = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                criticalVenueInfo: dxValue.value,
            },
        });
    };

    handleChangeDivision = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                division: dxValue.value,
            },
        });
    };

    handleChangeBrand = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                brand: dxValue.value,
            },
        });
    };

    handleChangeOnboarded = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                onboarded: dxValue.value,
            },
        });
    };

    handleChangeContactActive = (dxValue: RadioButtonOnSelectionChanged) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                contact: {
                    ...this.state.requestFormData.contact,
                    active: dxValue.value,
                },
            },
        });
    }

    handleChangeUserName = (dxValue: TextBoxOnValueChangedEvent) => {
        this.setState({
            requestFormData: {
                ...this.state.requestFormData,
                user: {
                    ...this.state.requestFormData.user,
                    name: dxValue.value,
                },
            },
        });
    };

    handleCancelOnClick = () => {
        confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
            if (hasConfirmed) {
                this.props.handlePopUpClose(false, "");
            }
        });
    };

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
         
        var profileSubmitRequest = this.utils.convertFormDataToVenueProfileRequest(this.state.requestFormData);        
        this.artistService
            .saveVenuePageData(profileSubmitRequest)
            .then(this.handleSuccessOnFormSubmit)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    loadPanelVisible: false,
                    IsShowError: true,
                    IsShowSuccess: false,
                });
                this.topFunction();
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                });
            });
    }; 

    handleSuccessOnFormSubmit = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false,
            IsShowError: false,
            IsShowSuccess: true,
        });
        if(this.state.requestFormData.id == ""){
            this.props.handleContact("true");
        }
        //let contactId = this.state.requestFormData.contactId || '';
        let contactId = response?.data?.data?.contactId || '';
        //if (contactId) {
            let postObject: logicAppPostRequest = {
                ContactId: contactId
            };
            this.artistService.triggerLogicAppForContactSync(postObject);
        //}
        notify({ message: "Thank You! Profile Information Saved Successfully!", width: 300, shading: true, position: "center" }, 'success', 600);
        this.props.handlePopUpClose(false, "");
    };

    topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    onEditButtonClick = () => {        
        this.setState({
            isEditMode: true,
        });
        this.props.handlePopUpClose(true, "editMode");
    };

    venueInfoLastUpdatedDisplay = (date:any, type:boolean) => {
        let finalFormattedDate: string = "";
        let stringFormattedDate;
        let formattedDate;
        if(this.state.requestFormData.lastUpdatedOn !== null && this.state.requestFormData.lastUpdatedOn !== ""){
            let dateCreatedTime = this.state.requestFormData.lastUpdatedOn;
            let dateCreatedTimeLocal = new Date(dateCreatedTime);
            let formattedTime = this.conversionUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
            if(type == true){
                stringFormattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
                formattedDate = this.conversionUtils.convertStringToddmmmyyyyForm(stringFormattedDate);
            }
            else {
                formattedDate = this.conversionUtils.convertDateToString(dateCreatedTimeLocal);
            }
            finalFormattedDate = `${formattedDate} ${formattedTime}`;            
        }
        return finalFormattedDate;
    };

    render() {
        return (
            <div className="page-content">
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <div className="container">
                    <div className="row mt-3 mb-5" hidden={this.state.isEditMode}>
                        <div className="col-12 col-md-2">
                            <button className="btn btn-primary btn--large" onClick={this.onEditButtonClick}>
                                EDIT
                            </button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-9">
                            <h5 className="artist-profile__section-header">
                                <b>Venue Details</b>
                                <span className="mx-3">({this.state.requestFormData.id})</span>
                            </h5>
                            <div>
                                Venue Name
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeVenueName}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.venueName}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Venue Short Name
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeVenueShortName}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.venueNameWOLocation}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>                          
                            <div className="mt-3">
                                Business Type
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <SelectBox
                                    className="mt-2"
                                    dataSource={this.state.organisationLookUp}
                                    width={"100%"}
                                    readOnly={false}
                                    valueExpr="id"
                                    displayExpr="value"
                                    value={this.state.requestFormData.organisationTypeId}
                                    disabled={true}
                                />
                            </div>
                            <div className="mt-3">
                                Client
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    isRoot={false}
                                    parentMappingId={""}
                                    displayExpression={"value"}
                                    value={this.state.requestFormData.businessEntityId}
                                    isDisabled={!this.state.isEditMode}
                                />
                            </div>   
                            <div className="mt-3">
                                Contact Type
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <SelectBox
                                    className="mt-2"
                                    dataSource={this.state.contactTypeLookUp}
                                    width={"100%"}
                                    readOnly={false}
                                    valueExpr="id"
                                    displayExpr="value"
                                    value={this.state.requestFormData.contact.contactTypeLookUpId}
                                    disabled={true}
                                />
                            </div>                    
                            <div className="mt-3">
                                Email
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeEmail}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.email}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Phone
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangePhone}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.phone}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                House Number
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeHouseNumber}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.houseNumber}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Address
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeAddress}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.address}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Town/City
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeCity}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.city}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Country
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeCountry}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.county}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Post Code
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangePostcode}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.postCode}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>                          
                        </div>                        
                        <div className="col-lg-9">
                            <div className="artist-profile__edit-section"></div>
                            <h5 className="artist-profile__section-header">
                                <b>Venue Categories</b>
                            </h5> 
                            <div>
                                Venue Type
                            </div>
                            <div>
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.venueTypeLookUp}
                                    onValueChanged={this.handleChangeVenueTypeSelect}
                                    value={this.state.requestFormData.venueTypeId}
                                    disableSignal={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Critical Venue Information
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeVenueInfo}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.criticalVenueInfo}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Division
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeDivision}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.division}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Brand
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeBrand}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.brand}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>

                            <div className="mt-3">
                                Onboarded
                            </div>
                            <div>
                                <RadioGroup
                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={this.state.requestFormData.onboarded == "Yes" || this.state.requestFormData.onboarded == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    onValueChanged={this.handleChangeOnboarded}
                                    layout="horizontal"
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="artist-profile__edit-section"></div>
                            <h5 className="artist-profile__section-header">
                                <b>Venue System Info</b>
                            </h5>
                            <div>
                                Active
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <RadioGroup
                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={this.state.requestFormData.contact.active == "Yes" || this.state.requestFormData.contact.active == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    onValueChanged={this.handleChangeContactActive}
                                    layout="horizontal"
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                Contact Id
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.contactId}
                                    disabled={true}
                                />
                            </div>
                            <div className="mt-3">
                                Last Updated
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    valueChangeEvent="keyup"
                                    value={this.state.venueCreatedDateTime}
                                    disabled={true}
                                />
                            </div>
                        </div>  
                        <div className="col-lg-9">
                            <div className="artist-profile__edit-section"></div>
                            <h5 className="artist-profile__section-header">
                                <b>User Details</b>
                                <span className="mx-3">({this.state.requestFormData.user.id})</span>
                            </h5>
                            <div>
                                User Name
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div>
                                <TextBox
                                    width={"100%"}
                                    readOnly={false}
                                    onValueChanged={this.handleChangeUserName}
                                    valueChangeEvent="keyup"
                                    value={this.state.requestFormData.user.name}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="mt-3">
                                User Role
                                <span className="unscheduled-shift" hidden={this.state.requestFormData.user.active == undefined || this.state.requestFormData.user.active == "No" || this.state.requestFormData.user.active == "False" 
                                    || this.state.requestFormData.user.active == ""}>*</span>
                            </div>
                            <div>
                                <SelectBox
                                    className="mt-2"
                                    dataSource={this.state.roleLookUp}
                                    width={"100%"}
                                    readOnly={false}
                                    valueExpr="id"
                                    displayExpr="value"
                                    value={this.state.requestFormData.user.userRole.id}
                                    // disabled={true}
                                    disabled={!this.state.isEditMode}
                                />
                            </div>                                    
                            <div className="mt-3">
                                Active User
                                <span className="unscheduled-shift">*</span>
                            </div>
                            <div>
                                <RadioGroup
                                    items={this.taxApplicable && this.taxApplicable.length > 0 ? this.taxApplicable : []}
                                    displayExpr="value"
                                    valueExpr="value"
                                    value={this.state.requestFormData.user.active == "Yes" || this.state.requestFormData.user.active == "True"
                                            ? "Yes"
                                            : "No"
                                    }
                                    onValueChanged={this.handleChangeUserActive}
                                    layout="horizontal"
                                    disabled={!this.state.isEditMode}
                                />
                            </div>
                            <div className="artist-profile__section-header mt-3">
                                <span className="font-size-one">Last Updated:&nbsp;{this.state.userCreatedDateTime}</span>
                            </div>
                            {this.state.IsShowError && this.state.errorMessage != null ? (
                                <span className="unscheduled-shift">
                                    <ul>
                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mx-0 mb-3" hidden={!this.state.isEditMode}>
                        <div className="col-12 col-lg-12 row pr-0">
                            <div className="col-12 col-xl-2 pl-0">
                                <button
                                    className="btn btn--ghost btn--large"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.handleCancelOnClick()
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-12 col-xl-2 pl-0 mt-3 mt-xl-0">
                                <button
                                    className="btn btn-primary btn--large"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default withTranslation()(AddVenue);
