import { AxiosResponse } from "axios";
import { _get, _delete, _setURL, _post, _aiTraceAsError } from "./BaseApiService";

const VENUE_HIERARCY_API = "VenueContactHierarchy";
const CLIENT = "Client";
const VENUE = "Venue";
const CONTACT = "Contact";
const BUS_ENT = "BusinessEntity";

type NullableBool = boolean | null | undefined;
export interface VenueHierarchyGridRowItem {
    id: string;
    venueId: string;
    venueName: string;
    contactId: string;
    contactName: string;
    houseId: string;
    clientId: string;
    clientName: string;
    userId: string;
    userName: string;
    rollId: string;
    rollName: string;
    managerId: string;
    managerName: string;
    lastUpdatedOn: string;
    lastUpdateBy: string;
}

export interface VenueHierarchyFormResponse {
    id: string;
    venueId: string;
    venueName: string;
    contactId: string;
    contactName: string;
    houseId: string;
    clientId: string;
    clientName: string;
    userId: string;
    userName: string;
    rollId: string;
    rollName: string;
    managerId: string;
    managerName: string;
    lastUpdatedOn: string;
    lastUpdateBy: string;
    level: string;
}

export interface VenueHierarchyFormRequest {
    id: string;
    clientId: string;
    venueId: string;
    contactId: string;
    houseId: string;
    userId: string;
    userName: string;
    rollId: string;
    rollName: string;
    managerId: string;
    level: string;
}
export interface VenueByClientTypeItem {
    id: string;
    houseNumber: string;
    venueName: string;
}
export interface ContactByClientTypeItem {
    contactId: string;
    contactName: string;
    userId: string;
    userName: string;
    roleId: string;
    roleName: string;
}
export const venueservice = {
    venueHierarchy: "VenueHierarchy",
};
class VenueHierarchyService {
    //#region VenueHierarchy Service Call
    getVenueHierarchyData(id: string): Promise<AxiosResponse<any>> {
        return _get(`${VENUE_HIERARCY_API}/${CLIENT}/${id}`);
    }
    getVenueHierarchyDataItem(id: any): Promise<AxiosResponse<any>> {
        return _get(`${VENUE_HIERARCY_API}/${id}`);
    }
    saveVenueHierarchyDataItem(venueHierarchyObject: object) {
        return _post(VENUE_HIERARCY_API, venueHierarchyObject);
    }

    deleteVenueHierarchyDataItem(id: any) {
        return _delete(`${VENUE_HIERARCY_API}/${id}`);
    }
    fetchVenueByClient(id: string): Promise<AxiosResponse<any>> {
        return _get(`${VENUE_HIERARCY_API}/${CLIENT}/${id}/${VENUE}`);
    }
    fetchContactByClient(id: string): Promise<AxiosResponse<any>> {
        return _get(`${BUS_ENT}/${CLIENT}/${id}/${CONTACT}`+`?filterBy=${'active'}`);
    }
    fetchContactByClientAll(id: string): Promise<AxiosResponse<any>> {
        return _get(`${BUS_ENT}/${CLIENT}/${id}/${CONTACT}`+`?filterBy=${"all"}`);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }

    //#endregion
}

export default VenueHierarchyService;
