import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    ColumnChooser,
    SearchPanel,
    StateStoring,
    FilterPanel,
    GroupPanel,
    Summary,
    TotalItem,
    ValueFormat,
    Format,
    Selection,
} from "devextreme-react/data-grid";
import AllUserGridService from "../grid/AllUserGridService";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import gridUtils, { currencyTypeToSymbolMatrix } from "../grid/GridUtilities";
import sharedUtils from "../grid/sharedUtilities";
import CostReportService, { simpleShiftsGridRowItem } from "../../services/CostReportService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import CheckInOutCell from "../grid/ManageViewShiftGridRowCell/CheckInOutCellComponent";
import { Link } from "react-router-dom";
import ClientInformationMainContent from "../ClientInformation/ClientInformationMainContent";
import outstandingActionsUtilities, { NullableDate } from "../Outstanding-Actions/OutstandingActionsUtilities";
import SimpleShiftGlobalFilterService from "../../services/SimpleShiftGlobalFilterService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import DeleteEditShiftActionCellV2 from "../GridCells/DeleteEditShiftActionCellV2";
import SIALicenceDetails from "../SIA_License/SIALicenceDetails";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import ApproveActionCell from "../GridCells/ApproveActionCell";
import QueryActionCell from "../GridCells/QueryActionCell";
import ChangeActionCell from "../GridCells/ChangeActionCell";
import ViewShiftActionCell from "../GridCells/ViewShiftActionCell";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import { onExportingEvent, DataGridOnSelectionChanged } from "../../types/DevExtremeTypes";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";
import { LoadIndicator, Popup, ScrollView } from "devextreme-react";
import { itemTypes } from "../PostApproval/PostApprovalUtils";
import { alert, confirm } from "devextreme/ui/dialog";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import StarRating from "../FindAnArtist/starRating";
import { Status } from "../../services/AddEditPopUpService";
//props
interface SimpleShiftsByServiceGridProps {
    billableItemId?: string;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDate: string;
    endDate: string;
    setCalculations: (totalValue: number, loaded: boolean) => void;
    getBuild: (flag: boolean) => void;  
    getSelectedBillableItems?: (item1: any, item2:any) => void;  
    refreshBulkApproval?: (flag: boolean) => void;
    refreshBulkConfirmed?: (flag: boolean) => void;
    resetRefreshShift?: (flag: boolean) => void;
    onRefresh?: (flag: boolean) => void;
    navigatedFrom?: string;
    licenseNumber?: string;
    paymentId?: string;
    queryId?: string;
    burgerMenuName?: string;
    pointOfNavigation?: string;
    refreshSignal?: boolean;
    residencyId?: string;
    isShowUnbilledItems?: boolean;
    isUnPaidItems?: boolean;
    isUnconfirmedItems?: boolean;
    isUnapprovedItems?:boolean;
    approvalSuccess?: boolean;
    approvalResponse?: any;
    showGrid?: (flag: boolean) => void; 
    shiftConfirmed?: boolean;
    shiftResponse?: any;
    isRefreshButtonClicked?: boolean;
}

// State
interface SimpleShiftsByServiceGridState {
    simpleShiftsGridDataSource: simpleShiftsGridRowItem[];
    showProgressIndicator: boolean;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    startDateId: string;
    endDateId: string;
    venuePopupVisible?: boolean;
    providerPopupVisibleEntertainment?: boolean;
    providerPopupVisibleOther?: boolean;
    licencePopupVisible?: boolean;
    billableItemId: string;
    commentsPopupVisible: boolean;
    internalCommentsPopupVisible: boolean;
    externalComments: string;
    internalComments: string;
    businessEntityId: string;
    feedbackCommentsPopupVisible: boolean;
    feedbackComments: string;
    finalDateFrom: string;
    finalDateTo: string;
    selectedBillableItemId: [];
    selectedAllBillableItemId: [];
    serviceName: string;
}

// Component - displays both the header and child grids
class SimpleShiftsByService extends React.Component<SimpleShiftsByServiceGridProps> {
    state: SimpleShiftsByServiceGridState;
    service: CostReportService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    costReportUtils: CostReportUtils;
    utils: outstandingActionsUtilities;
    isEditMode: string;
    alreadyLoaded: boolean;
    dataGrid:any;
    constructor(props: SimpleShiftsByServiceGridProps) {
        super(props);
        this.dataGrid = null;
        this.isEditMode = "";
        this.alreadyLoaded = false;
        // Initialize state and services/utils
        this.state = {
            simpleShiftsGridDataSource: [],
            showProgressIndicator: false,
            clientId: this.props.clientId,
            venueId: this.props.venueId,
            serviceTypeId: this.props.serviceTypeId,
            startDateId: this.props.startDate,
            endDateId: this.props.endDate,
            venuePopupVisible: false,
            providerPopupVisibleEntertainment: false,
            providerPopupVisibleOther: false,
            licencePopupVisible: false,
            billableItemId: this.props.billableItemId ? this.props.billableItemId : "",
            commentsPopupVisible: false,
            internalCommentsPopupVisible: false,
            externalComments: "",
            internalComments: "",
            businessEntityId: "",
            feedbackCommentsPopupVisible: false,
            feedbackComments: "",
            finalDateFrom: "",
            finalDateTo: "",
            selectedBillableItemId: [],
            selectedAllBillableItemId: [],
            serviceName: "",
        };
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
        this.costReportUtils = new CostReportUtils();
        this.utils = new outstandingActionsUtilities();
        // Functions
        this.service = new CostReportService();
        this.onCustomGridLoad = this.onCustomGridLoad.bind(this);
        this.onCustomGridSave = this.onCustomGridSave.bind(this);
    }

    // Initialize state from server
    componentDidMount() {
        if (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") {
            this.simpleShiftsByServiceGridDataSource();
        } else if (this.props.paymentId) {
            this.simpleShiftsByServiceGridDataSource();
        } else if (this.props.queryId) {
            this.simpleShiftsByServiceGridDataSource();
        } else if (this.props.burgerMenuName) {
            this.setState({
                simpleShiftsGridDataSource: null,
            });
        } else {
            if(this.props.navigatedFrom == "queryform" && this.props.queryId == ""){
                this.setState({
                    showProgressIndicator: false,
                });
            }
            else{
                this.setState({
                    showProgressIndicator: true,
                }); 
            }
            
        }
    }

    simpleShiftsByServiceGridDataSource = () => {
        GlobalService.setGridType(GridTypes.SimpleGrid);
        this.props.setCalculations(0, false); //This will set the Period week and Total texts on the main page to be invisible.
        this.setState({
            showProgressIndicator: true,
        });
        var filterValues = SimpleShiftGlobalFilterService.getFilterValues();
        var dateFrom = this.sharedUtils.convertDateToString(filterValues.dateFrom);
        var dateTo = this.sharedUtils.convertDateToString(filterValues.dateTo);

        if (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") {
            this.service
                .getLicenseChildGridDataSource(
                    this.props.serviceTypeId ? this.props.serviceTypeId : "",
                    this.props.licenseNumber ? this.props.licenseNumber : "",
                    "dateFrom"
                )
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else if (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "residencies") {
            GlobalService.setGridType(GridTypes.residencyShifts);
            this.service
                .getShiftDataItemByResidency(this.props.residencyId ? this.props.residencyId : "")
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else if (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "queryform") {
            GlobalService.setGridType(GridTypes.queryForm);
            this.service
                .getQueryRelatedData(this.props.queryId ? this.props.queryId : "")
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else if (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "contractcleaning") {
            GlobalService.setGridType(GridTypes.contractCleaningShifts);
            this.service
                .getShiftDataItemByContractCleaning(this.props.residencyId ? this.props.residencyId : "")
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else if (this.props.paymentId) {
            this.service.getPaymentDetailsById(this.props.paymentId).then(this.handleSuccess).catch(this.handleError);
        } else if (this.state.billableItemId) {
            this.service
                .getSimpleShiftGridById(this.state.billableItemId)
                .then(this.handleSuccess)
                .catch(this.handleError);
        } else if (this.props.isShowUnbilledItems && (!dateFrom || !this.props.startDate)) {
            this.service
                .getUnbilledBillableItems(this.props.serviceTypeId, this.props.isShowUnbilledItems, this.props.endDate)
                .then(this.handleSuccess)
                .catch(this.handleError) 
        } else if (this.props.isUnPaidItems && (!dateFrom || !this.props.startDate)) {
            this.service
                .getUnpaidBillableItems(this.props.serviceTypeId, this.props.isUnPaidItems, this.props.endDate)
                .then(this.handleSuccess)
                .catch(this.handleError)
        } else if (this.props.isUnconfirmedItems && (!dateFrom || !this.props.startDate)) {
            this.service
                .getUnconfirmedBillableItems(this.props.serviceTypeId, this.props.isUnconfirmedItems, this.props.endDate)
                .then(this.handleSuccess)
                .catch(this.handleError)
        } else if (this.props.isUnapprovedItems && (!dateFrom || !this.props.startDate)) {
            this.service
                .getUnapprovedBillableItems(this.props.serviceTypeId, this.props.isUnapprovedItems, this.props.endDate)
                .then(this.handleSuccess)
                .catch(this.handleError)
        }
        else {
            if (!this.props.paymentId && (this.props.burgerMenuName || this.props.navigatedFrom == 'providerPayments')) {
                this.setState({
                    simpleShiftsGridDataSource: null,
                    showProgressIndicator: false,
                });
            } else {
                if ((dateFrom || this.props.startDate) && (dateTo || this.props.endDate)) {
                    let noOfDays: number | null = null;
                    if (this.props.startDate && this.props.endDate) {
                        noOfDays = this.differenceBetweenTwoDates(this.sharedUtils.convertStringToDate(this.props.startDate), this.sharedUtils.convertStringToDate(this.props.endDate));
                    }
                    else if (dateFrom && dateTo) {
                        noOfDays = this.differenceBetweenTwoDates(this.sharedUtils.convertStringToDate(dateTo), this.sharedUtils.convertStringToDate(dateFrom));
                    }
                    if (noOfDays == 0 || (noOfDays && noOfDays < 62)) {
                        this.service
                            .getSimpleShiftGridRows(
                                this.props.clientId,
                                this.props.venueId,
                                this.props.pointOfNavigation == "popUp" && dateFrom ? dateFrom : this.props.startDate,
                                this.props.pointOfNavigation == "popUp" && dateTo ? dateTo : this.props.endDate,
                                this.props.serviceTypeId
                            )
                            .then(this.handleSuccess)
                            .catch(this.handleError);
                    }
                    else {
                        this.setState({
                            showProgressIndicator: false,
                            simpleShiftsGridDataSource: []
                        });
                        alert("Please narrow your date selection to 2 months or less and retry", "Date range too large");
                    }
                }
                else {
                    this.setState({
                        showProgressIndicator: false,
                        simpleShiftsGridDataSource: []
                    });
                    alert("Please narrow your date selection to 2 months or less and retry", "Date range too large");
                }

            }
        }
    };

    differenceBetweenTwoDates = (minDateVal: NullableDate, maxDateVal: NullableDate) => {
        // To calculate the time difference of two dates
        if (minDateVal && maxDateVal) {
            let Difference_In_Time = maxDateVal.getTime() - minDateVal.getTime();
            // To calculate the no. of days between two dates
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            return Difference_In_Days;
        }
        else {
            return null;
        }
    }


    handleSuccess = (response: AxiosResponse<any>) => {
        var eventUser: boolean =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        var total: number = 0;
        if (response.status == 204) {
            this.props.setCalculations(total, false);
            this.setState({
                simpleShiftsGridDataSource: [], // data will be an empty string ("")
                showProgressIndicator: false,
            });
        } else {
            // show notification for new build            
            let version = localStorage.getItem("BuildVersion");
            let headerVersion = response.headers["x-application-version"];
            if(this.props.isRefreshButtonClicked == true) {
                this.props.resetRefreshShift ? this.props.resetRefreshShift(false) : null;
            }
            // if(version == "" || version == null){
            //     version = getHeaderVersion;
            //     localStorage.setItem("BuildVersion", getHeaderVersion);
            // }        
            if(version !== headerVersion){
                localStorage.setItem("BuildVersion", headerVersion);
                this.props.getBuild(true);              
            }
            // if(this.props.navigatedFrom == "queryform" && response.data.data.length > 0){
            //     this.props?.showGrid ? this.props?.showGrid(true) : null;
            // }
            let queryDataSource = [];
            if(this.props.navigatedFrom == 'queryform'){
                
                if(this.props.queryId && response?.data?.data?.length > 0){
                    queryDataSource = response?.data?.data;
                    this.props?.showGrid ? this.props?.showGrid(true) : null;
                } 
            }
            // end
            if (eventUser) {
                total = this.costReportUtils.returnCalculationsExcludingItemType([], response.data.data, true);
            } else {
                total = this.costReportUtils.returnCalculationsExcludingItemType([], response.data.data, false);
            }
            this.props.setCalculations(total, true);
            this.setState({
                simpleShiftsGridDataSource: this.props.navigatedFrom == 'queryform' ? queryDataSource :response.data.data,
                showProgressIndicator: false,
            });
        }
    };

    handleError = (error: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    componentDidUpdate = (prevprops: SimpleShiftsByServiceGridProps, prevState: SimpleShiftsByServiceGridState) => {
        if(this.props.shiftConfirmed !== prevprops.shiftConfirmed && this.props.shiftConfirmed == true){
            this.dataGrid.instance.clearSelection();
            this.setState({
                selectedBillableItemId:[],
                selectedAllBillableItemId: [],
            });
            this.props.refreshBulkConfirmed ? this.props.refreshBulkConfirmed(true) : null;
        }
        if(this.props.approvalSuccess !== prevprops.approvalSuccess && this.props.approvalSuccess == true){
            this.dataGrid.instance.clearSelection();
            this.setState({
                selectedBillableItemId:[],
                selectedAllBillableItemId: [],
            });
            this.props.refreshBulkApproval ? this.props.refreshBulkApproval(true) : null;
        }
        if (this.props.burgerMenuName != prevprops.burgerMenuName) {
            this.setState({
                simpleShiftsGridDataSource: null,
                showProgressIndicator: false,
                billableItemId: "",
            });
        } else if (
            this.props.clientId != prevprops.clientId ||
            this.props.venueId != prevprops.venueId ||
            this.props.serviceTypeId != prevprops.serviceTypeId ||
            this.props.startDate != prevprops.startDate ||
            this.props.endDate != prevprops.endDate ||
            this.props.paymentId != prevprops.paymentId || 
            this.props.queryId != prevprops.queryId ||
            this.props.refreshSignal != prevprops.refreshSignal || 
            this.props.isShowUnbilledItems != prevprops.isShowUnbilledItems || 
            this.props.isUnPaidItems != prevprops.isUnPaidItems || 
            this.props.isUnconfirmedItems != prevprops.isUnconfirmedItems || 
            this.props.isUnapprovedItems != prevprops.isUnapprovedItems ||
            (this.props.isRefreshButtonClicked != prevprops.isRefreshButtonClicked && this.props.isRefreshButtonClicked == true)
        ) {
            this.alreadyLoaded = false;
            this.setState({
                serviceTypeId: this.props.serviceTypeId,
                billableItemId: "",
            });
            this.simpleShiftsByServiceGridDataSource();
        } else if(prevState.finalDateFrom !== this.state.finalDateFrom || prevState.finalDateTo !== this.state.finalDateTo){
            this.alreadyLoaded = false;
        }
    };

    isBlank(billableItemId: string) {
        return !billableItemId || /^\s*$/.test(billableItemId) || 0 === billableItemId.length;
    }

    onCustomGridLoad() {
        var filterValues = SimpleShiftGlobalFilterService.getFilterValues();
        var dateFrom = this.sharedUtils.convertDateToString(filterValues.dateFrom);
        var dateTo = this.sharedUtils.convertDateToString(filterValues.dateTo);      
        let finalDateFrom = dateFrom ? dateFrom : this.props.startDate;
        let finalDateTo = dateTo ? dateTo : this.props.endDate;
        this.setState({
            finalDateFrom: finalDateFrom,
            finalDateTo: finalDateTo,
        })
        let d1 = finalDateFrom.split("/");
        let d2 = finalDateTo.split("/");
        let dfrom = new Date(parseInt(d1[2]), parseInt(d1[1])-1, parseInt(d1[0]));  // -1 because months are from 0 to 11
        let dTo   = new Date(parseInt(d2[2]), parseInt(d2[1])-1, parseInt(d2[0]));
        if(this.state.simpleShiftsGridDataSource !== null && this.state.simpleShiftsGridDataSource.length !== 0 && !this.alreadyLoaded && this.props?.pointOfNavigation == "burgerMenu"){
            let lastIndex = this.state.simpleShiftsGridDataSource.length-1;
            let dateFromFirst = this.state.simpleShiftsGridDataSource[0].date.split("/");
            let dateFromLast = this.state.simpleShiftsGridDataSource[lastIndex].date.split("/");
            let dateToFirst = this.state.simpleShiftsGridDataSource[0].dateTo.split("/");
            let dateToLast = this.state.simpleShiftsGridDataSource[lastIndex].dateTo.split("/");
            let itemDateFromFirst = new Date(parseInt(dateFromFirst[2]), parseInt(dateFromFirst[1])-1, parseInt(dateFromFirst[0]));
            let itemDateFromLast = new Date(parseInt(dateFromLast[2]), parseInt(dateFromLast[1])-1, parseInt(dateFromLast[0]));
            let itemDateToFirst = new Date(parseInt(dateToFirst[2]), parseInt(dateToFirst[1])-1, parseInt(dateToFirst[0]));
            let itemDateToLast = new Date(parseInt(dateToLast[2]), parseInt(dateToLast[1])-1, parseInt(dateToLast[0]));
            if((itemDateFromFirst >= dfrom && itemDateFromLast <= dTo) || (itemDateToFirst >= dfrom && itemDateToLast <= dTo)){
                //nothing to do
            }
            else{
                if(!this.props.isShowUnbilledItems && !this.props.isUnPaidItems && !this.props.isUnconfirmedItems && !this.props.isUnapprovedItems){
                    this.setState({
                        showProgressIndicator: true,
                    });
                    this.alreadyLoaded = true;
                    this.service
                        .getSimpleShiftGridRows(
                            this.props.clientId,
                            this.props.venueId,
                            dateFrom ? dateFrom : this.props.startDate,
                            dateTo ? dateTo : this.props.endDate,
                            this.props.serviceTypeId
                        )
                        .then(this.handleSuccess)
                        .catch(this.handleError);
                }                
            }
        }
        if (this.props.serviceTypeId == ServiceType.Security) {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.doorsupervisionShiftsGrid);
        } else if (this.props.serviceTypeId == ServiceType.ContractCleaning) {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.contractCleaningShiftGrid);
        } else if (this.props.serviceTypeId == ServiceType.Entertainment) {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.entertainmentShiftGrid);
        } else {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.simpleManageShiftsGrid);
        }
    }

    onCustomGridSave(gridState: Object) {
        if (this.props.serviceTypeId == ServiceType.Security) {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.doorsupervisionShiftsGrid, gridState);
        } else if (this.props.serviceTypeId == ServiceType.ContractCleaning) {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.contractCleaningShiftGrid, gridState);
        } else if (this.props.serviceTypeId == ServiceType.Entertainment) {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.entertainmentShiftGrid, gridState);
        } else {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.simpleManageShiftsGrid, gridState);
        }
    }

    renderDelEditV2TitleHeader = (data: any) => {
        return UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">DELETE</div> <span>|</span>
                    {"  "} <div className="ml-1">MASTER EDIT</div>
                </div>
            </>
        ) : UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ? (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">DELETE</div>
                </div>
            </>
        ) : null;
    };

    showInfoVenue = (clientId: string, venueId: string) => {
        this.setState({
            venuePopupVisible: true,
            clientId: clientId,
            venueId: venueId,
        });
    };
    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string, service: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceTypeId: serviceTypeId,
            businessEntityId: businessEntityId,
            serviceName: service
        });
        if (serviceTypeId == "10") {
            this.setState({
                providerPopupVisibleOther: true,
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: true,
            });            
        }
    };

    showInfoFeedbackComments = (feedbackComments: string) => {
        this.setState({
            feedbackCommentsPopupVisible: true,
            feedbackComments: feedbackComments,
        });
    };

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }
    hideInfoVenue = () => {
        this.setState({
            venuePopupVisible: false,
        });
    };
    hideInfoProvider = (e: any) => {
        this.setState({
            providerPopupVisibleOther: false,
        });
    };

    hideInfoFeedbackComments = () => {
        this.setState({
            feedbackCommentsPopupVisible: false,
        });
    };

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    showInfoComments = (comments: string) => {
        this.setState({
            commentsPopupVisible: true,
            externalComments: comments,
        });
    };
    showInfoInternalComments = (internalComments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: internalComments,
        });
    };
    hideInfoComments = () => {
        this.setState({
            commentsPopupVisible: false,
        });
    };
    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false,
        });
    };
    //Helper function that sets the serviceTypeId in the session.
    linkToCostReport = (serviceId: string) => {
        sessionStorage.setItem("serviceTypeFilter", serviceId);
    };

    //Custom cell rendering function that links the Billed column to the cost report.
    billedCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                {this.props.burgerMenuName ? (
                    cellInfo.data.billPeriod
                ) : (
                    <Link
                        onClick={() => this.linkToCostReport(cellInfo.data.serviceTypeId)}
                        className={
                            cellInfo.data && cellInfo.data.invoiceSentDate
                                ? "approval-query-column"
                                : "disabled approval-query-column"
                        }
                        to={{
                            pathname: "/CostReport",
                            state: {
                                clientId: cellInfo.data.clientId,
                                venueId: cellInfo.data.venueId,
                                startDateId: cellInfo.data.periodStartId,
                                endDateId: cellInfo.data.periodEndId,
                            },
                        }}
                    >
                        {cellInfo.data.billPeriod}
                    </Link>
                )}
            </div>
        );
    };

    venueCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoVenue(cellInfo.data.clientId, cellInfo.data.venueId);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.venue}
                </Link>
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data?.onboarded;
        let valToShow: string = "";
        if (onboardedVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {valToShow}
            </div>
        );

    }

    payReqCellDisplayContent = (cellInfo: any) => {
        let fasterPaymentVal: string = "";
        let confirmedPaymentVal: string = "";
        let paymentReqId: string = "";
        if (cellInfo && cellInfo.data && cellInfo.data.paymentRequest && cellInfo.data.paymentRequest.fasterPayment) {
            fasterPaymentVal = cellInfo.data.paymentRequest.fasterPayment;
            paymentReqId = cellInfo.data.paymentRequest.id;
        }
        else if (cellInfo && cellInfo.data && cellInfo.data.paymentRequest == null && cellInfo.data.fasterPayment !== "") {
            confirmedPaymentVal = cellInfo.data.fasterPayment;
        }
        else if (this.props.shiftResponse?.statusText == "OK") {
            let index = this.props.shiftResponse?.data.map((o:any) => o.billableItemId).indexOf(parseInt(cellInfo.data.billableItemId));
            if(index !== -1){
                confirmedPaymentVal = this.props.shiftResponse.data[index].fasterPayment;
            }            
        }
        return (
            <div>
                {fasterPaymentVal ?
                    <Link
                        className="approval-query-column"
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            window.open(`/MatchedPopUpPage?filterBy=${paymentReqId}`, "Related Payment Request/ Artist Job Notification",
                                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                        }}
                        to="#"
                    >
                        {fasterPaymentVal}
                    </Link> : <span className="confirmed-query-column"
                    >{confirmedPaymentVal}</span>}
            </div>
        );
    };

    commentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoComments(cellInfo.data.comments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.comments
                        ? cellInfo.data.comments.length > 16
                            ? `${cellInfo.data.comments.substring(0, 16)}...`
                            : cellInfo.data.comments
                        : ""}
                </Link>
            </div>
        );
    };

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internalComments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.internalComments
                        ? cellInfo.data.internalComments.length > 16
                            ? `${cellInfo.data.internalComments.substring(0, 16)}...`
                            : cellInfo.data.internalComments
                        : ""}
                </Link>
            </div>
        );
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientId, cellInfo.data.venueId, this.props.serviceTypeId, cellInfo.data.providerId, cellInfo.data.service);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.provider}
                </Link>
            </div>
        );
    };

    licenceCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.unscheduled == "Yes" ? (
                    <Link
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.showInfoLicence(cellInfo.data.billableItemId);
                        }}
                        className="approval-query-column"
                        to="#"
                    >
                        {this.sharedUtils.licenseNumberFormat(cellInfo.data.identificationNumber)}
                    </Link>
                ) : (
                    this.sharedUtils.licenseNumberFormat(cellInfo.data.identificationNumber)
                )}
            </div>
        );
    };

    getUpdate = (flag: boolean) => {
        if(flag == true){
            this.dataGrid.instance.clearSelection();
            this.setState({
                selectedBillableItemId:[],
                selectedAllBillableItemId: [],
            });
            this.props.refreshBulkApproval ? this.props.refreshBulkApproval(true) : null;
        }
    }

    approveActionCell = (cellInfo: any) => {
        return(
            <div>
                <ApproveActionCell data={cellInfo} getUpdate={this.getUpdate} onRefresh={this.props.onRefresh}
                approvalSuccess={this.props.approvalSuccess} approvalResponse={this.props.approvalResponse} dataId={this.state.selectedBillableItemId} i18n={cellInfo} tReady={false} t={cellInfo}/>
            </div>        
        );
    }

    showInfoLicence(billableItemId: string) {
        this.setState({
            billableItemId: billableItemId,
            licencePopupVisible: true,
        });
    }
    hideInfoLicence = () => {
        this.setState({
            licencePopupVisible: false,
        });
    };

    selectionOfColourForApproveShiftColumn = (timeDiffIndicator: string) => {
        var isBlue: boolean;
        var isRed: boolean;
        var classname: string = "";
        var isEventOrClient =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager);

        if (timeDiffIndicator && timeDiffIndicator.toLowerCase() == "no change") {
            isBlue = false;
            isRed = false;
        } else if (isEventOrClient && timeDiffIndicator && timeDiffIndicator.toLowerCase() == "less hours") {
            isBlue = true;
            isRed = false;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) &&
            timeDiffIndicator &&
            timeDiffIndicator.toLowerCase() == "less hours"
        ) {
            isRed = true;
            isBlue = false;
        } else if (isEventOrClient && timeDiffIndicator && timeDiffIndicator.toLowerCase() == "more hours") {
            isRed = true;
            isBlue = false;
        } else if (
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) &&
            timeDiffIndicator &&
            timeDiffIndicator.toLowerCase() == "more hours"
        ) {
            isBlue = true;
            isRed = false;
        } else {
            isBlue = true;
            isRed = false;
        }

        if (isBlue) {
            classname = "check-blue";
        }
        if (isRed) {
            classname = "check-red";
        }

        return classname;

    }

    approvedShiftCellDisplayContent = (cellInfo: any) => {
        var timeDiffIndicator: string = "";
        if (cellInfo.data && cellInfo.data.timeDiffIndicator) {
            timeDiffIndicator = cellInfo.data.timeDiffIndicator;
        }
        var classname = this.selectionOfColourForApproveShiftColumn(timeDiffIndicator);
        var approvedShift: string = "";
        if (cellInfo.data && cellInfo.data.approvedShift) {
            approvedShift = cellInfo.data.approvedShift;
        }
        return (
            <div>
                <span className={classname}>{`${approvedShift}`}</span>
            </div>
        );
    };

    billableTimeCellDisplayContent = (cellInfo: any) => {
        if (
            cellInfo.data.billableTime &&
            cellInfo.data.billableTime.includes("(") &&
            (cellInfo.data.itemType == "AdjustmentClientDebit" || cellInfo.data.itemType == "AdjustmentProviderDebit")
        ) {
            var billedTime: string[] = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "manageshift"
            );
            var billableExtraHrsStr = cellInfo.data.billableTime.substring(
                cellInfo.data.billableTime.lastIndexOf("(") + 1,
                cellInfo.data.billableTime.lastIndexOf(")")
            );
            var billableHrsStr = cellInfo.data.billableTime.substr(0, cellInfo.data.billableTime.indexOf("(") - 1);
            return (
                <div>
                    <span className={billedTime[0]}>{billableHrsStr}</span>
                    <span> ({billableExtraHrsStr})</span>
                </div>
            );
        } else {
            var billedTime: string[] = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "manageshift"
            );
            return (
                <div>
                    <span className={billedTime[0]}>{`${billedTime[1]}`}</span>
                </div>
            );
        }

    };

    billableRateCellDisplayContent = (cellInfo: any) => {
        var billableExtraRateStr = "";
        var billableRateStr = "";
        var billedRate: string[] = this.costReportUtils.billableRateDisplayContent(
            cellInfo.data ? cellInfo.data : "",
            "manageshift"
        );
        if (cellInfo.data.rate && cellInfo.data.rate.includes("(")) {
            billableExtraRateStr = billedRate[1].substring(
                cellInfo.data.rate.lastIndexOf("(") + 1,
                cellInfo.data.rate.lastIndexOf(")")
            );
            billableExtraRateStr = "(" + billableExtraRateStr + ")";
            billableRateStr = cellInfo.data.rate.substr(0, billedRate[1].indexOf("("));
        }
        return (
            <div>
                {this.renderBillableRate(billableExtraRateStr, cellInfo.data.itemTypeId, billedRate, billableRateStr)}
            </div>
        );
    };

    feedbackCellDisplayContent = (cellInfo: any) => {
        if (cellInfo.data?.feedback?.feedbackScore !== "") {
            let feedbackScore: number = parseFloat(cellInfo.data?.feedback?.feedbackScore);
            return (
                <div className="artist-card__rating">
                    <StarRating score={feedbackScore} />
                </div>
            );
        }
    };

    getDayValue = (rowData:any)=>{
        let date = this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
        let dayName = this.gridUtils.getDaysFromDate(date)
        return dayName;

    }

    feedbackCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={() => {
                        this.showInfoFeedbackComments(cellInfo?.data?.feedback?.feedbackComments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo?.data?.feedback?.feedbackComments?.length > 16
                        ? `${cellInfo?.data?.feedback?.feedbackComments.substring(0, 16)}...`
                        : cellInfo?.data?.feedback?.feedbackComments}
                </Link>
            </div>
        );
    };

    // return rate based on itemType 
    renderBillableRate = (billableExtraRateStr: string, itemType: string, billedRate: string[], billableRateStr: string) => {
        if (billableExtraRateStr && itemType == itemTypes.Original) {
            return (
                <>
                    <span>{billableRateStr}</span> <span className={billedRate[0]}>{billableExtraRateStr}</span>
                </>
            );
        }
        else if (billableExtraRateStr && (itemType == itemTypes.AdjustmentClientDebit || itemType == itemTypes.AdjustmentProviderDebit || itemType == itemTypes.Adjustment)) {
            return (
                <>
                    <span className={billedRate[0]}>{billableRateStr}</span> <span>{billableExtraRateStr}</span>
                </>
            );
        }
        else {
            return (
                <ul className="list-style-type">
                    <li> {billedRate[1]} </li>
                </ul>
            );
        }
    }

    renderTitleV2Header = (data: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                {UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                    UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? (
                    <>
                        <div className="mr-4">APPROVE</div>
                        <span>|</span> {"      "}
                        <div className="ml-3 mr-3">QUERY</div> <span>|</span> {"      "}
                        <div className="ml-3 mr-3">VIEW</div>
                        <span>|</span> {"      "}
                        <div className="ml-3 mr-3">CHANGE</div>
                    </>
                ) : UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ? (
                    <>
                        {" "}
                        <div className="mr-2 ml-2">ACCEPT</div>
                        <span>|</span> {"  "}
                        <div className="mr-6 ml-2">QUERY</div>
                    </>
                ) : (
                    <>
                        {" "}
                        <div className="mr-2 ml-2">APPROVE</div>
                        <span>|</span> {"  "}
                        <div className="mr-6 ml-2">QUERY</div>
                    </>
                )}
            </div>
        );
    };

    renderContractHours = (cellInfo: any) => {
        if (cellInfo.data.subTypeId == SubServiceType.WeeklyCleaning) {
            return (
                <div>
                    {cellInfo.data.contractHours}
                </div>
            );
        }
    }


    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            //topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && gridCell.column.dataField === "identificationNumber") {
                        if (gridCell.value) {
                            excelCell.value = parseInt(gridCell.value);
                            excelCell.numFmt = "####-####-####-####";
                        }
                    }
                    if (gridCell.column && gridCell.column.dataField === "timeDecimal") {
                        if (gridCell.value) {
                            excelCell.value = parseFloat(gridCell.value);
                        }
                    }
                    if (gridCell.column && gridCell.column.dataField == "checkInCheckOut") {
                        let checkInOutArr: any[] = [];
                        let checkInClass: string = "";
                        let checkOutClass: string = ""
                        checkInOutArr = this.gridUtils.convertStringToStringArray(gridCell.value);
                        if (gridCell.data.checkInColor == "Red" && gridCell.data.checkInFont == "Bold") {
                            checkInClass = "FFFF0000";
                        } else if (gridCell.data.checkInColor == "Green" && gridCell.data.checkInFont == "Bold") {
                            checkInClass = "0000AEEF";
                        } else if (gridCell.data.checkInColor == "Red" && !gridCell.data.checkInFont) {
                            checkInClass = "FFFF0000";
                        } else if (gridCell.data.checkInColor == "Green" && (!gridCell.data.checkInFont)) {
                            checkInClass = "0000AEEF";
                        }

                        if (gridCell.data.checkOutColor == "Red" && gridCell.data.checkOutFont == "Bold") {
                            checkOutClass = "FFFF0000";
                        } else if (gridCell.data.checkOutColor == "Green" && gridCell.data.checkOutFont == "Bold") {
                            checkOutClass = "0000AEEF";
                        } else if (gridCell.data.checkOutColor == "Red" && !gridCell.data.checkOutFont) {
                            checkOutClass = "FFFF0000";
                        } else if (gridCell.data.checkOutColor == "Green" && !gridCell.data.checkOutFont) {
                            checkOutClass = "0000AEEF";
                        }


                        excelCell.value = {
                            'richText': [
                                {
                                    'font': {
                                        'size': 12,
                                        'color': {
                                            'argb': checkInClass
                                        },
                                        'name': 'Calibri',
                                        'family': 2,
                                        'scheme': 'minor'
                                    },
                                    'text': checkInOutArr.length > 0 ? checkInOutArr[0] : ""
                                },
                                {
                                    'font': {
                                        'size': 12,
                                        'color': {
                                            'argb': checkOutClass
                                        },
                                        'name': 'Calibri',
                                        'scheme': 'minor'
                                    },
                                    'text': checkInOutArr.length > 0 ? "-" + " " + checkInOutArr[1] : ""
                                },
                            ]
                        }
                    }
                    if (gridCell.column && gridCell.column.dataField == "approvedShift") {
                        var classname: string = "";
                        var actualClassName: string = "";
                        if (gridCell.data && gridCell.data.timeDiffIndicator) {
                            classname = this.selectionOfColourForApproveShiftColumn(gridCell.data.timeDiffIndicator);
                        }
                        if (classname == "check-blue") {
                            actualClassName = "0000AEEF";
                        }
                        else if (classname == "check-red") {
                            actualClassName = "FFFF0000";
                        }
                        excelCell.value = {
                            'richText': [
                                {
                                    'font': {
                                        'size': 12,
                                        'color': {
                                            'argb': actualClassName
                                        },
                                        'name': 'Calibri',
                                        'family': 2,
                                        'scheme': 'minor'
                                    },
                                    'text': gridCell.value ? gridCell.value : ""
                                }
                            ]
                        }

                    }
                    if (this.props.serviceTypeId !== ServiceType.Entertainment && gridCell.column?.dataField == "rate") {
                        if(gridCell.value){
                            excelCell.value =  parseFloat(gridCell.value.substring(1))
                            excelCell.numFmt = "£#,##0.00";
                        }
                    }
                }
                if (gridCell && gridCell.rowType === "totalFooter") {
                    if (gridCell.value && gridCell.totalSummaryItemName == "total") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerNet") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerVat") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerGross") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "eventFeeNet") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "accrualNet") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "accrualFee") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "accrualTotal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "totalNet") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }

                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        let Ids:any = []; 
        let allIds:any = [];           
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            let statusColumnIndex: number = 0;
            let payDateColumnIndex: number = 0;
            payDateColumnIndex = value?.component?.getVisibleColumnIndex("payDate") ? value?.component?.getVisibleColumnIndex("payDate") : 0;            

            value.selectedRowsData.forEach((items) => {                
                let statusId: string = "";                
                if (items.statusId) {
                    statusId = items.statusId;
                }
                else if (items.billableStatusLookupId) {
                    statusId = items.billableStatusLookupId;
                }
                let tenseIdentifier: string = items.tenseFlag;
                let futureShift: boolean = false;
                let iconDisabled: boolean = false;
                let pendingIconVisible: boolean = false;
                let isApprovalDate: boolean = false;
                if (items.approvalDate || items.approval_Date) {
                    isApprovalDate = true;
                }
                else {
                    isApprovalDate = false;
                }
                let itemTypeId: string = items.itemTypeId ? items.itemTypeId : "";
                const isEventUk =
                    UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                    UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
                const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
                const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);
                let noIconToDisplay: boolean = false;
                let disableQueryIcon: boolean = false;
                if (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "future") {
                    iconDisabled = true;
                    futureShift = true;
                } else {
                    if (
                        (statusId && statusId == Status.Approved) ||
                        (statusId && statusId == Status.Accepted) ||
                        (items.billableStatusLookUp && items.billableStatusLookUp.value == "Approved") || (items.status == "Approved" || items.statusValue == "Approved") ||
                        (items.billableStatusLookUp && items.billableStatusLookUp.value == "Accepted") || (items.status == "Accepted" || items.statusValue == "Accepted") ||
                        items.approvalDate || items.approval_Date ||
                        items.acceptDate || items.accept_Date
                    ) {
                        iconDisabled = true;
                        pendingIconVisible = false;
                    } else if (
                        (tenseIdentifier && tenseIdentifier.trim().toLowerCase() == "past" &&
                            isEventUk || isVenueManager &&
                            statusId &&
                            statusId == Status.Pending && ((items.billableStatusLookUp && items.billableStatusLookUp.value == "Pending") || items.status == "Pending")) ||
                        (isEventUk || isVenueManager && statusId && statusId == Status.PendingAcceptance && ((items.billableStatusLookUp && items.billableStatusLookUp.value == "Pending Acceptance") || items.status == "Pending Acceptance")) ||
                        (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) &&items.isAccrual && !items.paymentId) ||
                        (isEventUk || isVenueManager && tenseIdentifier.trim().toLocaleLowerCase() == "past" && (!isApprovalDate) && !items.isAccrual && !items.invoiceId)
                    ) {
                        iconDisabled = false;
                        pendingIconVisible = true;
                    }
                    else if ((items.invoiceId && !items.isAccrual) ||
                        (items.paymentId && items.invoiceId && items.isAccrual)) {
                        iconDisabled = true;
                        pendingIconVisible = false;
                    } else if (
                        (isVenueManager && itemTypeId && itemTypeId == itemTypes.AdjustmentProviderDebit) ||
                        (isProvider &&
                            itemTypeId &&
                            itemTypeId == itemTypes.AdjustmentClientDebit &&
                            tenseIdentifier.trim().toLowerCase() == "past")
                    ) {
                        iconDisabled = true;
                        pendingIconVisible = true;
                    } else {
                        noIconToDisplay = true;
                    }
                }
                if (items.queries && items.queries.length > 0) {
                    disableQueryIcon = this.costReportUtils.disableRaiseQueryIcon(items.queries);
                }    
                
                if (items.statusValue) {
                    statusColumnIndex = value?.component?.getVisibleColumnIndex("statusValue") ? value?.component?.getVisibleColumnIndex("statusValue") : 0;
                }
                else if (items.status) {
                    statusColumnIndex = value?.component?.getVisibleColumnIndex("status") ? value?.component?.getVisibleColumnIndex("status") : 0;
                }
                else if (items.billableStatusLookUp && items.billableStatusLookUp.value) {
                    statusColumnIndex = value?.component?.getVisibleColumnIndex("billableStatusLookUp.value") ? value?.component?.getVisibleColumnIndex("billableStatusLookUp.value") : 0;
                }

                if(!futureShift && pendingIconVisible && !noIconToDisplay && !disableQueryIcon) Ids.push({
                    "id": items.billableItemId,
                    "statusColumnIndex": statusColumnIndex,
                    "payDateColumnIndex": payDateColumnIndex
                });

                allIds.push({
                    "id": items.billableItemId
                });
            });            
            this.setState({
                selectedBillableItemId: Ids,
                selectedAllBillableItemId: allIds,
            })
        }
        else{
            this.setState({
                selectedBillableItemId: [],
                selectedAllBillableItemId: [],
            });
        }     
        this.props.getSelectedBillableItems ? this.props.getSelectedBillableItems(this.state.selectedBillableItemId, this.state.selectedAllBillableItemId) : null; 
    };

    calculatePayAmounts = (cellInfo: any) => {
        let subAmount = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.providerPayAmountLocal,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        let fasterPayOriginalAmount = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayOriginalAmount,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        let fasterPayFee = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayFee,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div className="fa-file">
                { cellInfo.data.immediatePayment == true ? 
                    <b>{subAmount} ({fasterPayOriginalAmount} - {fasterPayFee})</b>
                    : <b>{subAmount}</b>
                }
            </div>        
        );
    }

    renderProviderNet = (cellInfo: any) => {
        let providerNet = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.providerPayAmountLocal,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div className="fa-file">
                <b>{providerNet}</b>
            </div>        
        );
    }

    renderFeeNet = (cellInfo: any) => {
        let feeNet = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.grossProfitAmountLocal,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div className="fa-file">
                <b>{feeNet}</b>
            </div>        
        );
    }

    renderTotalNet = (cellInfo: any) => {
        let totalNet = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.clientBillableAmountLocal,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div className="fa-file">
                <b>{totalNet}</b>
            </div>        
        );
    }

    fasterPayCellDisplayContent = (cellInfo: any) => {
        let fasterPayVal = cellInfo.data.data.immediatePayment;
        let valToShow: string = "";
        if (fasterPayVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );

    }
    displayBooleanContent = (Val:any)=>{
        let valToShow: string = "";
        if (Val) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );
    }

    calculateBooleanContent = (Val:any)=>{
        let valToShow: boolean;
        if (Val) {
            valToShow = true;
        }
        else {
            valToShow = false;
        }
        return valToShow;
    }

    render() {
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        const isEventSM = UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        const isProvider = UserService.isUserInGroup(RoleGroupNames.ProviderScheduler);
        const isVenueManager = UserService.isUserInGroup(RoleGroupNames.VenueManager);

        return (
            <div>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) :  this.props.navigatedFrom == "queryform" && this.state.simpleShiftsGridDataSource.length == 0 ? null : (
                    <DataGrid
                        dataSource={this.state.simpleShiftsGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        onSelectionChanged={this.onSelectionChanged}
                        ref={(ref) => { this.dataGrid = ref; }}
                        onExporting={this.onExporting}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        columnMinWidth={100}
                    >
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "residencies")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "contractCleaning")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "queryform")
                            || this.props.burgerMenuName ? null : (
                            <ColumnChooser enabled={true} />
                        )}
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <StateStoring
                            enabled={true}
                            type="custom"
                            customLoad={this.onCustomGridLoad}
                            customSave={this.onCustomGridSave}
                            savingTimeout='0'
                        />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                            this.props.burgerMenuName ? (
                            <></>
                        ) : (
                            <Scrolling useNative={true} showScrollbar={"always"} />
                        )}
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "residencies") 
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "queryform")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "contractcleaning") ? null : (
                            <Export enabled={true} allowExportSelectedData={true} />
                        )}
                        <Paging defaultPageSize={10} />
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "residencies") 
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "queryform")
                            || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "contractcleaning") ? null : (
                                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode={(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck")
                                || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "residencies") 
                                || (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "contractcleaning")  ? "none" : "always"}></Selection>
                        )}
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} /> 
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                            this.props.burgerMenuName ? null : (
                            <Column caption="APPROVE" cellRender={this.approveActionCell} allowExporting={false} />
                        )}
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                            this.props.burgerMenuName ? null : (
                            <Column caption="QUERY" cellComponent={QueryActionCell} allowExporting={false} />
                        )}
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                            this.props.burgerMenuName ? null : isEventUk ? (
                                <Column caption="CHANGE" cellComponent={ChangeActionCell} allowExporting={false} />
                            ) : null}
                        {(this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                            this.props.burgerMenuName ? null : isEventUk ? (
                                <Column caption="VIEW" cellComponent={ViewShiftActionCell} allowExporting={false} />
                            ) : null}
                        <Column dataField="statusValue" caption="STATUS" />
                        <Column dataField="originator.value" caption="ORIGINATOR" />

                        <Column dataField="fasterPayment" caption="PAY REQUEST" cellRender={this.payReqCellDisplayContent} />
                        <Column
                            dataField="immediatePayment"
                            caption=" FASTER PAY"
                            cellComponent={this.fasterPayCellDisplayContent}
                            dataType="boolean"
                        ></Column>
                        <Column
                            dataField="venue"
                            caption="VENUE"
                            cellRender={this.venueCellDisplayContent}
                        />
                        <Column dataField="number" caption="VENUE NO" />
                        <Column 
                            dataField="onboarded" 
                            caption="ONBOARDED" 
                            cellRender={this.onboardedCellDisplayContent}
                        />
                        <Column dataField="client" caption="CLIENT" />
                        <Column dataField="service" caption="SERVICE" cellComponent={AllUserGridService} />
                        <Column dataField="subType" caption="SUB-TYPE" />

                        <Column
                            dataField={this.props.serviceTypeId == ServiceType.Security ? "role" : "type"}
                            caption={this.props.serviceTypeId == ServiceType.Security ? "ROLE" : "TYPE"}
                        />

                        <Column
                            dataField={
                                this.props.serviceTypeId == ServiceType.Entertainment ? "artistName" : "provider"
                            }
                            caption={this.props.serviceTypeId == ServiceType.Entertainment ? "ARTIST NAME" : "PROVIDER"}
                            cellRender={this.providerCellDisplayContent}
                        />

                        <Column
                            dataField="providerId"
                            caption={this.props.serviceTypeId == ServiceType.Entertainment ? "ARTIST ID" : "PROVIDER ID"}
                        />

                        {(this.props.serviceTypeId == ServiceType.Entertainment) ? (
                            <Column
                                dataField="actName"
                                caption="ACT NAME"
                            />
                        ) : null}

                            {/* {(this.props.serviceTypeId !== ServiceType.Security) ? (
                                <> */}
                                <Column
                                    dataField="businessEntityVerified"
                                    caption="BUSINESS SET-UP VERIFIED"
                                    // cellRender={this.feedbackCellDisplayContent}
                                    cellComponent={(cellInfo:any)=>{return this.displayBooleanContent(cellInfo?.data?.data?.businessEntityVerified)}}
                                    calculateCellValue={(cellInfo:any)=>{return this.calculateBooleanContent(cellInfo?.businessEntityVerified)}}
                                    dataType="boolean"
                                />
                                <Column
                                    dataField="businessEntityVATVerified"
                                    caption="VAT VERIFIED"
                                    cellComponent={(cellInfo:any)=>{return this.displayBooleanContent(cellInfo?.data?.data?.businessEntityVATVerified)}}
                                    calculateCellValue={(cellInfo:any)=>{return this.calculateBooleanContent(cellInfo?.businessEntityVATVerified)}}
                                    dataType="boolean"
                                    //cellRender={this.feedbackCellDisplayContent}
                                />
                                <Column
                                    dataField="bankAccountVerified"
                                    caption="BANK ACCOUNT VERIFIED"
                                    cellComponent={(cellInfo:any)=>{return this.displayBooleanContent(cellInfo?.data?.data?.bankAccountVerified)}}
                                    calculateCellValue={(cellInfo:any)=>{return this.calculateBooleanContent(cellInfo?.bankAccountVerified)}}
                                    dataType="boolean"
                                    //cellRender={this.feedbackCellDisplayContent}
                                />
                             {/* </>
                            ) : null} */}


                        {(this.props.serviceTypeId !== ServiceType.Security) ? (
                            <Column
                                dataField="feedback.feedbackScore"
                                caption="FEEDBACK"
                                cellRender={this.feedbackCellDisplayContent}
                            />
                        ) : null}

                        {(this.props.serviceTypeId !== ServiceType.Security) ? (
                            <Column
                                dataField="feedback.feedbackComments"
                                caption="FEEDBACK COMMENTS"
                                cellRender={this.feedbackCommentsCellDisplayContent}
                            />
                        ) : null}

                            {(this.props.serviceTypeId === ServiceType.Entertainment) ? (
                                <Column
                                    dataField="date"
                                    name="Day"
                                    caption="DAY"
                                    calculateSortValue={(rowData: any) => {
                                        return this.getDayValue(rowData);
                                    }}
                                    calculateCellValue={(rowData: any) => {
                                        return this.getDayValue(rowData);
                                    }}
                                />
                            ) : null}
                        <Column
                            dataField="date"
                            caption="DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.date);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />

                        <Column
                            dataField="approvalDate"
                            caption="APPROVAL DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.approvalDate);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.approvalDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />

                        <Column
                            dataField="shiftCompleteAcknowledgmentDateTime"
                            caption="SHIFT ACKNOWLEDGEMENT DATE"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.shiftCompleteAcknowledgmentDateTime);
                            }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.shiftCompleteAcknowledgmentDateTime);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                        />

                        {this.props.serviceTypeId == ServiceType.Security ? (
                            <Column dataField="name" caption="NAME" />
                        ) : null}

                        {(this.props.serviceTypeId == ServiceType.Security) ? (
                            <Column
                                dataField="feedback.feedbackScore"
                                caption="FEEDBACK"
                                cellRender={this.feedbackCellDisplayContent}
                            />
                        ) : null}

                        {(this.props.serviceTypeId == ServiceType.Security) ? (
                            <Column
                                dataField="feedback.feedbackComments"
                                caption="FEEDBACK COMMENTS"
                                cellRender={this.feedbackCommentsCellDisplayContent}
                            />
                        ) : null}

                        {this.props.serviceTypeId == ServiceType.Security ? (
                            <Column
                                dataField="identificationNumber"
                                caption="SIA LICENCE NUMBER"
                                cellRender={this.licenceCellDisplayContent}
                            />
                        ) : null}
                        {this.props.serviceTypeId == ServiceType.Security ?
                            <Column dataField="workerStatus" caption="WORKER STATUS" /> : null}

                        {this.props.serviceTypeId == ServiceType.Security ? (
                            <Column
                                dataField="checkInCheckOut"
                                caption="CHECK IN & OUT"
                                cellComponent={CheckInOutCell}
                                allowReordering={false}
                            />
                        ) : null}

                        {this.props.serviceTypeId == ServiceType.ContractCleaning || this.props.serviceTypeId == ServiceType.Gardening || this.props.serviceTypeId == ServiceType.WindowCleaning || this.props.serviceTypeId == ServiceType.Maintenance || this.props.serviceTypeId == ServiceType.Miscelleanous ? null : (
                            <Column
                                dataField={
                                    this.props.serviceTypeId == ServiceType.Security
                                        ? "expectedShift"
                                        : "performanceTime"
                                }
                                caption={
                                    this.props.serviceTypeId == ServiceType.Security
                                        ? "SCHEDULED SHIFT"
                                        : "PERFORMANCE TIME"
                                }
                                allowReordering={false}
                            />
                        )}

                        {this.props.serviceTypeId == ServiceType.Security ? (
                            <Column
                                dataField="approvedShift"
                                caption="APPROVED SHIFT"
                                cellRender={this.approvedShiftCellDisplayContent}
                                allowReordering={false}
                            />
                        ) : null}
                            <Column
                                dataField={
                                    this.props.serviceTypeId == ServiceType.Entertainment
                                        ? "performanceDetails"
                                        : "billableTime"
                                }
                                caption={
                                    this.props.serviceTypeId == ServiceType.Entertainment
                                        ? "PERFORMANCE DETAILS"
                                        : "TIME / QUANTITY" 
                                }
                                cellRender={this.billableTimeCellDisplayContent}
                                allowReordering={false}
                            /> 
                            { this.state.serviceTypeId == ServiceType.ContractCleaning ?
                                <Column
                                    dataField={
                                        "contractHours"
                                    }
                                    caption={
                                        "CONTRACT HOURS"
                                    }
                                    allowReordering={false}
                                    cellRender={this.renderContractHours}
                                /> : null}

                        {this.props.serviceTypeId == ServiceType.Security ? (
                            <Column dataField="timeDecimal" caption="TIME(DECIMAL)" />
                        ) : null}

                        {this.props.serviceTypeId == ServiceType.Entertainment ? null : (
                            <Column
                                dataField="rate"
                                caption="RATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertCurrencyAsStringToFloat(rowData.rate);
                                }}
                                cellRender={this.billableRateCellDisplayContent}
                            />
                        )}

                        <Column
                            dataField="total"
                            caption="TOTAL"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>
                        <Column
                            dataField="comments"
                            caption="COMMENTS"
                            cellRender={this.commentsCellDisplayContent}
                        />
                        {isEventUk && (
                            <Column
                                dataField="internalComments"
                                caption="INTERNAL COMMENTS"
                                cellRender={this.internalCommentsCellDisplayContent}
                            />
                        )}
                        <Column
                            dataField="fasterPayOriginalAmount"
                            caption="PAY AMOUNTS (FP CALC)"
                            cellRender={this.calculatePayAmounts}
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                        >
                        </Column>
                        {isEventUk && (
                            <Column
                                dataField="providerPayAmountLocal"
                                caption="PROVIDER NET"
                                dataType="number"
                                cellRender={this.renderProviderNet}
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="providerPayAmountTaxLocal"
                                caption="PROVIDER VAT"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="providerPayAmountTotalLocal"
                                caption="PROVIDER GROSS"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="grossProfitAmountLocal"
                                caption="EVENT FEE NET"
                                dataType="number"
                                cellRender={this.renderFeeNet}
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="providerPayAmountAccrual"
                                caption="ACCRUAL NET"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="grossProfitAmountAccrual"
                                caption="ACCRUAL FEE"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="clientBillableAmountAccrual"
                                caption="ACCRUAL TOTAL"
                                dataType="number"
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}
                        {isEventUk && (
                            <Column
                                dataField="clientBillableAmountLocal"
                                caption="TOTAL NET"
                                dataType="number"
                                cellRender={this.renderTotalNet}
                            >
                                <Format type="currency" currency='GBP' precision={2}></Format>
                            </Column>
                        )}

                        {(isEventUk || isVenueManager) && (
                            <Column
                                dataField="billPeriod"
                                caption="BILLED"
                                cellRender={this.billedCellDisplayContent}
                            />
                        )}

                        {(isEventUk || isProvider) && (
                            <Column
                                dataField="payDate"
                                caption="EARLIEST PAY DATE"
                                calculateSortValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                                }}
                                calculateCellValue={(rowData: any) => {
                                    return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                                }}
                                format="dd/MM/yyyy"
                                dataType="date"
                            />
                        )}

                        {isEventUk && <Column dataField="paymentStatus" caption="PAYMENT STATUS" />}      

                        {(isEventUk || isProvider) && (
                            <Column
                                dataField="paymentDate"
                                caption="PAYMENT DATE"
                            // calculateSortValue={(rowData: any) => {
                            //     return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                            // }}
                            calculateCellValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.paymentDate);
                            }}
                            format="dd/MM/yyyy"
                            dataType="date"
                            />
                        )}
                        {isEventUk && <Column dataField="sortCode" caption="SORT CODE" />}
                        {isEventUk && <Column dataField="accountNumber" caption="ACCOUNT NUMBER" />}
                        {isEventUk && <Column dataField="reference" caption="REFERENCE" />}
                        {isEventUk && <Column dataField="dateTo" caption="DATE TO" 
                        calculateCellValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.dateTo);
                        }}
                        format="dd/MM/yyyy"
                        dataType="date"
                        />}
                        {isEventUk && <Column dataField="dateCreated" caption="DATE CREATED"
                            format="dd/MM/yyyy"
                            dataType='date'/>}
                        {isEventUk && <Column dataField="clientAmendDateTime" caption="CLIENT AMEND DATE" 
                        calculateSortValue={(rowData: any) => {return this.gridUtils.convertddmmyyyyStringToDate(rowData.clientAmendDateTime);}}
                        calculateCellValue={(rowData: any) => {
                            return this.gridUtils.convertddmmyyyyStringToDate(rowData.clientAmendDateTime);
                        }}
                        format="dd/MM/yyyy"
                        dataType="date"
                        />}
                        {isEventUk && <Column dataField="itemType" caption="ITEM TYPE" />}
                        {isEventUk && <Column dataField="billableItemId" caption="Id" />}

                        {(this.props.serviceTypeId == ServiceType.Security ||
                            this.props.serviceTypeId == ServiceType.ContractCleaning ||
                            this.props.serviceTypeId == ServiceType.Entertainment ||
                            this.props.serviceTypeId == ServiceType.Gardening ||
                            this.props.serviceTypeId == ServiceType.WindowCleaning ||
                            this.props.serviceTypeId == ServiceType.Miscelleanous ||
                            this.props.serviceTypeId == ServiceType.Maintenance) &&
                            isEventSM ? (
                            (this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "licensecheck") ||
                                this.props.burgerMenuName ? null : (
                                <Column
                                    headerCellRender={this.renderDelEditV2TitleHeader}
                                    cellComponent={DeleteEditShiftActionCellV2}
                                    allowExporting={false}
                                />
                            )
                        ) : null}  

                        { this.props.navigatedFrom && this.props.navigatedFrom.toLowerCase() == "queryform" ? 
                                <Column
                                    headerCellRender={this.renderDelEditV2TitleHeader}
                                    cellComponent={DeleteEditShiftActionCellV2}
                                    allowExporting={false}
                                /> : null}                        

                        <FilterRow visible={true} applyFilter="auto" />

                        <Summary>
                            <TotalItem
                                column="total"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="total">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="providerPayAmountLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="providerNet">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="providerPayAmountTaxLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="providerVat">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="providerPayAmountTotalLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="providerGross">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="grossProfitAmountLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="eventFeeNet">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="providerPayAmountAccrual"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="accrualNet">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="grossProfitAmountAccrual"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="accrualFee">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="clientBillableAmountAccrual"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="accrualTotal">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>
                            <TotalItem
                                column="clientBillableAmountLocal"
                                summaryType="sum"
                                displayFormat="{0}"
                                name="totalNet">
                                <ValueFormat type="currency" currency='GBP' precision={2}></ValueFormat>
                            </TotalItem>

                        </Summary>
                    </DataGrid>
                )}

                <Popup
                    visible={this.state.venuePopupVisible}
                    onHiding={this.hideInfoVenue}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Client Information & Schedule"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <ClientInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            isShowByVenue={true}
                            serviceTypeId={this.props.serviceTypeId}
                        ></ClientInformationMainContent>
                    </ScrollView>
                </Popup>

                <Popup
                    visible={this.state.licencePopupVisible}
                    onHiding={this.hideInfoLicence}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Door Supervision - Unscheduled Shift"
                    resizeEnabled={true}
                    className="pop-up pop-up--tall pop-up--left-aligned"
                >
                    <ScrollView width="100%" height="100%">
                        <SIALicenceDetails billableItemId={this.state.billableItemId}></SIALicenceDetails>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.props.serviceTypeId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title={this.state.serviceTypeId == "30" ? "Artist Details" : "Provider Details"}
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    serviceType={this.state.serviceName}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
                <Popup
                    visible={this.state.commentsPopupVisible}
                    onHiding={this.hideInfoComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.externalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.feedbackCommentsPopupVisible}
                    onHiding={this.hideInfoFeedbackComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"FEEDBACK COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.feedbackComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default SimpleShiftsByService;
