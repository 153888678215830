import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import QueryCardList from "../Query/QueryCardList";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import QueryGridSidePanel from "./QueryGridSidePanel";
import QueryGrid from "./QueryGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import GridTitleCard from "../Cards/GridTitleCard";
import { faUserCog } from "@fortawesome/pro-solid-svg-icons";

interface QueryGridPageProps extends RouteComponentProps {
    title: string;
    category: string;
    location: any;
    queryId: string;
}

interface QueryGridPageState {
    clientId: string,
    venueId: string,
    serviceId: string,
    statusId: string
}

class QueryGridPage extends Component<QueryGridPageProps> {
    //Initialize the State
    state: QueryGridPageState;

    constructor(props: QueryGridPageProps) {
        super(props);

        this.state = {
            clientId: "",
            venueId: "",
            serviceId: "",
            statusId: ""
        };
    }

    onApplyButtonClick = (clientId: string, venueId: string, serviceId: string, statusId: string,isApplyClicked:boolean) => {
        if (isApplyClicked && this.props?.location?.state) {
            this.props.location.state = {};
        }
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceId: serviceId,
            statusId: statusId
        });
    }

    render() {
        return (
            <LightModePageTemplate>
                <SideBarTemplate isFixedDesktopOnly={true}>
                    <QueryGridSidePanel
                        onApplyButtonClick={this.onApplyButtonClick}
                        serviceTypeId=""
                        clientId=""
                        venueId="" />
                    <BulletinPanel />
                </SideBarTemplate>
                <div className="page-content--with-sidebar-hidden-mobile">
                    <header className="grid-info mt-3 mb-3">
                        <GridTitleCard title="Queries" cardStyle="admin" icon={faUserCog}/>
                    </header>
                    <div className="grid-info__button-container text-center text-lg-left">
                        <Link
                            className={"btn btn-primary btn--large col-12 col-sm-6 col-xl-3 mb-3"}
                            to={
                                {
                                    pathname: "/QueryManageForm",
                                    state: {
                                        id: "",
                                        isView: false
                                    },
                                }
                            }
                        >
                            <span className="btn__icon">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </span>
                            Add General Query
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            {
                                <QueryGrid statusId={this.state.statusId}
                                    serviceTypeId={this.state.serviceId}
                                    venueId={this.state.venueId}
                                    clientId={this.state.clientId} 
                                    location={this.props.location}
                                    />
                            }
                        </div>
                        <div></div>
                    </div>
                </div>
            </LightModePageTemplate>
        );
    }
}

export default withRouter(QueryGridPage);
