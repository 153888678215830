import React from "react";
import { AxiosResponse, AxiosError } from "axios";
import DataGrid, { Pager, Paging, Export, FilterRow, Column, Selection, SearchPanel,StateStoring } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react";
import gridUtils, { currencyTypes, currencyTypeToSymbolMatrix } from "../grid/GridUtilities";
import PaymentService, { PaymentGridRowItem, paymentGridTypes } from "../../services/PaymentService";
import { DataGridOnSelectionChanged } from "../../types/DevExtremeTypes";
import UserService, { RoleGroupNames } from "../../services/UserService";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import InvoiceAmountCell from "../../components/Payments/ProviderPayment/InvoiceAmountCellComponent";
import PayDateAdviceCell from "./ProviderPayment/PayDateAdviceCellComponent";
import paymentActionCell from "../Payments/PaymentActionCellComponent";
import RelatedItemActionCell from "../Payments/BillableItemCellComponent";
import sharedUtils from "../grid/sharedUtilities";

interface PaymentsGridProviderProps {
    setPaymentIdOnParent: (paymentId: string) => void;
    gridType: string;
    paymentsRefresh: boolean;
    location: any;
    history: any;
    providerId?: string;
    startDate?: string;
    endDate?: string;
    serviceTypeId?: string;
    status?: [];
    isFasterPayments?: boolean;
}
interface PaymentsGridProviderState {
    gridDataSource: PaymentGridRowItem[];
    showProgressIndicator: boolean;
}

class PaymentsGridProvider extends React.Component<PaymentsGridProviderProps> {
    state: PaymentsGridProviderState;
    //Initialize the service to fetch the data.
    Service: PaymentService;
    sharedUtils: sharedUtils;
    manageGridService: ManageDocumentsGridService;
    //Reuse the Grid utils component to format the cells.
    gridUtils: gridUtils;
    userHasAccess: boolean;
    //_isMounted:boolean;

    constructor(props: PaymentsGridProviderProps) {
        super(props);
        // Initialize state and services/utils
        this.Service = new PaymentService();
        this.sharedUtils = new sharedUtils();
        this.gridUtils = new gridUtils();
        this.manageGridService = new ManageDocumentsGridService();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.ProviderScheduler) ||
            UserService.isUserInGroup(RoleGroupNames.ProviderSiteManager) ||
            UserService.isUserInGroup(RoleGroupNames.Artist);
        this.state = {
            gridDataSource: [],
            showProgressIndicator: false,
        };
        //this._isMounted = false;

        this.updateGridDataSource = this.updateGridDataSource.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    componentDidMount() {
        //this._isMounted = true;
    
        if(this.props.location?.state?.navigateFrom && this.props.location?.state?.navigateFrom !== "PaymentRequestFailed" && this.props.location?.state?.navigateFrom !== "PaymentWithFasterPayments"){
            this.updateGridDataSource();
        }
    }

    componentDidUpdate(prevprops: PaymentsGridProviderProps) {
        if (
            this.props.paymentsRefresh != prevprops.paymentsRefresh ||
            this.props.serviceTypeId != prevprops.serviceTypeId ||
            this.props.providerId != prevprops.providerId ||
            this.props.startDate != prevprops.startDate ||
            this.props.endDate != prevprops.endDate || 
            this.props.status != prevprops.status ||  
            this.props.isFasterPayments != prevprops.isFasterPayments || 
            this.props.location.state?.navigateFrom != prevprops.location.state?.navigateFrom
        ) {
            this.updateGridDataSource();
        }
    }

    // componentWillUnmount() {
    //     // fix Warning: Can't perform a React state update on an unmounted component
    //     this.setState = (state,callback)=>{
    //         return;
    //     };
    // }

    // componentWillUnmount() {
    //     this._isMounted = false;
    //  }
    //  setState = (state:any, callback?:any) => {
    //     if (this._isMounted) {
    //       super.setState(state, callback);
    //     }
    //  }

    updateGridDataSource() {
        if (this.userHasAccess) {
            this.setState({
                showProgressIndicator: true,
            });
            let startDate: string = "";
            let endDate: string = "";
            //removing this as this part is getting set in payment side bar panel on load
            // if(this.props.startDate == "" && this.props.isFasterPayments == false && this.props.location.state.navigateFrom !== "PaymentRequestFailed"){
            //     var dateFrom = new Date();
            //     dateFrom.setDate(dateFrom.getDate() - 15);
            //     var dateTo = new Date();
            //     dateTo.setDate(dateTo.getDate());
            //     startDate = this.sharedUtils.convertDateToString(dateFrom);
            //     endDate =  this.sharedUtils.convertDateToString(dateTo);
            // }
            //else{
                startDate = this.props.startDate ? this.props.startDate : "";
                endDate =  this.props.endDate ? this.props.endDate : "";
            //}
            if(this.props.location?.state?.navigateFrom == "PaymentRequestFailed"){
                this.setState({
                    allowSelection: "none",
                });
                this.Service.generatePaymentsForProvider(
                    this.props.providerId,
                    this.props.serviceTypeId,
                    startDate,
                    endDate,
                    this.props.status,
                    this.props.isFasterPayments,
                    "PaymentRequestFailed"                    
                )
                    .then(this.handleSuccess)
                    .catch(this.handleError);
            }
            else if(this.props.location.state?.navigateFrom == "PaymentWithFasterPayments") {
                this.setState({
                    allowSelection: "none",
                });
                this.Service.generatePaymentsForProvider(
                    this.props.providerId,
                    this.props.serviceTypeId,
                    startDate,
                    endDate,
                    this.props.status,
                    this.props.isFasterPayments,
                    "PaymentWithFasterPayments"                    
                )
                    .then(this.handleSuccess)
                    .catch(this.handleError);
            }
            else {
                var typeOfGridDataSource = this.props.gridType;
                switch (typeOfGridDataSource) {
                    case paymentGridTypes.providerPayment:
                        this.setState({
                            allowSelection: "none",
                        });
                        this.Service.generatePaymentsForProvider(
                            this.props.providerId,
                            this.props.serviceTypeId,
                            startDate,
                            endDate,
                            this.props.status,
                            this.props.isFasterPayments
                        )
                            .then(this.handleSuccess)
                            .catch(this.handleError);
                        break;
                    case paymentGridTypes.artistPayment:
                        this.setState({
                            allowSelection: "single",
                        });
                        this.Service.generatePaymentsForProvider(
                            this.props.providerId,
                            this.props.serviceTypeId,
                            startDate,
                            endDate,
                            this.props.status,
                            this.props.isFasterPayments
                        )
                            .then(this.handleSuccess)
                            .catch(this.handleError);
                        break;
                }
            }
        }
    }

    handleSuccess(response: AxiosResponse<any>) {
        this.setState({
            gridDataSource: response.data.data,
            showProgressIndicator: false,
        });
    }

    handleError(error: AxiosError<any>) {
        // Redirect back to home page on a 401 unauth error
        if (error.response != null && error.response.status === 401) {
            this.props.history.push({
                pathname: "/",
            });
        } else {
            this.setState({
                showProgressIndicator: false,
            });
            var respMessage: string = "Payments grid provider failed with response: " + JSON.stringify(error);

            if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
                // AppInsights is not available
                console.error(respMessage);
            }
        }
    }

    // Fire parent component's callback with data from grid
    onSelectionChanged = (event: DataGridOnSelectionChanged) => {
        // Capture the PaymentId of the clicked row.
        var paymentId: string = "";
        if (event.selectedRowsData && event.selectedRowsData.length > 0) {
            paymentId = event.selectedRowsData[0].paymentId;
        }
        this.props.setPaymentIdOnParent(paymentId);
    };

    setVarianceColour = (cellElement: any, cellInfo: any) => {
        var cellVarianceWithCurrencySymbol = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.varience,
            currencyTypeToSymbolMatrix[currencyTypes.GBP]
        );
        if (cellInfo.data.rag == "Green" && cellInfo.data.varience) {
            cellElement.classList.add("text-success");
        } else if (cellInfo.data.rag == "Amber" && cellInfo.data.varience) {
            cellElement.classList.add("text-warning");
        }
        if (cellInfo.data.varience) {
            cellElement.append(cellVarianceWithCurrencySymbol);
        }
    };
    
    calculatePayAmounts = (cellInfo: any) => {
        let subAmount = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.subAmount,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        let fasterPayOriginalAmount = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayOriginalAmount,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        let fasterPayFee = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayFee,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div className="fa-file">
                { cellInfo.data.fasterPay == true && cellInfo.data.originatingBillableItemId != "" ? 
                    <b>{subAmount} ({fasterPayOriginalAmount} - {fasterPayFee})</b>
                    : <b>{subAmount}</b>
                }
            </div>        
        );
    }

    calculatefasterPayOriginalAmount = (cellInfo: any) => {
        
        let fasterPayOriginalAmount = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayOriginalAmount,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        
        return(
            <div>
                {fasterPayOriginalAmount}
            </div>        
        );
    }

    calculatefasterPayFee = (cellInfo: any) => {
       
        let fasterPayFee = this.gridUtils.convertDecimalAsStringToCurrency(
            cellInfo.data.fasterPayFee,
            currencyTypeToSymbolMatrix[cellInfo.data.currency]
        );
        return(
            <div>
                    {fasterPayFee}
            </div>        
        );
    }

    fasterPayCellDisplayContent = (cellInfo: any) => {
        let fasterPayVal = cellInfo.data.data.fasterPay;
        let valToShow: string = "";
        if (fasterPayVal) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );

    }

    render() {
        return (
            <div>
                <h4 className="ml-3 font-weight-bold"></h4>
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={50}
                            width={50}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={this.state.gridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        onSelectionChanged={this.onSelectionChanged}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        className="entertainment-payment-grid"
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <Selection mode="single" />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        <StateStoring
                                enabled={true}
                                type="localStorage" storageKey="providers_payment_grid"
                                savingTimeout='0'
                            />
                        { UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? 
                            <Column dataField="action" caption="Edit" cellComponent={paymentActionCell} allowExporting={false} /> : 
                            null                            
                        } 
                        <Column dataField="paymentId" caption="ID" />
                        <Column dataField="entity" caption="PROVIDER" />
                        <Column dataField="entityId" caption="PROVIDER ID" />
                        <Column dataField="reference" caption="REFERENCE" />
                        <Column
                            dataField="fasterPay"
                            caption=" FASTER PAY"
                            cellComponent={this.fasterPayCellDisplayContent}
                            dataType="boolean"
                        >
                        </Column>
                        <Column
                            dataField="fasterPayOriginalAmount"
                            caption="ORIGINAL FEE"
                            cellRender={this.calculatefasterPayOriginalAmount}
                        >
                        </Column>
                        <Column
                            dataField="fasterPayFee"
                            caption="FASTER PAY FEE"
                            cellRender={this.calculatefasterPayFee}
                        >
                        </Column>
                        <Column
                            dataField="fasterPayOriginalAmount"
                            name='fasterPayOriginalAmountTotal'
                            caption="PAY AMOUNTS (FP CALC)"
                            cellRender={this.calculatePayAmounts}
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                        />
                        <Column
                            dataField="subAmount"
                            caption="NET PAY AMOUNT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.subAmount,
                                    currencyTypeToSymbolMatrix[rowData.currency]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.subAmount);
                            }}
                        />
                        <Column
                            dataField="tax"
                            caption="VAT"
                            calculateDisplayValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToCurrency(
                                    rowData.tax,
                                    currencyTypeToSymbolMatrix[rowData.currency]
                                );
                            }}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.tax);
                            }}
                        />
                        <Column
                            dataField="amount"
                            caption="GROSS PAY AMOUNT"
                            cellComponent={InvoiceAmountCell}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertDecimalAsStringToFloat(rowData.amount);
                            }}
                        />
                        <Column dataField="sortCode" caption="SORT CODE" />
                        <Column dataField="accountNumber" caption="ACCOUNT NUMBER" />
                        <Column dataField="status" caption="STATUS" />
                        <Column dataField="comments" caption="COMMENTS" />
                        <Column
                            dataField="advisedDate"
                            caption="PAY INVOICE DATE"
                            format="dd/MM/yyyy"
                            cellComponent={PayDateAdviceCell}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.advisedDate);
                            }}
                        />
                         <Column
                            dataField="payDate"
                            caption="PAYMENT DATE"
                            format="dd/MM/yyyy"
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertddmmyyyyStringToDate(rowData.payDate);
                            }}
                        />
                        <Column dataField="originatingBillableItemId" caption="RELATED ITEM" cellComponent={RelatedItemActionCell} allowExporting={false} />
                        <Column dataField="rag" caption="RAG" visible={false} />
                        <Column dataField="currency" caption="CURRENCY" visible={false} />
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                )}                
            </div>
        );
    }
}
export default PaymentsGridProvider;
