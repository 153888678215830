import { DeleteIdListObject, IdListObject } from "../components/grid/GridUtilities";
import sharedUtils from "../components/grid/sharedUtilities";

type NullableDate = Date | null | undefined;
export interface gridFilter {
    serviceType: string;
    dateFrom: NullableDate;
    dateTo: NullableDate;
}

export interface buttonState {
    amendIdValues: number[];
    approveIdValues: IdListObject;
    raiseQueryIdValues: number[];
    deleteIdValues: DeleteIdListObject;
}

export interface buttonClickable {
    isApproveValues: boolean;
    isDeleteValues: boolean;
}

const SimpleShiftGlobalFilterService = (function () {
    var currentFilterValues: gridFilter = {
        serviceType: "",
        dateFrom: null,
        dateTo: null,
    };
    var buttonStatesValues: buttonState = {
        amendIdValues: [],
        approveIdValues: {
            approvableBillableIdList: [],
            notaApprovableBillableIdList: [],
        },
        raiseQueryIdValues: [],
        deleteIdValues: {
            deletableIdList: [],
            notdeletableIdList: [],
        },
    };
    var buttonClickableOrNot: buttonClickable = {
        isApproveValues: true,
        isDeleteValues: true,
    };
    let paymentTypeVal : string = "";

    //
    const getFilterValues = function (): gridFilter {
        return {
            serviceType: currentFilterValues.serviceType,
            dateFrom: currentFilterValues.dateFrom,
            dateTo: currentFilterValues.dateTo,
        };
    };

    const setPaymentTypeVal = function (paymentTypeId: string): void {
        paymentTypeVal = paymentTypeId;
    }

    const getPaymentTypeVal = function (): string {
       return paymentTypeVal;
    }



    // Set datefrom and dateto from Query String (D365)
    const setDateFromValue = function (dateFrom: NullableDate): void {
        currentFilterValues.dateFrom = dateFrom;
    };
    const setDateToValue = function (dateTo: NullableDate): void {
        currentFilterValues.dateTo = dateTo;
    };

    const removeDateToValue = function (): void {
        currentFilterValues.dateFrom = null;
    };

    const removeDateFromvalue = function (): void {
        currentFilterValues.dateFrom = null;
    };

    const IsUpdateRequired = function (preValue: gridFilter) {
        let utils = new sharedUtils();
        if (
            currentFilterValues.serviceType != preValue.serviceType ||
            utils.convertDateToString(currentFilterValues.dateFrom) != utils.convertDateToString(preValue.dateFrom) ||
            utils.convertDateToString(currentFilterValues.dateTo) != utils.convertDateToString(preValue.dateTo)
        ) {
            return true;
        } else {
            return false;
        }
    };
    const IsUpdateServiceType = function (preValue: gridFilter) {
        if (currentFilterValues.serviceType != preValue.serviceType) {
            return true;
        } else {
            return false;
        }
    };

    const setParentButtonStates = function (
        amendIdList: number[],
        approveIdList: IdListObject,
        enableRaiseQueryList: number[],
        enableDeleteList: DeleteIdListObject
    ) {
        buttonStatesValues.amendIdValues = amendIdList;
        buttonStatesValues.approveIdValues = approveIdList;
        buttonStatesValues.raiseQueryIdValues = enableRaiseQueryList;
        buttonStatesValues.deleteIdValues = enableDeleteList;
        setButtonClickableState();
    };

    const setButtonClickableState = function () {
        if (
            buttonStatesValues.approveIdValues.approvableBillableIdList.length != 0 &&
            buttonStatesValues.approveIdValues.notaApprovableBillableIdList.length == 0
        ) {
            buttonClickableOrNot.isApproveValues = false;
        } else if (buttonStatesValues.approveIdValues.notaApprovableBillableIdList.length != 0) {
            buttonClickableOrNot.isApproveValues = true;
        } else {
            buttonClickableOrNot.isApproveValues = true;
        }
        if (
            buttonStatesValues.deleteIdValues.deletableIdList.length != 0 &&
            buttonStatesValues.deleteIdValues.notdeletableIdList.length == 0
        ) {
            buttonClickableOrNot.isDeleteValues = false;
        } else if (buttonStatesValues.deleteIdValues.notdeletableIdList.length != 0) {
            buttonClickableOrNot.isDeleteValues = true;
        } else {
            buttonClickableOrNot.isDeleteValues = true;
        }
    };
    const getButtonClickableState = function () {
        return buttonClickableOrNot;
    };

    const getButtonStates = function () {
        return buttonStatesValues;
    };

    return {
        getFilterValues: getFilterValues,
        IsUpdateRequired: IsUpdateRequired,
        setDateFromValue: setDateFromValue,
        setDateToValue: setDateToValue,
        IsUpdateServiceType: IsUpdateServiceType,
        setParentButtonStates: setParentButtonStates,
        getButtonStates: getButtonStates,
        setButtonClickableState: setButtonClickableState,
        getButtonClickableState: getButtonClickableState,
        removeDateFromvalue: removeDateFromvalue,
        removeDateToValue: removeDateToValue,
        setPaymentTypeVal: setPaymentTypeVal,
        getPaymentTypeVal: getPaymentTypeVal
    };
})();

export default SimpleShiftGlobalFilterService;
