import { providerProfileResponse, providerProfileRequest, venueProfileRequest, ContactProfileRequest } from "../../services/ArtistService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;

interface ConfigurationLookupValues {
    id: string[];
    value: string[];
}

export interface linkTypeInfo {
    id: string;
    businessEntityId: string;
    linkTypeId: string;
    linkTypeName: string;
    linkTypeOther: string;
    link: string;
    active: string;
    lastUpdatedOn: string | null;
    lastUpdatedBy: string | null;
}

export interface businessDocumentInfo {
    id: string;
    name: string;
    link: string;
    displayLink: string;
    formatType: string;
    documentType: string;
    dateFrom: string;
    dateTo: string;
    updatedBy: string;
    updatedOn: string;
}

export interface postSignUpMessageInfo {
    id: string;
    value: string;
    parentMappingId: string;
}

export interface typeList {
    id: string;
    value: string;
    otherDescription: string;
}

export interface genreList {
    id: string;
    value: string;
}
export interface musicTypeList {
    id: string;
    value: string;
}


/**
 * The possible values for a BusinessEntityTaxApplicableStatus.businessEntityType
 */
export const BusinessEntityTypes = {
    Client: "Client",
    Provider: "Provider",
    Company: "Company",
};

export interface ArtistPageFormData {
    businessEntityID: string;
    businessEntityTypeLookUpId: string;
    businessEntityType: string;
    legalName: string;
    tradingName: string;
    shortName: string;
    businessEntityLegalStatusLookUpId: string;
    businessEntityLegalStatus: string;
    companyNumber: string;
    taxApplicable: string;
    taxVerified: string;
    taxVerifiedBy: string;
    taxVerifiedDateTime: string;
    verified: string;
    verifiedBy: string;
    verifiedDateTime: string;
    openingHours: string;
    webSite: string;
    officePhone: string;
    email: string;
    providerPaymentOffsetDays: string;
    taxRegistrationNumber: string;
    addressStreet1: string;
    addressStreet2: string;
    addressStreet3: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
    professionalName: string;
    score: string;
    bio: string;
    shortDescription: string;
    coverageMiles: string;
    coverageTownCity?: string;
    termsAgreed: boolean;
    businessEntityStatusId: string;
    businessEntityStatus: string;
    termsAgreedDateTime: string;    
    venueFavourite: string;
    lastPlayedVenue: string;
    lastUpdatedOn: string,
    lastUpdatedBy:string,
    isActive: string;
    trustedUser: string;
    hideProfileExternally: string;
    hideFromSource: string;
    agent:string;
    //artist:string;
    factored:string;
    updateEmail?: boolean;
    onboardedDate:NullableDate;
    contactInformation: {
        contactId: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: NullableDate;
        utr: string;
        ni: string;
        optOut: string;
        active: string;
        lastUpdatedOn: string;
    } | null;
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        allowWebApp:string;
        disallowApp:string;
        disallowPortal:string;
    } | null;
    providerSiteManagerInformation: {
        contactId: string;
        title: string;
        firstName: string;
        lastName: string;
        name: string;
        mobileTelephone: string;
        businessTelephone: string;
        otherTelephone: string;
        email: string;
        addressStreet1: string;
        addressStreet2: string;
        city: string;
        state: string;
        postCode: string;
        emailNotification: string;
        dateOfBirth: NullableDate;
        utr: string;
        ni: string;
    } | null;
    bankInformation: {
        id: string;
        businessEntityID: string;
        contactID: string;
        bankName: string;
        branchName: string;
        accountNumber: string;
        sortCode: string;
        buildingSociety: string;
        nameOnAccount: string;
        active: string;
        verified: string;
        verifiedBy: string;
        verifiedDateTime: string;
        createdDateTime: string;
        serviceTypeLookUpId: string;
        serviceSubTypeLookUpId: string;
    } | null;
    requirePayment: string;
    requirePaymentComment: string;
    artistApart: string;
    linkInformation: linkTypeInfo[];
    typeList: typeList[];
    businessEntityDocument: businessDocumentInfo[];
    validationErrors: string;
    postSignUpMessages: postSignUpMessageInfo[];
    genreList: genreList[],
    musicTypeList: musicTypeList[],
    equipment: string;
    coveragePostCode: string;
    registration: boolean;
    profile: boolean;
    bankaccount: boolean;
    feedbackTypeId: string;
    feedbackScore: string;
    feedbackComments: string;
    oooProtocol: string;
    serviceTypeList: any[];
    profileFlag: string;
}

export interface ContactPageFormData {
    id: string;
    name: string;
    businessEntityId: string;
    organisationTypeId: string;
    organisationType: {
        id: string;
        value: string;
    };
    contactTypeId: string;
    email: string;
    mobile: string;
    jobTitle: string;
    active: string;
    lastUpdatedOn: string;
    businessEntity: {
        id: string;
        shortName: string;
    };
    contactType: {
        id: string;
        value: string;
    };
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        };
        allowWebApp:string;
        disallowApp:string;
        disallowPortal:string;
    }
}

export interface VenuePageFormData {
    id: string;
    venueName: string;
    venueNameWOLocation: string;
    contact: {
        id: string;
        contactTypeLookUpId: string;
        active: string;
        contactTypeLookUp: {
            id: string;
            value: string;
        };
    };
    businessEntityId: string;
    businessEntity: {
        id: string;
        shortName: string;
    };
    generalManager: {
        id: string;
        value: string;
    };
    organisationTypeId: string;
    organisationType: {
        id: string;
        value: string;
    };
    email: string;
    phone: string;
    houseNumber: string;
    address: string;
    city: string;
    county: string;
    postCode: string;
    venueTypeId: string;
    venueType: {
        id: string;
        value: string;
    };
    criticalVenueInfo: string;
    division: string;
    brand: string;
    onboarded:string;
    dynamicsContactID: string;
    contactId: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
    user: {
        id: string;
        name: string;
        active: string;
        lastUpdatedOn: string;
        userRole: {
            id: string;
            value: string;
            parentMappingId: any;
        };
    };
    website: string;
    contractCleaningBusinessEntity: {
        id: string;
        shortName: string;
    };
    securityProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    gardeningProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    windowProviderBusinessEntity: {
        id: string;
        shortName: string;
    };
    primaryCleaningContact: {
        id: string;
        name: string;
    };
    primarySecurityContact: {
        id: string;
        name: string;
    };
    primaryGardeningContact: {
        id: string;
        name: string;
    };
    primaryWindowContact: {
        id: string;
        name: string;
    };
}

export default class artistProfileUtils {
    utilsForConversion: sharedUtils;
    constructor() {
        this.utilsForConversion = new sharedUtils();
        this.initializeArtistProfileItem = this.initializeArtistProfileItem.bind(this);
        this.convertFormDataToArtistProfileRequest = this.convertFormDataToArtistProfileRequest.bind(this);
        this.convertArtistProfileResponeToFormData = this.convertArtistProfileResponeToFormData.bind(this);
    }

    initializeArtistProfileItem(idFromGrid: string): ArtistPageFormData {
        var formData: ArtistPageFormData = {
            businessEntityID: "",
            businessEntityTypeLookUpId: "",
            businessEntityType: "",
            legalName: "",
            tradingName: "",
            shortName: "",
            businessEntityLegalStatusLookUpId: "",
            businessEntityLegalStatus: "",
            companyNumber: "",
            taxApplicable: "",
            taxVerified: "",
            taxVerifiedBy: "",
            taxVerifiedDateTime: "",
            verified: "",
            verifiedBy: "",
            verifiedDateTime: "",
            openingHours: "",
            webSite: "",
            officePhone: "",
            email: "",
            providerPaymentOffsetDays: "",
            taxRegistrationNumber: "",
            addressStreet1: "",
            addressStreet2: "",
            addressStreet3: "",
            city: "",
            state: "",
            country: "",
            postCode: "",
            professionalName: "",
            score: "",
            bio: "",
            shortDescription: "",
            coverageMiles: "",
            coverageTownCity: "",
            termsAgreed: false,
            termsAgreedDateTime: "",
            isActive: "true",
            trustedUser: "",
            hideProfileExternally:'',
            hideFromSource:'',
            agent:'',
            //artist:'',
            factored:'',
            updateEmail: false,
            businessEntityStatusId: "",
            businessEntityStatus: "",
            venueFavourite: "",
            lastPlayedVenue: "",
            lastUpdatedOn: '',
            lastUpdatedBy:'',
            onboardedDate:undefined,
            contactInformation: {
                contactId: "",
                name: "",
                mobileTelephone: "",
                businessTelephone: "",
                otherTelephone: "",
                email: "",
                addressStreet1: "",
                addressStreet2: "",
                city: "",
                state: "",
                postCode: "",
                emailNotification: "",
                dateOfBirth: undefined,
                utr: "",
                ni: "",
                optOut: "",
                active: "Yes",
                lastUpdatedOn: "",
            },
            user: {
                id: "",
                name: "",
                active: "Yes",
                lastUpdatedOn: "",
                allowWebApp: '',
                disallowApp: '',
                disallowPortal: ''
            },
            providerSiteManagerInformation: {
                contactId: "",
                title: "",
                firstName: "",
                lastName: "",
                name: "",
                mobileTelephone: "",
                businessTelephone: "",
                otherTelephone: "",
                email: "",
                addressStreet1: "",
                addressStreet2: "",
                city: "",
                state: "",
                postCode: "",
                emailNotification: "",
                dateOfBirth: undefined,
                utr: "",
                ni: "",
            },
            bankInformation: {
                id: "",
                businessEntityID: "",
                contactID: "",
                bankName: "",
                branchName: "",
                accountNumber: "",
                sortCode: "",
                buildingSociety: "",
                nameOnAccount: "",
                active: "",
                verified: "",
                verifiedBy: "",
                verifiedDateTime: "",
                createdDateTime: "",
                serviceTypeLookUpId: "",
                serviceSubTypeLookUpId: "",
            },
            requirePayment: "",
            requirePaymentComment: "",
            artistApart: "",
            linkInformation: [],
            typeList: [],
            businessEntityDocument: [],
            validationErrors: "",
            postSignUpMessages: [],
            genreList: [],
            musicTypeList: [],
            equipment: "",
            coveragePostCode: "",
            registration: false,
            profile: false,
            bankaccount: false,
            feedbackTypeId: "",
            feedbackScore: "",
            feedbackComments: "",
            oooProtocol: "",
            serviceTypeList: [],
            profileFlag: "",
        };
        return formData;
    }

    initializeContactPageItem(type:string): ContactPageFormData {
        let typeId = "";
        if(type == "ViewProviderContact"){
            typeId = "20";
        }
        else if(type == "ViewCompanyContact"){
            typeId = "30";
        }
        else {
            typeId = "10";
        }
        var formData: ContactPageFormData = {
            id: "",
            name: "",
            businessEntityId: "",
            organisationTypeId: typeId,
            organisationType: {
                id: "",
                value: "",
            },
            contactTypeId: "",
            email: "",
            mobile: "",
            jobTitle: "",
            active: "",
            lastUpdatedOn: "",
            businessEntity: {
                id: "",
                shortName: "",
            },
            contactType: {
                id: "",
                value: "",
            },
            user: {
                id: "",
                name: "",
                active: "",
                lastUpdatedOn: "",
                userRole: {
                    id: "",
                    value: "",
                    parentMappingId: null
                },
                allowWebApp: '',
                disallowApp: '',
                disallowPortal: ''
            }
        }
        return formData;
    }

    initializeVenuePageItem(): VenuePageFormData {
        var formData: VenuePageFormData = {
            id: "",
            venueName: "",
            venueNameWOLocation: "",
            contact: {
                id: "",
                contactTypeLookUpId: "93",
                active: "",
                contactTypeLookUp: {
                    id: "93",
                    value: "",
                },
            },            
            businessEntityId: "",
            businessEntity: {
                id: "",
                shortName: "",
            },
            generalManager: {
                id: "",
                value: "",
            },
            organisationType: {
                id: "10",
                value: "Client",
            },
            organisationTypeId: "10",
            email: "",
            phone: "",
            houseNumber: "",
            address: "",
            city: "",
            county: "",
            postCode: "",
            venueTypeId: "",
            venueType: {
                id: "",
                value: "",
            },
            criticalVenueInfo: "",
            division: "",
            brand: "",
            onboarded:'',
            dynamicsContactID: "",
            contactId: "",
            lastUpdatedOn: "",
            lastUpdatedBy: "",
            user: {
                id: "",
                name: "",
                active: "",
                lastUpdatedOn: "",
                userRole: {
                    id: "10",
                    value: "Venue Manager",
                    parentMappingId: null
                },
            },
            website: "",
            contractCleaningBusinessEntity: {
                id: "",
                shortName: "",
            },
            securityProviderBusinessEntity: {
                id: "",
                shortName: "",
            },
            gardeningProviderBusinessEntity: {
                id: "",
                shortName: "",
            },
            windowProviderBusinessEntity: {
                id: "",
                shortName: "",
            },
            primaryCleaningContact: {
                id: "",
                name: "",
            },
            primarySecurityContact: {
                id: "",
                name: "",
            },
            primaryGardeningContact: {
                id: "",
                name: "",
            },
            primaryWindowContact: {
                id: "",
                name: "",
            },
        }
        return formData;
    }

    // Convert the server response into the component's form state
    convertArtistProfileResponeToFormData(serverResponse: providerProfileResponse): ArtistPageFormData {
        var formData: ArtistPageFormData = {
            businessEntityID: serverResponse.businessEntityID,
            businessEntityTypeLookUpId: serverResponse.businessEntityTypeLookUpId,
            businessEntityType: serverResponse.businessEntityType,
            legalName: serverResponse.legalName,
            tradingName: serverResponse.tradingName,
            shortName: serverResponse.shortName,
            businessEntityLegalStatusLookUpId: serverResponse.businessEntityLegalStatusLookUpId,
            businessEntityLegalStatus: serverResponse.businessEntityLegalStatusLookUpId,
            companyNumber: serverResponse.companyNumber,
            taxApplicable: this.utilsForConversion.convertBooleanToString(serverResponse.taxApplicable),
            taxVerified: this.utilsForConversion.convertBooleanToString(serverResponse.taxVerified),
            taxVerifiedBy: serverResponse.taxVerifiedBy,
            taxVerifiedDateTime: serverResponse.taxVerifiedDateTime,
            verified: this.utilsForConversion.convertBooleanToString(serverResponse.verified),
            verifiedBy: serverResponse.verifiedBy,
            verifiedDateTime: serverResponse.verifiedDateTime,
            openingHours: serverResponse.openingHours,
            webSite: serverResponse.webSite,
            officePhone: serverResponse.officePhone,
            email: serverResponse.email,
            providerPaymentOffsetDays: serverResponse.providerPaymentOffsetDays,
            taxRegistrationNumber: serverResponse.taxRegistrationNumber,
            addressStreet1: serverResponse.addressStreet1,
            addressStreet2: serverResponse.addressStreet2,
            addressStreet3: serverResponse.addressStreet3,
            city: serverResponse.city,
            state: serverResponse.state,
            country: serverResponse.country,
            postCode: serverResponse.postCode,
            professionalName: serverResponse.professionalName,
            score: serverResponse.score,
            bio: serverResponse.bio,
            shortDescription: serverResponse.shortDescription,
            coverageMiles: serverResponse.coverageMiles,
            coverageTownCity: serverResponse.coverageTownCity,
            termsAgreed: Boolean(serverResponse.termsAgreed),
            termsAgreedDateTime: serverResponse.termsAgreedDateTime,
            isActive: serverResponse.isActive,
            trustedUser: this.utilsForConversion.convertBooleanToSpecificVal(serverResponse.trustedUser),
            hideProfileExternally:serverResponse.hideProfileExternally?'Yes':'No',
            hideFromSource:serverResponse.hideFromSource?'Yes':'No',
            agent:serverResponse.agent?'Yes':'No',
            //artist:serverResponse.artist ? 'Yes' : 'No',
            factored:serverResponse.factored?'Yes':'No',
            updateEmail: serverResponse.updateEmail,
            businessEntityStatusId: serverResponse.businessEntityStatusId,
            businessEntityStatus: serverResponse.businessEntityStatus,
            venueFavourite: serverResponse.venueFavourite,
            lastPlayedVenue: serverResponse.lastPlayedVenue,
            lastUpdatedOn:  serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            onboardedDate : serverResponse.onboardedDate ? this.utilsForConversion.convertStringToDate(serverResponse.onboardedDate):null,
            contactInformation: {
                contactId: serverResponse.contactInformation ? serverResponse.contactInformation.contactId : "",
                name: serverResponse.contactInformation ? serverResponse.contactInformation.name : "",
                mobileTelephone: serverResponse.contactInformation
                    ? serverResponse.contactInformation.mobileTelephone
                    : "",
                businessTelephone: serverResponse.contactInformation
                    ? serverResponse.contactInformation.businessTelephone
                    : "",
                otherTelephone: serverResponse.contactInformation
                    ? serverResponse.contactInformation.otherTelephone
                    : "",
                email: serverResponse.contactInformation ? serverResponse.contactInformation.email : "",
                addressStreet1: serverResponse.contactInformation
                    ? serverResponse.contactInformation.addressStreet1
                    : "",
                addressStreet2: serverResponse.contactInformation
                    ? serverResponse.contactInformation.addressStreet2
                    : "",
                city: serverResponse.contactInformation ? serverResponse.contactInformation.city : "",
                state: serverResponse.contactInformation ? serverResponse.contactInformation.state : "",
                postCode: serverResponse.contactInformation ? serverResponse.contactInformation.postCode : "",
                emailNotification: serverResponse.contactInformation
                    ? serverResponse.contactInformation.emailNotification
                    : "",
                dateOfBirth: serverResponse.contactInformation
                    ? this.utilsForConversion.convertStringToDate(serverResponse.contactInformation.dateOfBirth)
                    : null,
                utr: serverResponse.contactInformation ? serverResponse.contactInformation.utr : "",
                ni: serverResponse.contactInformation ? serverResponse.contactInformation.ni : "",
                optOut: serverResponse.contactInformation
                    ? this.utilsForConversion.convertBooleanToString(serverResponse.contactInformation.optOut)
                    : "",
                active: serverResponse.contactInformation
                    ? this.utilsForConversion.convertBooleanToString(serverResponse.contactInformation.active)
                    : "",
                lastUpdatedOn: serverResponse.contactInformation ? serverResponse.contactInformation.lastUpdatedOn : "",
            },
            user: {
                id: serverResponse.user?.id,
                name: serverResponse.user?.name,
                active: serverResponse.user?.active, 
                lastUpdatedOn: serverResponse.user?.lastUpdatedOn,
                allowWebApp: serverResponse.user?.allowWebApp?'Yes':'No', 
                disallowApp: serverResponse.user?.disallowApp?'Yes':'No', 
                disallowPortal: serverResponse.user?.disallowPortal?'Yes':'No', 
            },
            providerSiteManagerInformation: null,
            bankInformation: {
                id: serverResponse.bankInformation ? serverResponse.bankInformation.id : "",
                businessEntityID: serverResponse.bankInformation ? serverResponse.bankInformation.businessEntityID : "",
                contactID: serverResponse.bankInformation ? serverResponse.bankInformation.contactID : "",
                bankName: serverResponse.bankInformation ? serverResponse.bankInformation.bankName : "",
                branchName: serverResponse.bankInformation ? serverResponse.bankInformation.branchName : "",
                accountNumber: serverResponse.bankInformation ? serverResponse.bankInformation.accountNumber : "",
                sortCode: serverResponse.bankInformation ? serverResponse.bankInformation.sortCode : "",
                buildingSociety: serverResponse.bankInformation ? serverResponse.bankInformation.buildingSociety : "",
                nameOnAccount: serverResponse.bankInformation ? serverResponse.bankInformation.nameOnAccount : "",
                active: serverResponse.bankInformation ? serverResponse.bankInformation.active : "",                
                verified: serverResponse.bankInformation ? serverResponse.bankInformation.verified : "",
                verifiedBy: serverResponse.bankInformation ? serverResponse.bankInformation.verifiedBy : "",
                verifiedDateTime: serverResponse.bankInformation ? serverResponse.bankInformation.verifiedDateTime : "",
                createdDateTime: serverResponse.bankInformation ? serverResponse.bankInformation.createdDateTime : "",
                serviceTypeLookUpId: serverResponse.bankInformation ? serverResponse.bankInformation.serviceTypeLookUpId : "",
                serviceSubTypeLookUpId: serverResponse.bankInformation ? serverResponse.bankInformation.serviceSubTypeLookUpId : "",

            },
            linkInformation: serverResponse.linkInformation,
            requirePayment: serverResponse.requirePayment,
            requirePaymentComment: serverResponse.requirePaymentComment,
            artistApart: serverResponse.artistApart,
            typeList: serverResponse.typeList,
            businessEntityDocument: serverResponse.businessEntityDocument ? serverResponse.businessEntityDocument : [],
            validationErrors: serverResponse.validationErrors,
            postSignUpMessages: [],
            genreList: serverResponse.genreList,
            musicTypeList: serverResponse.musicTypeList,
            equipment: this.utilsForConversion.convertBooleanToString(serverResponse.equipment),
            coveragePostCode: serverResponse.coveragePostCode,
            registration: serverResponse.registration,
            profile: serverResponse.profile,
            bankaccount: serverResponse.bankaccount,
            feedbackTypeId: serverResponse.feedbackTypeId,
            feedbackScore: serverResponse.feedbackScore,
            feedbackComments: serverResponse.feedbackComments,
            oooProtocol: serverResponse.oooProtocol,
            serviceTypeList: serverResponse.serviceTypeList,
            profileFlag: serverResponse.profileFlag,
        };
        return formData;
    }

    // Convert the form's data into a proper Artist Profile submit request
    convertFormDataToArtistProfileRequest(formData: ArtistPageFormData, type:string): providerProfileRequest {
        let taxVerifiedDateTime = JSON.parse('null');
        var submitRequest: providerProfileRequest = {
            businessEntityID: formData.businessEntityID,
            businessEntityTypeLookUpId: formData.businessEntityTypeLookUpId,
            businessEntityType: formData.businessEntityType,
            legalName: formData.legalName,
            tradingName: formData.tradingName,
            shortName: formData.shortName,
            businessEntityLegalStatusLookUpId: formData.businessEntityLegalStatusLookUpId,
            businessEntityLegalStatus: formData.businessEntityLegalStatus,
            companyNumber: formData.companyNumber,
            taxApplicable: this.utilsForConversion.convertSpecificStringValToBoolean(formData.taxApplicable),
            taxVerified: this.utilsForConversion.convertSpecificStringValToBoolean(formData.taxVerified),
            taxVerifiedBy: formData.taxVerifiedBy,
            taxVerifiedDateTime: formData.taxVerifiedDateTime == "" ? taxVerifiedDateTime : formData.taxVerifiedDateTime,
            verified: this.utilsForConversion.convertSpecificStringValToBoolean(formData.verified),
            verifiedBy: formData.verifiedBy,
            verifiedDateTime: formData.verifiedDateTime == "" ? taxVerifiedDateTime : formData.verifiedDateTime,
            openingHours: formData.openingHours,
            onboardedDate:this.utilsForConversion.convertDateToString(formData.onboardedDate),
            webSite: formData.webSite,
            officePhone: formData.officePhone,
            email: formData.email,
            providerPaymentOffsetDays: formData.providerPaymentOffsetDays,
            taxRegistrationNumber: formData.taxRegistrationNumber,
            addressStreet1: formData.addressStreet1,
            addressStreet2: formData.addressStreet2,
            addressStreet3: formData.addressStreet3,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            postCode: formData.postCode,
            professionalName: formData.professionalName,
            score: formData.score,
            bio: formData.bio,
            shortDescription: formData.shortDescription,
            coverageMiles: formData.coverageMiles,
            coverageTownCity: formData.coverageTownCity ? formData.coverageTownCity : "",
            termsAgreed: formData.termsAgreed,
            businessEntityStatusId: formData.businessEntityStatusId,
            businessEntityStatus: formData.businessEntityStatus,
            venueFavourite: formData.venueFavourite,
            lastPlayedVenue: formData.lastPlayedVenue,
            termsAgreedDateTime: formData.termsAgreedDateTime,
            isActive: formData.isActive,
            trustedUser: this.utilsForConversion.convertSpecificStringValToBoolean(formData.trustedUser),
            hideProfileExternally:this.utilsForConversion.convertSpecificStringValToBoolean(formData.hideProfileExternally),
            hideFromSource:this.utilsForConversion.convertSpecificStringValToBoolean(formData.hideFromSource),
            agent:this.utilsForConversion.convertSpecificStringValToBoolean(formData.agent),
            //artist : this.utilsForConversion.convertSpecificStringValToBoolean(formData.artist),
            factored:this.utilsForConversion.convertSpecificStringValToBoolean(formData.factored),
            updateEmail: formData.updateEmail ? formData.updateEmail : false,
            contactInformation: formData.contactInformation
                ? {
                    contactId: formData.contactInformation.contactId,
                    name: formData.contactInformation.name,
                    mobileTelephone: formData.contactInformation.mobileTelephone,
                    businessTelephone: formData.contactInformation.businessTelephone,
                    otherTelephone: formData.contactInformation.otherTelephone,
                    email: formData.contactInformation.email,
                    addressStreet1: formData.contactInformation.addressStreet1,
                    addressStreet2: formData.contactInformation.addressStreet2,
                    city: formData.contactInformation.city,
                    state: formData.contactInformation.state,
                    postCode: formData.contactInformation.postCode,
                    emailNotification: formData.contactInformation.emailNotification,
                    dateOfBirth: this.utilsForConversion.convertDateToString(formData.contactInformation.dateOfBirth),
                    utr: formData.contactInformation.utr,
                    ni: formData.contactInformation.ni,
                    optOut: this.utilsForConversion.convertSpecificStringValToBoolean(formData.contactInformation.optOut),
                    active: this.utilsForConversion.convertSpecificStringValToBoolean(formData.contactInformation.active),
                    lastUpdatedOn: formData.contactInformation.lastUpdatedOn,
                }
                : null,
            user: formData.user
                ? {
                id: formData.user?.id,
                name: formData.user?.name,
                active: formData.user?.active == "Yes" || formData.user?.active == "True" ? "True" : "False", 
                lastUpdatedOn: formData.user?.lastUpdatedOn,
                allowWebApp:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.allowWebApp),
                disallowApp:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.disallowApp),
                disallowPortal:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.disallowPortal)
            } : null,
            providerSiteManagerInformation: null,
            bankInformation: formData.bankInformation,
            requirePayment: formData.requirePayment,
            requirePaymentComment: formData.requirePaymentComment,
            artistApart: formData.artistApart,
            linkInformation: formData.linkInformation,
            typeList: formData.typeList,
            businessEntityDocument: [],
            validationErrors: null,
            postSignUpMessages: [],
            genreList: formData.genreList,
            musicTypeList: formData.musicTypeList,
            equipment: this.utilsForConversion.convertSpecificStringValToBoolean(formData.equipment),
            coveragePostCode: formData.coveragePostCode,
            registration: formData.registration,
            profile: formData.profile,
            bankaccount: formData.bankaccount,
            feedbackTypeId: formData.feedbackTypeId,
            feedbackScore: formData.feedbackScore,
            feedbackComments: formData.feedbackComments,
            oooProtocol: formData.oooProtocol,
            serviceTypeList: type == "Entertainment" ? [] : formData.serviceTypeList,
            profileFlag: formData.profileFlag,
        };

        return submitRequest;
    }

    // Convert the form's data into a proper Venue profile request
    convertFormDataToVenueProfileRequest(formData: VenuePageFormData): venueProfileRequest {
        var submitRequest: venueProfileRequest = {
            id: formData.id,
            venueName: formData.venueName,
            venueNameWOLocation: formData.venueNameWOLocation,
            contact: {
                id: formData.id,
                contactTypeLookUpId: formData.contact?.contactTypeLookUpId,
                active: formData.contact?.active == "Yes" || formData.contact?.active == "True" ? "True" : "False", 
                contactTypeLookUp: {
                    id: formData.contact?.contactTypeLookUp?.id,
                    value: formData.contact?.contactTypeLookUp?.value,
                },
            },            
            businessEntityId: formData.businessEntityId,
            businessEntity: {
                id: formData.businessEntity?.id,
                shortName: formData.businessEntity?.shortName,
            },
            generalManager: {
                id: formData.generalManager.id,
                value: formData.generalManager.value,
            },
            organisationType: {
                id: "10",
                value: "Client",
            },
            organisationTypeId: formData.organisationTypeId,
            email: formData.email,
            phone: formData.phone,
            houseNumber: formData.houseNumber,
            address: formData.address,
            city: formData.city,
            county: formData.county,
            postCode: formData.postCode,
            venueTypeId: formData.venueTypeId,
            venueType: {
                id: formData.venueType.id,
                value: formData.venueType.value,
            },
            criticalVenueInfo: formData.criticalVenueInfo,
            division: formData.division,
            brand: formData.brand,
            onboarded:this.utilsForConversion.convertSpecificStringValToBoolean(formData.onboarded),
            dynamicsContactID: formData.dynamicsContactID,
            contactId: formData.contactId,
            lastUpdatedOn: formData.lastUpdatedOn,
            lastUpdatedBy: formData.lastUpdatedBy,
            user: {
                id: formData.user?.id,
                name: formData.user?.name,
                active: formData.user?.active == "Yes" || formData.user?.active == "True" ? "True" : "False", 
                lastUpdatedOn: formData.user?.lastUpdatedOn,
                userRole: {
                    id: formData.user?.userRole?.id ? formData.user?.userRole?.id : "",
                    value: formData.user?.userRole?.value ? formData.user?.userRole?.value : "",
                    parentMappingId: formData.user?.userRole?.parentMappingId ? formData.user?.userRole?.parentMappingId : ""
                },
            },
            website: formData.website,
            contractCleaningBusinessEntity: {
                id: formData.contractCleaningBusinessEntity?.id,
                shortName: formData.contractCleaningBusinessEntity?.shortName,
            },
            securityProviderBusinessEntity: {
                id: formData.securityProviderBusinessEntity?.id,
                shortName: formData.securityProviderBusinessEntity?.shortName,
            },
            gardeningProviderBusinessEntity: {
                id: formData.gardeningProviderBusinessEntity?.id,
                shortName: formData.gardeningProviderBusinessEntity?.shortName,
            },
            windowProviderBusinessEntity: {
                id: formData.windowProviderBusinessEntity?.id,
                shortName: formData.windowProviderBusinessEntity?.shortName,
            },
            primaryCleaningContact: {
                id: formData.primaryCleaningContact?.id,
                name: formData.primaryCleaningContact?.name,
            },
            primarySecurityContact: {
                id: formData.primarySecurityContact?.id,
                name: formData.primarySecurityContact?.name,
            },
            primaryGardeningContact: {
                id: formData.primaryGardeningContact?.id,
                name: formData.primaryGardeningContact?.name,
            },
            primaryWindowContact: {
                id: formData.primaryWindowContact?.id,
                name: formData.primaryWindowContact?.name,
            },
        }
        return submitRequest;
    }

    // Convert the server response into the component's form state
    convertVenueProfileResponeToFormData(serverResponse: venueProfileRequest): VenuePageFormData {
        var formData: VenuePageFormData = {
            id: serverResponse.id,
            venueName: serverResponse.venueName,
            venueNameWOLocation: serverResponse.venueNameWOLocation,
            contact: {
                id: serverResponse.id,
                contactTypeLookUpId: serverResponse.contact?.contactTypeLookUpId,
                active: serverResponse.contact?.active,
                contactTypeLookUp: {
                    id: serverResponse.contact?.contactTypeLookUp?.id,
                    value: serverResponse.contact?.contactTypeLookUp?.value,
                },
            },
            businessEntityId: serverResponse.businessEntityId,
            businessEntity: {
                id: serverResponse.businessEntity?.id,
                shortName: serverResponse.businessEntity?.shortName,
            },   
            generalManager: {
                id: serverResponse.generalManager?.id,
                value: serverResponse.generalManager?.value,
            },        
            organisationType: {
                id: serverResponse.organisationType?.id,
                value: serverResponse.organisationType?.value,
            },
            organisationTypeId: serverResponse.organisationTypeId,
            email: serverResponse.email,
            phone: serverResponse.phone,
            houseNumber: serverResponse.houseNumber,
            address: serverResponse.address,
            city: serverResponse.city,
            county: serverResponse.county,
            postCode: serverResponse.postCode,
            venueTypeId: serverResponse.venueTypeId,
            venueType: {
                id: serverResponse.venueType?.id,
                value: serverResponse.venueType?.value,
            },
            criticalVenueInfo: serverResponse.criticalVenueInfo,
            division: serverResponse.division,
            brand: serverResponse.brand,
            onboarded : serverResponse.onboarded ? 'Yes' : 'No',
            dynamicsContactID: serverResponse.dynamicsContactID,
            contactId: serverResponse.contactId,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            lastUpdatedBy: serverResponse.lastUpdatedBy,
            user: {
                id: serverResponse.user?.id,
                name: serverResponse.user?.name,
                active: serverResponse.user?.active, 
                lastUpdatedOn: serverResponse.user?.lastUpdatedOn,
                userRole: {
                    id: serverResponse.user?.userRole?.id,
                    value: serverResponse.user?.userRole?.value,
                    parentMappingId: serverResponse.user?.userRole?.parentMappingId
                },
            },
            website: serverResponse.website,
            contractCleaningBusinessEntity: {
                id: serverResponse.contractCleaningBusinessEntity?.id,
                shortName: serverResponse.contractCleaningBusinessEntity?.shortName,
            },
            securityProviderBusinessEntity: {
                id: serverResponse.securityProviderBusinessEntity?.id,
                shortName: serverResponse.securityProviderBusinessEntity?.shortName,
            },
            gardeningProviderBusinessEntity: {
                id: serverResponse.gardeningProviderBusinessEntity?.id,
                shortName: serverResponse.gardeningProviderBusinessEntity?.shortName,
            },
            windowProviderBusinessEntity: {
                id: serverResponse.windowProviderBusinessEntity?.id,
                shortName: serverResponse.windowProviderBusinessEntity?.shortName,
            },
            primaryCleaningContact: {
                id: serverResponse.primaryCleaningContact?.id,
                name: serverResponse.primaryCleaningContact?.name,
            },
            primarySecurityContact: {
                id: serverResponse.primarySecurityContact?.id,
                name: serverResponse.primarySecurityContact?.name,
            },
            primaryGardeningContact: {
                id: serverResponse.primaryGardeningContact?.id,
                name: serverResponse.primaryGardeningContact?.name,
            },
            primaryWindowContact: {
                id: serverResponse.primaryWindowContact?.id,
                name: serverResponse.primaryWindowContact?.name,
            },
        }
        return formData;
    }

    // Convert the form's data into a proper Contact profile request
    convertFormDataToContactProfileRequest(formData: ContactPageFormData): ContactProfileRequest {
        var submitRequest: ContactProfileRequest = {
            id: formData.id,
            name: formData.name,
            businessEntityId: formData.businessEntityId,
            organisationTypeId: formData.organisationTypeId,
            organisationType: {
                id: formData.organisationTypeId,
                value: formData.organisationType.value,
            },
            contactTypeId: formData.contactTypeId,
            email: formData.email,
            mobile: formData.mobile,
            jobTitle: formData.jobTitle,
            active: formData.active == "Yes" || formData.active == "True" ? "True" : "False",
            lastUpdatedOn: formData.lastUpdatedOn,
            businessEntity: {
                id: formData.businessEntity.id,
                shortName: formData.businessEntity.shortName,
            },
            contactType: {
                id: formData.contactType.id,
                value: formData.contactType.value,
            },
            user: {
                id: formData.user?.id ? formData.user?.id : "",
                name: formData.user?.name ? formData.user?.name : "",
                active: formData.user?.active == "Yes" || formData.user?.active == "True" ? "True" : "False",
                lastUpdatedOn: formData.user?.lastUpdatedOn ? formData.user?.lastUpdatedOn : "",
                userRole: {
                    id: formData.user?.userRole?.id ? formData.user?.userRole?.id : "",
                    value: formData.user?.userRole?.value ? formData.user?.userRole?.value : "",
                    parentMappingId: formData.user?.userRole?.parentMappingId ? formData.user?.userRole?.parentMappingId : null
                },
                allowWebApp:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.allowWebApp),
                disallowApp:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.disallowApp),
                disallowPortal:this.utilsForConversion.convertSpecificStringValToBoolean(formData.user?.disallowPortal)
            }
        }
        return submitRequest;
    }

    // Convert the server response into the component's form state
    convertContactProfileResponeToFormData(serverResponse: ContactProfileRequest): ContactPageFormData {
        var formData: ContactPageFormData = {
            id: serverResponse.id,
            name: serverResponse.name,
            businessEntityId: serverResponse.businessEntityId,
            organisationTypeId: serverResponse.organisationTypeId,
            organisationType: {
                id: serverResponse.organisationType.id,
                value: serverResponse.organisationType.value,
            },
            contactTypeId: serverResponse.contactTypeId,
            email: serverResponse.email,
            mobile: serverResponse.mobile,
            jobTitle: serverResponse.jobTitle,
            active: serverResponse.active,
            lastUpdatedOn: serverResponse.lastUpdatedOn,
            businessEntity: {
                id: serverResponse.businessEntity.id,
                shortName: serverResponse.businessEntity.shortName,
            },
            contactType: {
                id: serverResponse.contactType.id,
                value: serverResponse.contactType.value,
            },
            user: {
                id: serverResponse.user?.id,
                name: serverResponse.user?.name,
                active: serverResponse.user?.active,
                lastUpdatedOn: serverResponse.user?.lastUpdatedOn,
                userRole: {
                    id: serverResponse.user?.userRole?.id,
                    value: serverResponse.user?.userRole?.value,
                    parentMappingId: serverResponse.user?.userRole?.parentMappingId
                },
                allowWebApp: serverResponse.user?.allowWebApp?'Yes':'No', 
                disallowApp: serverResponse.user?.disallowApp?'Yes':'No', 
                disallowPortal: serverResponse.user?.disallowPortal?'Yes':'No',
            }
        }
        return formData;
    }
}
