import React from "react";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { AxiosResponse } from "axios";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import ArtistCard from "./ArtistCard";
import gridUtils from "../grid/GridUtilities";
import FindAnArtistService, {
    artistCardUIType,
    ArtistProfileResponse,
    RequestStatus,
    RequestStatusTypes,
    ShowInterestStatusIdTypes,
    ShowInterestStatusTypes,
    ShowInterestType,
} from "../../services/FindAnArtistService";
import ArtistUtilities, { RequestFormData, StatusDetail, VenueChoiceDetail, filterObject, subTypeCount } from "./FindAnArtistUtils";
import NewRequestForm from "./NewRequestForm";
import { alert } from "devextreme/ui/dialog";
import lookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import RestrictRouter from "./RestrictRouter";
import GlobalService from "../../services/GlobalService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

interface ArtistCardListProps {
    // artistCards: ArtistProfileResponse[],
    cardType: string;
    openForm: boolean;
    location: any;
    history: any;
    viewAll: () => void;
    supplyTypesListToParent: (modifiedListOfTypes: subTypeCount[]) => void;
    pendingRequest: boolean;
    requestId: string;
    typeListObject: filterObject;
    countOftypes: number;
    firstTimeRequest: boolean;
    requestStatus: string;
    postCode: string;
    distance: string;
    parentComponentImageLink: (imageLink: string) => void;
    parentAddArtist: (isAddArtist: boolean) => void;
    updateSearchButton: (flag: boolean) => void;
    getVenue: (client: string, venue: string) => void;
    resetRefreshGig: (flag: boolean) => void;
    isShowPopUp: boolean;
    isNavigateFindAnArtist: boolean;
    searchFieldVal: string;
    typeList: string;
    isClickArtistSearch: boolean;
    isClickSearchButton: boolean;
    artistAdded: string;
    trustedValue: boolean;
    rating: number;
    isRefreshButtonClicked?: boolean;
}

interface ArtistCardListState {
    showProgressIndicator: boolean;
    listOfRequestedCards: string[];
    listOfFavoritedCards: string[];
    cards: ArtistProfileResponse[];
    filteredArtistCards: ArtistProfileResponse[];
    count: number;
    artistTotalData: ShowInterestType[];
    eventUKIndicator: boolean;
    statusObjList: StatusDetail[];
    venueChoiceObjList: VenueChoiceDetail[];
    errorMessage: [];
    reload: boolean;
    disableUpdateButton: boolean;
    disableBookButton: boolean;
    isSetOfferedStatus: boolean;
    isShowOfferedError: boolean;
    bookedArtistList: StatusDetail[];
    invalidArtistList: StatusDetail[];
    alreadyBookedArtistList: ShowInterestType[];
    requestSubmitted: boolean;
    typeListObjectArray: filterObject[];
    updateClickFlag: boolean;
    cardsLoaded: boolean;
    statusLookUp: LookupTypeItem[];
    loadPanelVisible: boolean;
    listOfArtists: [],
    statusId: string;
    fetchedCard: number;
    isBlocking: boolean;
    confirmAdd: boolean;
    isUpdateSuccess: boolean;
    makeOffAmtForBookedArtist: string;
    disabledButtonOnProcessing: boolean;
    isAddArtist: boolean;
    reBookData: any;
    reBookSuccess: boolean;
    requestAmountCalculateData: any;
    isRecalculate: boolean;
}

class ArtistCardList extends React.Component<ArtistCardListProps> {
    state: ArtistCardListState;
    lookUpService: lookupService;
    gridUtils: gridUtils;
    utils: ArtistUtilities;
    service: FindAnArtistService;
    //State object to manage the state of the List of cards.
    // state: ArtistCardListState
    //Service that fetches the data for the individual cards.
    updateBtnClicked: boolean;
    constructor(props: ArtistCardListProps) {
        super(props);
        //Initializing the service.
        // this.cardService = new CheckInCardService();
        this.updateBtnClicked = true;
        this.service = new FindAnArtistService();
        this.lookUpService = new lookupService();
        this.utils = new ArtistUtilities();
        this.state = {
            fetchedCard: 0,
            isBlocking: false,
            showProgressIndicator: false,
            listOfRequestedCards: [],
            listOfFavoritedCards: [],
            cards: [],
            filteredArtistCards: [],
            count: 0,
            artistTotalData: [],
            eventUKIndicator:
                UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
                UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager),
            statusObjList: [],
            venueChoiceObjList: [],
            errorMessage: [],
            reload: false,
            disableUpdateButton: false,
            disableBookButton: false,
            isSetOfferedStatus: false,
            isShowOfferedError: false,
            bookedArtistList: [],
            invalidArtistList: [],
            alreadyBookedArtistList: [],
            requestSubmitted: false,
            updateClickFlag: false,
            cardsLoaded: false,
            statusLookUp: [],
            typeListObjectArray: [
                {
                    serviceSubtype: "DJ",
                    types: [],
                    count: 0,
                },
                {
                    serviceSubtype: "Live Music",
                    types: [],
                    count: 0,
                },
                {
                    serviceSubtype: "Karaoke",
                    types: [],
                    count: 0,
                },
                {
                    serviceSubtype: "Pub Quiz",
                    types: [],
                    count: 0,
                },
            ],
            loadPanelVisible: false,
            listOfArtists: [],
            statusId: "",
            confirmAdd: false,
            isUpdateSuccess: false,
            makeOffAmtForBookedArtist: "",
            isAddArtist: false,
            disabledButtonOnProcessing: true,
            reBookData: {},
            reBookSuccess: false,
            requestAmountCalculateData: {},
            isRecalculate: false,
        };
        this.gridUtils = new gridUtils();
    }

    componentDidMount() {
        this.setState({
            showProgressIndicator: false,
        });
        this.fetchShowInterestLookup();

    }

    fetchShowInterestLookup = () => {
        this.lookUpService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.showintereststatus)
            .then(this.handleStatusLookUpSuccess)
            .catch();
    }

    handleStatusLookUpSuccess = (response: AxiosResponse<any>) => {
        // if (response && response.data && response.data.data && response.data.data && response.data.data.length > 0) {
        //     var cardDetailsAsPerDate = response.data.data.filter(
        //         (item: LookupTypeItem) => item.value === this.state.statusDetail
        //     );
        //     var statId = cardDetailsAsPerDate && cardDetailsAsPerDate.length > 0 && cardDetailsAsPerDate[0].id;
        this.setState({
            statusLookUp: response.data.data
        });
    }

    fetchArtistDetails = () => {
        let { postCode, distance, searchFieldVal, typeList, trustedValue, rating } = this.props;
        if ((this.props.requestId && this.props.requestId == "0") && (this.props.location.state.navigateFrom != "FindAnArtistMenu")) {
            this.setState({
                showProgressIndicator: false,
                cardsLoaded: false,
            });
        }
        else if ((!this.props.requestId || this.state.isAddArtist)) {
            this.setState({
                showProgressIndicator: true,
                cardsLoaded: false,
            });
            if (postCode || distance || searchFieldVal || typeList || !trustedValue || trustedValue || rating > 0 || rating==0) {
                this.service.getListOfArtists(postCode, distance, searchFieldVal, typeList, trustedValue, rating).then(this.handleSuccess).catch(this.handleFailure);
            }
            else {
                this.setState({
                    showProgressIndicator: false,
                    cardsLoaded: false,
                });
            }
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                showProgressIndicator: false,
                cards: response.data.data,
                filteredArtistCards: response.data.data,
                cardsLoaded: true,
            },
            () => this.setFavoritedCards()
        );
    };

    setFavoritedCards = () => {
        var listOfFavoritedCards: string[] = [];
        for (var item = 0; item < this.state.cards.length; item++) {
            if (this.state.cards[item].venueFavourite.toString().toLowerCase() == "true") {
                listOfFavoritedCards.push(this.state.cards[item].businessEntityID);
            }
        }
        this.setState({
            listOfFavoritedCards: listOfFavoritedCards,
        });
    };

    handleFailure = () => {
        this.setState({
            showProgressIndicator: false,
        });
    };

    // A callback that receives the values from the card component and builds the List of Requested Cards.
    buildListOfRequestedCards = (businessEntityID: string) => {
        var listOfRequestedCards: string[] = [];
        listOfRequestedCards = this.state.listOfRequestedCards;
        listOfRequestedCards.push(businessEntityID);
        this.setState({
            listOfRequestedCards: listOfRequestedCards,
        });
    };

    buildListOfFavoritedCards = (businessEntityID: string, favourited: string) => {
        var listOfFavoritedCards: string[] = [];
        listOfFavoritedCards = this.state.listOfFavoritedCards;
        if (favourited.toString().toLowerCase() == "true") {
            listOfFavoritedCards.push(businessEntityID);
        } else {
            const index = listOfFavoritedCards.indexOf(businessEntityID);
            if (index > -1) {
                listOfFavoritedCards.splice(index, 1);
            }
        }
        this.setState({
            listOfFavoritedCards: listOfFavoritedCards,
            count: listOfFavoritedCards.length,
        });
    };

    renderCards = () => {
        var typeList: filterObject[] = this.state.typeListObjectArray;
        var newListOfTypes: filterObject[] = [];
        newListOfTypes = this.utils.updateTypeListArray(typeList, this.props.typeListObject);
        var countOfTypesintheList: number = this.utils.countOfTypes(newListOfTypes);

        if (this.props.cardType == artistCardUIType.Requested) {
            var filteredRequestedCards: ArtistProfileResponse[] = this.utils.filterViewAllIntoRequests(
                this.state.cards,
                this.state.listOfRequestedCards
            );
            var sortedCards: ArtistProfileResponse[] = this.utils.sortCardsBasedOnStarRating(filteredRequestedCards);
            var filteredCards: ArtistProfileResponse[] = this.utils.filterArtistCardsBasedOnType(
                sortedCards,
                newListOfTypes
            );
            // var modifiedListOfTypes: filterObject[] = this.utils.countCardsBasedOnType(filteredCards , newListOfTypes);
            var modifiedListOfTypes: subTypeCount[] = this.utils.countCardsBasedOnType(filteredCards, newListOfTypes);
            this.props.supplyTypesListToParent(modifiedListOfTypes);
            this.setState({
                filteredArtistCards:
                    filteredCards && filteredCards.length > 0
                        ? filteredCards
                        : countOfTypesintheList > 0
                            ? []
                            : sortedCards,
            });
        } else if (this.props.cardType == artistCardUIType.All) {
            var filteredCards: ArtistProfileResponse[] = this.utils.filterArtistCardsBasedOnType(
                this.state.cards,
                newListOfTypes
            );
            // var modifiedListOfTypes: filterObject[] = this.utils.countCardsBasedOnType(filteredCards , newListOfTypes);
            var modifiedListOfTypes: subTypeCount[] = this.utils.countCardsBasedOnType(filteredCards, newListOfTypes);
            this.props.supplyTypesListToParent(modifiedListOfTypes);
            this.setState({
                filteredArtistCards:
                    filteredCards && filteredCards.length > 0
                        ? filteredCards
                        : countOfTypesintheList > 0
                            ? []
                            : this.state.cards,
            });
        } else if (this.props.cardType == artistCardUIType.Favorites) {
            var filteredFavoritedCards: ArtistProfileResponse[] = this.utils.filterViewAllIntoRequests(
                this.state.cards,
                this.state.listOfFavoritedCards
            );
            var sortedCards: ArtistProfileResponse[] = this.utils.sortCardsBasedOnStarRating(filteredFavoritedCards);
            var filteredCards: ArtistProfileResponse[] = this.utils.filterArtistCardsBasedOnType(
                sortedCards,
                newListOfTypes
            );
            // var modifiedListOfTypes: filterObject[] = this.utils.countCardsBasedOnType(filteredCards , newListOfTypes);
            var modifiedListOfTypes: subTypeCount[] = this.utils.countCardsBasedOnType(filteredCards, newListOfTypes);
            this.props.supplyTypesListToParent(modifiedListOfTypes);
            this.setState({
                filteredArtistCards:
                    filteredCards && filteredCards.length > 0
                        ? filteredCards
                        : countOfTypesintheList > 0
                            ? []
                            : sortedCards,
            });
        }
    };

    componentDidUpdate = (prevprops: ArtistCardListProps, prevState: ArtistCardListState) => {
        // if (this.props.isClickArtistSearch == true && 
        //     ((this.props.cardType != prevprops.cardType &&
        //         this.props.pendingRequest &&
        //         this.props.cardType == artistCardUIType.All && this.props.location.state.navigateFrom == "FindAnArtistMenu") ||
        //     this.props.postCode != prevprops.postCode || this.props.trustedValue != prevprops.trustedValue || this.props.rating != prevprops.rating ||
        //     this.props.distance != prevprops.distance || this.props.searchFieldVal != prevprops.searchFieldVal || this.props.typeList != prevprops.typeList)
        // ) {
        //     this.fetchArtistDetails();
        if(this.props.isClickSearchButton && this.props.isClickSearchButton != prevprops.isClickSearchButton){
            this.fetchArtistDetails();
            this.props.updateSearchButton(false);
        } else if(this.props.isClickArtistSearch && this.props.isClickArtistSearch != prevprops.isClickArtistSearch && (this.props.typeList != "" 
            || this.props.searchFieldVal != "" || !this.props.trustedValue || this.props.trustedValue || this.props.rating == 0 || this.props.rating > 0)){
            this.fetchArtistDetails();
            this.props.updateSearchButton(false);
        } else if (
            this.props.cardType != prevprops.cardType ||
            (this.state.count != prevState.count && this.props.cardType == artistCardUIType.Favorites) ||
            this.props.countOftypes != prevprops.countOftypes ||
            this.props.typeListObject.serviceSubtype != prevprops.typeListObject.serviceSubtype
        ) {
            this.renderCards();
        }
        // if (((this.props.location && this.props.location.state) && (this.props.location.state.navigateFrom != prevprops.location.state.navigateFrom))) {
        //     this.fetchArtistDetails();
        //     this.renderCards();
        // }
        if (this.props.artistAdded !== prevprops.artistAdded) {
            this.fetchArtistDetails();
        }
    };

    renderRedirect = () => {
        this.props.viewAll();
        this.setState({
            requestSubmitted: true,
            listOfRequestedCards: [],
        });
    };

    fetchArtists = (
        artistData: ShowInterestType[],
        alreadyBookedList: ShowInterestType[],
        isUpdateClicked: boolean
    ) => {
        var artProfItem: ArtistProfileResponse;
        var artProfArray: ArtistProfileResponse[];
        artProfArray = [];
        this.updateBtnClicked = true;
        artistData && artistData.length > 0
            ? artistData.map((item: ShowInterestType) => {
                if (item.artistDetails) {
                    let artistCardDetails = item.artistDetails;
                    artistCardDetails.makeOfferAmount = item.makeOfferAmount;
                    artistCardDetails.comments = item.comments;
                    artistCardDetails.addJobDetail = item.addJobDetail ? item.addJobDetail : "";
                    artProfItem = artistCardDetails;
                }
                artProfArray.push(artProfItem);
            })
            : null
        var newRequestedCardsForPendingRequest =
            !isUpdateClicked && this.state.listOfRequestedCards && this.state.listOfRequestedCards.length > 0
                ? this.mapNewRequestedCards()
                : null;
        newRequestedCardsForPendingRequest && newRequestedCardsForPendingRequest.length > 0
            ? newRequestedCardsForPendingRequest.map((item: ArtistProfileResponse) => {
                item.addJobDetail = item.addJobDetail ? item.addJobDetail : "";
                artProfArray.push(item);
            })
            : null
        this.setState({
            filteredArtistCards: artProfArray,
            artistTotalData: artistData,
            alreadyBookedArtistList: alreadyBookedList && alreadyBookedList.length > 0 ? alreadyBookedList : [],
            fetchedCard: artistData.length
        });
        if (this.state.fetchedCard !== this.state.filteredArtistCards.length) {
            this.setState({ isBlocking: true })
        }
    };
    mapNewRequestedCards = (): ArtistProfileResponse[] => {
        var filteredRequestedCards: ArtistProfileResponse[] = this.utils.filterViewAllIntoRequests(
            this.state.cards,
            this.state.listOfRequestedCards
        );
        var filteredCards: ArtistProfileResponse[] = this.utils.sortCardsBasedOnStarRating(filteredRequestedCards);
        return filteredCards;
    };
    buildStatusList = (statusObj: StatusDetail, makeOfferAmount: string) => {
        var listOfArtists: StatusDetail[] = [];
        listOfArtists = this.state.statusObjList;

        listOfArtists =
            listOfArtists && listOfArtists.length > 0
                ? listOfArtists.filter((item: StatusDetail) => {
                    return item.businessEntityId !== statusObj.businessEntityId;
                })
                : [];
        listOfArtists.push(statusObj);
        this.setState(
            {
                statusObjList: listOfArtists
            },
            () => this.disableUpdateButton(listOfArtists)
        );
        this.setState({
            statusId: statusObj.showIntereststatus,
            makeOffAmtForBookedArtist: makeOfferAmount
        });
    };
    buildVenueChoice = (choiceObj: VenueChoiceDetail) => {
        let choiceData: VenueChoiceDetail[] = [];
        choiceData = this.state.venueChoiceObjList;
        choiceData =
            choiceData && choiceData.length > 0
                ? choiceData.filter((item: VenueChoiceDetail) => {
                    return item.businessEntityId !== choiceObj.businessEntityId;
                })
                : [];
        choiceData.push(choiceObj);
        this.setState(
            {
                venueChoiceObjList: choiceData
            },
        );
    };
    disableUpdateButton = (listOfArtists: StatusDetail[]) => { 
        var invalidList =
            listOfArtists && listOfArtists.length > 0
                ? listOfArtists.filter((item: StatusDetail) => {
                    return (
                        (item.showInterestPreviousStatus == ShowInterestStatusTypes.Requested &&
                            item.showIntereststatus == ShowInterestStatusIdTypes.Applied) ||
                        (item.showInterestPreviousStatus == ShowInterestStatusTypes.Requested &&
                            item.showIntereststatus == ShowInterestStatusIdTypes.Suggested) ||
                        (item.showInterestPreviousStatus == ShowInterestStatusTypes.Suggested &&
                            item.showIntereststatus == ShowInterestStatusIdTypes.Requested) ||
                        (item.showInterestPreviousStatus == ShowInterestStatusTypes.Applied &&
                            item.showIntereststatus == ShowInterestStatusIdTypes.Requested) ||
                        item.showIntereststatus == ShowInterestStatusIdTypes.Booked
                    );
                })
                : [];
        var bookedList =
            listOfArtists && listOfArtists.length > 0
                ? listOfArtists.filter((item: StatusDetail) => {
                    return item.showIntereststatus == ShowInterestStatusIdTypes.Booked;
                })
                : [];
        let offeredList = 
            listOfArtists && listOfArtists.length > 0
            ? listOfArtists.filter((item: StatusDetail) => {
                return item.showIntereststatus == ShowInterestStatusIdTypes.Offered;
            })
            : [];            
        this.setState({
            // disableUpdateButton: invalidList && invalidList.length > 0 ? true : false,
            invalidArtistList: invalidList && invalidList.length > 0 ? invalidList : [],
            //disableBookButton: (bookedList && bookedList.length >= 2) || bookedList.length == 0 ? true : false,
            listOfArtists: listOfArtists,
            bookedArtistList: bookedList && bookedList.length > 0 ? bookedList : [],
            isSetOfferedStatus: offeredList && offeredList.length > 0 ? true : false,
            isShowOfferedError: offeredList && offeredList.length > 1 ? true : false,
        });
    };
    // fetch artist name of duplicate venue choice
    getArtistName = (showInterestListDuplicateChoice: any) => {
        let name = this.state.filteredArtistCards.find(
            (item) => item.businessEntityID === showInterestListDuplicateChoice.providerBusinessEntityId
        );
        return name;
    }
    onSubmit = (requestFormData: RequestFormData, showInterestList: ShowInterestType[], statusId: string, requestType: string) => {
        let gigType = GlobalService.getGigComingFrom();
        var newRequestSubmitRequest = gigType !== "Entertainment" ? gigType == "Security" ? this.utils.createUpdateRequestSecurity(requestFormData, "") : this.utils.createUpdateRequestCleaning(requestFormData, "") : this.utils.createUpdateRequest(requestFormData, showInterestList, "");
        const myObjStr = JSON.stringify(newRequestSubmitRequest);
        this.setState({
            loadPanelVisible: true
        });
        // check duplicate value of venue choice for each card
        const showInterestListDuplicateChoice: any = showInterestList.find((choice, index) => {
            return showInterestList.find((x, ind) => x.selectedOrderNumber !== "" && x.selectedOrderNumber === choice.selectedOrderNumber && index !== ind)
        })
        if (showInterestListDuplicateChoice == undefined || showInterestListDuplicateChoice.length == 0) {
            this.setState({ isBlocking: false })
            //passing object data to the server
            if (statusId == RequestStatus.Published) {
                this.service
                    .updateArtistRequest(this.props.requestId, newRequestSubmitRequest)
                    .then(() => { this.handleUpdateSuccess(statusId, requestFormData) })
                    .catch(this.handleUpdateFailure);
            }
            else {
                if(gigType == "Security" && requestType == "securityBook"){
                    newRequestSubmitRequest.requestStatusId = RequestStatus.Booked;
                    newRequestSubmitRequest.requestStatusValue = RequestStatusTypes.Booked;
                }
                if(gigType == "Entertainment" && statusId == "22"){
                    newRequestSubmitRequest.requestStatusId = RequestStatus.Offered;
                }
                this.service
                    .updateArtistRequest(this.props.requestId, newRequestSubmitRequest)
                    .then(() => { this.handleUpdateSuccess() })
                    .catch(this.handleUpdateFailure);
            }
        }
        else {
            let duplicateCardName = this.getArtistName(showInterestListDuplicateChoice)?.professionalName;
            this.setState({
                loadPanelVisible: false
            });
            alert("For artist" + " " + duplicateCardName + " " + "the Venue Choice has been duplicated with another card", "Error").then(() => {
            });
        }
    };
    onBookSubmit = (requestFormData: RequestFormData, showInterestList: ShowInterestType[], requestType: string) => {
        //this.showLoadpanel();
        let gigType = GlobalService.getGigComingFrom();
        var newRequestSubmitRequest =  gigType !== "Entertainment" ? gigType == "Security" ? this.utils.createBookRequestSecurity(requestFormData) : this.utils.createBookRequestCleaning(requestFormData) : this.utils.createBookRequest(requestFormData, showInterestList);
        const myObjStr = JSON.stringify(newRequestSubmitRequest);
        this.setState({ isBlocking: false })
        //passing object data to the server
        if(requestType == "book"){
            this.service
                .bookArtistRequest(this.props.requestId, newRequestSubmitRequest)
                .then(this.handleBookSuccess)
                .catch(this.handleUpdateFailure);
        }
        else if(requestType == "re-book"){
            this.service
                .updateArtistRequest(this.props.requestId, newRequestSubmitRequest)
                .then(this.handleReBookSuccess)
                .catch(this.handleUpdateFailure);
        }
    };
    handleBookSuccess = () => {
        this.setState((prevState: ArtistCardListState) => ({
            reload: !prevState.reload,
            errorMessage: [],
            listOfRequestedCards: [],
            disabledButtonOnProcessing: false,
        }), () => {
            notify({ message: "Request has been booked successfully!!", width: 300, shading: true, position: "center" }, 'success', 600);
            //call the API for handling the response.
            this.renderPendingPage();
        })
    };
    handleReBookSuccess = (response: AxiosResponse<any>) => {
        this.setState((prevState: ArtistCardListState) => ({
            reload: !prevState.reload,
            errorMessage: [],
            listOfRequestedCards: [],
            disabledButtonOnProcessing: false,
            reBookData: response.data.data,
            reBookSuccess: true,
        }))        
    };
    handleUpdateSuccess = (statusId?: string, requestFormData?: RequestFormData) => {
        if (statusId && statusId == RequestStatus.Published && requestFormData) {
            let type = requestFormData?.typeLookUpId ? requestFormData.typeLookUpId : "";
            let publishRequest = this.utils.mapPublishRequest(type);
            this.service.publishStatus(requestFormData.id, publishRequest)
                .then(this.handlSuccessFormSubmission)
                .catch(this.handleUpdateFailure)
        }
        else {
            this.handlSuccessFormSubmission();
        }

    };

    handlSuccessFormSubmission = () => {
        this.setState({
            errorMessage: [],
            listOfRequestedCards: [],
            loadPanelVisible: false,
            isUpdateSuccess: true,
            disabledButtonOnProcessing: false,
        }, () => { !this.state.confirmAdd || this.updateBtnClicked ? this.showAlert() : this.showArtistUpdateAlert() })
    }

    changeReBookSuccess = (flag:boolean) => {
        this.setState({reBookSuccess: flag})
    }

    changeDisableButton = (flag:boolean) => {
        this.setState({disabledButtonOnProcessing: flag})
    }

    isRecalculateChange = (flag:boolean) => {
        this.setState({isRecalculate: flag})
    }

    showAlert = () => {
        notify({ message: "Request updated successfully!!", width: 300, shading: true, position: "center" }, 'success', 600);
        //call the API for handling the response.
        this.props.history.goBack();
    }

    showArtistUpdateAlert = () => {
        notify({ message: "Request updated successfully!!", width: 300, shading: true, position: "center" }, 'success', 1000);
    }

    renderPendingPage = () => {
        this.props.history.goBack();
    };
    handleUpdateFailure = (err: any) => {
        this.setState({
            loadPanelVisible: false,
            disabledButtonOnProcessing: false,
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null,
        });
    };
    bookArtist = (requestFormData: RequestFormData, showInterestList: ShowInterestType[], requestType: string) => {
        var showInterestItem: ShowInterestType;
        var showInterestArray: ShowInterestType[];
        showInterestArray = [];
        // showInterestList && showInterestList.length > 0 && this.state.listOfArtists.length > 0
        //     ? this.state.listOfArtists.map((item: StatusDetail) => {
        //         showInterestItem = {
        //             id: this.getId(showInterestList, item),
        //             providerBusinessEntityId: item.businessEntityId,
        //             requestId: this.getRequestId(showInterestList, item),
        //             showInterestStatusId: item.showIntereststatus,
        //             makeOfferAmount: this.getOfferAmount(showInterestList, item),
        //             comments: this.getComments(showInterestList, item)
        //         };
        //         showInterestArray.push(showInterestItem);
        //     })
        //     : null;
        showInterestList && showInterestList.length > 0
            ? showInterestList.map((item: ShowInterestType) => {
                showInterestItem = {
                    id: item.id,
                    providerBusinessEntityId: item.providerBusinessEntityId,
                    requestId: item.requestId,
                    showInterestStatusId: this.getStatus(this.state.statusObjList, item),
                    makeOfferAmount: item.makeOfferAmount,
                    comments: item.comments,
                    selectedOrderNumber: this.getVenueItem(this.state.venueChoiceObjList, item)
                };
                showInterestArray.push(showInterestItem);
            })
            : null;
        this.onBookSubmit(requestFormData, showInterestArray, requestType);
    };
    requestBookData = (requestFormData: RequestFormData, showInterestList: ShowInterestType[]) => {
        var showInterestItem: ShowInterestType;
        var showInterestArray: ShowInterestType[];
        showInterestArray = [];
        showInterestList && showInterestList.length > 0
            ? showInterestList.map((item: ShowInterestType) => {
                showInterestItem = {
                    id: item.id,
                    providerBusinessEntityId: item.providerBusinessEntityId,
                    requestId: item.requestId,
                    showInterestStatusId: this.getStatus(this.state.statusObjList, item),
                    makeOfferAmount: item.makeOfferAmount,
                    comments: item.comments,
                    selectedOrderNumber: this.getVenueItem(this.state.venueChoiceObjList, item)
                };
                showInterestArray.push(showInterestItem);
            })
            : null;
            let newRequestSubmitRequest =  this.utils.createUpdateRequest(requestFormData, showInterestArray, "");
            this.setState({
                requestAmountCalculateData: newRequestSubmitRequest,
                isRecalculate: true,
            })
    };
    getId = (showIntList: ShowInterestType[], bookData: StatusDetail): any => {
        var resultArray: ShowInterestType[] = [];
        resultArray =
            showIntList && showIntList.length > 0
                ? showIntList.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == bookData.businessEntityId;
                })
                : [];
        var showIntid = resultArray && resultArray.length > 0 && resultArray[0].id ? resultArray[0].id : null;
        return showIntid;
    };
    getRequestId = (showIntList: ShowInterestType[], bookData: StatusDetail): any => {
        var resultArray: ShowInterestType[] = [];
        resultArray =
            showIntList && showIntList.length > 0
                ? showIntList.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == bookData.businessEntityId;
                })
                : [];
        var reqId = resultArray && resultArray.length > 0 && resultArray[0].requestId ? resultArray[0].requestId : null;
        return reqId;
    };
    getOfferAmount = (showIntList: ShowInterestType[], bookData: StatusDetail): any => {
        let resultArray: ShowInterestType[] = [];
        resultArray =
            showIntList && showIntList.length > 0
                ? showIntList.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == bookData.businessEntityId;
                })
                : [];
        return resultArray && resultArray.length > 0 && resultArray[0].makeOfferAmount ? resultArray[0].makeOfferAmount : null;
    };
    getComments = (showIntList: ShowInterestType[], bookData: StatusDetail): any => {
        let resultArray: ShowInterestType[] = [];
        resultArray =
            showIntList && showIntList.length > 0
                ? showIntList.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == bookData.businessEntityId;
                })
                : [];
        return resultArray && resultArray.length > 0 && resultArray[0].comments ? resultArray[0].comments : null;
    };
    updateArtist = (
        requestFormData: RequestFormData,
        showInterestList: ShowInterestType[],
        updateClickIndicator: boolean,
        statusId: string,
        requestType: string
    ) => {
        var showInterestItem: ShowInterestType;
        var showInterestArray: ShowInterestType[];
        showInterestArray = [];
        showInterestList && showInterestList.length > 0
            ? showInterestList.map((item: ShowInterestType) => {
                showInterestItem = {
                    id: item.id,
                    providerBusinessEntityId: item.providerBusinessEntityId,
                    requestId: item.requestId,
                    showInterestStatusId: this.getStatus(this.state.statusObjList, item),
                    makeOfferAmount: item.makeOfferAmount,
                    comments: item.comments,
                    selectedOrderNumber: this.getVenueItem(this.state.venueChoiceObjList, item)
                };
                showInterestArray.push(showInterestItem);
            })
            : null;
        this.setState({
            updateClickFlag: updateClickIndicator,
        });
        this.onSubmit(requestFormData, showInterestArray, statusId, requestType);
    };

    getStatus = (artistCards: StatusDetail[] | undefined, showIntObj: ShowInterestType): any => {
        var resultArray: StatusDetail[] = [];
        resultArray =
            artistCards && artistCards.length > 0
                ? artistCards.filter((item: StatusDetail) => {
                    return item.businessEntityId == showIntObj.providerBusinessEntityId;
                })
                : [];
        var status =
            resultArray && resultArray.length > 0 && resultArray[0].showIntereststatus
                ? resultArray[0].showIntereststatus
                : showIntObj.showInterestStatusId;
        return status;
    };

    getVenueItem = (choiceObj: VenueChoiceDetail[] | undefined, showIntObj: ShowInterestType): any => {
        let resultArray: VenueChoiceDetail[] = [];
        resultArray =
            choiceObj && choiceObj.length > 0
                ? choiceObj.filter((item: VenueChoiceDetail) => {
                    return item.businessEntityId == showIntObj.providerBusinessEntityId;
                })
                : [];
        let choice =
            resultArray && resultArray.length > 0 && resultArray[0].selectedOrderNumber
                ? String(resultArray[0].selectedOrderNumber)
                : showIntObj.selectedOrderNumber;
        return choice;
    };

    imageLink = (displayLink: string) => {
        this.props.parentComponentImageLink(displayLink);
    }

    showCard = (businessEntId: string): boolean => {
        var showFlag = true;
        if (this.props.pendingRequest && !this.state.eventUKIndicator) {
            var status = this.getArtistStatus(this.state.artistTotalData, businessEntId);
            if (status == ShowInterestStatusTypes.Applied || status == ShowInterestStatusTypes.Removed) {
                showFlag = false;
            }
        }
        return showFlag;
    };
    handleNavPopUpClose = (flag: boolean) => {
        this.setState({
            isBlocking: flag,
        });
    }
    handleConfirmAdd = (flag: boolean) => {
        this.setState({
            confirmAdd: flag,
        });
        this.updateBtnClicked = false;
    }
    getArtistStatus = (artistCards: ShowInterestType[] | undefined, businessEntityID: string): string => {
        var resultArray: ShowInterestType[] = [];
        resultArray =
            businessEntityID && artistCards && artistCards.length > 0
                ? artistCards.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == businessEntityID;
                })
                : [];
        var status =
            resultArray && resultArray.length > 0 && resultArray[0].showInterestStatus
                ? resultArray[0].showInterestStatus
                : "";
        return status;
    };

    addArtist = (isAddArtist: boolean) => {
        this.setState({
            isAddArtist: isAddArtist
        }, () => { this.props.parentAddArtist(isAddArtist) });
    }

    // filter artist card added in the gig request on the basis of status 
    filterArtistCardCategory = () => {
        let result: ArtistProfileResponse[] = [];
        result = this.state.filteredArtistCards && this.state.filteredArtistCards.length > 0
            ? this.state.filteredArtistCards.filter((item1: ArtistProfileResponse) => {
                let index = this.state.artistTotalData.findIndex((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == item1.businessEntityID;
                })
                return item1.businessEntityID == this.state.artistTotalData[index]?.providerBusinessEntityId && (this.state.artistTotalData[index]?.showInterestStatusId == "35" || this.state.artistTotalData[index]?.showInterestStatusId == "36"
                    || this.state.artistTotalData[index]?.showInterestStatusId == "37" || this.state.artistTotalData[index]?.showInterestStatusId == "40" || this.state.artistTotalData[index]?.showInterestStatusId == "22")
            })
            : [];
        return result;
    };

    filterArtistCardOtherCategory = () => {
        let result: ArtistProfileResponse[] = [];
        result = this.state.filteredArtistCards && this.state.filteredArtistCards.length > 0
            ? this.state.filteredArtistCards.filter((item1: ArtistProfileResponse) => {
                let index = this.state.artistTotalData.findIndex((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == item1.businessEntityID;
                })
                return item1.businessEntityTypeLookUpId == null || (item1.businessEntityID == this.state.artistTotalData[index]?.providerBusinessEntityId && (this.state.artistTotalData[index]?.showInterestStatusId !== "35" && this.state.artistTotalData[index]?.showInterestStatusId !== "36"
                    && this.state.artistTotalData[index]?.showInterestStatusId !== "37" && this.state.artistTotalData[index]?.showInterestStatusId !== "40"  && this.state.artistTotalData[index]?.showInterestStatusId !== "22"))
            })
            : [];
        return result;
    };

    filterArtistdiffCategory = (category: ArtistProfileResponse[], othercategory: ArtistProfileResponse[]) => {
        // && this.state.cardsLoaded
        //  && this.state.cardsLoaded
        return <>
            {category.length > 0 ?
                (
                    <>
                        <h3 className="card-form__heading font-weight-bold">Shortlisted / Booked / Offered / Accepted / Declined</h3>
                        <section className="row mb-6">
                            {category.map((cardItem: ArtistProfileResponse) => {
                                return (
                                    <div
                                        key={cardItem.businessEntityID}
                                        className="col col-12 col-md-6 col-lg-6 col-xl-4 mt-4"
                                    >
                                        <ArtistCard
                                            artistCardData={cardItem}
                                            buildListOfRequestedCards={this.buildListOfRequestedCards}
                                            buildListOfFavoritedCards={this.buildListOfFavoritedCards}
                                            listOfRequestedCards={this.state.listOfRequestedCards}
                                            listOfFavoritedCards={this.state.listOfFavoritedCards}
                                            totalArtistDetails={this.state.artistTotalData}
                                            filterData={this.state.filteredArtistCards}
                                            pendingRequestFlag={this.props.pendingRequest}
                                            eventUKIndicator={this.state.eventUKIndicator}
                                            buildStatusList={this.buildStatusList}
                                            buildVenueChoice={this.buildVenueChoice}
                                            refreshSignal={this.state.reload}
                                            requestSubmitted={this.state.requestSubmitted}
                                            cardUIType={this.props.cardType}
                                            requestStatus={this.props.requestStatus}
                                            isNavigateFindAnArtist={this.props.isNavigateFindAnArtist}
                                            showInterestStatusLookup={this.state.statusLookUp}
                                            handleNavPopUpClose={this.handleNavPopUpClose}
                                            handleConfirmAdd={this.handleConfirmAdd} 
                                            isShowOfferedError={this.state.isShowOfferedError}/>
                                    </div>
                                );
                            })}
                        </section>
                    </>
                ) : null}
            {othercategory.length > 0 ?
                (
                    <>
                        <h3 className="card-form__heading font-weight-bold">Other Applicants</h3>
                        <section className="row mb-6">
                            {othercategory.map((cardItem: ArtistProfileResponse) => {
                                return (
                                    <div
                                        key={cardItem.businessEntityID}
                                        className="col col-12 col-md-6 col-lg-6 col-xl-4 mt-4"
                                    >
                                        <ArtistCard
                                            artistCardData={cardItem}
                                            buildListOfRequestedCards={this.buildListOfRequestedCards}
                                            buildListOfFavoritedCards={this.buildListOfFavoritedCards}
                                            listOfRequestedCards={this.state.listOfRequestedCards}
                                            listOfFavoritedCards={this.state.listOfFavoritedCards}
                                            totalArtistDetails={this.state.artistTotalData}
                                            filterData={this.state.filteredArtistCards}
                                            pendingRequestFlag={this.props.pendingRequest}
                                            eventUKIndicator={this.state.eventUKIndicator}
                                            buildStatusList={this.buildStatusList}
                                            buildVenueChoice={this.buildVenueChoice}
                                            refreshSignal={this.state.reload}
                                            requestSubmitted={this.state.requestSubmitted}
                                            cardUIType={this.props.cardType}
                                            requestStatus={this.props.requestStatus}
                                            isNavigateFindAnArtist={this.props.isNavigateFindAnArtist}
                                            showInterestStatusLookup={this.state.statusLookUp}
                                            handleNavPopUpClose={this.handleNavPopUpClose}
                                            handleConfirmAdd={this.handleConfirmAdd} 
                                            isShowOfferedError={this.state.isShowOfferedError}/>
                                    </div>
                                );
                            })}
                        </section>
                    </>
                ) : null}
        </>;
    }

    renderFilteredCategory = (category: ArtistProfileResponse[], othercategory: ArtistProfileResponse[]) => {
        return category.length > 0 || othercategory.length > 0 ?
            (
                this.filterArtistdiffCategory(category, othercategory)
            ) : ( null

                // <div className="row col-lg-12">
                //     <div className="col text-center">
                //         Please click <b>search icon</b>  <FontAwesomeIcon icon={faSearch} /> or <b>search button</b> to find artists
                //     </div>

                // </div>

            );
    }

    artistCardsList = (filteredListOfArtistCards: ArtistProfileResponse[]) => {
        return filteredListOfArtistCards.length > 0 && this.state.cardsLoaded ?
            (
                <section className="row mb-6">
                    {filteredListOfArtistCards.map((cardItem: ArtistProfileResponse) => {
                        return (
                            <div
                                key={cardItem.businessEntityID}
                                className="col col-12 col-md-6 col-lg-6 col-xl-4 mt-4"
                            >
                                <ArtistCard
                                    artistCardData={cardItem}
                                    filterData={this.state.filteredArtistCards}
                                    buildListOfRequestedCards={this.buildListOfRequestedCards}
                                    buildListOfFavoritedCards={this.buildListOfFavoritedCards}
                                    listOfRequestedCards={this.state.listOfRequestedCards}
                                    listOfFavoritedCards={this.state.listOfFavoritedCards}
                                    totalArtistDetails={this.state.artistTotalData}
                                    pendingRequestFlag={this.props.pendingRequest}
                                    eventUKIndicator={this.state.eventUKIndicator}
                                    buildStatusList={this.buildStatusList}
                                    buildVenueChoice={this.buildVenueChoice}
                                    refreshSignal={this.state.reload}
                                    requestSubmitted={this.state.requestSubmitted}
                                    cardUIType={this.props.cardType}
                                    requestStatus={this.props.requestStatus}
                                    isNavigateFindAnArtist={this.props.isNavigateFindAnArtist}
                                    showInterestStatusLookup={this.state.statusLookUp}
                                    handleNavPopUpClose={this.handleNavPopUpClose}
                                    handleConfirmAdd={this.handleConfirmAdd} 
                                    isShowOfferedError={this.state.isShowOfferedError}/>
                            </div>
                        );
                    })}
                </section>
            ) : (
                <div className="row col-lg-12 mt-3">
                    <div className="col text-center"> Please click search icon  <div className="filter-box__search-icon">
                        <FontAwesomeIcon icon={faSearch} className="svg-search"/> <span className="sr-only">Submit Search</span></div> 
                        &nbsp;or search button <div className="btn-search-icon">SEARCH</div>&nbsp;
                        to find artists
                    </div>

                </div>
            );
    }


    render() {
        let listOfArtistCards: ArtistProfileResponse[] = this.state.filteredArtistCards;
        let filteredListOfArtistCards: ArtistProfileResponse[] = listOfArtistCards;
        let category: ArtistProfileResponse[] = this.filterArtistCardCategory();
        let othercategory: ArtistProfileResponse[] = this.filterArtistCardOtherCategory();
        let disableBook =
            (this.state.bookedArtistList && this.state.bookedArtistList.length > 1) ||
                this.state.bookedArtistList.length == 0 ||
                this.props.requestStatus == RequestStatusTypes.Booked
                ? true
                : false;
        let disableUpdate =
            (this.state.invalidArtistList && this.state.invalidArtistList.length > 0) ||
                this.state.alreadyBookedArtistList.length > 0
                ? true
                : false;

        return this.state.showProgressIndicator ? (
            <div className="row col-lg-12">
                <span className="col-lg-12 text-center">
                    <LoadIndicator
                        id="simple-grid-indicator"
                        height={100}
                        width={100}
                        visible={this.state.showProgressIndicator}
                    />
                </span>
            </div>
        )
            :
            (

                <>
                    <div>
                        {this.props.openForm ? (
                            <>
                                <RestrictRouter
                                    when={this.state.isBlocking}
                                    location={this.props.location}
                                    history={this.props.history}
                                    isNavigate={() => { return null }}
                                />
                                <NewRequestForm
                                    location={this.props.location}

                                    history={this.props.history}
                                    artistBusList={this.state.listOfRequestedCards}
                                    renderRedirect={this.renderRedirect}
                                    pendingRequestFlag={this.props.pendingRequest}
                                    eventUKIndicator={this.state.eventUKIndicator}
                                    requestId={this.props.requestId}
                                    fetchArtistInformation={this.fetchArtists}
                                    updateArtist={this.updateArtist}
                                    bookArtist={this.bookArtist}
                                    requestBookData={this.requestBookData}
                                    requestAmountCalculateData={this.state.requestAmountCalculateData}
                                    isRecalculate={this.state.isRecalculate}
                                    isRecalculateChange={this.isRecalculateChange}
                                    refreshSignal={this.state.reload}
                                    errorMessage={this.state.errorMessage}
                                    disableUpdateButton={disableUpdate}
                                    disableBookButton={disableBook}
                                    firstTimeRequest={this.props.firstTimeRequest}
                                    cardUIType={this.props.cardType}
                                    statusId={this.state.statusId}
                                    imageLink={this.imageLink}
                                    addArtist={this.addArtist}
                                    getVenue={this.props.getVenue}
                                    confirmAdd={this.state.confirmAdd}
                                    isUpdateSuccess={this.state.isUpdateSuccess}
                                    makeoffAmtForBookedArtist={this.state.makeOffAmtForBookedArtist}
                                    disabledButtonOnProcessing={this.state.disabledButtonOnProcessing}
                                    isSetOfferedStatus={this.state.isSetOfferedStatus}
                                    isRefreshButtonClicked={this.props.isRefreshButtonClicked}
                                    resetRefreshGig={this.props.resetRefreshGig}
                                    reBookData={this.state.reBookData}
                                    reBookSuccess={this.state.reBookSuccess}
                                    changeReBookSuccess={this.changeReBookSuccess}
                                    changeDisableButton={this.changeDisableButton}
                                ></NewRequestForm>
                            </>
                        ) : null}
                    </div>
                    <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                    {!this.props.isShowPopUp && (this.props.location?.state?.navigation == "gigRequestAdd" || this.props.location?.state?.navigation == "gigRequestEdit") ?
                        (
                            this.renderFilteredCategory(category, othercategory)
                        ) : (
                            this.artistCardsList(filteredListOfArtistCards)
                        )

                    }
                </>
            );
    }
}

export default ArtistCardList;
