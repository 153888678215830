import React from "react";
import StarRating from "./starRating";
import FavouriteButton from "./favouriteButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageLines } from "@fortawesome/pro-solid-svg-icons";
import { faBuilding, faEnvelope, faPhone, faPiggyBank, faUser, faRibbon } from "@fortawesome/free-solid-svg-icons";
import gridUtils from "../grid/GridUtilities";
import { confirm } from "devextreme/ui/dialog";
import moment from "moment";
import FindAnArtistService, {
    ArtistProfileResponse,
    ShowInterestType,
    ShowInterestStatusTypes,
    ShowInterestStatusIdTypes,
    artistCardUIType,
    RequestStatusTypes,
    PhotoTypes,
} from "../../services/FindAnArtistService";
import ArtistUtilities, { StatusDetail, VenueChoiceDetail } from "./FindAnArtistUtils";
import "../../../assets/images/logo-icon-white-landscape.svg";
import { AxiosResponse } from "axios";
import { LoadIndicator, LoadPanel, Popup, ScrollView, SelectBox, NumberBox } from "devextreme-react";
import { SelectBoxOnValueChangedEvent, NumberBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem } from "../../services/LookupService";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import { feedbackScoreType } from "../Outstanding-Actions/OutstandingActionsUtilities";
import { faFaceThinking, faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import sharedUtils from "../grid/sharedUtilities";

interface ArtistCardProps {
    artistCardData: ArtistProfileResponse;
    buildListOfRequestedCards: (businessEntityID: string) => void;
    buildListOfFavoritedCards: (businessEntityID: string, favourited: string) => void;
    listOfRequestedCards: string[];
    listOfFavoritedCards: string[];
    totalArtistDetails?: ShowInterestType[];
    filterData?:ArtistProfileResponse[];
    pendingRequestFlag?: boolean;
    eventUKIndicator?: boolean;
    buildStatusList?: (statusDetail: StatusDetail,makeOfferAmount: string) => void;
    buildVenueChoice?: (venueChoiceDetail: VenueChoiceDetail) => void;
    disableUpdateButton?: () => void;
    refreshSignal?: boolean;
    requestSubmitted?: boolean;
    cardUIType?: string;
    requestStatus?: string;
    isNavigateFindAnArtist: boolean;
    showInterestStatusLookup: LookupTypeItem[];
    handleNavPopUpClose: (isPopupClose: boolean) => void;
    handleConfirmAdd: (flag: boolean) => void;
    isShowOfferedError?: boolean;
}

interface ArtistCardState {
    alterButtonState: boolean;
    artistDataItem: ArtistProfileResponse;
    showProgressIndicator: boolean;
    statusDetail: string | null | undefined;
    venueChoiceDetail: string | null | undefined;
    statusText: string;
    previousStatusText: string;
    statusLookUp: LookupTypeItem[];
    resetSubmit: boolean;
    popupVisible: boolean;
    statusColor: string;
    onClickCross: boolean;
    selectedOrderNumber: number;
    notificationSentDate: string;
    allowNotification: boolean;
    isNotificationSuccess: boolean;
    triggerNotificationAPI: boolean;
    statusObj: any;
    showAddArtistPopup: boolean;
    loadPanelVisible: boolean;
}

class ArtistCard extends React.Component<ArtistCardProps> {
    conversionUtils: sharedUtils;
    utils: ArtistUtilities;
    gridUtils: gridUtils;
    service: FindAnArtistService;
    state: ArtistCardState;
    lookUpService: LookupService;
    isEditMode: string;
    constructor(props: ArtistCardProps) {
        super(props);
        this.conversionUtils = new sharedUtils();
        this.utils = new ArtistUtilities();
        this.isEditMode = "";
        this.state = {
            alterButtonState: false,
            artistDataItem: this.props.artistCardData,
            showProgressIndicator: false,
            statusDetail: "",
            venueChoiceDetail: "",
            statusText: "",
            previousStatusText: "",
            statusLookUp: [],
            resetSubmit: false,
            popupVisible: false,
            statusColor: "",
            onClickCross: false,
            selectedOrderNumber: 0,
            notificationSentDate: "",
            allowNotification: false,
            isNotificationSuccess: false,
            triggerNotificationAPI: false,
            statusObj: {},
            showAddArtistPopup: false,
            loadPanelVisible: false,
        };
        this.gridUtils = new gridUtils();
        this.service = new FindAnArtistService();
        this.lookUpService = new LookupService();
    }

    componentDidMount() {
        if (this.props.pendingRequestFlag) {
            var status = this.getStatus(this.props.totalArtistDetails, this.props.artistCardData.businessEntityID);
            let venueChoice = this.getVenueChoice(this.props.totalArtistDetails, this.props.artistCardData.businessEntityID);
            let notificationDetails:any = this.getNotificationDetails(this.props.filterData, this.props.artistCardData.businessEntityID);
            this.setState({
                statusDetail: status,
                venueChoiceDetail: venueChoice,
                selectedOrderNumber: venueChoice,
                allowNotification: notificationDetails?.allowNotification,
                notificationSentDate: notificationDetails?.addJobNotificationSentTime,
                isNotificationSuccess:notificationDetails?.isNotificationSuccess,
            }, () => { this.setStatusVal(this.props.showInterestStatusLookup) });
            //     this.lookUpService
            //         .getLookupByLookupTypeIndex(LookupTypeIndexes.showintereststatus)
            //         .then(this.handleStatusLookUpSuccess)
            //         .catch(this.handleFailure);
        }
    }

    componentDidUpdate = (prevProps: ArtistCardProps, prevState: ArtistCardState) => {
        if (this.props.refreshSignal != prevProps.refreshSignal) {
            this.setStatus(this.state.statusText);
        }
        if (this.props.totalArtistDetails != prevProps.totalArtistDetails) {
            var status = this.getStatus(this.props.totalArtistDetails, this.props.artistCardData.businessEntityID);
            let venueChoice = this.getVenueChoice(this.props.totalArtistDetails, this.props.artistCardData.businessEntityID);
            let notificationDetails:any = this.getNotificationDetails(this.props.filterData, this.props.artistCardData.businessEntityID);
            this.setState({
                statusDetail: status,
                venueChoiceDetail: venueChoice,
                selectedOrderNumber: venueChoice,
                allowNotification: notificationDetails?.allowNotification,
                notificationSentDate: notificationDetails?.addJobNotificationSentTime,
                isNotificationSuccess:notificationDetails?.isNotificationSuccess,
            }, () => { this.setStatusVal(this.props.showInterestStatusLookup) });
        }
        
    };

    setStatus = (statusText: any) => {
        var text = "";
        if (this.state.statusText == ShowInterestStatusIdTypes.Requested) {
            text = ShowInterestStatusTypes.Requested;
        } else if (this.state.statusText == ShowInterestStatusIdTypes.Suggested) {
            text = ShowInterestStatusTypes.Suggested;
        } else if (this.state.statusText == ShowInterestStatusIdTypes.Applied) {
            text = ShowInterestStatusTypes.Applied;
        } else if (this.state.statusText == ShowInterestStatusIdTypes.Booked) {
            text = ShowInterestStatusTypes.Booked;
        } else if (this.state.statusText == ShowInterestStatusIdTypes.Removed) {
            text = ShowInterestStatusTypes.Removed;
        } else if (this.state.statusText == ShowInterestStatusIdTypes.Unavailable) {
            text = ShowInterestStatusTypes.Unavailable;
        }
        this.setState({ statusDetail: text });
    };

    getStatus = (artistCards: ShowInterestType[] | undefined, businessEntityID: string): string => {
        var resultArray: ShowInterestType[] = [];
        resultArray =
            businessEntityID && artistCards && artistCards.length > 0
                ? artistCards.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == businessEntityID;
                })
                : [];
        var status =
            resultArray && resultArray.length > 0 && resultArray[0].showInterestStatus
                ? resultArray[0].showInterestStatus
                : "";
        return status;
    };

    getVenueChoice = (artistCards: ShowInterestType[] | undefined, businessEntityID: string): number => {
        let resultArray: ShowInterestType[] = [];
        resultArray =
            businessEntityID && artistCards && artistCards.length > 0
                ? artistCards.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == businessEntityID;
                })
                : [];
        let choice =
            resultArray && resultArray.length > 0 && resultArray[0].selectedOrderNumber
                ? resultArray[0].selectedOrderNumber
                : null;
        return Number(choice);
    };
    
    getShowIntrestId = (artistCards: ShowInterestType[] | undefined, businessEntityID: string): any => {        
        let resultArray: ShowInterestType[] = [];
        resultArray =
            businessEntityID && artistCards && artistCards.length > 0
                ? artistCards.filter((item: ShowInterestType) => {
                    return item.providerBusinessEntityId == businessEntityID;
                })
                : [];
        let showInterestId =
            resultArray && resultArray.length > 0 && resultArray[0].id
                ? resultArray[0].id
                : "";
        let requestId = resultArray && resultArray.length > 0 && resultArray[0].requestId
        ? resultArray[0].requestId
        : "";
        let ids = {
            "showInterestId": showInterestId,
            "requestId": requestId
        }
        return ids;
    };

    getNotificationDetails = (artistCards: ArtistProfileResponse[] | undefined, businessEntityID: string): object => {
        let resultArray: ArtistProfileResponse[] = [];
        resultArray =
            businessEntityID && artistCards
                ? artistCards.filter((item: ArtistProfileResponse) => {
                    return item.businessEntityID == businessEntityID;
                })
                : [];
           let details = resultArray && resultArray.length > 0 && resultArray[0].addJobDetail
                ? resultArray[0].addJobDetail
                : {};
        return details;
    };

    setStatusVal = (showInterestStatusLookup: any) => {
        if (showInterestStatusLookup && showInterestStatusLookup.length > 0) {
            var cardDetailsAsPerDate = showInterestStatusLookup.filter(
                (item: LookupTypeItem) => item.value === this.state.statusDetail
            );
            var statId = cardDetailsAsPerDate && cardDetailsAsPerDate.length > 0 && cardDetailsAsPerDate[0].id;
            this.setState({
                statusLookUp: showInterestStatusLookup,
                statusText: statId,
                statusColor: this.determineStatusColor(statId),
            });
        }
    };

    //Function thay would toggle the state of the Button to change the Css class attached to the button.
    toggleButton = () => {
        if (
            !this.props.pendingRequestFlag ||
            (this.props.cardUIType == artistCardUIType.All && this.utils.isBlank(this.state.statusDetail))
        ) {
            this.setState((prevState: ArtistCardState) => ({
                alterButtonState: true,
            }));
            this.onAddingToRequest();
        }
    };

    // Fire parent component's callback with data from the individual card to store the Data inside the Browser's session.
    onAddingToRequest = () => {
        const { businessEntityID } = this.state.artistDataItem;
        // Fire parent callback with IDs required for the button's onClick event
        this.props.buildListOfRequestedCards(businessEntityID);
    };

    selectFavorite = () => {
        const { businessEntityID } = this.state.artistDataItem;
        this.setState({
            showProgressIndicator: true,
        });
        this.service.toggleFavorite(businessEntityID).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        const { businessEntityID } = this.state.artistDataItem;
        this.setState(
            {
                artistDataItem: {
                    ...this.state.artistDataItem,
                    venueFavourite: response.data.toString(),
                },
            },
            () => this.props.buildListOfFavoritedCards(businessEntityID, this.state.artistDataItem.venueFavourite)
        );
    };

    handleNotificationSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            isNotificationSuccess: response.data.data.isNotificationSuccess,
            allowNotification: response.data.data.allowNotification,
            notificationSentDate : response.data.data.addJobNotificationSentTime,
            triggerNotificationAPI : true,
            loadPanelVisible: false,
        });        
    };

    handleFailure = (error: any) => {
        this.setState({
            showProgressIndicator: false,
            loadPanelVisible: false,
        });
    };

    handleChangeStatusSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if(this.state.statusText !== dxValueChange.value){
            this.props.handleNavPopUpClose(true);
        }        
        this.setState(
            {
                statusText: dxValueChange.value,
                previousStatusText: this.state.statusDetail,
                statusColor: this.determineStatusColor(dxValueChange.value),
            },
            () => this.mapStatusList()
        );
    };

    handleChangeVenueChoice = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if(this.state.selectedOrderNumber !== dxValueChange.value){
            this.props.handleNavPopUpClose(true);
        }        
        if (dxValueChange.event !== undefined) {
            this.setState(
                {
                    selectedOrderNumber: dxValueChange.value,
                },
                () => this.mapVenueChoice()
            );
        }
    }

    mapStatusList = () => {
        let makeOfferAmt: string = "";
        if(this.state.statusText == ShowInterestStatusIdTypes.Booked) {
            makeOfferAmt = this.props.artistCardData.makeOfferAmount;
        }
        var statusObj: StatusDetail = {
            businessEntityId: this.state.artistDataItem.businessEntityID,
            showIntereststatus: this.state.statusText,
            showInterestPreviousStatus: this.state.previousStatusText,
        };
        //if(statusObj)
        this.setState({
            statusObj: statusObj,
        })
        if (this.props.buildStatusList) {
            this.props.buildStatusList(statusObj, makeOfferAmt);
        }
    };

    mapVenueChoice = () => {
        let venueObj: VenueChoiceDetail = {
            businessEntityId: this.state.artistDataItem.businessEntityID,
            selectedOrderNumber:this.state.selectedOrderNumber
        };
        if (this.props.buildVenueChoice) {
            this.props.buildVenueChoice(venueObj);
        }
    };
    showInfo = (e: any) => {
        e.preventDefault();
        this.setState({
            popupVisible: true,
        });
    };
    hideInfo = (e: any) => {
        e.cancel = true;
        if(this.isEditMode == "editMode"){
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        popupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else{
            this.setState({
                popupVisible: false,
            });  
        }
    };

    sendNotification = () =>{  
        let id = this.getShowIntrestId(this.props.totalArtistDetails, this.props.artistCardData.businessEntityID);
        if(id?.showInterestId !== ""){
            let jobNotificationRequest = {
                "showInterestId": id?.showInterestId,
                "requestId": id?.requestId
            }
            this.setState({loadPanelVisible: true});
            this.service.sendJobNotification(jobNotificationRequest).then(this.handleNotificationSuccess).catch(this.handleFailure);
        }
        else{ 
            this.setState({showAddArtistPopup:true});
        }
    }

    handlePopUpClose = (flag:boolean, editmode: string) => {      
        this.isEditMode = editmode;
        this.setState({
            popupVisible: flag,
        });
    }
    closeAddArtistPopup  = () => {   
        this.setState({
            showAddArtistPopup: false,
        });
    }
    handleConfirmAdd  = () => {   
        this.setState({
            showAddArtistPopup: false,
        });
        this.props.handleConfirmAdd(true);
    }
    handleFeedbackUpdate = (feedbackScore: string, trustedUser: string) => {  
        this.setState({
            artistDataItem: {
                ...this.state.artistDataItem,
                feedbackScore: feedbackScore,
                trustedUser: this.conversionUtils.convertSpecificStringValToBoolean(trustedUser),
            },
        }); 
    } 
    determineStatusColor = (status: any): string => {
        var color: string = "";
        color =
            status == ShowInterestStatusIdTypes.Requested
                ? "entertainer"
                : status == ShowInterestStatusIdTypes.Suggested
                    ? "info"
                    : status == ShowInterestStatusIdTypes.Unavailable || status == ShowInterestStatusIdTypes.Removed
                        ? "grey"
                        : status == ShowInterestStatusIdTypes.Applied
                            ? "warning"
                            : status == ShowInterestStatusIdTypes.Booked
                                ? "primary"
                                : "";
        return color;
    };

    renderNotificationInsideCard = (allowNotification:boolean, notificationSentDate:string, isNotificationSuccess:boolean) => {
        if(this.state.statusText == "22"){
            // let allowNoti = allowNotification !== undefined ? JSON.parse(allowNotification) : false;
            // let notiSuccess = isNotificationSuccess !== undefined ? JSON.parse(isNotificationSuccess) : false;
            let allowNoti = allowNotification ? true : false;
            let notiSuccess = isNotificationSuccess ? true : false;
            if(!notificationSentDate){
                // if((isNotificationSuccess == "false" || !notiSuccess) && this.state.triggerNotificationAPI){
                //     return (
                //         <div className="artist-profile__notification-failed">
                //             <p><FontAwesomeIcon icon={faMessageLines} /> FAILED: User Device not accepting notifications </p>                               
                //         </div> 
                //     )
                // }
                // else if(!this.state.triggerNotificationAPI && (allowNotification == "true" || allowNoti || allowNotification == undefined)) {                    
                //     return (
                //         <div className="artist-profile__notification">
                //             {/* <p onClick={this.sendNotification}><FontAwesomeIcon icon={faMessageLines} /> Click icon to trigger job offer </p>*/}
                //             <p><a href="#" onClick={(event: any) => {event.preventDefault();this.sendNotification() }}><FontAwesomeIcon icon={faMessageLines} /> Click here to re-send a reminder of the job offer </a></p>
                //         </div>                       
                //     )
                // }
                // else if(allowNotification == "false" || !allowNoti || allowNotification == null){
                //     return (
                //         <div className="artist-profile__notification-failed">
                //             <p><FontAwesomeIcon icon={faMessageLines} /> User has notifications turned off.</p>                               
                //         </div> 
                //     )
                // }

                if(this.state.triggerNotificationAPI && !notiSuccess){
                    return (
                        <div className="artist-profile__notification-failed">
                            <p><FontAwesomeIcon icon={faMessageLines} /> FAILED: User Device not accepting notifications </p>                               
                        </div> 
                    )
                }
                else if(!this.state.triggerNotificationAPI && allowNoti) {                    
                    return (
                        <div className="artist-profile__notification">
                            <p><a href="#" onClick={(event: any) => {event.preventDefault();this.sendNotification() }}><FontAwesomeIcon icon={faMessageLines} /> Click icon to send job offer </a></p>
                        </div>                       
                    )
                }
                else if(!this.state.triggerNotificationAPI && !allowNoti){
                    return (
                        <div className="artist-profile__notification-failed">
                            <p><FontAwesomeIcon icon={faMessageLines} /> User has notifications turned off.</p>                               
                        </div> 
                    )
                }

            }
            else {
                let newDate = moment(notificationSentDate, "MM/DD/YYYY h:mm A").format("Do MMM YYYY HH:mm");                
                return (
                    <div className="artist-profile__notification">
                        <div style={{marginTop:'1rem'}}><FontAwesomeIcon icon={faMessageLines} /> Sent : {newDate} </div>    
                        <p><a href="#" onClick={(event: any) => {event.preventDefault();this.sendNotification() }}><FontAwesomeIcon icon={faMessageLines} /> Click here to re-send a reminder of the job offer </a></p>                         
                    </div> 
                ) 
            } 
        }       
        
    }

    renderOfferedError = () => { 
        if(this.state.statusText == "22"){      
            if(this.props.isShowOfferedError == true){
                return (
                    <div className="artist-profile__notification-failed">
                        <p>Please only select one artist to be offered </p>                               
                    </div> 
                )
            } 
        }      
        
    }

    getAddressDetails = () => {
        let {city,state,contactInformation} = this.state.artistDataItem;
        let addressString = '';
        if(city || state){
            addressString += city ? city : '';
            if(addressString && state){
                addressString += `, ${state}`;
            } else {
                addressString += state ? state :'';
            }
        } else if(contactInformation && (contactInformation?.city || contactInformation?.state)){
            addressString += contactInformation.city ? contactInformation.city : '';
            if(addressString && contactInformation.state){
                addressString += `, ${contactInformation.state}`;
            } else {
                addressString += contactInformation.state ? contactInformation.state :'';
            }
        }
        return addressString;
    }

    render() {
        var showStatusDropdown =
            this.props.eventUKIndicator &&
                this.props.pendingRequestFlag &&
                this.props.cardUIType != artistCardUIType.All
                ? true
                : false;
        var separator: string = ",";
        var lastVenueAttended: string = "Last Played Venue: ";
        var isArtistFavorited: string = this.props.listOfFavoritedCards.includes(
            this.state.artistDataItem.businessEntityID
        )
            ? "true"
            : "false";
        const {
            city,
            state,
            score,
            linkInformation,
            venueFavourite,
            typeList,
            lastPlayedVenue,
            professionalName,
            artistApart,
            contactInformation,
            bankInformation
        } = this.state.artistDataItem;
        let iconToShow: string = "";
        if (this.state.artistDataItem.feedbackScore == feedbackScoreType.thumbsUp) {
            iconToShow = "faThumbsUp";
        }
        else if (this.state.artistDataItem.feedbackScore == feedbackScoreType.thumbsDown) {
            iconToShow = "faThumbsDown";
        }
        else if (this.state.artistDataItem.feedbackScore == feedbackScoreType.faceThinking) {
            iconToShow = "faFaceThinking";
        }
        var buttonState: boolean = this.props.listOfRequestedCards.includes(this.state.artistDataItem.businessEntityID)
            ? true
            : false;
        var favorited: boolean = isArtistFavorited == "true" ? true : false;
        var profilePicture: string =
            linkInformation && linkInformation.length > 0 ? this.utils.returnPrimaryPhoto(linkInformation) : "";
        var profileBanner: string =
            linkInformation && linkInformation.length > 0
                ? this.utils.returnLink(linkInformation, PhotoTypes.AlternatePhoto)
                : "";
        var artistPicture: string = profilePicture ? profilePicture : "/assets/images/event-UK-image.jpeg";
        var artistBanner: string = profileBanner ? profileBanner : "/assets/images/logo-icon-white-landscape.svg";
        var feedbackScore: number = this.utils.convertStringToFloat(score);
        var cityState: string = city && state ? `${city}${separator}${state}` : "";
        let addressCityState : string = this.getAddressDetails();
        let offerAmount: string = "";
        let offerComments: string = "";
        let allowNotification = this.state.allowNotification;
        let notificationSentDate = this.state.notificationSentDate;
        let isNotificationSuccess = this.state.isNotificationSuccess;

        if (this.props.artistCardData.makeOfferAmount) {
            offerAmount = `Artist Made Offer: £${this.props.artistCardData.makeOfferAmount}`;
        }
        if (this.props.artistCardData.comments) {
            offerComments = `Comments : ${this.props.artistCardData.comments}`;
        }

        return (
            <>
            <article className="artist-card">
                <div className="artist-card__image">
                    <picture
                        style={{
                            backgroundImage: `url("${artistBanner}")`,
                        }}
                    >
                        <img src={artistPicture} alt="Alt text for specific artist image"></img>
                    </picture>
                </div>
                <div className="artist-card__content">
                    <div className="artist-card__feedback">
                        <a
                            className={`faThumbsUp-btn-artist`}
                        >
                            {iconToShow == "faThumbsUp" ? <FontAwesomeIcon icon={faThumbsUp} className="text-success" /> :
                                iconToShow == "faThumbsDown" ? <FontAwesomeIcon icon={faThumbsDown} className="text-danger" /> :
                                    iconToShow == "faFaceThinking" ? <FontAwesomeIcon icon={faFaceThinking} className="text-warning" /> : null}
                        </a>
                    </div>
                    <div className="artist-card__rating">
                        <StarRating score={feedbackScore} distance={artistApart} />
                        <span className="matchedPopUpText" hidden={!showStatusDropdown}>{offerAmount}</span>
                    </div>
                    <a className="artist-card__name mt-3" href="#" onClick={this.showInfo}>
                        {professionalName ? professionalName : ""}
                        &nbsp;
                        <button className="btn icon-btn" onClick={this.showInfo}>
                            <span className="sr-only">View profile for {this.state.artistDataItem.legalName}</span>
                            <FontAwesomeIcon icon={faUser} className="mr-1" />
                            {this.state.artistDataItem.trustedUser ? <FontAwesomeIcon icon={faRibbon} className="mr-1"/> : null}
                            <span className="id-size">({this.state.artistDataItem.businessEntityID})</span>
                        </button>
                    </a>

                    <div>
                        {typeList.length > 0 && typeList.map((item) => {
                            return (
                                item.value == "Other" ? (
                                    <>
                                        <span className="badge badge-secondary bg-light text-dark font-weight-normal mr-1">
                                            {item.value}
                                        </span>
                                        <span className="badge badge-secondary bg-light text-dark font-weight-normal mr-1">
                                            {item.otherDescription}
                                        </span>
                                    </>
                                ) : (
                                    <span className="badge badge-secondary bg-light text-dark font-weight-normal mr-1">
                                        {item.value}
                                    </span> 
                                )
                            )
                        })
                        }
                    </div>
                    <div className="artist-card__location">{cityState}</div>
                    <div className="artist-card__description">
                        {this.state.artistDataItem.bio ? this.state.artistDataItem.bio : ""}
                    </div>
                    <div className="artist-card__otherDetails mt-3">
                        {lastPlayedVenue ? `${lastVenueAttended}${lastPlayedVenue}` : ""}
                    </div>
                    <div className="artist-profile__contact-detail">
                        <FontAwesomeIcon icon={faUser} />
                        {contactInformation ? `${contactInformation.name}` : ""}
                    </div>
                    <div className="artist-profile__contact-detail">
                        <FontAwesomeIcon icon={faPhone} />
                        <a
                            href={`mobile:${contactInformation ? `${contactInformation.mobileTelephone}` : ""}}`}
                        >
                            {contactInformation ? `${contactInformation.mobileTelephone}` : ""}
                        </a>

                    </div>
                    <div className="artist-profile__contact-detail">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a
                            href={`mailto:${contactInformation ? `${contactInformation.email}` : ""}}`}
                        >
                            {contactInformation ? `${contactInformation.email}` : ""}
                        </a>
                    </div>
                    <div className="artist-profile__contact-detail">
                        <FontAwesomeIcon icon={faBuilding} />
                        {/* {contactInformation ? `${contactInformation.city}` : ""} */}
                        {addressCityState}
                    </div>
                    <div className="artist-profile__contact-detail">
                        <FontAwesomeIcon icon={faPiggyBank} />
                        {bankInformation ? `${bankInformation.accountNumber}` : ""}
                    </div>
                    <div className="artist-card__controls" hidden={this.props.isNavigateFindAnArtist}>
                        <button
                            className={`btn btn-${(this.state.alterButtonState && !this.props.requestSubmitted) || buttonState
                                ? "entertainer"
                                : this.props.pendingRequestFlag && !this.utils.isBlank(this.state.statusDetail)
                                    ? this.state.statusColor == "" ? "normal" : this.state.statusColor
                                    : "primary"
                                }`}
                            onClick={this.toggleButton}
                            hidden={showStatusDropdown}
                        >
                            {(this.state.alterButtonState && !this.props.requestSubmitted) || buttonState
                                ? "Added to Request"
                                : this.props.pendingRequestFlag && !this.utils.isBlank(this.state.statusDetail)
                                    ? this.state.statusDetail
                                    : "Add to Request"}
                        </button>

                        {/* <div hidden={!showStatusDropdown || this.props.popUpView}> */}
                        <div hidden={!showStatusDropdown}>
                            <div className="row">
                                <div
                                    className={`tile bg-${!this.utils.isBlank(this.state.statusText) ? this.state.statusColor : "info"
                                        }`}
                                ></div>
                                <div className="">
                                    <SelectBox
                                        dataSource={this.props.showInterestStatusLookup}
                                        displayExpr={"value"}
                                        valueExpr={"id"}
                                        onValueChanged={this.handleChangeStatusSelect}
                                        disabled={this.props.requestStatus == RequestStatusTypes.Booked ? true : false}
                                        value={
                                            !this.utils.isBlank(this.state.statusText)
                                                ? this.state.statusText
                                                : ShowInterestStatusIdTypes.Suggested
                                        }
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.showintereststatus}
                                        type="hidden"
                                        name={LookupTypeIndexes.showintereststatus}
                                    ></input>
                                </div>
                                <div className="mx-3 mt-2">Venue Choice</div>
                                <div  className="artist-profile__venue-box">
                                    <NumberBox
                                        min={1}
                                        defaultValue={null}
                                        onValueChanged={this.handleChangeVenueChoice}
                                        valueChangeEvent="input"
                                        value={(this.state.selectedOrderNumber == 0 ? undefined : this.state.selectedOrderNumber)}
                                    />
                                </div>                                
                            </div>
                            {this.renderNotificationInsideCard(allowNotification, notificationSentDate, isNotificationSuccess)}    
                            {this.renderOfferedError()}                        
                        </div>
                        <div>

                            {venueFavourite ? (
                                this.state.showProgressIndicator ? (
                                    <LoadIndicator
                                        id="simple-grid-indicator"
                                        height={20}
                                        width={25}
                                        visible={this.state.showProgressIndicator}
                                    />
                                ) : (
                                    <FavouriteButton favourited={favorited} selectFavorite={this.selectFavorite} />
                                )
                            ) : (
                                ""
                            )}
                        </div>

                    </div>

                    <div className="row mt-3" hidden={!showStatusDropdown}>
                        <span>{offerComments}</span>
                    </div>
                </div>
                {
                    this.state.popupVisible ? (
                        <Popup
                            visible={this.state.popupVisible}
                            onHiding={this.hideInfo}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showTitle={true}
                            showCloseButton={true}
                            title="Artist Details"
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.artistDataItem.businessEntityID.toString()}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.artistDataItem.businessEntityID}
                                    handleFeedbackUpdate={this.handleFeedbackUpdate}
                                    handleAddArtist = {()=>{return null}}
                                    serviceType={"Entertainment"}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : null
                }
            </article >
            <Popup
                wrapperAttr={{
                    class: "pending-request-panel__raise-query-pop-up",
                }}
                visible={this.state.showAddArtistPopup}
                onHiding={this.closeAddArtistPopup}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                showCloseButton={false}
                title="Unable to send notification"
                maxWidth="500px"
                maxHeight="300px"
            >
                <div className="pending-request-panel__raise-query">
                    <div className="pending-request-panel__query-box">
                            <p className='text-center'>In order to send notifications to this artist – the request needs to be saved and updated <br></br>  Please save changes and re-try?</p>
                            <div className='text-center'>
                              <button className="btn btn-update-popup mr-3" onClick={this.handleConfirmAdd}> SAVE CHANGES </button>
                              <button className="btn btn-close-popup" onClick={this.closeAddArtistPopup}> CLOSE </button>   
                            </div>                         
                    </div>
                </div>
            </Popup>
            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </>
        );
    }
}
export default ArtistCard;
