import { PaymentTypes } from "../../services/FindAnArtistService";
import {
    AddEditPaymentReqSubmitRequest,
    AddEditPaymentReqGetResponse,
    PaymentRequestMatchedPopUpResponse,
    paymentRequestStatus,
    paymentStatus
} from "../../services/PaymentService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;


//Interface type for the payment cycle form fields.
export interface PaymentRequestFormData {
    id: string,
    providerBusinessEntityId: string,
    clientBusinessEntityId: string,
    serviceSubTypeId: string,
    startTime: NullableDate,
    finishTime: NullableDate,
    venueId: string,
    serviceTypeId: string,
    dateFrom: NullableDate,
    dateTo: NullableDate,
    description: string,
    providerPayAmountLocalExtrasDescription: string,
    providerPayAmountLocal: number | undefined,
    quantityOrHours: number | undefined,
    rate: number | undefined,
    requestApprover:string,
    providerFasterPayFee: number | undefined,
    providerFasterPayOriginalAmount: number | undefined,
    internalComments: string,
    comments: string,
    requestDate: NullableDate,
    requestDate_Web: string,
    status: string,
    ValidationErrors: [],
    fasterPayment: string,
    providerPayAmountLocalExtras: number | undefined,
    paymentRequestTypeID: string,
    paymentRequestStatusID: string,
    typeLookUpId: string,
    otherDescription: string,
    client: string,
    houseNumber: string,
    venueDetails: string,
    subType:string
}

export interface PaymentRequestMatchedPopUpFormData {
    id: string,
    providerBusinessEntity: {
        id: string,
        shortName: string,
    },
    clientBusinessEntity: {
        id: string,
        shortName: string,
    },
    venue: {
        id: string,
        houseNumber: string,
        venueName: string,
    },
    paymentRequestStatusLookUp: {
        id: string,
        value: string
    },
    providerPayAmountLocal: number | undefined,
    providerFasterPayFee: number | undefined,
    fasterPayment: string;
    providerFasterPayOriginalAmount: number | undefined,
    dateFrom: NullableDate,
    dateTo: NullableDate,
    description: string,
    providerPayAmountLocalExtrasDescription: string,
    serviceType: {
        id: string,
        value: string,
    },
    requestDate: string,
    serviceSubType: {
        id: string,
        value: string,
    },
    startTime: string,
    finishTime: string,
    providerPayAmountLocalExtras: number | undefined,
    paymentRequestType: {
        id: string,
        value: string
    },
    quantityOrHours: number | undefined,
    rate: number | undefined,
    typeLookUpId: {
        id: string,
        value: string,
    },
    otherDescription: string,
}

// Calculation request interface
export interface CalculationRequest {
    paymentRequestId: string;
    clientId: string;
    providerId: string;
    providerPayAmountLocal: string;
    providerPayAmountLocalExtras?: string;
    providerPayAmountLocalWOExtras?: string;
    providerFasterPayFee?: string;
    providerFasterPayOriginalAmount?: string;
    providerPayAmountLocalExtrasDescription: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    fasterPayment: boolean;
    multiplier: string;

    
}

export default class PaymentRequestUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeAddEditPaymentItems = this.initializeAddEditPaymentItems.bind(this);
        this.convertPaymentResponseToFormData = this.convertPaymentResponseToFormData.bind(this);
        this.convertFormDataToPaymentRequest = this.convertFormDataToPaymentRequest.bind(this);
    }

    //Initializes the items for the Payment Request Form Data and Matched Pop Up data

    initializePaymentRequestMatchedPopUpItems(): PaymentRequestMatchedPopUpFormData {
        var formData: PaymentRequestMatchedPopUpFormData = {
            id: "",
            providerBusinessEntity: {
                id: "",
                shortName: "",
            },
            clientBusinessEntity: {
                id: "",
                shortName: "",
            },
            venue: {
                id: "",
                houseNumber: "",
                venueName: "",
            },
            providerPayAmountLocal: undefined,
            providerFasterPayFee: undefined,
            fasterPayment: "false",
            providerFasterPayOriginalAmount: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            description: "",
            providerPayAmountLocalExtrasDescription: "",
            serviceType: {
                id: "",
                value: "",
            },
            requestDate: "",
            paymentRequestStatusLookUp: {
                id: "",
                value: ""
            },
            serviceSubType: {
                id: "",
                value: ""
            },
            startTime: "",
            finishTime: "",
            providerPayAmountLocalExtras: undefined,
            paymentRequestType: {
                id: "",
                value: ""
            },
            quantityOrHours: undefined,
            rate: undefined,
            typeLookUpId: {
                id: "",
                value: "",
            },
            otherDescription: "",
        };
        return formData;
    }


    initializeAddEditPaymentItems(idFromGrid?: string): PaymentRequestFormData {
        var formData: PaymentRequestFormData = {
            id: "",
            providerBusinessEntityId: "",
            clientBusinessEntityId: "",
            venueId: "",
            serviceTypeId: "",
            dateFrom: undefined,
            dateTo: undefined,
            description: "",
            providerPayAmountLocalExtrasDescription: "",
            providerPayAmountLocal: undefined,
            quantityOrHours: 1,
            rate: undefined,
            requestApprover:'',
            providerFasterPayFee: undefined,
            providerFasterPayOriginalAmount: undefined,
            internalComments: "",
            comments: "",
            requestDate: undefined,
            requestDate_Web: "",
            status: "",
            ValidationErrors: [],
            serviceSubTypeId: "",
            startTime: undefined,
            finishTime: undefined,
            fasterPayment: "",
            providerPayAmountLocalExtras: undefined,
            paymentRequestTypeID: "",
            paymentRequestStatusID: "",
            client: "",
            houseNumber: "",
            venueDetails: "",
            typeLookUpId: "",
            otherDescription: "",
            subType:''
        };

        return formData;
    }

    //convert the server response into components form state 

    convertMatchedPopUpResponseToFormData(serverResponse: PaymentRequestMatchedPopUpResponse): PaymentRequestMatchedPopUpFormData {
        var formData: PaymentRequestMatchedPopUpFormData = {
            id: serverResponse.id,
            providerBusinessEntity: {
                id: serverResponse.providerBusinessEntity ? serverResponse.providerBusinessEntity.id.toString() : "",
                shortName: serverResponse.providerBusinessEntity ? serverResponse.providerBusinessEntity.shortName : "",
            },
            clientBusinessEntity: {
                id: serverResponse.clientBusinessEntity ? serverResponse.clientBusinessEntity.id.toString() : "",
                shortName: serverResponse.clientBusinessEntity ? serverResponse.clientBusinessEntity.shortName : "",
            },
            venue: {
                id: serverResponse.venue ? serverResponse.venue.id.toString() : "",
                houseNumber: serverResponse.venue ? serverResponse.venue.houseNumber : "",
                venueName: serverResponse.venue ? serverResponse.venue.venueName : "",
            },
            providerPayAmountLocal: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocal),
            providerFasterPayFee: this.utils.convertStringToFloat(serverResponse.providerFasterPayFee),
            fasterPayment: this.utils.convertBooleanToString(serverResponse.fasterPayment),
            providerFasterPayOriginalAmount: this.utils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
            dateFrom: this.utils.convertStringToDate(serverResponse.dateFrom),
            dateTo: this.utils.convertStringToDate(serverResponse.dateTo),
            description: serverResponse.description,
            providerPayAmountLocalExtrasDescription: serverResponse.providerPayAmountLocalExtrasDescription,
            serviceType: {
                id: serverResponse.serviceType ? serverResponse.serviceType.id.toString() : "",
                value: serverResponse.serviceType ? serverResponse.serviceType.value : "",
            },
            requestDate: serverResponse.requestDate,
            paymentRequestStatusLookUp: {
                id: serverResponse.paymentRequestStatusLookUp.id,
                value: serverResponse.paymentRequestStatusLookUp.value
            },
            serviceSubType: {
                id: serverResponse.serviceSubType ? serverResponse.serviceSubType.id.toString() : "",
                value: serverResponse.serviceSubType ? serverResponse.serviceSubType.value : ""
            },
            startTime: serverResponse.startTime,
            finishTime: serverResponse.finishTime,
            providerPayAmountLocalExtras: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
            paymentRequestType: {
                id: serverResponse.paymentRequestType ? serverResponse.paymentRequestType.id.toString() : "", 
                value: serverResponse.paymentRequestType ? serverResponse.paymentRequestType.value.toString() : ""
            },
            quantityOrHours: this.utils.convertStringToFloat(serverResponse.quantityOrHours),
            rate: this.utils.convertStringToFloat(serverResponse.rate),
            typeLookUpId: {
                id: serverResponse.typeLookUp ? serverResponse.typeLookUp.id.toString() : "",
                value: serverResponse.typeLookUp ? serverResponse.typeLookUp.value : ""
            },
            otherDescription: serverResponse.otherDescription,
        }
        return formData;
    }


    // Convert the server response into the component's form state
    convertPaymentResponseToFormData(serverResponse: AddEditPaymentReqGetResponse): PaymentRequestFormData {
        let fasterPaymentVal: boolean = false;
        if (serverResponse.fasterPayment) {
            fasterPaymentVal = true;
        }
        var formData: PaymentRequestFormData = {
            id: serverResponse.id,
            providerBusinessEntityId: serverResponse.providerBusinessEntityId,
            clientBusinessEntityId: serverResponse.clientBusinessEntityId,
            venueId: serverResponse.venueId,
            serviceTypeId: serverResponse.serviceTypeId,
            dateFrom: this.utils.convertStringToDate(serverResponse.dateFrom),
            dateTo:this.utils.convertStringToDate(serverResponse.dateTo),
            description: serverResponse.description,
            providerPayAmountLocalExtrasDescription: serverResponse.providerPayAmountLocalExtrasDescription,
            providerPayAmountLocal: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocal),
            quantityOrHours: this.utils.convertStringToFloat(serverResponse.quantityOrHours),
            rate: this.utils.convertStringToFloat(serverResponse.rate),
            requestApprover:serverResponse.requestApprover,
            providerFasterPayFee: this.utils.convertStringToFloat(serverResponse.providerFasterPayFee),
            providerFasterPayOriginalAmount: this.utils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
            internalComments: serverResponse.internalComments,
            comments: serverResponse.comments,
            requestDate: this.utils.convertStringToDate(serverResponse.requestDate),
            requestDate_Web: serverResponse.requestDate_Web,
            status: serverResponse.status,
            ValidationErrors: serverResponse.ValidationErrors,
            serviceSubTypeId: serverResponse.serviceSubTypeId,
            startTime: this.utils.convertStringToTime(serverResponse.startTime),
            finishTime: this.utils.convertStringToTime(serverResponse.finishTime),
            fasterPayment: this.utils.convertBooleanToString(fasterPaymentVal),
            providerPayAmountLocalExtras: this.utils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
            paymentRequestTypeID: serverResponse.paymentRequestTypeID,
            paymentRequestStatusID: serverResponse.paymentRequestStatusID,
            typeLookUpId: serverResponse.typeLookUpId,
            otherDescription: serverResponse.otherDescription,
            client: serverResponse.client,
            houseNumber: serverResponse.houseNumber,
            venueDetails: serverResponse.venueDetails,
            subType:serverResponse.subType
        };

        return formData;
    }

    // Convert the form's data into a proper billable item submit request
    convertFormDataToPaymentRequest(
        formData: PaymentRequestFormData,
        status?: string
    ): AddEditPaymentReqSubmitRequest {
        // var reqDate: string = "";
        // var reqTime: string | undefined = "";
        // var reqDateTime: string = "";
        // if (!formData.id) {
        //     var requestDate = this.utils.convertDateToString(new Date());
        //     reqDate = this.utils.convertStringToddmmmyyyyForm(requestDate);
        //     reqTime = this.utils.convertDateTimeStringToHHMM(new Date().toString());
        //     reqDateTime = reqDate + " " + reqTime;
        // }
        // else {
        //     var requestDateEdit = formData.requestDate?.toString().split(" ");
        //     if (requestDateEdit) {
        //         reqDate = this.utils.convertStringToddmmmyyyyForm(requestDateEdit[0]);
        //         reqTime = this.utils.convertTime12to24(requestDateEdit[1] + " " + requestDateEdit[2]);
        //         reqDateTime = reqDate + " " + reqTime;
        //     }
        // }
        let statusId : string = "";
        if(status){
            if(status == "cancel"){
                if(formData.paymentRequestTypeID == PaymentTypes.AddJob){
                    statusId = paymentRequestStatus.AddJobCancelled;
                }
                else if(formData.paymentRequestTypeID == PaymentTypes.PaymentRequest){
                    statusId = paymentRequestStatus.Cancelled;
                 }
            }
            else {
                statusId = formData.paymentRequestStatusID;
            }
        }
        else {
            statusId = formData.paymentRequestStatusID;
        }
        var submitRequest: AddEditPaymentReqSubmitRequest = {
            id: formData.id,
            providerBusinessEntityId: formData.providerBusinessEntityId,
            clientBusinessEntityId: formData.clientBusinessEntityId,
            venueId: formData.venueId,
            serviceTypeId: formData.serviceTypeId,
            dateFrom: this.utils.convertDateToString(formData.dateFrom),
            dateTo: this.utils.convertDateToString(formData.dateTo),
            description: formData.description,
            providerPayAmountLocalExtrasDescription: formData.providerPayAmountLocalExtrasDescription,
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : undefined,
            quantityOrHours: formData.quantityOrHours ? this.utils.convertNumberToString(formData.quantityOrHours) : undefined,
            rate: formData.rate ? this.utils.convertNumberToString(formData.rate) : undefined,
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : undefined,
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : undefined,
            internalComments: formData.internalComments,
            comments: formData.comments,
            requestDate: formData.requestDate,
            requestDate_Web: formData.requestDate_Web,
            finishTime: this.utils.convertTimeToString(formData.finishTime),
            startTime: this.utils.convertTimeToString(formData.startTime),
            serviceSubTypeId: formData.serviceSubTypeId,
            fasterPayment: this.utils.convertSpecificStringValToBoolean(formData.fasterPayment),
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            paymentRequestTypeID: formData.paymentRequestTypeID,
            paymentRequestStatusID: statusId,
            typeLookUpId: formData.typeLookUpId,
            otherDescription: formData.otherDescription,
            client: formData.client,
            houseNumber: formData.houseNumber,
            venueDetails: formData.venueDetails,
            subType:formData.subType
        };

        return submitRequest;
    }

    convertPayCalcDataToCalcRequest(formData: PaymentRequestFormData, providerNetOverride?: number | null | undefined, multiplierOverride?: number, isAddorEdit?: number): CalculationRequest {        
      
        return {
            paymentRequestId: formData.id ? formData.id : "",
            clientId: formData.clientBusinessEntityId,
            providerId: formData.providerBusinessEntityId,
            providerPayAmountLocal: formData.providerPayAmountLocal ? this.utils.convertNumberToString(formData.providerPayAmountLocal) : "0.00",
            providerPayAmountLocalExtras: formData.providerPayAmountLocalExtras ? this.utils.convertNumberToString(formData.providerPayAmountLocalExtras) : "",
            providerFasterPayFee: formData.providerFasterPayFee ? this.utils.convertNumberToString(formData.providerFasterPayFee) : "",
            providerFasterPayOriginalAmount: formData.providerFasterPayOriginalAmount ? this.utils.convertNumberToString(formData.providerFasterPayOriginalAmount) : "0.00",
            serviceTypeId: formData.serviceTypeId.toString(),
            serviceSubTypeId: formData.serviceSubTypeId.toString(),
            fasterPayment: formData.fasterPayment == "true" || formData.fasterPayment == "Yes" ? true : false,
            providerPayAmountLocalExtrasDescription: formData.providerPayAmountLocalExtrasDescription,
            multiplier: "0",
        }
    }

    
}

