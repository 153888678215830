import { providerProfileResponse, providerProfileTNCRequest } from "../../services/ArtistService";
import sharedUtils from "../grid/sharedUtilities";
import artistProfileUtils, { ArtistPageFormData } from "../Artist-Profile/ArtistProfileUtilitise";



export default class providerProfileUtils {
    utilsForConversion: sharedUtils;
    providerPageUtils: artistProfileUtils;
    constructor() {
        this.utilsForConversion = new sharedUtils();
        this.providerPageUtils = new artistProfileUtils();
        this.initializeProviderProfileItem = this.initializeProviderProfileItem.bind(this);
        this.convertFormDataToProviderProfileRequest = this.convertFormDataToProviderProfileRequest.bind(this);
        this.convertProviderProfileResponeToFormData = this.convertProviderProfileResponeToFormData.bind(this);
    }

    initializeProviderProfileItem(idFromGrid: string): ArtistPageFormData {
        var formData: ArtistPageFormData = {
            businessEntityID: "",
            businessEntityTypeLookUpId: "",
            businessEntityType: "",
            legalName: "",
            tradingName: "",
            shortName: "",
            businessEntityLegalStatusLookUpId: "",
            businessEntityLegalStatus: "",
            companyNumber: "",
            taxApplicable: "",
            taxVerified: "",
            taxVerifiedBy: "",
            taxVerifiedDateTime: "",
            verified: "",
            verifiedBy: "",
            verifiedDateTime: "",
            openingHours: "",
            webSite: "",
            officePhone: "",
            email: "",
            providerPaymentOffsetDays: "",
            taxRegistrationNumber: "",
            addressStreet1: "",
            addressStreet2: "",
            addressStreet3: "",
            city: "",
            state: "",
            country: "",
            postCode: "",
            professionalName: "",
            score: "",
            bio: "",
            shortDescription: "",
            coverageMiles: "",
            termsAgreed: false,
            termsAgreedDateTime: "",
            isActive: "",
            trustedUser: "",
            hideProfileExternally:'',
            hideFromSource:'',
            agent:'',
            //artist:'',
            factored:'',
            businessEntityStatusId: "",
            businessEntityStatus: "",
            venueFavourite: "",
            lastPlayedVenue: "",
            lastUpdatedOn :'',
            lastUpdatedBy :'',
            onboardedDate:undefined,
            contactInformation: {
                contactId: "",
                name: "",
                mobileTelephone: "",
                businessTelephone: "",
                otherTelephone: "",
                email: "",
                addressStreet1: "",
                addressStreet2: "",
                city: "",
                state: "",
                postCode: "",
                emailNotification: "",
                dateOfBirth: undefined,
                utr: "",
                ni: "",
                optOut: "",
                active: "",
                lastUpdatedOn: "",
            },
            user: {
                id: "",
                name: "",
                active: "",
                lastUpdatedOn: "",
                allowWebApp: '',
                disallowApp: '',
                disallowPortal: ''
            },
            providerSiteManagerInformation: {
                contactId: "",
                title: "",
                firstName: "",
                lastName: "",
                name: "",
                mobileTelephone: "",
                businessTelephone: "",
                otherTelephone: "",
                email: "",
                addressStreet1: "",
                addressStreet2: "",
                city: "",
                state: "",
                postCode: "",
                emailNotification: "",
                dateOfBirth: undefined,
                utr: "",
                ni: "",
            },
            bankInformation: {
                id: "",
                businessEntityID: "",
                contactID: "",
                bankName: "",
                branchName: "",
                accountNumber: "",
                sortCode: "",
                buildingSociety: "",
                nameOnAccount: "",
                active: "",
                verified: "",
                verifiedBy: "",
                verifiedDateTime: "",
                createdDateTime: "",
                serviceTypeLookUpId: "",
                serviceSubTypeLookUpId: "",
            },
            requirePayment: "",
            requirePaymentComment: "",
            artistApart: "",
            linkInformation: [],
            typeList: [],
            businessEntityDocument: [],
            validationErrors: "",
            postSignUpMessages: [],
            genreList: [],
            musicTypeList: [],
            equipment: "",
            coveragePostCode: "",
            registration: false,
            profile: false,
            bankaccount: false,
            feedbackTypeId: "",
            feedbackScore: "",
            feedbackComments: "",
            oooProtocol: "",
            serviceTypeList: [],
            profileFlag: "",
        };
        return formData;
    }

    // Convert the server response into the component's form state
    convertProviderProfileResponeToFormData(serverResponse: providerProfileResponse): ArtistPageFormData {
        var formData: ArtistPageFormData = {
            businessEntityID: serverResponse.businessEntityID,
            businessEntityTypeLookUpId: serverResponse.businessEntityTypeLookUpId,
            businessEntityType: serverResponse.businessEntityType,
            legalName: serverResponse.legalName,
            tradingName: serverResponse.tradingName,
            shortName: serverResponse.shortName,
            businessEntityLegalStatusLookUpId: serverResponse.businessEntityLegalStatusLookUpId,
            businessEntityLegalStatus: serverResponse.businessEntityLegalStatusLookUpId,
            companyNumber: serverResponse.companyNumber,
            taxApplicable: this.utilsForConversion.convertBooleanToString(serverResponse.taxApplicable),
            taxVerified: this.utilsForConversion.convertBooleanToString(serverResponse.taxVerified),
            taxVerifiedBy: serverResponse.taxVerifiedBy,
            taxVerifiedDateTime: serverResponse.taxVerifiedDateTime,
            verified: this.utilsForConversion.convertBooleanToString(serverResponse.verified),
            verifiedBy: serverResponse.verifiedBy,
            verifiedDateTime: serverResponse.verifiedDateTime,
            openingHours: serverResponse.openingHours,
            webSite: serverResponse.webSite,
            officePhone: serverResponse.officePhone,
            email: serverResponse.email,
            providerPaymentOffsetDays: serverResponse.providerPaymentOffsetDays,
            taxRegistrationNumber: serverResponse.taxRegistrationNumber,
            addressStreet1: serverResponse.addressStreet1,
            addressStreet2: serverResponse.addressStreet2,
            addressStreet3: serverResponse.addressStreet3,
            city: serverResponse.city,
            state: serverResponse.state,
            country: serverResponse.country,
            postCode: serverResponse.postCode,
            professionalName: serverResponse.professionalName,
            score: serverResponse.score,
            bio: serverResponse.bio,
            shortDescription: serverResponse.shortDescription,
            coverageMiles: serverResponse.coverageMiles,
            termsAgreed: Boolean(serverResponse.termsAgreed),
            termsAgreedDateTime: serverResponse.termsAgreedDateTime,
            isActive: serverResponse.isActive,
            trustedUser: this.utilsForConversion.convertBooleanToSpecificVal(serverResponse.trustedUser),
            hideProfileExternally:serverResponse.hideProfileExternally?'Yes':'No',
            hideFromSource:serverResponse.hideFromSource ? 'Yes':'No',
            agent:serverResponse.agent ? 'Yes':'No',
            //artist :serverResponse.artist ? 'Yes' :'No',
            factored:serverResponse.factored ? 'Yes':'No',
            businessEntityStatusId: serverResponse.businessEntityStatusId,
            businessEntityStatus: serverResponse.businessEntityStatus,
            venueFavourite: serverResponse.venueFavourite,
            lastPlayedVenue: serverResponse.lastPlayedVenue,
            lastUpdatedOn :serverResponse.lastUpdatedOn,
            lastUpdatedBy :serverResponse.lastUpdatedBy,
            onboardedDate : serverResponse.onboardedDate ? this.utilsForConversion.convertStringToDate(serverResponse.onboardedDate):null,
            contactInformation: null,
            user: null,
            providerSiteManagerInformation: {
                contactId: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.contactId
                    : "",
                title: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.title
                    : "",
                firstName: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.firstName
                    : "",
                lastName: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.lastName
                    : "",
                name: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.name
                    : "",
                mobileTelephone: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.mobileTelephone
                    : "",
                businessTelephone: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.businessTelephone
                    : "",
                otherTelephone: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.otherTelephone
                    : "",
                email: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.email
                    : "",
                addressStreet1: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.addressStreet1
                    : "",
                addressStreet2: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.addressStreet2
                    : "",
                city: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.city
                    : "",
                state: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.state
                    : "",
                postCode: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.postCode
                    : "",
                emailNotification: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.emailNotification
                    : "",
                dateOfBirth: serverResponse.contactInformation
                    ? this.utilsForConversion.convertStringToDate(serverResponse.contactInformation.dateOfBirth)
                    : null,
                utr: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.utr
                    : "",
                ni: serverResponse.providerSiteManagerInformation
                    ? serverResponse.providerSiteManagerInformation.ni
                    : "",
            },
            bankInformation:  serverResponse.bankInformation,
            linkInformation: serverResponse.linkInformation,
            requirePayment: serverResponse.requirePayment,
            requirePaymentComment: serverResponse.requirePaymentComment,
            artistApart: serverResponse.artistApart,
            typeList: serverResponse.typeList,
            businessEntityDocument: serverResponse.businessEntityDocument ? serverResponse.businessEntityDocument : [],
            validationErrors: serverResponse.validationErrors,
            postSignUpMessages: [],
            genreList: serverResponse.genreList,
            musicTypeList: serverResponse.musicTypeList,
            equipment: this.utilsForConversion.convertBooleanToString(serverResponse.equipment),
            coveragePostCode: serverResponse.coveragePostCode,
            registration: serverResponse.registration,
            profile: serverResponse.profile,
            bankaccount: serverResponse.bankaccount,
            feedbackTypeId: serverResponse.feedbackTypeId,
            feedbackScore: serverResponse.feedbackScore,
            feedbackComments: serverResponse.feedbackComments,
            oooProtocol: serverResponse.oooProtocol,
            serviceTypeList: serverResponse.serviceTypeList,
            profileFlag: serverResponse.profileFlag,
        };

        return formData;
    }

    // Convert the form's data into a proper Artist Profile submit request
    convertFormDataToProviderProfileRequest(formData: ArtistPageFormData): providerProfileTNCRequest {
        var submitRequest: providerProfileTNCRequest = {
            businessEntityID: formData.businessEntityID,     
            termsAgreed: formData.termsAgreed.toString(),
        };

        return submitRequest;
    }
}
