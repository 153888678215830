import { DashboardResponseCount } from "../../services/DashBoardService";
import sharedUtils from "../grid/sharedUtilities";

type NullableDate = Date | null | undefined;


export default class DashBoardCountUtils {
    utils: sharedUtils;
    constructor() {
        this.utils = new sharedUtils();
        this.initializeDashBoardCount = this.initializeDashBoardCount.bind(this);
    }

    initializeDashBoardCount(): DashboardResponseCount {
        var dashBoardCount: DashboardResponseCount = {
            paymentRequestPending: "",
            paymentRequestQueried: "",
            paymentFailed: "",
            fasterPayment: "",
            addJobPending: "",
            addJobQueried: "",
            signUpPending: "",
            queriesPending: "",
            gigRequestPending: "",
            gigRequestPublished: "",
            gigRequestOptionsSent: "",
            gigRequestConfirming: "",
            gigRequestReConfirm: "",
            gigRequestShortlisted: "",
            gigRequestOffered: "",
            requestByDate:
                [{
                    date: "",
                    newRequest: "",
                    advertised: "",
                    optionsSent: "",
                    shortlisted: "",
                    offered: "",
                    confirming: "",
                    reConfirm: "",
                    booked: ""
                }]
        };

        return dashBoardCount;
    }
}
