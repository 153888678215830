import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateBox, TextBox, NumberBox, LoadPanel, TextArea, SelectBox, Popup, ScrollView } from "devextreme-react";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes, LookupTypeItem, UnitOfMeasureTypes } from "../../services/LookupService";
import { AxiosResponse } from "axios";
import LightModePageTemplate from "../page/Templates/LightModeTemplate";
import SideBarTemplate from "../side-bar/SideBarTemplate";
import BulletinPanel from "../BulletinPanel/BulletinPanel";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import QuickAddShiftUtils, { QuickShiftFormData } from "./QuickAddShiftUtils";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";
import { serviceTypes } from "../../services/FileService";
import outstandingActionsUtilities, { minuteLookup } from "../Outstanding-Actions/OutstandingActionsUtilities";
import AddEditPopUpService, { QuickBillableItemSubmitRequest, Status, CalculationResponse } from "../../services/AddEditPopUpService";
import ServiceFeeService from "../../services/ServiceFeesService";
import addEditPopupUtils, { BusinessEntityTaxApplicableStatus, BusinessEntityTypes, itemTypes } from "../grid/AddEditPopUpUtilities";
import billableItemUtils from "../Utility/billableItemUtils";
import sharedUtils from "../grid/sharedUtilities";
import FindAnArtistUtils from "../FindAnArtist/FindAnArtistUtils";
import AddEditFormV2 from "../grid/AddEditFormV2";
import moment from "moment";

interface QuickAddShiftFormProps extends WithTranslation {
    location: any;
    history: any;
    billableItemId?: string;
    navigateFrom?: string;
    hidePopup: (
        isPopupVisible: boolean
    ) => void;
}

interface QuickAddShiftFormState {
    loadPanelVisible: boolean;
    quickShiftFormData: QuickShiftFormData;
    businessEntityTaxApplicableStatuses: BusinessEntityTaxApplicableStatus[];
    errorMessage: any;
    confirmationPanelVisible: boolean;
    errorPanelVisible: boolean;
    createNewRequestButtonVisible: boolean;
    hours: number;
    minutes: string;
    hourChangeTimer: any;
    unitOfMeasureList: LookupTypeItem[];
    CleaningMinutesToDisplay: string;
    typeLookUp: LookupTypeItem[];
    offeringsTypeLookup: LookupTypeItem[];
    showWarningPopup: boolean;
    showCalculatedPopup: boolean;
    isPopupProviderNetChange: boolean;
    isPopupEventNetChange: boolean;
    isRequestCalculationChange: boolean;
}

// Product Backlog Item 16295: Quick Add Shift - Pop-Up - UI and Testing
class QuickAddShiftForm extends React.Component<QuickAddShiftFormProps> {
    utils: QuickAddShiftUtils;
    findAnArtistUtils: FindAnArtistUtils;
    outStandingActionUtils: outstandingActionsUtilities;
    addEditutils: addEditPopupUtils;
    billableItemUtils: billableItemUtils;
    state: QuickAddShiftFormState;
    sharedUtils: sharedUtils;
    service: AddEditPopUpService;
    serviceFeeService: ServiceFeeService;
    lookupService: LookupService;
    updateCorrectionDataSource: LookupTypeItem[];
    submitRecord: string;
    constructor(props: QuickAddShiftFormProps) {
        super(props);
        this.submitRecord = "";
        this.utils = new QuickAddShiftUtils();
        this.sharedUtils = new sharedUtils();
        this.service = new AddEditPopUpService();
        this.serviceFeeService = new ServiceFeeService();
        this.lookupService = new LookupService();
        this.addEditutils = new addEditPopupUtils();
        this.billableItemUtils = new billableItemUtils();
        this.findAnArtistUtils = new FindAnArtistUtils();
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.updateCorrectionDataSource = this.lookupService.getUpdateOrCorrection();
        // Initialize state
        let itemId: string = "";
        if (props.location && props.location.state && props.location.state.id) {
            itemId = props.location.state.id.toString();
        }
        let convertedQuickShiftFormData = this.utils.initializeQuickShiftItems(itemId);
        // Initialize state
        this.state = {
            loadPanelVisible: false,
            quickShiftFormData: convertedQuickShiftFormData,
            errorMessage: [],
            confirmationPanelVisible: false,
            errorPanelVisible: false,
            createNewRequestButtonVisible: false,
            hours: 0,
            minutes: "",
            hourChangeTimer: null,
            unitOfMeasureList: [],
            businessEntityTaxApplicableStatuses: [],
            CleaningMinutesToDisplay: "",
            typeLookUp: [],
            offeringsTypeLookup: [],
            showWarningPopup: false,
            showCalculatedPopup: false,
            isPopupProviderNetChange: false,
            isPopupEventNetChange: false,
            isRequestCalculationChange: false,
        };
    }

    componentDidMount(): void {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.unitOfMeasure)
            .then(this.handleUnitOfMeasureLookupSuccess)
            .catch();
        this.service
            .getTaxApplicableStatus()
            .then(this.handleTaxApplicableSuccess)
            .catch();
        if (this.props.location.state?.id) {
            this.service
                .getEditViewData(parseInt(this.props.location.state.id))
                .then(this.handleSuccessViewDataSource)
                .catch();
        }
        else if(this.props.billableItemId){
            this.service
                .getEditViewData(parseInt(this.props.billableItemId))
                .then(this.handleSuccessViewDataSource)
                .catch();
        }
        else {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    billableStatusLookupId: Status.Pending,
                    itemTypeId: itemTypes.Original,
                    billableItemId: "0"
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<QuickAddShiftFormProps>): void {
        if (this.props.billableItemId && this.props.billableItemId != prevProps.billableItemId) {
            this.service
                .getEditViewData(parseInt(this.props.billableItemId))
                .then(this.handleSuccessViewDataSource)
                .catch();
        }             
    }

    offeringsTypeDataSource = (serviceTypeId: string) => {
        //retrieve the role list 
        if (serviceTypeId == ServiceType.Entertainment || serviceTypeId == ServiceType.ContractCleaning 
            || serviceTypeId == ServiceType.Gardening || serviceTypeId == ServiceType.WindowCleaning) {
            this.lookupService
                .getLookupByLookupTypeIndex(LookupTypeIndexes.offeringsType)
                .then(this.handleSuccesOfferingsLookUp)
                .catch(() => { });
        }
    }

    handleSuccesOfferingsLookUp = (response: AxiosResponse<any>) => {
        let typeDropDown = this.findAnArtistUtils.lookUpCreation(response);
        this.setState({
            typeLookUp: typeDropDown,
        }, () => { this.filterDataSourceForRole(typeDropDown, this.state.quickShiftFormData.serviceSubTypeId); });
    }

    filterDataSourceForRole = (data: LookupTypeItem[], filterId: string) => {
        let mappingId: number = this.sharedUtils.convertStringToInt(filterId);
        let roleTypeItems = mappingId > 0
            ? data.filter((item: LookupTypeItem) => {
                return item.parentMappingId === filterId;
            })
            : data;

        this.setState({
            offeringsTypeLookup: roleTypeItems
        });

    };

    handleUnitOfMeasureLookupSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            unitOfMeasureList: response.data.data,
        });

        this.initializeFieldsBasedOnUnitOfMeasure(
            this.state.quickShiftFormData.serviceSubTypeId,
            this.state.unitOfMeasureList
        );
    };

    /**
     * Handle the success response for retrieving Tax Applicable statuses
     *
     * @param response {AxiosResponse<any>} - server response
     */
    handleTaxApplicableSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                businessEntityTaxApplicableStatuses: response.data.data,
            }
        );
        this.setFeeTaxApplicableOnInitialLoad();
    };

    setFeeTaxApplicableOnInitialLoad = () => {
        let taxApplicable: string = "No";
        let statuses: BusinessEntityTaxApplicableStatus[] = this.state.businessEntityTaxApplicableStatuses;
        // Set the Fee tax applicable field immediately
        let businessEntityItem = statuses.find(
            (item) => item.businessEntityType === BusinessEntityTypes.Company
        );
        if (businessEntityItem && businessEntityItem.taxApplicable) {
            taxApplicable = "Yes";
        }
        if (businessEntityItem) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    fee_Tax_Applicable: taxApplicable,
                },
            });
        }
    }

    initializeFieldsBasedOnUnitOfMeasure = (subTypeId: string, unitOfMeasureList: LookupTypeItem[]) => {
        // Disabling fields dependent on: 1) the form data server request 2) the unit of measure list
        // Both success promises (this is one) will need to set execute the disable as it cannot be known who's promise will complete first
        if (subTypeId && subTypeId.length > 0) {
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                subTypeId,
                UnitOfMeasureTypes.time,
                unitOfMeasureList
            )
                ? this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time)
                : this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    };

    // change for PBI-18255 start
    setUpdateOrCorrectionConditionally = (isAccrual:boolean, invoiceId:string, paymentId:string) => {    
        if(isAccrual == true && invoiceId !== "" && paymentId == ""){
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    updateOrCorrection: "true",
                },
            })
        }         
    }
    // change for PBI-18255 end

    handleSuccessViewDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        let viewDataSource = this.utils.convertQuickShiftResponeToFormData(response.data.data);
        this.setState({
            quickShiftFormData: viewDataSource,
            CleaningMinutesToDisplay: response.data.data.equipmentQuantity
        }, () => { this.setUpdateOrCorrectionConditionally(this.state.quickShiftFormData.isAccrual, this.state.quickShiftFormData.invoiceId, this.state.quickShiftFormData.paymentId) });
        this.offeringsTypeDataSource(viewDataSource.serviceTypeId);
        if (this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
            let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(
                this.state.CleaningMinutesToDisplay
            );
            this.setState({
                hours:
                    quantitySubFields.length > 0 && quantitySubFields[0]
                        ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                        : 0,
                minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00"
            });
        }
    }

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                venueId: dxValueChange.value,
            },
        });
    };

    handleChangeProviderTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                providerId: dxValueChange.value
            },
        });
        this.setFeeTaxApplicableOnInitialLoad();
        this.setTaxApplicableStatus(dxValueChange.value);
    }

    handleChangeServiceTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if(dxValueChange.event !== undefined){
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    serviceTypeId: dxValueChange.value,                    
                },
                isRequestCalculationChange: true,
            }, () => { this.offeringsTypeDataSource(dxValueChange.value) });
            // if (dxValueChange.value == ServiceType.Entertainment) {
            //     this.setState({
            //         quickShiftFormData: {
            //             ...this.state.quickShiftFormData,
            //             equipmentScheduleQuantity: 1,
            //         },
            //         isRequestCalculationChange: true,
            //     });
            // }
            this.setQuantityAutomaticallyBasedOnService();
        }        
        this.handleMultiplierValueSelection();
        this.selectHardApprovalHoldPaymentValue();

    }

    selectHardApprovalHoldPaymentValue = () => {
        // if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment) {
        //     this.setState({
        //         quickShiftFormData: {
        //             ...this.state.quickShiftFormData,
        //             hardApproval: "No",
        //             holdPayment: "Yes"
        //         },
        //     });            
        // }
        // else 
        if (this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning || this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment ||
                this.state.quickShiftFormData.serviceTypeId == ServiceType.Miscelleanous || this.state.quickShiftFormData.serviceTypeId == ServiceType.Maintenance || 
                this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning || this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    hardApproval: "No",
                    holdPayment: "Yes"
                },
            });
        } 
        else if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Security) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    hardApproval: "No",
                    holdPayment: "No"
                },
            });
        }        
    }

    handleMultiplierValueSelection = () => {
        let serviceFeeMatrixDerivedValues = this.billableItemUtils.serviceFeeMatrixDerivation(this.state.quickShiftFormData.clientId, this.state.quickShiftFormData.serviceTypeId, this.state.quickShiftFormData.serviceSubTypeId);
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                fee_Percentage_Local_Ccy: this.sharedUtils.convertStringToFloat(serviceFeeMatrixDerivedValues.vaueOfMultiplier),
                isAccrual: serviceFeeMatrixDerivedValues.valueOfAccrual,
            }
        });

    };

    setQuantityAutomaticallyBasedOnService = () => {
        if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Maintenance ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Miscelleanous ||
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.AdditionalCleaning)) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    equipmentQuantity: 1,
                    equipmentScheduleQuantity: 1,
                    billable_Start: "",
                    billable_Finish: "",
                    shift_End: "",
                    shift_Start: ""
                },
            },()=>{
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
            });
        }
        else if(this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning){
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    equipmentScheduleQuantity: this.state.quickShiftFormData.equipmentQuantity,
                },
            },()=>{
                this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
            });
        } else if(this.state.quickShiftFormData.serviceTypeId == ServiceType.Security){
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    }

    dateToChangeLogic = () => {
        if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.quickShiftFormData.serviceSubTypeId,
                UnitOfMeasureTypes.time,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.time);
        } else if (
            this.lookupService.isSubTypeIdEqualToUnitOfMeasureType(
                this.state.quickShiftFormData.serviceSubTypeId,
                UnitOfMeasureTypes.quantity,
                this.state.unitOfMeasureList
            )
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        } else if (
            this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment
        ) {
            this.disableFieldsBasedOnUnitOfMeasure(UnitOfMeasureTypes.quantity);
        }
    };


    disableFieldsBasedOnUnitOfMeasure = (unitOfMeasureType: string) => {
        let dateTo = this.addEditutils.setDateToField(
            unitOfMeasureType,
            this.state.quickShiftFormData.date,
            this.state.quickShiftFormData.serviceTypeId,
            this.state.quickShiftFormData.serviceSubTypeId
        );
        let isLockTimeFields = this.addEditutils.lockTimeFields(unitOfMeasureType);
        let isLockQuantityFields = this.addEditutils.lockQuantityFields(unitOfMeasureType);
        this.setState({
            IsLockTimeFields: isLockTimeFields,
            IsLockQuantityFields: isLockQuantityFields,
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                dateTo: dateTo,
            },
        });
    };

    handleChangeServiceSubTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    serviceSubTypeId: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            }, () => { this.filterDataSourceForRole(this.state.typeLookUp, dxValueChange.value) });
        }
        this.setQuantityAutomaticallyBasedOnService();
        this.handleMultiplierValueSelection();
        this.selectHardApprovalHoldPaymentValue();
    }

    handleChangeClientTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    clientId: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            });
        }
        this.handleMultiplierValueSelection();
        this.setFeeTaxApplicableOnInitialLoad();
        this.setTaxApplicableStatus(dxValueChange.value);
    }

    handleChangeWorkDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                description: dxValueChange.value,
            },
        });
    };

    handleChangeWorkOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                otherDescription: dxValueChange.value,
            },
        });
    };


    handleChangeOriginator = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    originator: {
                        id: dxValueChange.value,
                        value: ""
                    }
                },
            });
        }
    }

    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                comments: dxValueChange.value,
            },
        });
    };

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                internalComments: dxValueChange.value,
            },
        });
    };

    hideWarningPopup = () => {
        this.setState({
            showWarningPopup: false,
            showCalculatedPopup: false
        });
    };

    showCalculationPopup = () => {
        this.setState({showCalculatedPopup: true}); 
    }

    onSubmit = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let recalculate = false;
        recalculate = this.state.isPopupProviderNetChange == true || this.state.isPopupEventNetChange == true ? true : false;
        let quickShiftSubmitRequest = this.utils.convertFormDataToQuickShiftRequest(this.state.quickShiftFormData, recalculate); 

        // for PBI-18685 start
        if(this.state.isPopupProviderNetChange == true) {                
            quickShiftSubmitRequest.providerNetOverride = quickShiftSubmitRequest.provider_Pay_Amount_Local_Ccy;
            
        }
        if(this.state.isPopupEventNetChange == true) {
            quickShiftSubmitRequest.feeAmountOverride = quickShiftSubmitRequest.fee_Amount_Local_Ccy;
            quickShiftSubmitRequest.providerNetOverride = quickShiftSubmitRequest.provider_Pay_Amount_Local_Ccy;
        }
        if(this.state.isPopupProviderNetChange == false) {   
            if(this.state.isPopupEventNetChange == true) quickShiftSubmitRequest.providerNetOverride = quickShiftSubmitRequest.provider_Pay_Amount_Local_Ccy;             
            else quickShiftSubmitRequest.providerNetOverride = "";                
        }
        if(this.state.isPopupEventNetChange == false) {
            quickShiftSubmitRequest.feeAmountOverride = "";
        }
        // for PBI-18685 end

        let billableItemSubmitRequestArr: QuickBillableItemSubmitRequest[] = [];
        billableItemSubmitRequestArr.push(quickShiftSubmitRequest);        
        //passing object data to the server
        this.service
            .uploadEditAddDataV3(billableItemSubmitRequestArr)
            .then(this.handleFormDataSubmissionSuccess)
            .catch(this.handleFormSubmissionFailure)
            .finally(() => {
                this.hideLoadpanel();
            });
    };    

    handleFormDataSubmissionSuccess = (response: AxiosResponse<any>) => { 
        this.hideLoadpanel();
        this.handleSuccessContinueDataSource(response); 
        this.setState({
            showCalculatedPopup: false,
            showWarningPopup: false,
            confirmationPanelVisible: true,
            errorPanelVisible: false
        });
        if(this.props.navigateFrom == "queryGrid"){
            this.props.hidePopup(false);
        }
        else if (this.props.location.state.pointOfNavigation && this.props.location.state.pointOfNavigation.toLowerCase() == "findanartistlandingpage") {
            this.props.history.goBack();
        }
        else{
            this.addEditutils.renderRedirectCommonMethod(this.props, true, "apply",'');
            // this.props.history.goBack();
            // this.props.history.push({
            //     pathname: "/simpleShifts",
            //     state: {
            //         pointOfNavigation: "popUp",
            //         startDate: this.props.location.state.filterStartDate,
            //         endDate: this.props.location.state.filterEndDate,
            //     },
            // });
        }
    };

    submitShift = () => {
        let errors = [];
        if(this.state.quickShiftFormData.serviceTypeId == ""){
            let error = {
                "columnName": "Service",
                "errorType": "Error",
                "errorMessage": "Service has not been provided. Please select service."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceSubTypeId == ""){
            let error = {
                "columnName": "Sub Type",
                "errorType": "Error",
                "errorMessage": "Sub Type has not been provided. Please select sub type."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.clientId == ""){
            let error = {
                "columnName": "Client",
                "errorType": "Error",
                "errorMessage": "Client has not been provided. Please select client."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning && this.state.hours == 0){
            let error = {
                "columnName": "Hours",
                "errorType": "Error",
                "errorMessage": "Please provide the Hours before submitting the Form"
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning && this.state.minutes == ""){
            let error = {
                "columnName": "Minutes",
                "errorType": "Error",
                "errorMessage": "Minutes has not been provided. Please select minutes."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == serviceTypes.Entertainment && this.state.quickShiftFormData.shift_Start == undefined){
            let error = {
                "columnName": "Start Time",
                "errorType": "Error",
                "errorMessage": "Start Time has not been provided. Please select start time."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == serviceTypes.Entertainment && this.state.quickShiftFormData.shift_End == undefined){
            let error = {
                "columnName": "Finish Time",
                "errorType": "Error",
                "errorMessage": "Finish Time has not been provided. Please select finish time."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel && this.state.quickShiftFormData.billable_Start == undefined){
            let error = {
                "columnName": "Finish Time",
                "errorType": "Error",
                "errorMessage": "Finish Time has not been provided. Please select finish time."
            };
            errors.push(error);
        }
        if(this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel && this.state.quickShiftFormData.billable_Finish == undefined){
            let error = {
                "columnName": "Finish Time",
                "errorType": "Error",
                "errorMessage": "Finish Time has not been provided. Please select finish time."
            };
            errors.push(error);
        }  
        if(errors.length > 0){            
            this.setState({
                errorMessage: errors,
                errorPanelVisible: true,
                confirmationPanelVisible: false,
            });
        }     
        else{
            if(this.state.isRequestCalculationChange){
                this.financialCalculations();
            }
            else{
                let providerNet = this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy.toString() == "" || this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy.toString() == "0.00" || 
                    this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy.toString() == "£0.00" ? "0" : this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy;
                let feeNet = this.state.quickShiftFormData.fee_Amount_Local_Ccy.toString() == "" || this.state.quickShiftFormData.fee_Amount_Local_Ccy.toString() == "0.00" || 
                    this.state.quickShiftFormData.fee_Amount_Local_Ccy.toString() == "£0.00" ? "0" : this.state.quickShiftFormData.fee_Amount_Local_Ccy;
                let clientNet = this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy.toString() == "" || this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy.toString() == "0.00" || 
                    this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy.toString() == "£0.00" ? "0" : this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy;
                
                if(providerNet == "0" || feeNet == "0" || clientNet == "0"){
                        this.setState({showWarningPopup: true});                
                } 
                else{
                    this.setState({showCalculatedPopup: true}); 
                } 
            }
        }
    }

    financialCalculations = () => {  
        this.setState({
            loadPanelVisible: true,
        });       
        let calCulationObject = this.addEditutils.convertQuickAddCalcDataToCalcRequest(
            this.state.quickShiftFormData,
            null,
            0,
            this.props.location && this.props.location.state ? this.props.location.state.id : this.props.billableItemId
        );

        this.service
            .uploadFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                    disabledApplybutton: true,
                });
                this.failureMessageVisible();
                this.setState({
                    loadPanelVisible: false,
                }); 
            })
            .finally(() => {
                this.setState({
                    loadPanelVisible: false,
                }); 
            });

    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: CalculationResponse = response.data.data;
        let providerNet = serverResponse.providerPayAmount.toString() == "" || serverResponse.providerPayAmount.toString() == "0.00" || 
            serverResponse.providerPayAmount.toString() == "£0.00" ? "0" : serverResponse.providerPayAmount;
        let feeNet = serverResponse.grossProfitAmountLocal.toString() == "" || serverResponse.grossProfitAmountLocal.toString() == "0.00" || 
            serverResponse.grossProfitAmountLocal.toString() == "£0.00" ? "0" : serverResponse.grossProfitAmountLocal;
        let clientNet = serverResponse.clientBillableAmountLocal.toString() == "" || serverResponse.clientBillableAmountLocal.toString() == "0.00" || 
            serverResponse.clientBillableAmountLocal.toString() == "£0.00" ? "0" : serverResponse.clientBillableAmountLocal;
        this.setState({
            errorPanelVisible: false,
            disabledApplybutton: false,
            loadPanelVisible: false,
            isRequestCalculationChange: false,
        });
        if(this.state.quickShiftFormData.updateOrCorrection == "false"){
            this.setState({
                editViewDataItem: {
                    ...this.state.quickShiftFormData,
                    scheduledMinutes: serverResponse.billableMinutes,
                }
            })
        }
        if(providerNet == "0" || feeNet == "0" || clientNet == "0"){
                this.setState({showWarningPopup: true});                
        } 
        else{
            this.setState({showCalculatedPopup: true}); 
        } 
        // Convert server response to proper data types
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                billable_Minutes: this.sharedUtils.convertStringToFloat(serverResponse.billableMinutes),
                provider_Pay_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmount),
                provider_Pay_Tax_Local_Ccy: this.state.quickShiftFormData.provider_Tax_Applicable == "Yes" || this.state.quickShiftFormData.provider_Tax_Applicable == "True" ? this.sharedUtils.convertStringToFloat(serverResponse.providerPayTaxLocalCcy) : 0.00,
                provider_Pay_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.providerPayTotalLocalCcy),
                provider_Pay_Currency_Local_Ccy: serverResponse.providerPayAmountLocalCurrency, // Ex: "GBP"
                client_Billable_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableAmountLocal
                ),
                client_Billable_Tax_Local_Ccy: this.state.quickShiftFormData.client_Tax_Applicable == "Yes" || this.state.quickShiftFormData.client_Tax_Applicable == "True" ? this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableTaxLocalCcy
                ) : 0.00,
                client_Billable_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(
                    serverResponse.clientBillableTotalLocalCcy
                ),
                client_Billable_Currency_Local_Ccy: serverResponse.clientBillableAmountLocalCurrency,
                fee_Amount_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
                fee_Tax_Local_Ccy: this.state.quickShiftFormData.fee_Tax_Applicable == "Yes" || this.state.quickShiftFormData.fee_Tax_Applicable == "True" ? this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTaxLocalCcy) : 0.00,
                fee_Total_Local_Ccy: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitTotalLocalCcy),
                fee_Currency_Local_Ccy: serverResponse.grossProfitAmountLocalCurrency,
                equipmentBillableQuantity: this.sharedUtils.convertStringToFloat(serverResponse.billableQuantity),
                billableRate: this.sharedUtils.convertStringToFloat(serverResponse.billableRate),
                providerPayAmountLocal: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocal),
                providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
                providerPayAmountLocalWOExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalWOExtras),
                providerFasterPayFee: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayFee),
                providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
                accrual: serverResponse.accrual
                    ? {
                        id: serverResponse.accrual.id,
                        accrualTypeId: serverResponse.accrual.accrualTypeId,
                        accrualStatusId: serverResponse.accrual.accrualStatusId,
                        accrualBillableItemId: serverResponse.accrual.accrualBillableItemId,
                        accrualInvoiceId: serverResponse.accrual.accrualInvoiceId,
                        providerPayAmountAccrual: serverResponse.accrual.providerPayAmountAccrual,
                        providerPayAmountTaxAccrual: this.state.quickShiftFormData.provider_Tax_Applicable == "Yes" || this.state.quickShiftFormData.provider_Tax_Applicable == "True" ? serverResponse.accrual.providerPayAmountTaxAccrual : 0.00,
                        providerPayAmountTotalAccrual: serverResponse.accrual.providerPayAmountTotalAccrual,
                        grossProfitAmountAccrual: serverResponse.accrual.grossProfitAmountAccrual,
                        grossProfitAmountTaxAccrual: this.state.quickShiftFormData.fee_Tax_Applicable == "Yes" || this.state.quickShiftFormData.fee_Tax_Applicable == "True" ? serverResponse.accrual.grossProfitAmountTaxAccrual : 0.00,
                        grossProfitAmountTotalAccrual: serverResponse.accrual.grossProfitAmountTotalAccrual,
                        clientBillableAmountAccrual: serverResponse.accrual.clientBillableAmountAccrual,
                        clientBillableAmountTaxAccrual: this.state.quickShiftFormData.client_Tax_Applicable == "Yes" || this.state.quickShiftFormData.client_Tax_Applicable == "True" ? serverResponse.accrual.clientBillableAmountTaxAccrual : 0.00,
                        clientBillableAmountTotalAccrual: serverResponse.accrual.clientBillableAmountTotalAccrual,
                        lastUpdatedOn: serverResponse.accrual.lastUpdatedOn,
                        lastUpdatedBy: serverResponse.accrual.lastUpdatedBy,
                    }
                    : null,
            },
            expectedFee: this.sharedUtils.convertStringToFloat(serverResponse.grossProfitAmountLocal),
        });

        // if (this.props.location && this.props.location.state && this.props.location.state.isAdjust) {
        //     // below block is applicable for Adjustment
        //     if (
        //         this.state.isChangeBillableStart ||
        //         this.state.isChangeBillableFinish ||
        //         this.state.isChangeRate ||
        //         this.state.isChangeQuantity ||
        //         this.state.isChangeHour ||
        //         this.state.isChangeMinute ||
        //         this.state.editViewDataItem.noCharge
        //     ) {
        //         if (
        //             (this.state.acceptDate != null ||
        //                 this.state.acceptDate != undefined ||
        //                 this.state.editViewDataItem.paymentId != null ||
        //                 this.state.editViewDataItem.paymentId != undefined ||
        //                 this.state.approvalDate != null ||
        //                 this.state.approvalDate != undefined ||
        //                 this.state.editViewDataItem.invoiceId != null ||
        //                 this.state.editViewDataItem.invoiceId != undefined) &&
        //             (this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[1].id ||
        //                 this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[3].id ||
        //                 this.state.editViewDataItem.itemTypeId == this.state.itemTypeLookUp[4].id)
        //         ) {
        //             if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 1) {
        //                 this.setState({
        //                     calculationMessageClassName: "check-red",
        //                 });
        //                 if (UserService.isUserInGroup(RoleGroupNames.VenueManager)) {
        //                     this.state.calculationMessage.push(
        //                         "The new cost" +
        //                         currencyTypeToSymbolMatrix[
        //                         this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
        //                         ] +
        //                         this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
        //                         " will be sent for processing after this has been created"
        //                     );
        //                 } else {
        //                     this.state.calculationMessage.push(
        //                         "An additional cost " +
        //                         currencyTypeToSymbolMatrix[
        //                         this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
        //                         ] +
        //                         this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
        //                         " will be shown on the Client's next available cost report (after approval)"
        //                     );
        //                 }
        //             } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == -1) {
        //                 this.setState({
        //                     calculationMessageClassName: "check-blue",
        //                 });
        //                 if (UserService.isUserInGroup(RoleGroupNames.ProviderScheduler)) {
        //                     this.state.calculationMessage.push(
        //                         "The new cost " +
        //                         currencyTypeToSymbolMatrix[
        //                         this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
        //                         ] +
        //                         this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
        //                         " will be sent for processing after this has been created"
        //                     );
        //                 } else {
        //                     this.state.calculationMessage.push(
        //                         "A credit of " +
        //                         currencyTypeToSymbolMatrix[
        //                         this.state.editViewDataItem.provider_Pay_Currency_Local_Ccy
        //                         ] +
        //                         this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy.toFixed(2) +
        //                         " will be shown on the Client's next available cost report (after approval)"
        //                     );
        //                 }
        //             } else if (Math.sign(this.state.editViewDataItem.provider_Pay_Amount_Local_Ccy) == 0) {
        //                 this.state.calculationMessage.push("");
        //             }
        //         }
        //     }
        // }

        // this.providerPayTaxCalculation();
        // this.feeTaxCalculation();
        // this.clientTaxCalculation();
        // taking the latest amount from state calculating the total amount field
        let feeTax = this.state.quickShiftFormData.fee_Tax_Local_Ccy ? this.state.quickShiftFormData.fee_Tax_Local_Ccy : 0;
        let clientTax = this.state.quickShiftFormData.client_Billable_Tax_Local_Ccy ? this.state.quickShiftFormData.client_Billable_Tax_Local_Ccy : 0;
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                provider_Pay_Total_Local_Ccy:
                    this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy +
                    this.state.quickShiftFormData.provider_Pay_Tax_Local_Ccy,
                fee_Total_Local_Ccy:
                    this.state.quickShiftFormData.fee_Amount_Local_Ccy + feeTax,
                client_Billable_Total_Local_Ccy:
                    this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy + clientTax,
            },
        });
        if (this.state.quickShiftFormData.isAccrual && serverResponse.accrual) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    accrual: {
                        ...this.state.quickShiftFormData.accrual,
                        providerPayAmountTotalAccrual:
                            serverResponse.accrual.providerPayAmountAccrual +
                            serverResponse.accrual.providerPayAmountTaxAccrual,
                        grossProfitAmountTotalAccrual:
                            serverResponse.accrual.grossProfitAmountAccrual +
                            serverResponse.accrual.grossProfitAmountTaxAccrual,
                        clientBillableAmountTotalAccrual:
                            serverResponse.accrual.clientBillableAmountAccrual +
                            serverResponse.accrual.clientBillableAmountTaxAccrual,
                    },
                },
            });
        }
        // this.handleItemTypeSelection();
    };

    failureMessageVisible = () => {
        this.setState({
            errorPanelVisible: true,
        });
    }; 

    onContinue = () => {
        this.setState({
            confirmationPanelVisible: true,
            errorPanelVisible: false
        });
        if(this.props.navigateFrom == "queryGrid"){
            this.props.hidePopup(false);
        }
        else{
            this.props.history.goBack();
            this.props.history.push({
                pathname: "/simpleShifts",
                state: {
                    pointOfNavigation: "popUp",
                    startDate: this.props.location.state.filterStartDate,
                    endDate: this.props.location.state.filterEndDate,
                },
            });
        }
    }

    handleSuccessContinueDataSource = (response: AxiosResponse<any>) => {
        // Convert to proper data types
        let viewDataSource = this.utils.convertQuickShiftResponeToFormData(response.data.data[0]);
        this.setState({
            quickShiftFormData: viewDataSource,
            CleaningMinutesToDisplay: response.data.data[0].equipmentQuantity
        });
        this.offeringsTypeDataSource(viewDataSource.serviceTypeId);
        if (this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
            let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(
                this.state.CleaningMinutesToDisplay
            );
            this.setState({
                hours:
                    quantitySubFields.length > 0 && quantitySubFields[0]
                        ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                        : 0,
                minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00"
            });
        }
    }

    hideLoadpanel() {
        this.setState({ loadPanelVisible: false });
    }

    handleFormSubmissionFailure = (err: any) => {
        this.hideLoadpanel();
        this.setState({
            errorMessage:
                err.response && err.response.data && err.response.data.data.length > 0 && err.response.data.data[0].validationErrors
                    ? JSON.parse(JSON.stringify(err.response.data.data[0].validationErrors))
                    : null,
            errorPanelVisible: true,
            confirmationPanelVisible: false,
            showCalculatedPopup: false,
        });
    };

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                hours: dxValueChange.value,
                isRequestCalculationChange: true,
            });
            this.state.hourChangeTimer = setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                minutes: dxValueChange.value,
                isRequestCalculationChange: true,
            });
            this.state.hourChangeTimer = setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    handleChangeTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    typeId: dxValueChange.value
                },
            });
        }
    };

    handleChangeShiftStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    shift_Start: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            });
        }
    };

    handleChangeShiftEnd = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    shift_End: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            });
        }
    };

    handleChangeBillableStart = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            if (this.props.location.state?.id == 0) {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        billable_Start: dxValueChange.value,
                        shift_Start: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
            else {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        billable_Start: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
        }
    };

    handleChangeBillableEnd = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            if (this.props.location.state.id == 0) {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        billable_Finish: dxValueChange.value,
                        shift_End: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
            else {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        billable_Finish: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
        }
    };

    handleChangeName = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    name: dxValueChange.value
                },
            });
        }
    };

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    rate_Decimal: dxValueChange.value,
                    billableRate: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            });
            if (this.props.location.state.id == 0) {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        scheduleRate: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
        }
    };

    handleChangeIdentificationNumber = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    identification_Number: dxValueChange.value
                },
            });
        }
    };

    handleChangeAuthorisedBy = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                requestApprover: dxValueChange.value
            },
        });
    };

    handleChangeAdditionalInfo = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    additionalInformation: dxValueChange.value
                },
            });
        }
    };

    handleChangeQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    equipmentQuantity: dxValueChange.value,
                },
                isRequestCalculationChange: true,
            });
            if (this.props.location.state.id == 0) {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        equipmentScheduleQuantity: dxValueChange.value,
                    },
                    isRequestCalculationChange: true,
                });
            }
        }
    };


    //Helper function to recalulate the Quantity when either of the minute or Hour field varies.
    reCalculateQuantity = () => {
        let delimeter = ".";
        let { hours, minutes } = this.state; //Destructure the state to pull out the contents.
        let convertedHours: string = this.sharedUtils.convertNumberToString(hours);
        let quantity: string = `${convertedHours}${delimeter}${minutes}`;
        this.setState({
            quickShiftFormData: {
                ...this.state.quickShiftFormData,
                equipmentQuantity: this.sharedUtils.convertStringToFloat(quantity),
            },
        });
        if (this.props.location.state.id == 0) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    equipmentScheduleQuantity: this.sharedUtils.convertStringToFloat(quantity),
                },
            });
        }
    };

    onContentReadyDateBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    date: dxValueChange.value,
                },
            });
            if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening ||
                this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning ||
                this.state.quickShiftFormData.serviceTypeId == ServiceType.Maintenance ||
                this.state.quickShiftFormData.serviceTypeId == ServiceType.Miscelleanous) {
                this.setState({
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        dateTo: dxValueChange.value
                    }
                });
            }
            else {
                this.dateToChangeLogic();
            }
        }
    };

    handleChangeRequestedOn = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    requestDate: dxValueChange.value,
                },
            });
        }
    };

    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    dateTo: dxValueChange.value,
                },
            });
        }
    };

    setTaxApplicableStatus = (businessEntityId: string) => {
        let taxStatus = this.state.businessEntityTaxApplicableStatuses.find(
            (item) => item.businessEntityID === businessEntityId
        );
        let taxApplicable: string = "No";

        // A client/provider is tax applicable if they have a tax registration number
        if (taxStatus && taxStatus.taxApplicable) {
            taxApplicable = "Yes";
        }

        if (taxStatus) {
            // Based on entity type, populate the tax applicable dropdown
            switch (taxStatus.businessEntityType) {
                case BusinessEntityTypes.Client:
                    this.setState({
                        quickShiftFormData: {
                            ...this.state.quickShiftFormData,
                            client_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
                case BusinessEntityTypes.Provider:
                    this.setState({
                        quickShiftFormData: {
                            ...this.state.quickShiftFormData,
                            provider_Tax_Applicable: taxApplicable,
                        },
                    });
                    break;
            }
        }
    };

    onCancel = () => {
        if(this.props.location.state?.pointOfNavigation == "ManageShifts"){
            // this.props.history.goBack();
            this.props.history.push({
                pathname: "/manageviewshift",
                state: {
                    pointOfNavigation: "popUp",
                    dateFrom: this.props.location.state.filterStartDate,
                    dateTo: this.props.location.state.filterEndDate,
                },
            });
        }   
        else if(this.props.navigateFrom == "queryGrid"){
            this.props.hidePopup(false);
        }
        else if (this.props.location.state?.pointOfNavigation && this.props.location.state.pointOfNavigation.toLowerCase() == "findanartistlandingpage") {
            this.props.history.goBack();
        }
        else{   
            this.addEditutils.renderRedirectCommonMethod(this.props, false, "cancel", ""); 
            // this.props.history.push({
            //     pathname: "/simpleShifts",
            //     state: {
            //         pointOfNavigation: "popUp",
            //         startDate: this.props.location.state.filterStartDate,
            //         endDate: this.props.location.state.filterEndDate,
            //     },
            // });
        }
    }

    handleChangeStatusTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    billableStatusLookupId: dxValueChange.value,
                },
            });
        }
    };

    handleChangeCorrectionUpdate = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                quickShiftFormData: {
                    ...this.state.quickShiftFormData,
                    updateOrCorrection: dxValueChange.value,
                },
            });
        }
    };

    handleChangeProviderNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState(
                {
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        provider_Pay_Amount_Local_Ccy: dxValueChange.value,
                        client_Billable_Amount_Local_Ccy: null,                        
                    },
                    isPopupProviderNetChange: true,
                });
        }
    };
    handleChangeEventNet = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState(
                {
                    quickShiftFormData: {
                        ...this.state.quickShiftFormData,
                        fee_Amount_Local_Ccy: dxValueChange.value,
                        client_Billable_Amount_Local_Ccy: null,
                    },
                    isPopupEventNetChange: true,
                });
        }
    };

    hidePopup = (isPopupVisible: boolean) => {
        this.props.hidePopup(isPopupVisible);
    }

    getCreatedAndUpdatedString = () => {
        let finalString = '';
        if(this.state.quickShiftFormData.dateCreated){
          let formattedDate = moment(this.state.quickShiftFormData.dateCreated).format("Do MMM YYYY HH:mm:ss");
          let createdBy = '';
          if(this.state.quickShiftFormData.createdBy){
            createdBy = this.state.quickShiftFormData.createdBy;
          }
          finalString = `Created On ${formattedDate} ${createdBy?`by ${createdBy}`:''}`;
        }
        if(this.state.quickShiftFormData.lastUpdatedOnTZ){
            if(finalString){
                finalString += ' / ';
            }
            let formattedDate = moment(this.state.quickShiftFormData.lastUpdatedOnTZ).format("Do MMM YYYY HH:mm:ss");
            let updatedBy = '';
            if(this.state.quickShiftFormData.lastUpdatedBy){
                updatedBy = this.state.quickShiftFormData.lastUpdatedBy;
            }
            finalString += `Last Updated On ${formattedDate} ${updatedBy?`by ${updatedBy}`:''}`;
          }
          if(finalString){
            finalString =` (${finalString})`;
          }
       return finalString;
    }

    renderCommonPart = () => {
        let isShowFasterPayFee: boolean = true;
        let isShowProviderPay: boolean = true;
        let isShowType: boolean = true;
        if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && (this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel || this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment)) {
            isShowType = false;
        }
        if (this.state.quickShiftFormData.fasterPayment && this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment) {
            isShowFasterPayFee = false;
        }
        if (this.state.quickShiftFormData.provider_Tax_Applicable == "True" || this.state.quickShiftFormData.provider_Tax_Applicable == "Yes") {
            isShowProviderPay = false;
        }
        let isShowRequestedOn: boolean = true;
        if (this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.AdditionalCleaning ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Maintenance ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Miscelleanous) {
            isShowRequestedOn = false;
        }
        let buttonName: string = "";
        if (this.props.location.state?.id || this.props.billableItemId) {
            buttonName = "Apply";
        }
        else {
            buttonName = "Add";
        }
        let isHide: boolean = false;
        let showOtherDesc: boolean = false;
        if ((this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && (this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel || this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment)) ||
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning) ||
            this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment) {
            isHide = true;
        }
        if ((this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.AdditionalCleaning && this.state.quickShiftFormData.typeId == "227") || 
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment && this.state.quickShiftFormData.serviceSubTypeId == "50") || 
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning && this.state.quickShiftFormData.serviceSubTypeId == "108" && this.state.quickShiftFormData.typeId == "516") || 
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening && this.state.quickShiftFormData.serviceSubTypeId == "106" && this.state.quickShiftFormData.typeId == "405") || 
            (this.state.quickShiftFormData.serviceTypeId == ServiceType.Maintenance || this.state.quickShiftFormData.serviceTypeId == ServiceType.Miscelleanous)) {
                showOtherDesc = true;
        }
        return (
            <div id="additionalCleaningForm" className={this.props.navigateFrom== "queryGrid" ? "page-content--without-sidebar-hidden-mobile" : "page-content--with-sidebar-hidden-mobile"}>
                <div className="col-12">
                    <h3 className="card-form__page-heading" hidden={this.props.navigateFrom== "queryGrid"}>Quick - Add Shift</h3>
                    <form data-testid="addEditPayment-form" className="card card-form my-5">
                        <div className="card-body">
                            <section>
                                <div className="card-form__field card-form__field--wide">
                                    Status
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <DropDownFromLookupTable
                                        lookupTypeIndex={LookupTypeIndexes.statusType}
                                        onValueChanged={this.handleChangeStatusTypeSelect}
                                        value={this.state.quickShiftFormData.billableStatusLookupId} 
                                        disableSignal={true}/>
                                    <input
                                        data-testid={LookupTypeIndexes.statusType}
                                        type="hidden"
                                        name={LookupTypeIndexes.statusType}
                                        value={
                                            this.state.quickShiftFormData.billableStatusLookupId
                                                ? this.state.quickShiftFormData.billableStatusLookupId
                                                : 0
                                        }
                                    ></input>
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    Service
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <DropDownFromLookupTableDynamic
                                        lookupTypeIndex={LookupTypeIndexes.serviceType}
                                        isRoot={true}
                                        parentMappingId={"0"}
                                        onValueChanged={this.handleChangeServiceTypeSelect}

                                        value={this.state.quickShiftFormData.serviceTypeId}
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.serviceType}
                                        type="hidden"
                                        name={LookupTypeIndexes.serviceType}
                                        value={
                                            this.state.quickShiftFormData.serviceTypeId
                                                ? this.state.quickShiftFormData.serviceTypeId
                                                : ""
                                        }
                                    ></input>
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    Sub Type
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                        isRoot={false}
                                        parentMappingId={this.state.quickShiftFormData.serviceTypeId}
                                        displayExpression={"value"}
                                        onValueChanged={this.handleChangeServiceSubTypeSelect}
                                        value={this.state.quickShiftFormData.serviceSubTypeId}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--wide" hidden={isShowType}>
                                    Type
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                        <DropDownFromLookupTableDynamic
                                            lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                            isRoot={false}
                                            parentMappingId={this.state.quickShiftFormData.serviceSubTypeId}
                                            onValueChanged={this.handleChangeTypeSelect}

                                            value={this.state.quickShiftFormData.typeId}
                                        />
                                        <input
                                            data-testid={LookupTypeIndexes.offeringsType}
                                            type="hidden"
                                            name={LookupTypeIndexes.offeringsType}
                                            value={
                                                this.state.quickShiftFormData.typeId
                                                    ? this.state.quickShiftFormData.typeId
                                                    : ""
                                            }
                                        ></input>
                                </div>
                                {this.renderServiceWiseType()}
                                <div className="card-form__field card-form__field--wide"  hidden={!showOtherDesc}>
                                    Description
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <TextBox
                                        onValueChanged={this.handleChangeWorkOtherDescription}
                                        value={this.state.quickShiftFormData.otherDescription}
                                        maxLength={25}
                                    ></TextBox>
                                </div>
                                <div className="card-form__field card-form__field--wide"  hidden={showOtherDesc}>
                                    Description
                                    <sup className="card-form__mandatory-symbol" hidden= {this.state.quickShiftFormData.serviceTypeId == ServiceType.Entertainment || this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning || 
                                                this.state.quickShiftFormData.serviceTypeId == ServiceType.WindowCleaning || this.state.quickShiftFormData.serviceTypeId == ServiceType.Gardening ||
                                                this.state.quickShiftFormData.serviceTypeId == ServiceType.Security}>*</sup>
                                    <TextBox
                                        onValueChanged={this.handleChangeWorkDescription}
                                        value={this.state.quickShiftFormData.description}
                                        maxLength={25}
                                    ></TextBox>
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    Client
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.clientType}
                                        onValueChanged={this.handleChangeClientTypeSelect}
                                        isRoot={false}
                                        parentMappingId={""}
                                        displayExpression={"value"}
                                        value={this.state.quickShiftFormData.clientId}
                                        recordType={this.props.location.state?.id ? "all" : "active"}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    {this.props.t("additionalCleaning.raiseForm.venue.label")}
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.venueType}
                                        onValueChanged={this.handleChangeVenueTypeSelect}
                                        isRoot={true}
                                        parentMappingId={this.state.quickShiftFormData.clientId}
                                        value={this.state.quickShiftFormData.venueId}
                                        displayExpression={"value"}
                                        recordType={this.props.location.state?.id ? "all" : "active"}
                                    />
                                </div>

                                <div className="card-form__field card-form__field--wide">
                                    Provider
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <LookupTypeListDynamic
                                        lookupTypeIndex={LookupTypeIndexes.providerType}
                                        onValueChanged={this.handleChangeProviderTypeSelect}
                                        isRoot={false}
                                        parentMappingId={""}
                                        displayExpression={"value"}
                                        value={this.state.quickShiftFormData.providerId}
                                        recordType={this.props.location.state?.id ? "all" : "active"}
                                    />
                                </div>                                
                                {this.renderServiceWisePart()}   
                                
                                <div hidden={isHide}>
                                    {/* <div className="card-form__field card-form__field--very-narrow" style={{ marginRight: '20px' }}>
                                        Work Date(s)
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <DateBox
                                            onValueChanged={this.handleChangeDate}
                                            value={
                                                this.state.quickShiftFormData.date
                                                    ? this.state.quickShiftFormData.date
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                        ></DateBox>
                                    </div>
                                    <div className="card-form__field card-form__field--very-narrow">
                                        Date To
                                        <sup className="card-form__mandatory-symbol">*</sup>
                                        <DateBox
                                            onValueChanged={this.handleChangeDateTo}
                                            value={
                                                this.state.quickShiftFormData.dateTo
                                                    ? this.state.quickShiftFormData.dateTo
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                            displayFormat="dd/MM/yyyy"
                                            onContentReady={this.onContentReadyDateBox}
                                        ></DateBox>
                                    </div> */}

                                    {this.commonDateSection()} 
                                </div>                         
                               
                                <div className="card-form__field card-form__field--very-narrow" hidden={isHide}>
                                    {this.props.t("additionalCleaning.raiseForm.agreedCost.label")}
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <NumberBox
                                        format={{ type: "currency", precision: 2, currency: "GBP" }}
                                        onValueChanged={this.handleChangeRate}
                                        valueChangeEvent="keyup"
                                        value={this.state.quickShiftFormData.rate_Decimal}
                                        onContentReady={this.onContentReadyNumberBox}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowFasterPayFee}>
                                    Faster Pay Fee
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onContentReady={this.onContentReadyNumberBox}
                                        value={this.state.quickShiftFormData.providerFasterPayFee}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowFasterPayFee}>
                                    Adjusted Artist Fee
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onContentReady={this.onContentReadyNumberBox}
                                        value={this.state.quickShiftFormData.providerPayAmountLocal}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowProviderPay}>
                                    Provider NET
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy}
                                        placeholder={"Calculation Pending...."}
                                        disabled={true}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowProviderPay}>
                                    Provider VAT
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        value={this.state.quickShiftFormData.provider_Pay_Tax_Local_Ccy}
                                        placeholder={"Calculation Pending...."}
                                        disabled={true}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowProviderPay}>
                                    Provider GROSS
                                    <NumberBox
                                        disabled={true}
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onContentReady={this.onContentReadyNumberBox}
                                        value={this.state.quickShiftFormData.provider_Pay_Total_Local_Ccy}
                                        placeholder={"Calculation Pending...."}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--wide" hidden={isHide}>
                                    {this.props.t("additionalCleaning.raiseForm.authorisedBy.label")}
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <TextBox
                                        onValueChanged={this.handleChangeAuthorisedBy}
                                        value={this.state.quickShiftFormData.requestApprover}
                                    ></TextBox>
                                </div>
                                <div className="card-form__field card-form__field--very-narrow" hidden={isShowRequestedOn}>
                                    Requested On
                                    <DateBox
                                        onValueChanged={this.handleChangeRequestedOn}
                                        value={
                                            this.state.quickShiftFormData.requestDate
                                                ? this.state.quickShiftFormData.requestDate
                                                : undefined
                                        }
                                        useMaskBehavior={true}
                                        displayFormat="dd/MM/yyyy"
                                    ></DateBox>
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    Originator
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <DropDownFromLookupTable
                                        lookupTypeIndex={LookupTypeIndexes.originator}
                                        onValueChanged={this.handleChangeOriginator}
                                        value={this.state.quickShiftFormData.originator && this.state.quickShiftFormData.originator.id ? this.state.quickShiftFormData.originator.id.toString() : ""}
                                        recordType="active"
                                    />
                                    <input
                                        data-testid={LookupTypeIndexes.originator}
                                        type="hidden"
                                        name={LookupTypeIndexes.originator}
                                        value={this.state.quickShiftFormData.originator &&
                                            this.state.quickShiftFormData.originator.id
                                            ? this.state.quickShiftFormData.originator.id
                                            : 0
                                        }
                                    ></input>
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    Update (original input was incorrect) vs. Correction (real external change)
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <SelectBox
                                        dataSource={this.updateCorrectionDataSource}
                                        displayExpr="value"
                                        valueExpr="id"
                                        onValueChanged={this.handleChangeCorrectionUpdate}
                                        value={
                                            this.state.quickShiftFormData.updateOrCorrection
                                        }
                                    />
                                </div>

                                <div className="card-form__field card-form__field--wide">
                                    Internal Comments
                                    <sup className="card-form__mandatory-symbol">*</sup>
                                    <TextArea
                                        height={100}
                                        value={this.state.quickShiftFormData.internalComments}
                                        onValueChanged={this.handleChangeInternalComments}
                                    />
                                </div>
                                <div className="card-form__field card-form__field--wide">
                                    External Comments
                                    <TextArea
                                        height={100}
                                        value={this.state.quickShiftFormData.comments}
                                        onValueChanged={this.handleChangeComments}
                                    />
                                </div>
                                {this.state.confirmationPanelVisible ? (
                                    <p className="card-form__confirmation">
                                        Your request has been submitted
                                    </p>
                                ) : (
                                    ""
                                )}
                                {this.state.errorPanelVisible ? (
                                    <ul className="card-form__errors">
                                        {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : ("")}

                                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            </section>
                            <section className="card-form__button-container">
                                {this.state.quickShiftFormData.billableItemId !== "0" ?
                                    <b> <div className="mx-4 mt-3">ID : {this.state.quickShiftFormData.billableItemId} {this.getCreatedAndUpdatedString()}</div> </b> : null}
                            </section>
                            <section className="card-form__button-container">
                                <div className="col-12 col-lg-4 col-xl-2">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.submitShift()
                                        }}
                                    >
                                        {buttonName}
                                    </button>
                                </div>
                                <div className="col-12 col-lg-4 col-xl-2">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onCancel();
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </section>
                        </div>
                    </form>
                </div>
                <Popup
                    visible={this.state.showCalculatedPopup}
                    onHiding={this.hideWarningPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title={"CONFIRM: Amounts Re-Calculated"}
                    resizeEnabled={true}
                    width={"55%"}
                    height={450}                        
                >
                    <ScrollView width='100%' height='100%'>
                        <div>
                            <div className="text-center card-form__subheading"><b>Amounts have been re-calculated as below - edit any changes required and click CONFIRM</b></div>
                        </div>                    
                        <div className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Provider NET
                            </h4>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                onValueChanged={this.handleChangeProviderNet}
                                valueChangeEvent="keyup"
                                value={this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy}
                                min={0}
                                max={9999.99}
                                step={0}
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Event NET
                            </h4>
                            <div className="row px-3 event-net-width">
                                <div className="col-12 col-xl-5 pl-0">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        onValueChanged={this.handleChangeEventNet}
                                        valueChangeEvent="keyup"
                                        value={this.state.quickShiftFormData.fee_Amount_Local_Ccy}
                                        min={0}
                                        max={9999.99}
                                        step={0}
                                        onContentReady={this.onContentReadyNumberBox}
                                    /> 
                                </div>
                                <div className="col-12 col-xl-7 pl-0 matching-error mt-0" hidden={!this.state.isPopupProviderNetChange}>
                                    <b>Provider NET has changed so the fee will be re-calculated afterwards unless over-typed</b>
                                </div>
                            </div>
                        </div>
                        <div className="card-form__field card-form__field--narrow">
                            <h4 className="card-form__subheading">
                                Client NET
                            </h4>
                            <NumberBox
                                format={{
                                    type: "currency",
                                    precision: 2,
                                    currency: "GBP",
                                }}
                                valueChangeEvent="keyup"
                                value={this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy}
                                min={0}
                                max={9999.99}
                                step={0}
                                readOnly={true}
                                placeholder={"Calculation Pending...."}
                                onContentReady={this.onContentReadyNumberBox}
                            />
                        </div>

                        <div className="row px-3">
                            <div className="col-12 col-xl-3 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onSubmit();
                                    }}
                                >
                                    CONFIRM
                                </button>
                            </div>
                            <div className="col-12 col-xl-3 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.hideWarningPopup();
                                    }}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div> 
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.showWarningPopup}
                    onHiding={this.hideWarningPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"WARNING: ZERO AMOUNTS"}
                    resizeEnabled={true}
                    width={"30%"}
                    height={300}                        
                >
                    <ScrollView width='100%' height='100%'>
                        <div>
                            <div className="text-center">Zero Amounts are present - please continue if expected otherwise please correct?</div>
                            <div className="text-center pt-3"><b>Provider Net</b> is {this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.provider_Pay_Amount_Local_Ccy}</b>}</div>
                            <div className="text-center pt-3"><b>Fee Net</b> is {this.state.quickShiftFormData.fee_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.fee_Amount_Local_Ccy}</b>}</div>
                            <div className="text-center pt-3"><b>Client Net</b> is {this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy == 0 ? <b className="color-red">£0.00</b> : <b>{this.state.quickShiftFormData.client_Billable_Amount_Local_Ccy}</b>}</div>
                        </div>
                        <div className="row px-3">
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.showCalculationPopup()
                                        }}
                                    >
                                        CONTINUE?
                                    </button>
                                </div>
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.hideWarningPopup();
                                        }}
                                    >
                                        CLOSE
                                    </button>
                                </div>
                            </div> 
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    commonDateSection = () =>{
        return (
            <div style={{ display: 'flex' }}>
                <div className="card-form__field card-form__field--very-narrow" style={{ marginRight: '20px' }}>
                    Work Date(s)
                    <sup className="card-form__mandatory-symbol">*</sup>
                    <DateBox
                        onValueChanged={this.handleChangeDate}
                        value={
                            this.state.quickShiftFormData.date
                                ? this.state.quickShiftFormData.date
                                : undefined
                        }
                        useMaskBehavior={true}
                        displayFormat="dd/MM/yyyy"
                    ></DateBox>
                </div>
                <div className="card-form__field card-form__field--very-narrow">
                    Date To
                    <sup className="card-form__mandatory-symbol">*</sup>
                    <DateBox
                        onValueChanged={this.handleChangeDateTo}
                        value={
                            this.state.quickShiftFormData.dateTo
                                ? this.state.quickShiftFormData.dateTo
                                : undefined
                        }
                        useMaskBehavior={true}
                        displayFormat="dd/MM/yyyy"
                        onContentReady={this.onContentReadyDateBox}
                    ></DateBox>
                </div>
            </div>
        )
    }

    renderServiceWisePart = () => {
        if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionPersonnel) {
            return (
                <>
                    <div className="card-form__field card-form__field--wide">
                        Date
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.quickShiftFormData.date
                                        ? this.state.quickShiftFormData.date
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>
                    </div>
                    {/* {this.commonDateSection()}  */}
                    <div className="card-form__field card-form__field--wide">
                        Start Time
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeBillableStart}
                                value={
                                    this.state.quickShiftFormData.billable_Start
                                        ? this.state.quickShiftFormData.billable_Start
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Finish Time
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeBillableEnd}
                                value={
                                    this.state.quickShiftFormData.billable_Finish
                                        ? this.state.quickShiftFormData.billable_Finish
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Rate
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRate}
                            valueChangeEvent="keyup"
                            value={
                                this.state.quickShiftFormData.rate_Decimal
                            }
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Name
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <TextBox
                            onValueChanged={this.handleChangeName}
                            value={
                                this.state.quickShiftFormData.name
                            }
                        ></TextBox>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Licence No.
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <TextBox
                            mask="0000-0000-0000-0000"
                            onValueChanged={this.handleChangeIdentificationNumber}
                            value={

                                this.state.quickShiftFormData.identification_Number
                            }
                        ></TextBox>
                    </div>
                </>
            )
        }
        else if (this.state.quickShiftFormData.serviceTypeId == ServiceType.Security && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.DoorSupervisionEquipment) {
            return (
                <>

                    {/* <div className="card-form__field card-form__field--wide">
                        Date From
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.quickShiftFormData.date
                                        ? this.state.quickShiftFormData.date
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Date To
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDateTo}
                                value={
                                    this.state.quickShiftFormData.dateTo
                                        ? this.state.quickShiftFormData.dateTo
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                                onContentReady={this.onContentReadyDateBox}
                            ></DateBox>
                        </div>
                    </div> */}
                    {this.commonDateSection()} 
                    <div className="card-form__field card-form__field--wide">
                        Quantity
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox

                            onValueChanged={this.handleChangeQuantity}
                            valueChangeEvent="keyup"
                            value={

                                this.state.quickShiftFormData.equipmentQuantity
                            }
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Rate
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRate}
                            valueChangeEvent="keyup"
                            value={
                                this.state.quickShiftFormData.rate_Decimal
                            }
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                </>
            )
        }
        else if (this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning && this.state.quickShiftFormData.serviceSubTypeId == SubServiceType.WeeklyCleaning) {
            return (
                <>
                    {/* <div className="card-form__field card-form__field--wide">
                        Date From
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.quickShiftFormData.date
                                        ? this.state.quickShiftFormData.date
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Date To
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDateTo}
                                value={
                                    this.state.quickShiftFormData.dateTo
                                        ? this.state.quickShiftFormData.dateTo
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                                onContentReady={this.onContentReadyDateBox}
                            ></DateBox>
                        </div>
                    </div> */}
                    {this.commonDateSection()} 
                    <div className="card-form__field card-form__field--wide">
                        Hours
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox
                            onValueChanged={this.handleChangeHours}
                            valueChangeEvent="keyup"
                            value={this.state.hours}
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Min
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <SelectBox
                            dataSource={minuteLookup}
                            displayExpr="value"
                            valueExpr="id"
                            onValueChanged={this.handleChangeMinutes}
                            value={
                                this.state.minutes
                            }
                        />
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Rate
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRate}
                            valueChangeEvent="keyup"
                            value={
                                this.state.quickShiftFormData.rate_Decimal
                            }
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                </>
            )
        }
        else if (this.state.quickShiftFormData.serviceTypeId == serviceTypes.Entertainment) {            
            return (
                <>
                    <div className="card-form__field card-form__field--wide">
                        Date
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                onValueChanged={this.handleChangeDate}
                                value={
                                    this.state.quickShiftFormData.date
                                        ? this.state.quickShiftFormData.date
                                        : undefined
                                }
                                useMaskBehavior={true}
                                displayFormat="dd/MM/yyyy"
                            ></DateBox>
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Start Time
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeShiftStart}
                                value={
                                    this.state.quickShiftFormData.shift_Start
                                        ? this.state.quickShiftFormData.shift_Start
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Finish Time
                        <div className="col-12 col-xl-6 pl-0">
                            <DateBox
                                type="time"
                                interval={15}
                                placeholder="00:00"
                                useMaskBehavior={true}
                                displayFormat={"HH:mm"}
                                onValueChanged={this.handleChangeShiftEnd}
                                value={
                                    this.state.quickShiftFormData.shift_End
                                        ? this.state.quickShiftFormData.shift_End
                                        : undefined
                                }
                                onContentReady={this.onContentReadyDateBox}
                            />
                        </div>
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Rate
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <NumberBox
                            format={{
                                type: "currency",
                                precision: 2,
                                currency: "GBP",
                            }}
                            onValueChanged={this.handleChangeRate}
                            valueChangeEvent="keyup"
                            value={
                                this.state.quickShiftFormData.rate_Decimal
                            }
                            onContentReady={this.onContentReadyNumberBox}
                            step={0}
                        />
                    </div>
                    <div className="card-form__field card-form__field--wide">
                        Additional Information
                        <sup className="card-form__mandatory-symbol">*</sup>
                        <TextBox
                            onValueChanged={this.handleChangeAdditionalInfo}
                            value={
                                this.state.quickShiftFormData.additionalInformation
                            }
                        ></TextBox>
                    </div>
                </>
            )
        }
    }

    renderServiceWiseType = () => {
        let isDisableOfferingsType: boolean = true;
        if ((this.state.offeringsTypeLookup && this.state.offeringsTypeLookup.length > 0) && this.state.quickShiftFormData.serviceSubTypeId) {
            isDisableOfferingsType = false;
        }
        if (this.state.quickShiftFormData.serviceTypeId == ServiceType.ContractCleaning || this.state.quickShiftFormData.serviceTypeId == serviceTypes.WindowCleaning || this.state.quickShiftFormData.serviceTypeId == serviceTypes.Gardening) {
            return (
                <div className="card-form__field card-form__field--wide">
                    Type
                    <SelectBox
                        dataSource={this.state.offeringsTypeLookup}
                        displayExpr={"value"}
                        valueExpr={"id"}
                        onValueChanged={this.handleChangeTypeSelect}
                        value={this.state.quickShiftFormData.typeId}
                        disabled={isDisableOfferingsType}
                    />
                </div>
            )
        }
        else if (this.state.quickShiftFormData.serviceTypeId == serviceTypes.Entertainment) {            
            return (
                <>
                    <div className="card-form__field card-form__field--wide">
                        Type
                        <sup className="card-form__mandatory-symbol" hidden={isDisableOfferingsType}>*</sup>
                        <SelectBox
                            dataSource={this.state.offeringsTypeLookup}
                            displayExpr={"value"}
                            valueExpr={"id"}
                            onValueChanged={this.handleChangeTypeSelect}
                            value={this.state.quickShiftFormData.typeId}
                            disabled={isDisableOfferingsType}
                        />
                    </div>
                </>
            )
        }
    }

    //#region render Component
    render() {
        return (
            <LightModePageTemplate>
                { this.props.navigateFrom !== "queryGrid" ? 
                    <SideBarTemplate isFixedDesktopOnly={true}>
                        <BulletinPanel />
                    </SideBarTemplate>
                    : null
                }                
                { this.state.quickShiftFormData.itemTypeId != "10" && this.props.navigateFrom == "queryGrid" ? 
                    <AddEditFormV2 location={this.props.location} history={this.props.history} billableItemId={this.props.billableItemId ? this.props.billableItemId : "0"} hidePopup={this.hidePopup}></AddEditFormV2>
                    : this.renderCommonPart() 
                }
            </LightModePageTemplate>
        );
    }
}

export default withTranslation()(QuickAddShiftForm);
