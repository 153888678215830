import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { LoadPanel } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { faTh } from "@fortawesome/pro-light-svg-icons";
import billingFormUtils,{documentObject} from "../Billing/BillingFormUtils";

interface ClientGrossActionCellProps {
    data: any;
}

interface ClientGrossActionCellState {
    filetype: string;
    loadPanelVisible: boolean;
    downloadLink: string;
}

class ClientGrossActionCell extends React.Component<ClientGrossActionCellProps> {
    state: ClientGrossActionCellState;
    service: ManageDocumentsGridService;
    sharedUtils: sharedUtils;
    billingFormUtils:billingFormUtils;
    constructor(props: ClientGrossActionCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
            downloadLink: ""
        };
        this.service = new ManageDocumentsGridService();
        this.sharedUtils = new sharedUtils();
        this.billingFormUtils = new billingFormUtils();
    }

    onDownloadClick = (link : string , id : string) => {
        this.setState({
            loadPanelVisible: true,
            downloadLink: link
        });
        this.service
            .getDocumentById(id)
            .then(this.handleDownloadSuccess)
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        let link = this.state.downloadLink.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    renderInvoiceDownloadButton = () => {
        if (this.props.data.data.documents) {
            if (this.props.data.data.documents.length > 0) {
                if (
                    this.props.data.data.documents[0].link &&
                    this.props.data.data.documents[0].documentType == "Invoice"
                ) {
                    return (
                        <div className="icon-btn">
                            <FontAwesomeIcon icon={faDownload} onClick={() => this.onDownloadClick(this.props.data.data.documents[0].link , this.props.data.data.documents[0].id)} />
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        </div>
                    );
                }
            }
        }
    }

    renderBreakdownButton() {
        if (this.props.data.data.documents) {
            if (this.props.data.data.documents.length > 0) {
                if (
                    this.props.data.data.documents[1].link &&
                    this.props.data.data.documents[1].documentType == "InvoiceBreakdown"
                ) {
                    return (
                        <div className="icon-btn">
                            <FontAwesomeIcon icon={faTh} onClick={() => this.onDownloadClick(this.props.data.data.documents[1].link , this.props.data.data.documents[1].id)} />
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        </div>
                    );
                }
            }
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                {this.sharedUtils.thousandsSeparator(this.props.data.data.total)}
                {this.renderInvoiceDownloadButton()}
                {this.renderBreakdownButton()}
            </div>
        );
    }
}

export default ClientGrossActionCell;
